<template>
  <div class="user_editName">
    <div class="edit_warp">
      <div class="edit_head">
        <span>{{ $t('main.head.editNickName') }}</span>
        <span class="cursor"
          ><i
            style="fontsize: 12px; color: rgba(0, 0, 0, 0.45)"
            class="iconfont icon-close"
            @click="hide"
            id="close_amend_nickname_btn"
          ></i
        ></span>
      </div>
      <div class="edit_name_form">
        <div class="form_item">
          <div class="form_item_input">
            <input
              type="text"
              v-model="loginMsg.name"
              id="amend_nickname_input_"
            />
          </div>
          <div class="edit_name_sure">
            <div
              class="edit_name_sureBtn cursor"
              @click="editName"
              id="sure_amend_nickname_btn"
            >
              {{ $t('common.confirm') }}
            </div>
            <div
              class="edit_name_cancelBtn cursor"
              @click="hide"
              id="cancel_amend_nickname_btn"
            >
              {{ $t('common.cancel') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'

import commonFun from '../util/commonFun'

export default {
  data() {
    return {
      loginMsg: {
        name: '',
      },
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    ...mapMutations(['setEditNamevisible']),
    hide(e) {
      if (e.currentTarget == e.target) {
        this.setEditNamevisible(false)
      }
    },
    editName() {
      let key = {
        name: this.loginMsg.name,
        logo_url: this.userInfo.logo_url,
      }
      this.$token('/update_user_info', key, (res) => {
        if (res.status == 1) {
          this.$token('/get_user_info', key, (res) => {
            this.$store.commit('setUserInfo', res.data)
          })
          this.$message({
            message: this.$t('common.saveSuccess'),
            type: 'success',
          })
          this.setEditNamevisible(false)
        }
      })
    },
  },
  mounted() {
    if (this.userInfo.hasOwnProperty('id')) {
      this.loginMsg.name = this.userInfo.name
    }
  },
}
</script>
<style lang="less" scoped>
.user_editName {
  position: fixed;
  z-index: 2003;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  .cursor {
    cursor: pointer;
    user-select: none;
  }
  .edit_warp {
    position: relative;
    top: 50%;
    width: 380px;
    height: 190px;
    margin: 0 auto;
    padding-bottom: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    background-color: #fff;
    border-radius: 4px;
    transform: translateY(-50%);
    > div {
      padding: 0 24px;
    }
    .edit_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      span {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .edit_name_form {
      padding: 24px 24px 0;
      color: #333;
      .form_item {
        .form_item_input {
          display: flex;
          align-items: center;
          height: 40px;
          background: #ffffff;
          border: 1px solid #e5e5e5;
          border-radius: 4px;
          input {
            width: 90%;
            text-indent: 16px;
            border: none;
            outline: none;
          }
        }
        .edit_name_sure {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          height: 52px;
          margin-top: 20px;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          border-top: 1px solid rgba(0, 0, 0, 0.06);
          .edit_name_cancelBtn {
            width: 65px;
            height: 32px;
            margin-right: 8px;
            line-height: 32px;
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 2px;
          }
          .edit_name_sureBtn {
            width: 65px;
            height: 32px;
            color: #ffffff;
            line-height: 32px;
            background: #18a8c7;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 2px;
          }
        }
      }
    }
  }
}
</style>
