<template>
  <div class="bluen_userInfo">
    <div class="userInfo_title_box">
      <div
        class="user_logoUrl"
        @mouseenter="showLoginImg = false"
        @mouseleave="showLoginImg = true"
      >
        <!-- <img :src="userInfo.logo_url" alt="头像" /> -->
        <img :src="userInfo.logo_url || defaultLogoUrl" alt="头像" />
        <div v-show="!showLoginImg">
          <i class="iconfont icon-edit_02"></i>
          <input
            type="file"
            name="file"
            accept="image/*"
            ref="file"
            @change="editImg()"
          />
        </div>
      </div>
      <div class="user_name_phone">
        <div class="name">{{ userInfo.name }}</div>
        <div class="phone">{{ userInfo.phone }}</div>
      </div>
    </div>
    <div class="user_version" v-if="!isFree && userInfo?.platform != 22">
      <div class="version">
        <div class="version_name">{{ userInfo.edition }}</div>
        <!-- <div class="version_dis"></div> -->
      </div>
      <div
        class="change_version_btn cursor"
        @click="toChangeVersion"
        id="immediate_upgrade_btn"
      >
        {{ $t('main.head.upgrade') }}
      </div>
    </div>
    <div class="edit_funs">
      <div class="edit_name">
        <span>{{ userInfo.name }}</span>
        <span
          class="text_color18A8C7 cursor"
          @click="editName"
          id="amend_nickname_btn"
          >{{ $t('main.head.editNickName') }}</span
        >
      </div>
      <div v-if="!isFree" class="edit_pwd cursor" @click="editPwd">
        <span>{{ $t('main.head.editPassword') }}</span>
        <span><i class="iconfont icon-right"></i></span>
      </div>
    </div>
    <div v-if="!isFree" class="login_out cursor" @click="loginOut">
      {{ $t('main.head.logout') }}
    </div>
  </div>
</template>

<script>
import OSS from 'ali-oss'
import { mapMutations, mapState } from 'vuex'

import commonFun from '../util/commonFun'

export default {
  data() {
    return {
      showLoginImg: true,
      defaultLogoUrl: require('@/assets/logo_url.png'),
    }
  },
  computed: {
    ...mapState(['userInfo']),
    isFree() {
      // return String(this.userInfo.edition).includes('免费')
      return (
        this.$store.state.thirdPlankData ||
        window.sessionStorage.getItem('thinkerx_material')
      )
    },
  },
  methods: {
    ...mapMutations(['setEditPwdvisible', 'setEditNamevisible']),
    // hidePerson(){
    // this.$emit('hide')
    //     this.$store.commit('setShowPerson',false)
    // },
    // dev(){
    //     this.$message({
    //         message:'开发中',
    //         duration:1500,
    //     })
    // },
    toChangeVersion() {
      window.open('https://www.eggrj.com/purchase.html')
    },
    loginOut() {
      sessionStorage.removeItem('first') //删除登录标志变量
      let key = {
        token: sessionStorage.getItem('token'),
        refresh_token: sessionStorage.getItem('refresh_token'),
      }
      let RSAKey = commonFun.calcKeyByRSA(key)
      this.$post('/logout', key, () => {}, { sign: RSAKey })
      for (let key in sessionStorage) {
        sessionStorage.removeItem(key)
      }
      this.$store.commit('clearAllInfo')
      this.$router.push({
        path: '/login',
        // query: {
        //     pageTo: this.$route.fullPath
        // }
      })
    },
    editPwd() {
      this.setEditPwdvisible(true)
      this.$store.commit('setShowPerson', false)
    },
    editName() {
      this.setEditNamevisible(true)
      this.$store.commit('setShowPerson', false)
    },
    editImg() {
      let inputfile = this.$refs.file.files[0]
      let time = new Date()
      let name = '/bluenUserImg/' + time.getTime() + '_' + inputfile.name
      this.$getByToken('/get_oss_token', {}, (res) => {
        if (res.status == 1) {
          let client = new OSS({
            region: 'oss-cn-hangzhou',
            accessKeyId: res.data.AccessKeyId,
            accessKeySecret: res.data.AccessKeySecret,
            stsToken: res.data.SecurityToken,
            bucket: 'eggrj',
          })
          client
            .multipartUpload(name, inputfile)
            .then((result) => {
              let url = 'https://eggrj.oss-cn-hangzhou.aliyuncs.com' + name
              if (result.res.status === 200) {
                this.saveImg(url)
              }
            })
            .catch((err) => {
              console.error(err)
              this.$message({
                type: 'error',
                message: '上传失败!',
              })
            })
        }
      })
    },
    saveImg(url) {
      let obj = {
        name: this.userInfo.name,
        logo_url: url,
      }
      this.$token('/update_user_info', obj, (res) => {
        if (res.status == 1) {
          this.$token('/get_user_info', obj, (res) => {
            this.$store.commit('setUserInfo', res.data)
          })
          this.$message({
            type: 'success',
            message: '保存成功!',
            duration: 1500,
            offset: 60,
          })
        }
      })
    },
  },
  components: {},
}
</script>

<style lang="less" scoped>
.bluen_userInfo {
  z-index: 2003;
  width: 260px;
  // height: 290px;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  position: absolute;
  right: 0;
  top: 60px;
  .cursor {
    cursor: pointer;
    user-select: none;
  }
  .userInfo_title_box {
    width: 100%;
    height: 82px;
    background-image: url('../assets/image/user_title_bg.png');
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    color: #fff;
    font-size: 14px;
    .user_logoUrl {
      width: 47px;
      height: 47px;
      overflow: hidden;
      border-radius: 50%;
      position: relative;
      input {
        position: absolute;
        z-index: 1;
        cursor: pointer;
        opacity: 0;
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
      }
      > div {
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(4, 4, 4, 0.52);
      }
      .icon-edit_02 {
        cursor: pointer;
      }
    }
    .user_name_phone {
      margin-left: 10px;
      .name {
        font-size: 16px;
      }
      .phone {
        color: #caf5ff;
      }
    }
  }
  .user_version {
    border-bottom: 1px solid #d8d8d8;
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    color: #333;
    .version {
      .version_name {
        font-size: 16px;
      }
    }
    .change_version_btn {
      width: 76px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      font-weight: 400;
      color: #563f31;
      text-align: center;
      background: linear-gradient(109deg, #f8d7be 0%, #f5be93 100%);
      box-shadow: 0px 3px 5px 0px rgba(245, 192, 150, 0.47);
      border-radius: 15px;
    }
  }
  .edit_funs {
    color: #333;
    > div {
      padding: 0 16px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        background-color: #e9f8fb;
      }
      &.edit_name {
        .text_color18A8C7 {
          color: #18a8c7;
        }
      }
      &.edit_pwd {
        user-select: none;
      }
    }
  }
  .login_out {
    height: 46px;
    line-height: 46px;
    text-align: center;
    color: #333;
    border-top: 1px solid #d8d8d8;
  }
}
</style>
