import { httpRequest } from '@/apis/request'
import type { PlankTexture } from '@/partTypes'
import { request } from '@/util/vueRequest.js'

import type {
  IGeKnivesDataType,
  IGetExcelDataParams,
  IGetPaibanParams,
} from './types'

enum Apis {
  getpaiban = '/get_paiban',
  allKnifes = '/all_bluen_knives',
  historyName = '/update_material_name',
  uploadTrialImage = 'production/trail/image',
  trialProduction = 'production/trail',
  trialRecode = 'production/trail/record',
  electronicSaw = 'electronic_saw',
  workshopFiles = 'workshop/files',
  produceStatus = 'orders/produce_status',
  useSurplus = 'use_surplus',
  excelData = 'production_tracking',
  sendToQianliyan = 'plank_num',
  deductBaseMaterial = 'original_plank ',
  isSingleOrder = 'order_room_status',
  asyncPaiban = 'async_paiban',
  searchSurplusByTexture = 'search_surplus_depot',
  edgeWaste = 'edge_waste',
  holeSlotShunt = 'hole_slot_shunt',
}

export function getExcelData(data: IGetExcelDataParams) {
  return httpRequest.post(Apis.excelData, data)
}

export function getPaiban(data: IGetPaibanParams) {
  return request({
    url: Apis.getpaiban,
    method: 'POST',
    data,
  })
}

export function getAllKnifes(params: { setting_id: number }) {
  return request<IGeKnivesDataType>({
    url: Apis.allKnifes,
    method: 'get',
    params,
  })
}

// 传递订单信息至老板良
export function saveOrderInfoToLbl(params: any) {
  return request.post(
    `${process.env.VUE_APP_TOOLS_URL}/gg_erp/materials/erp/batch`,
    params
  )
}

/**
 * 修改保存历史名称
 * @param params 请求参数
 * @returns
 */
export function editHistoryName(params: { id: number; name: string }) {
  return request.put(Apis.historyName, params)
}
export function checkTemplateStatus(params: {
  process_id: number
  origin: string
}) {
  return httpRequest.get(Apis.trialProduction, params)
}

export function uploadProductionTrialImage(data: {
  process_id: number
  image_url: string
  origin: string
}) {
  return httpRequest.post(Apis.uploadTrialImage, data)
}

export function uploadTrialRecord(data: {
  process_id: number
  process_name: string
  from: string
  produce_file_url: string
}) {
  return httpRequest.post(Apis.trialRecode, data)
}

export function electronicSaw(payload: {
  uid: number
  process_id: number
  layoutData: any[]
}) {
  return httpRequest.post(Apis.electronicSaw, payload)
}

export function workshopFiles(payload: any) {
  return httpRequest.post(Apis.workshopFiles, payload)
}
// 获取标示和已生产状态
// data: any
export function getProucePopupState(data: { order_ids: string[] }) {
  return httpRequest.post(Apis.produceStatus, data)
}
interface ProduceStatusData {
  order_ids: string[]
  mark_action: 0 | 1
}
export function updateProduceStatus(data: ProduceStatusData) {
  return httpRequest.put(Apis.produceStatus, data)
}

export function useSurplus(payload: any[]) {
  return httpRequest.post(Apis.useSurplus, { surplus_list: payload })
}

// 用料信息转发给千里眼
export function fetchSendUseMaterialData(
  payload: Record<string, Record<string, any>>
) {
  return httpRequest.post(Apis.sendToQianliyan, payload)
}

// 扣减原片(柜柜账号体系下)
export function fetchDeductBaseMaterial(
  payload: Record<string, Record<string, any>>
) {
  return httpRequest.post(Apis.deductBaseMaterial, payload)
}

// 扣减原片(柜柜账号体系下)
export function fetchIsSingleOrder(payload: {
  order_id: string | number
  room_ids: string[]
}) {
  return httpRequest.post(Apis.isSingleOrder, payload)
}

/**
 * 异步排版接口
 * @param data 参数同之前的 get_paiban 接口相同
 * @link https://api.thinkerx.com/web/#/p/cb0f631bf991a122ec7fde7925bc112e
 */
export function asyncPaiban(data: IGetPaibanParams) {
  const otherPlateSize =
    JSON.parse(data.data.layout_data).layoutConfig.otherPlateSize ?? []
  return new Promise(async (resolve) => {
    const res = await httpRequest.post(Apis.asyncPaiban, data)
    resolve({ ...res, otherPlateSize })
  })
}

/**
 * 获取异步排版的排版结果
 * @param payload 包含 task_id 和 超大板信息
 * @returns 排版结果
 */
export function fetchAsyncPaiban(payload: {
  task_id: number
  otherPlateSize: any
}) {
  return new Promise(async (resolve) => {
    const res = await httpRequest.post(
      `${Apis.asyncPaiban}/${payload.task_id}`,
      payload
    )
    resolve({
      ...res,
      otherPlateSize: payload.otherPlateSize,
    })
  })
}

/**
 *
 * @param payload 需要匹配的余料信息
 * @returns
 */
export function fetchSurplusByTexture(payload: PlankTexture[]) {
  return httpRequest.post(Apis.searchSurplusByTexture, {
    match_list: payload,
    limit: 9999,
    page: 1,
  })
}

export function getEdgeWasteSetting() {
  return httpRequest.get(Apis.edgeWaste)
}

export function dealHoleSlotShunt(payload: any) {
  return httpRequest.post(Apis.holeSlotShunt, payload)
}
