import LockedImg from '@/assets/jiesuo.png'
import UnLockedImg from '@/assets/suoding.png'

export enum DrawPlankColor {
  /** 活跃板件背景色 */
  active = '#94E2AD',
  /** 普通板件背景色 */
  normal = 'rgba(238, 238, 161, 0.8)',
  /** 板件冲突背景色 */
  plankMerge = '#F008',
  /** 孔槽冲突背景色 */
  holeSlotMerge = '#F808',
  /** 余料板件背景色 */
  surplus = 'rgba(255, 200, 200, 0.8)',
  /** 夹手背景色 */
  clampHand = 'rgba(170, 170, 170)',
}

/** 大板的绘制参数包含单个以及多个大板共存的参数 */
export enum DrawBigPlankParams {
  /** 板件正常边框宽度 */
  normalMarginStroke = 1,
  /** 板件活跃边框宽度 */
  activeMarginStroke = 2,
  /** 板件正常边框颜色 */
  normalMarginStrokeColor = 'rgba(0,0,0,0.3)',
  /** 板件活跃边框颜色 */
  activeMarginStrokeColor = '#f00',
  /** 边框和大板的间距 */
  margin = 5,
  /** 大板背景色 */
  plankBgColor = '#ECECEC',
  /** 大板背景色 2 */
  plankBgColor2 = '#eee',
  /** 两个大板之间的横向间距 */
  plankMarginX = 76,
  /** 两个大板之间的纵向间距 */
  plankMarginY = 65,
  /** 切换按钮高度 */
  toggleHeight = 33,
  /** 每行第一张大板的距左边距离 */
  plankMarginLeft = 65,
  /** 首张大板的距上边距离 */
  plankMarginTop = 33,
  /** 画布最后需要的额外距离 */
  canvasExtraMargin = 230,
  /** 顶部文字信息 单行默认高度 */
  topInfoHeight = 20,
  /** 顶部余料信息圆形半径 */
  topInfoSurplusCircleR = 10,
  /** 顶部文字信息起始的一个默认间距(第一行文字和大板边框之间的间隙) */
  topInfoMargin = 3,
  /** 顶部文字信息每一行之间的间隙 */
  topInfoLineGap = 1,
  /** 顶部文字信息文字颜色 */
  topInfoTextColor = '#333',
}

/** js文件使用 */
export const DrawBigPlankParamsJs = {
  /** 板件正常边框宽度 */
  normalMarginStroke: DrawBigPlankParams.normalMarginStroke,
  /** 板件活跃边框宽度 */
  activeMarginStroke: DrawBigPlankParams.activeMarginStroke,
  /** 板件正常边框颜色 */
  normalMarginStrokeColor: DrawBigPlankParams.normalMarginStrokeColor,
  /** 板件活跃边框颜色 */
  activeMarginStrokeColor: DrawBigPlankParams.activeMarginStrokeColor,
  /** 边框和大板的间距 */
  margin: DrawBigPlankParams.margin,
  /** 大板背景色 */
  plankBgColor: DrawBigPlankParams.plankBgColor,
  /** 大板背景色 2 */
  plankBgColor2: DrawBigPlankParams.plankBgColor2,
  /** 两个大板之间的横向间距 */
  plankMarginX: DrawBigPlankParams.plankMarginX,
  /** 两个大板之间的纵向间距 */
  plankMarginY: DrawBigPlankParams.plankMarginY,
  /** 切换按钮高度 */
  toggleHeight: DrawBigPlankParams.toggleHeight,
  /** 每行第一张大板的距左边距离 */
  plankMarginLeft: DrawBigPlankParams.plankMarginLeft,
  /** 每列第一张大板的距上边距禂 */
  plankMarginTop: DrawBigPlankParams.plankMarginTop,
  /** 画布最后需要的额外距禂 */
  canvasExtraMargin: DrawBigPlankParams.canvasExtraMargin,
  /** 顶部文字信息 单行默认高度 */
  topInfoHeight: DrawBigPlankParams.topInfoHeight,
  /** 顶部余料信息圆形半径 */
  topInfoSurplusCircleR: DrawBigPlankParams.topInfoSurplusCircleR,
  /** 顶部文字信息起始的一个默认间距(第一行文字和大板边框之间的间隙) */
  topInfoMargin: DrawBigPlankParams.topInfoMargin,
  /** 顶部文字信息每一行之间的间隙 */
  topInfoLineGap: DrawBigPlankParams.topInfoLineGap,
  /** 顶部文字信息文字颜色 */
  topInfoTextColor: DrawBigPlankParams.topInfoTextColor,
}

/** js文件使用 */
export const DrawPlankColorJs = {
  /** 活跃板件背景色 */
  active: DrawPlankColor.active,
  /** 普通板件背景色 */
  normal: DrawPlankColor.normal,
  /** 板件冲突背景色 */
  plankMerge: DrawPlankColor.plankMerge,
  /** 孔槽冲突背景色 */
  holeSlotMerge: DrawPlankColor.holeSlotMerge,
  /** 余料板件背景色 */
  surplus: DrawPlankColor.surplus,
}

/**
 * @description 创建用于大板绘制锁定的图片元素，需要提前生成
 */
function createBigPlankLockElement(type: 'lock' | 'unlock') {
  return new Promise<HTMLImageElement>((resolve) => {
    const image = new Image()
    image.src = type === 'lock' ? LockedImg : UnLockedImg
    image.onload = () => {
      resolve(image)
    }
  })
}

/** 用于绘制板件的锁定标志的元素 */
export const bigPlankLockImage: {
  lock?: HTMLImageElement
  unlock?: HTMLImageElement
} = {}

/**
 * @description 初始化一些公共数据
 */
export async function commonDataInit() {
  bigPlankLockImage.lock = await createBigPlankLockElement('lock')
  bigPlankLockImage.unlock = await createBigPlankLockElement('unlock')
}
