// 开料清单使用的文字映射
export const languageMap = new Map([
  ['翻译错误', 'common.default'],
  ['柜体', 'common.loc'],
  ['正在处理并发送NC文件......', 'materialPage.sendLoadingText'],
  ['共计', 'materialPage.arrangeLoading'],
  ['张小板正在优化排版中......', 'materialPage.arrangeLoading2'],
  ['发至车间', 'arrangedPage.sendToFactory.title'],
  ['暂无余料可用,你可以先去', 'materialPage.arrangeLoading2'],
  ['开始排版', 'materialPage.startArrange'],
  ['导入开料清单', 'materialPage.importMaterial'],
  ['保存至历史', 'materialPage.saveToHistory'],
  ['添加板件', 'materialPage.addPlank'],
  ['删除板件', 'materialPage.deletePlank'],
  ['批量修改', 'materialPage.batchEdit'],
  ['更多设置', 'materialPage.moreSetting'],
  ['未进行排版, 无法查看!', 'materialPage.checkErr'],
  ['没有可以保存的信息', 'materialPage.noCanSaveInfo'],
  ['删除成功', 'common.deleteSuccess'],
  ['查看排版', 'materialPage.checkArranged'],
  ['下载样表', 'materialPage.downloadSheet'],
  ['下载样表（展示科学计数法）', 'materialPage.downloadSheetShowScientific'],
  ['样式一', 'materialPage.style1'],
  ['样式二', 'materialPage.style2'],
  ['样式三', 'materialPage.style3'],
  ['导出板件孔槽图', 'materialPage.exportHoleSlotPDF'],
  ['料单导入不覆盖', 'materialPage.importNoCover'],
  ['板件数据默认收起', 'materialPage.defaultFold'],
  ['开启补件推荐', 'materialPage.openBujianPlankRecommend'],
  ['开启待排版小板推荐', 'materialPage.openAwaitStorePlankRecommend'],
  ['开启余料大板推荐', 'materialPage.openSurplusPlankRecommend'],
  ['存入待排版库', 'materialPage.pushToAwaitStore'],
  ['设置开料清单表头', 'materialPage.setTableHead'],

  ['板件尺寸', 'materialPage.plankSize'],
  ['搜索板件名称/板号', 'materialPage.searchPlaceholder'],
  [
    '可用余料共 {total} 种，已选 {quantity} 种',
    'materialPage.chooseSurplusQuantity',
  ],
  ['选择', 'common.select'],
  ['L形', 'common.Ltype'],
  ['矩形', 'common.rect'],
  ['暂无可用余料，您可以先去', 'materialPage.surplusTip'],
  ['新建余料', 'materialPage.creatSurplus'],
  ['订单信息', 'materialPage.orderInfo'],
  ['数据正在加载中......', 'common.dataLoading'],
  ['添加板件', 'materialPage.add.title'],
  ['板号', 'common.plankNo'],
  ['板件名称', 'common.plankName'],
  ['材质', 'common.matCode'],
  ['按回车自动创建材质', 'materialPage.enterAutoCreateMatCode'],
  ['高光板', 'common.heighGlossPlank'],
  ['非高光', 'common.notHeighGlossPlank'],
  ['颜色', 'common.color'],
  ['按回车将自动创建色卡', 'surplusPage.autoCreateColor'],
  ['厚度', 'common.thick'],
  ['成品长度', 'common.finishedHeight'],
  ['成品宽度', 'common.finishedWidth'],
  ['数量', 'common.count'],
  ['纹理', 'common.texture'],
  ['前封边', 'common.edge.front'],
  ['后封边', 'common.edge.back'],
  ['左封边', 'common.edge.left'],
  ['右封边', 'common.edge.right'],
  ['板件备注', 'common.plankRemark'],
  ['取消', 'common.cancel'],
  ['确定', 'common.confirm'],
  ['确定并继续', 'common.saveAndContinue'],
  ['温馨提示', 'common.warmTip'],
  [
    '检测到有小板尺寸过长，导致无法排版！请调整小板尺寸或添加对应尺寸原片',
    'materialPage.overSize.warning',
  ],
  ['查看板件详情', 'materialPage.overSize.checkInfo'],
  ['添加原片', 'materialPage.overSize.addPlank'],
  ['无法加工的板件详情', 'materialPage.overSize.nonWorkInfo'],
  ['返回', 'common.return'],
  ['上一个', 'common.previous'],
  ['下一个', 'common.next'],
  ['板件超尺', 'materialPage.overSize.title'],
  ['超尺板件清单', 'materialPage.overSize.subTitle'],
  ['超尺板件提示', 'materialPage.overSize.overSizeTip'],
  ['复制{name}数据成功', 'materialPage.copySuccess'],
  [
    '系统检查到排版小板中存在超尺板件，下面是特殊板材添加建议，可以手动修改板材长宽后点击表格下方',
    'materialPage.overSize.tipContent',
  ],
  ['未查询到相关板件!请确认是否绘制板件!', 'materialPage.plankNotFound'],
  ['板件材质', 'common.plankMatCode'],
  ['板件厚度', 'common.plankThick'],
  ['板件颜色', 'common.plankColor'],
  ['板件长度', 'common.plankHeight'],
  ['板件宽度', 'common.plankWidth'],
  ['操作', 'common.operation'],
  ['删除', 'common.delete'],
  ['一键添加', 'materialPage.overSize.add'],
  ['板件内部数据存在异常，无法进行排版生产！', 'materialPage.arrangeErr'],
  ['同步', 'common.sync'],
  [
    '柜柜与云排版原片数据不一致！是否同步为柜柜原片数据？',
    'materialPage.syncConfirm',
  ],
  ['注：可在原片管理中进行手动同步', 'materialPage.handleSync'],
  ['不再提示', 'materialPage.noMoreTip'],
  ['保存', 'common.save'],
  ['保存方案', 'materialPage.save.title'],
  ['历史方案', 'materialPage.save.historyName'],
  ['已自动保存至历史!', 'materialPage.autoSaveSuccess'],
  ['保存成功!', 'materialPage.save.successTip'],
  ['(注：历史仅保存一年)', 'materialPage.save.subTitle'],
  ['方案名称', 'materialPage.save.name'],
  ['暂无板件,无法进行导出', 'materialPage.exportErr'],
  ['请先至少选中一个板件再进行此操作!', 'materialPage.delete.errTip'],
  ['提示', 'common.tip'],
  ['排版超时, 是否重新排版', 'common.timeOut'],
  ['重新排版', 'arrangedPage.reArrange'],
  ['系统提示', 'common.sysTip'],
  ['未查询到相关数据!', 'materialPage.noCheckData'],
  [
    '是否确认将所选板件添加到待排版库?是否确认将所选板件添加到待排版库?',
    'materialPage.isConfirmPushToAwaitStore',
  ],
  ['是否创建对应的任务卡片?', 'materialPage.createTaskCard'],

  ['横纹', 'common.reverse'],
  ['竖纹', 'common.normal'],
  ['无纹理', 'common.notCare'],
  ['项目地址', 'common.orderAddress'],
  ['房间名', 'common.roomName'],
  ['下料长度', 'common.cutHeight'],
  ['下料宽度', 'common.cutWidth'],
  ['标签条码', 'common.plankNum'],
  ['请至少选择一块板件', 'common.pleaseSelectPlank'],
  ['入库失败', 'arrangedPage.awaitPlankStore.pushErr'],
  ['入库成功', 'arrangedPage.awaitPlankStore.pushSuccess'],
  ['前往添加', 'materialPage.goAdd'],
  ['无法直接查看排版方案，请点击开始排版', 'materialPage.noArrangeTip'],
  ['长度', 'common.long'],
  ['宽度', 'common.width'],
  ['材料', 'common.matCode2'],
  ['封边信息', 'common.edge.info'],
  ['名称', 'common.name'],
  ['客户', 'common.customer'],
  ['房间', 'common.room'],
  ['门板', 'common.isDoorPlank'],
  ['非门板', 'common.notDoorPlank'],
  ['异形', 'common.isSpecial'],
  ['非异形', 'common.notSpecial'],
  ['孔槽数量', 'common.holeSlotsStastic'],
  ['请选择', 'common.pleaseChoose'],
  ['未找到板件名称', 'materialPage.noPlankName'],
  ['板件数据错误，请仔细检查，避免生产损失', 'materialPage.plankDataErr'],
  ['请至少选中一个板件再进行排版', 'materialPage.delete.errTip'],
  ['删除该数据后不可恢复，是否确认删除该数据？', 'materialPage.delete.tip'],
  ['同步失败', 'basePlankManager.syncErr'],
  ['警告', 'common.warning'],
  ['五六面钻设置过老', 'materialPage.tooOld'],
  ['请联系售后人员重新对接', 'materialPage.contactUs'],
  ['板件内部存在异常数据,板号', 'materialPage.pathErr'],
  ['存在过大板件', 'materialPage.sizeErr'],
  ['未设置下料刀直径, 请前往雕刻机设置刀具!', 'materialPage.noKnife'],
  ['发送至车间成功!', 'materialPage.sendFactorySuccess'],
  ['发送车间失败，请联系售后人员请求帮助!', 'materialPage.sendFactoryErr'],
  ['请填写板件的长宽！', 'materialPage.overSize.warningTip'],
  ['余料名称', 'surplusPage.surplusName'],
  ['形状', 'materialPage.surplusHead.shape'],
  ['类型', 'materialPage.surplusHead.type'],
  ['短边长度', 'common.shortLong'],
  ['短边宽度', 'common.shortWidth'],
  ['添加时间', 'surplusPage.createTime'],
  ['所在余料库', 'surplusPage.branchName'],
  ['来源订单地址', 'surplusPage.orderAddress'],
  ['来源订单号', 'surplusPage.orderId'],
  ['备注', 'materialPage.surplusHead.remark'],
  ['面积', 'materialPage.surplusHead.area'],
  ['导出板件孔槽图时遇到未知错误，请联系我们解决', 'materialPage.exportPicErr'],
  ['导出孔槽三视图失败,请联系售后处理', 'materialPage.exportThreeViewErr'],
  ['当前用料方案存在小板没有可用大板进行排版', 'arrangedPage.plankErrTip'],
  ['(空)预排版方案', 'preLayoutSetting.preWayEmpty'],
  ['排版失败，存在大板数量不足或小板超尺', 'arrangedPage.arrangedErrTip'],
  ['下载完成', 'common.downloadDoneByType'],
  ['板件条码', 'common.plankNum'],
  ['订单号', 'common.orderNo'],
  ['房间备注', 'common.roomRemark'],
  ['颜色不能为空', 'common.colorNotUndefined'],
  ['材质不能为空', 'common.materialNotUndefined'],
  ['存在超尺，请重新添加', 'materialPage.addOverSizeTip'],
  ['添加特殊大板成功', 'materialPage.addPlankSuccess'],
  ['添加特殊大板失败', 'materialPage.addPlankFail'],
  ['继续排版', 'common.continueLayout'],
  ['调整设置', 'common.adjustPosition'],
  [
    '五/六面钻加工板件异形时，开启异形嵌套可能会导致切到板件，请确认是否继续排版？',
    'common.specialAndFiveSix',
  ],
  ['开门方向', 'materialPage.openDir'],
  ['铰链数据', 'materialPage.hingeData'],
])
