/** 板件吸附范围 */
export const ADSORPTION_RANGE = 40

/** 来源 */
export enum From {
  BLUEN = 'bluen',
  LBL = 'erp',
}

/** 非云排板使用待排版库，如不存在显示的板件图片使用此默认图片 */
export const DEFAULT_SHOW_AWAIT_STORE_IMAGE =
  'https://eggrj.oss-cn-hangzhou.aliyuncs.com/cloud_paiban/await_store/await_store-temp.png'

/** 待排版库图片可存在的图片尺寸 */
export enum AwaitStoreImageSize {
  width = 128,
  height = 134,
}

/** 云排版使用的 cookie key */
export enum CookieKeys {
  /** 云排版的版本信息，用户灰度发布等场景 */
  BLUEN_VERSION = 'bluen_version',
}

/** 云排版版本信息 */
export enum BluenVersions {
  /** 稳定版 */
  v1 = 'v1',
  /** 灰度版本 */
  v2 = 'v2',
}

/** @description 使用此参数来暂停某部分代码得执行(临时处理才需要使用此变量) */
export const CODE_PAUSE = false
