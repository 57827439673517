// 使用示例：
// compareVersions('4.0.0.3-17', '4.0.0.3-16') => 1
// compareVersions('4.0.0.3', '4.0.0.3-17') => -1
// compareVersions('4.1.0', '4.0.9') => 1
export function compareVersions(v1: string, v2: string): number {
  // 统一处理连字符和点分隔符
  const parseVersion = (version: string) => {
    return version.split(/[.-]/).map(Number)
  }

  const parts1 = parseVersion(v1)
  const parts2 = parseVersion(v2)

  const maxLength = Math.max(parts1.length, parts2.length)

  for (let i = 0; i < maxLength; i++) {
    const p1 = parts1[i] || 0
    const p2 = parts2[i] || 0

    if (p1 > p2) return 1
    if (p1 < p2) return -1
  }

  // 特殊处理连字符后的构建号（当基础版本相同时）
  if (v1.includes('-') !== v2.includes('-')) {
    return v1.includes('-') ? -1 : 1
  }

  return 0
}
