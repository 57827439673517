import { BujianStore, IPlateInfo } from '@/apis/bujianStore/types'
import { LibraryType } from '@/apis/bujianStore/types'
import { OSS_BASE_URL } from '@/constants'
import { AwaitStoreImageSize, From } from '@/data/constant'
import { PartType } from '@/partTypes'
import store from '@/store'
import type { AwaitStoreItem } from '@/store/modules/awaitPaibanStore'
import { Plank } from '@/util/canvas/drawPlank'
import { translate } from '@/util/commonFun'
import { uniquePlankNum } from '@/util/commonFuncs'
import { dealThinkerxMaterialData } from '@/util/dealThinkerxMaterialData'
import { plankDrawFactory } from '@/util/drawPlank'
import { calculateImageDisplaySize } from '@/util/plankCommonFuncs'
import type { IBujianOrStoreOssData } from '@/views/bujianManage/types'
import { jsonToFile, uploadFileToOss } from '@yige/utils'
import { message } from 'ant-design-vue'
import { Loading } from 'element-ui'
import { ElLoadingComponent } from 'element-ui/types/loading'
import { nanoid } from 'nanoid'

/** 生成待排版库需要的参数 */
export async function genAwaitStoreData(
  plank: PartType,
  preLayoutData: PartType[],
  imageSrc: string,
  type: 'local' | 'serve' = 'local'
): Promise<AwaitStoreItem | undefined> {
  const result = await calculateImageDisplaySize(imageSrc)
  if (!result) return
  const { width, height } = result
  const {
    address,
    thick,
    matCode,
    texture,
    plankID,
    partUniqueId,
    oriPartUniqueId,
    plKId,
  } = plank

  const title = `${thick}${matCode}(${texture})`
  const isShowChoose = preLayoutData.some(
    (_plank) => oriPartUniqueId === _plank.partUniqueId
  )
  const obj: AwaitStoreItem = {
    url: imageSrc,
    width,
    height,
    plank,
    title,
    address,
    plKId: plankID ?? plKId,
    partUniqueId,
    type,
    thick,
    matCode,
    from: From.BLUEN,
    color: texture,
    isDone: 'done',
    isShow: true,
    isSelect: false,
    isShowChoose,
  }
  return obj
}

/**
 * @description 检查当前拖动数据的状态，由于数据可能是服务器请求下来的，在这种情况下将拖动板件放入到板件前需要确认数据已加载
 */
export function checkCurrentDragPlankDataIsLoad(
  currentDragPlank: AwaitStoreItem,
  time = 20,
  isShowLoad = true
) {
  return new Promise((resolve) => {
    if (!currentDragPlank) return
    // 如果数据暂时请求不下来则进行加载
    let loading: null | ElLoadingComponent = null
    const timer = setInterval(() => {
      // 非load状态就将数据返回
      if (currentDragPlank.isDone && currentDragPlank.isDone !== 'load') {
        clearInterval(timer)
        loading?.close()
        // 错误状态则返回空
        if (currentDragPlank.isDone === 'err') {
          resolve(null)
          message.error(translate('arrangedPage.awaitPlankStore.plankLoadErr'))
          return
        }
        resolve(currentDragPlank)
        return
      }
      if (!loading && isShowLoad) {
        loading = Loading.service({
          text: translate('arrangedPage.awaitPlankStore.checkPlankStatus'),
        })
      }
    }, time)
  })
}

/** 生成板间的图片信息 */
export function getPlankImage(
  plank: PartType,
  isActive = true,
  options = {
    defaultScale: 6,
    scale: 1,
    plankLineWidth: 1,
  }
) {
  const { defaultScale, scale, plankLineWidth } = options
  const canvas = document.createElement('canvas')
  if (!plank) return
  const {
    rect: { width, height },
  } = plank
  const finalWidth = (width / defaultScale) * scale + plankLineWidth * 2
  const finalHeight = (height / defaultScale) * scale + plankLineWidth * 2
  canvas.width = finalWidth
  canvas.height = finalHeight
  const ctx = canvas.getContext('2d')!
  const gap = plankLineWidth / 2
  const drawPlank = new Plank(ctx, plank, gap, gap, defaultScale, scale, {
    plankLineWidth,
  })
  isActive && drawPlank.setFillStyle('#94E2AD')
  const draws = plankDrawFactory(
    ctx,
    plank,
    0.5,
    0.5,
    defaultScale,
    scale,
    undefined,
    ['plankSize']
  ) as any
  // 添加其他绘制
  drawPlank.addStrategy(Object.keys(draws).map((it) => draws[it]))
  drawPlank.draw()
  const result = canvas.toDataURL()
  return result
}

/**
 * 获取板件列表要发送到补件库的数据以及相关格式 所有地方保存待排版库均使用的此处
 * @param planks 板件列表
 * @param cb 执行回调 用于处理任务
 * @param isRePlankNum 是否重新生成条码
 * @returns
 */
export async function getPlankToAwaitStoreData(
  planks: PartType[],
  cb?: (index: number) => any,
  isRePlankNum = true
) {
  try {
    if (!planks?.length) return
    const result: BujianStore = {
      supplements_info: [],
      reason: '',
      duty: '',
      // 待排版库类型为2
      library_type: LibraryType.awaitStore,
    }
    // const storeOrderGroup = planks.reduce((res, item) => {
    //   const { orderId } = item
    //   if (!res[orderId]) {
    //     res[orderId] = 0
    //   }
    //   res[orderId]++
    //   return res
    // }, {})

    // Object.keys(storeOrderGroup).forEach((orderId) => {
    //   if (store.state.orderInfo.batch[orderId]) {
    //     store.commit('setOrderInfo', {
    //       batch: Object.assign(store.state.orderInfo.batch, {
    //         [orderId]:
    //           store.state.orderInfo.batch[orderId] - storeOrderGroup[orderId],
    //       }),
    //     })
    //   }
    // })
    /** 对板件的初始化处理 */
    const initPlank = (plank: PartType) => {
      // 在保存板件的时候重新设置唯一ID 如果保持一样的ID可能会有重复的问题
      plank.partUniqueId = nanoid()
      // 料单添加的板件不存在起始位置  需要添加
      plank.startX = plank.startX ?? 0
      plank.startY = plank.startY ?? 0
      // 因为每次只会添加一块板件所以都是1
      plank.amount = 1
      if (isRePlankNum) {
        // 板件条码重新生成
        const plankNum = uniquePlankNum.createUniquePlankNum()
        plank.plankNum = plankNum
        plank.oriPlankNum = plankNum
      }
    }
    /** 循环的单体函数 */
    const loopGenPlankStoreData = async (
      plank: PartType,
      collect: BujianStore,
      index: number
    ) => {
      const newPlank = JSON.parse(JSON.stringify(plank)) as PartType
      // 初始化部分数据
      initPlank(newPlank)
      // 生成图片时以一个固定的大小生成
      const plankImage = getPlankImage(newPlank, false, {
        defaultScale: 6,
        scale: 3,
        plankLineWidth: 1,
      })
      if (!plankImage) return
      // 获取图片的高度宽度
      const imageSize = await calculateImageDisplaySize(plankImage)

      const imageFile = base64ToFile(plankImage, 'plank.png')
      const ossBaseUrl = OSS_BASE_URL + '/' + 'await_store'
      // 板件图片oss链接
      const plankImageUrl = await uploadFileToOss(
        imageFile,
        undefined,
        undefined,
        ossBaseUrl,
        true
      )
      // 存储到oss的数据格式，为保证兼容补件和老板良的数据
      const plankToOssData = { plank: [newPlank] }
      // 板件数据oss链接
      const plankFile = jsonToFile(plankToOssData)
      const plankDataUrl = await uploadFileToOss(
        plankFile,
        undefined,
        undefined,
        ossBaseUrl,
        true
      )
      const {
        address,
        matCode,
        plankID,
        partUniqueId,
        plankNum,
        texture,
        customer_name,
        oRect,
        thick,
        is_high_gloss_plank,
        orderId,
      } = newPlank
      // 组装板件
      const title = `${thick}${matCode}(${texture})`
      const plateInfo: IPlateInfo = {
        address,
        color: texture,
        plKId: plankID,
        plankNum,
        partUniqueId,
        new: true,
        from: From.BLUEN,
        customer_name: customer_name || '',
        size: `${oRect.height}*${oRect.width}*${thick}`,
        width: imageSize?.width ?? `${AwaitStoreImageSize.width}px`,
        height: imageSize?.height ?? `${AwaitStoreImageSize.height}px`,
        matCode,
        isShow: true,
        title,
        thick,
        orderId,
        // 高光板标识
        isHighlightMatCode: is_high_gloss_plank,
        is_high_gloss_plank,
      }
      const supplement: BujianStore['supplements_info'][number] = {
        plate_info: plateInfo,
        render_url: plankDataUrl,
        img_url: plankImageUrl,
      }
      collect.supplements_info.push(supplement)
      // 调用外部的回调，让外部回调进行处理
      cb && cb(index + 1)
    }
    for (let index = 0; index < planks.length; index++) {
      const plank = planks[index]
      const { amount = 1 } = plank
      for (let i = 0; i < amount; i++) {
        await loopGenPlankStoreData(plank, result, index)
      }
    }
    // 清空收集的plankNum
    uniquePlankNum.plankNumCollect.clear()
    return result
  } catch (error) {
    console.error(error)
    return null
  }
}

/**
 * @description 查找指定板件的id
 * @param alreadyUseId 给板件加上补件/待排版库的id可用于后续扣减补件，需要记录使用了的id因为是按照plankId和planknum匹配的可能混出现重复板
 *
 */
export function findStoreId(
  data: any[],
  plank: PartType,
  alreadyUseId: Set<any>
) {
  const target = data.find(
    (it) =>
      Number(it.plankID) === Number(plank.plankID) &&
      it.plankNum === plank.plankNum &&
      !alreadyUseId.has(it.id)
  )
  if (target) {
    alreadyUseId.add(target.id)
  }
  return target
}

function base64ToFile(base64String: string, fileName: string) {
  const [mimeInfo, base64Data] = base64String.split(',') as any
  const mime = mimeInfo.match(/:(.*?);/)[1]

  const byteString = atob(base64Data)

  const arrayBuffer = new ArrayBuffer(byteString.length)
  const uintArray = new Uint8Array(arrayBuffer)

  for (let i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i)
  }

  const blob = new Blob([arrayBuffer], { type: mime })
  return new File([blob], fileName, { type: mime })
}

/** 解析待排版库url */
export async function parseAwaitStoreRenderUrl(url: string, from: From) {
  const result = (await fetch(url).then((result) => {
    if (result.status === 404) {
      throw new Error('文件不存在')
    }
    return result.json()
  })) as IBujianOrStoreOssData
  let plank = result.plank?.[0]
  // 老板良的数据需要进行一些基本的处理
  if (from === From.LBL) {
    plank = dealThinkerxMaterialData([plank])?.[0]
  }
  return plank
}

/**
 * @description 为板件添加待排版库的特征信息
 * @param plank
 */
export function plankAddAwaitStoreFeature(
  plank: PartType,
  awaitOriData: AwaitStoreItem
) {
  plank._awaitStoreId = awaitOriData?.id ?? ''
  plank._isAwaitStorePlank = true
  plank._isBujian = false
}
/**
 * @description 为板件添加补件库的特征信息
 * @param plank
 */
export function plankAddBujianStoreFeature(
  plank: PartType,
  bujianOriData: AwaitStoreItem
) {
  plank._bujianStoreId = bujianOriData?.id ?? ''
  plank._isBujian = true
  plank._isAwaitStorePlank = false
}
