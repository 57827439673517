import { Outline, Rect } from '@/partTypes'

/**判断两个矩形是否出现容纳的状态 */
export function isRectangleContained(rect1: Outline, rect2: Outline) {
  // 判断 rect1 是否完全包含 rect2
  const rect1ContainsRect2 =
    compareNumbers(rect1.x1, rect2.x1, '<=') &&
    compareNumbers(rect1.y1, rect2.y1, '<=') &&
    compareNumbers(rect1.x2, rect2.x2, '>=') &&
    compareNumbers(rect1.y2, rect2.y2, '>=')

  // 判断 rect2 是否完全包含 rect1
  const rect2ContainsRect1 =
    compareNumbers(rect2.x1, rect1.x1, '<=') &&
    compareNumbers(rect2.y1, rect1.y1, '<=') &&
    compareNumbers(rect2.x2, rect1.x2, '>=') &&
    compareNumbers(rect2.y2, rect1.y2, '>=')
  return {
    rect1ContainsRect2,
    rect2ContainsRect1,
    // 是否出现容纳
    isContain: rect1ContainsRect2 || rect2ContainsRect1,
  }
}

/** 判断两个矩形是否有可能出现碰撞 */
export function judgeRectIntersect(box1: Outline, box2: Outline) {
  // 结果为真则存在碰撞
  return !(
    box1.x2 < box2.x1 ||
    box1.x1 > box2.x2 ||
    box1.y2 < box2.y1 ||
    box1.y1 > box2.y2
  )
}

/** 计算一个矩形轮廓的面积 */
export function calcArea(outline: Outline) {
  const { x1, y1, x2, y2 } = outline
  return (x2 - x1) * (y2 - y1)
}

/** 轮廓处理成路径 */
export function rectToPath(rect: Outline) {
  return [
    { x: rect.x1, y: rect.y1 },
    { x: rect.x2, y: rect.y1 },
    { x: rect.x2, y: rect.y2 },
    { x: rect.x1, y: rect.y2 },
  ]
}

/** 获取矩形的相关边界信息以及中心点 */
export function getRectangleBounds(rect: Rect) {
  return {
    top: rect.y,
    bottom: rect.y + rect.height,
    left: rect.x,
    right: rect.x + rect.width,
    verticalCenter: rect.y + rect.height / 2,
    horizontalCenter: rect.x + rect.width / 2,
  }
}

/**
 * 重点
 * 比较两个数字 此函数需要应用在所有关于数字比较运算中
 * @param {number} num1 - 第一个数字
 * @param {number} num2 - 第二个数字
 * @param {string} operator - 比较运算符（'=', '>=', '<='）
 * @param {number} [epsilon=0.00001] - 允许的误差范围
 * @returns {boolean} - 如果比较结果为真返回true，否则返回false
 */
type NumberOperate = '=' | '>' | '<' | '>=' | '<='
export function compareNumbers(
  num1: number,
  num2: number,
  operator: NumberOperate = '=',
  epsilon = 0.00001
) {
  const diff = Math.abs(num1 - num2)
  switch (operator) {
    case '=':
      return diff < epsilon
    case '>=':
      return num1 > num2 || diff < epsilon
    case '<=':
      return num1 < num2 || diff < epsilon
    case '>':
      return num1 > num2 && diff >= epsilon
    case '<':
      return num1 < num2 && diff >= epsilon
    default:
      throw new Error('operator params Invalid')
  }
}
