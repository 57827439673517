/**
 * 维吾尔语
 */
export default {
  common: {
    checkPlankIsConflict: 'ئېغىرچىلىق ھالىتىنى تەكشۈرۈۋاتىدۇ',
    preferenceSaveErr: 'مايىللىق تەڭشەك ساقلاش ئەھۋالى غەيرىي',
    guimen: 'كابىنەت ئىشىكى',
    preferenceLoadTip: 'مايىللىق تەڭشىكىڭىزنى يۈكلىۋاتىدۇ',
    preferenceLoadErrTip:
      'مايىللىق تەڭشەك يۈكلەش مەغلۇپ بولدى، يېڭىلاشنى قايتا سىناڭ!',
    localFileIsBeingWrite: 'يەرلىك ھۆججەت يېزىلىۋاتىدۇ',
    taskMonitor: 'ۋەزىپە كۆزەتكۈچ',
    downloadByType: '{type} چۈشۈرۈش',
    slotKnifeErr:
      'ئوچۇق ئورۇنلاشتۇرۇش قورالى يوق، ئىشلەپچىقىرىش لىنىيىسى تەڭشىكىنى تەكشۈرۈڭ! 【{msg}】',
    anewChooseCatalog: 'قىسقۇچنى قايتا تاللاڭ',
    downloadSetting: 'چۈشۈرۈش تەڭشىكى',
    attention: 'دىققەت',
    unit: 'دانە',
    defaultUse: 'سۈكۈتتىكى ئىشلىتىش',
    defaultChooseUse: 'سۈكۈتتىكى تاللاش',
    requestFail: 'ئىلتىماس مەغلۇپ بولدى',
    downloadDoneByType: '{type} چۈشۈرۈش تاماملاندى',
    sysTip: 'سىستېما ئۇقتۇرۇشى',
    pleaseChoose: 'تاللاڭ',
    confirmDelete: 'ئۆچۈرۈشنى جەزملەشتۈرۈڭ',
    notDataTipByType: 'ھازىرچە سانلىق مەلۇمات يوق، ئاۋۋال {msg}',
    paginationTotalTip: '{current}-{page} قۇر / جەمئىي {total} قۇر',
    chooseAmountData: '{number} قۇر سانلىق مەلۇمات تاللاندى',
    moreOperate: 'تېخىمۇ كۆپ',
    transferByType: '{type} يۆتكەش',
    clickCopy: '{type} نۇسخىلاش ئۈچۈن چېكىڭ',
    currentLine: 'نۆۋەتتىكى قۇر',
    remark: 'ئىزاھات',
    customFunction: 'خاس ئىقتىدار',
    limitedTimeTrial: 'چەكلىمىسىز',
    startDate: 'باشلانغان چېسلا',
    endDate: 'ئاخىرلاشقان چېسلا',
    startTime: 'باشلانغان ۋاقتى',
    endTime: 'ئاخىرلاشقان ۋاقتى',
    lastMonth: 'ئۆتكەن ئاي',
    lastSixMonth: 'ئۆتكەن يەتتە ئاي',
    lastYear: 'ئۆتكەن يىل',
    searchByType: '{type} ئىزدەش ئۈچۈن كىرگۈزۈڭ',
    addTime: 'قوشۇلغان ۋاقتى',
    startArrange: 'تەرتىپلەشنى باشلاڭ',
    select: 'تاللاڭ',
    name: 'ئات',
    plankNo: 'تاختىنىڭ نومۇرى',
    plankName: 'تاختا ئاتى',
    orderNo: 'زاكاز نومۇرى',
    order: 'زاكاز',
    plankRemark: 'تاختا ئىزاھاتى',
    room: 'ئۆي',
    orderAddress: 'پروگرامما ئادرېسى',
    matCode: 'ماتېرىال',
    matCode2: 'ماتېرىال',
    plankMatCode: 'تاختا ماتېرىالى',
    count: 'سانى',
    customer: 'خېرىدار',
    customerInfo: 'خېرىدار ئۇچۇرى',
    factoryOrderNo: 'فابرىكا زاكاز نومۇرى',
    roomName: 'ئۆي ئاتى',
    color: 'رەڭ',
    plankColor: 'تاختا رەڭگى',
    plankThick: 'تاختا قېلىنلىقى',
    plankHighGloss: 'يۇقىرى يالتىراقلىق خاسلىقى',
    thick: 'قېلىنلىق',
    front: 'ئالدى',
    back: 'ئارقا',
    slot: 'ئوچۇق',
    hole: 'تېشىك',
    hsInfo: '{hole} تېشىك {slot} ئوچۇق',
    diameter: 'دىئامېتىر',
    finishedWidth: 'مۇكەممەل كەڭلىك',
    finishedHeight: 'مۇكەممەل ئۇزۇنلۇق',
    texture: 'تېكىستۇرا',
    normal: 'تىك',
    reverse: 'توغرا',
    notCare: 'تېكىستۇرا يوق',
    holeSlotsStastic: 'تېشىك ۋە ئوچۇق سانى',
    edge: {
      title: 'چىگارلاش',
      info: 'چىگارلاش ئۇچۇرى',
      front: 'ئالدى چىگارلاش',
      back: 'ئارقا چىگارلاش',
      left: 'سول چىگارلاش',
      right: 'ئوڭ چىگارلاش',
      up: 'يۇقىرى چىگارلاش',
      down: 'تۆۋەن چىگارلاش',
    },
    isSpecial: 'ئالاھىدە',
    notSpecial: 'ئالاھىدە ئەمەس',
    isDoorPlank: 'ئىشىك تاختىسى',
    notDoorPlank: 'ئىشىك تاختىسى ئەمەس',
    width: 'كەڭلىك',
    long: 'ئۇزۇنلۇق',
    plankWidth: 'تاختا كەڭلىكى',
    plankHeight: 'تاختا ئۇزۇنلۇقى',
    shortLong: 'قىسقا تەرەپ ئۇزۇنلۇقى',
    shortWidth: 'قىسقا تەرەپ كەڭلىكى',
    plankShape: 'تاختا شەكلى',
    detail: 'تەپسىلات',
    cancel: 'بىكار قىل',
    confirm: 'جەزملەشتۈرۈڭ',
    alreadyKnow: 'مەن بىلىمەن',
    Noprompts: 'ئۇقتۇرۇش بەرمە',
    deep: 'چوڭقۇرلۇق',
    other: 'باشقا',
    heighGloss: 'يۇقىرى يالتىراق',
    heighGlossPlank: 'يۇقىرى يالتىراق تاختا',
    notHeighGloss: 'يۇقىرى يالتىراق ئەمەس',
    notHeighGlossPlank: 'يۇقىرى يالتىراق تاختا ئەمەس',
    saveSuccess: 'ساقلاش مۇۋەپپەقىيەتلىك بولدى',
    deleteSuccess: 'ئۆچۈرۈش مۇۋەپپەقىيەتلىك بولدى',
    addSuccess: 'قوشۇش مۇۋەپپەقىيەتلىك بولدى',
    editSuccess: 'تەھرىرلەش مۇۋەپپەقىيەتلىك بولدى',
    engraving: 'ئويما ماشىنىسى',
    electronicSaw: 'ئېلېكترونلۇق ئارا',
    glassEquip: 'ئەينەك كېسىش ماشىنىسى',
    labelingMachine: 'بېلگە چاپلاش ماشىنىسى',
    copy: 'كۆچۈرۈش',
    paste: 'چاپلا',
    undo: 'ئەمەلدىن قالدۇر',
    move: 'يۆتكەش',
    PTP: 'PTP',
    dock56FDrill: 'بەش/ئالتە يۈزلىك ماتور',
    sideHoleDrill: 'يان تېشىك ماتورى',
    combination: 'بىرلەشتۈرۈلگەن قورال',
    integration: 'بىرلەشتۈرۈلگەن',
    standardPlank: 'ئۆلچەملىك چوڭ تاختا',
    standardMaterial: 'ئۆلچەملىك تاختا ماتېرىالى',
    standardMaterialTip:
      'ئەسكەرتىش: چوڭ تاختا ئۇچۇرىنى تەڭشەپ بولغاندىن كېيىن، كابىنەت تەرتىپلىگۈچىلەر كابىنەت خېرىدار تەرەپنى قايتا قوزغىتىشى كېرەك!',
    specialMaterial: 'ئالاھىدە تاختا ماتېرىالى',
    specialPlank: 'ئالاھىدە چوڭ تاختا',
    singlePlank: 'بىر تەرەپلىك تاختا',
    previous: 'ئالدىنقى',
    plankInfo: 'چوڭ تاختا تەپسىلاتى',
    moreResult: 'تېخىمۇ كۆپ تەرتىپلەش پىلانى',
    moreResultTip:
      'چوڭ تاختادا چىگارلاش ئوچۇقى بار بولسا، چوڭ تاختا قايتا تەرتىپلەشكە قاتناشمايدۇ',
    ok: 'جەزملەشتۈرۈڭ',
    dispersePaibanTip:
      'بەزى چوڭ تاختىلارنىڭ تارقىتىپ تەرتىپلەشكە بولمايدىغانلىقىنى بايقىدىڭىز، قولدا تەڭشەش ياكى تەرتىپلەش پىلانىنى ئالماشتۇرۇپ، قاچىرىشتىن ساقلىنىڭ!',
    next: 'كېيىنكى',
    rate: 'پايدىلىنىش نىسبىتى',
    paibanBatch: 'تەرتىپلەش پارتىيىسى',
    return: 'قايتىش',
    FGNo: 'تۇزۈش نومۇرى',
    refresh: 'يېڭىلاش',
    inde: 'تەرتىپ نومۇرى',
    productionLine: 'ئىشلەپچىقىرىش لىنىيىسى',
    operation: 'مەشغۇلات',
    colorPlaceHolder:
      'كىرگۈزۈپ ئەنگىرەتسىڭىز، رەڭ كارتىسى ئاپتوماتىك ھاسىل بولىدۇ',
    saveAndContinue: 'ساقلاپ داۋاملاشتۇرۇڭ',
    reset: 'قايتا تەڭشەش',
    check: 'سىناش',
    inputPlaceholder: 'كىرگۈزۈڭ',
    filter: 'سۈزگۈچ',
    all: 'ھەممىسى',
    exportPDF: 'PDF چىقىرىش',
    exportPic: 'رەسىم چىقىرىش',
    add: 'قوشۇش',
    edit: 'تەھرىرلەش',
    delete: 'ئۆچۈرۈش',
    save: 'ساقلاش',
    saveSetting: 'تەڭشەكنى ساقلاش',
    isHeightGloss: 'يۇقىرى يالتىراق تاختا ئەمەسلىكىنى تاللاڭ',
    noSync: 'ماسلىشمايدۇ',
    sync: 'ماسلىشىدۇ',
    formatSuccess: '{type} مۇۋەپپەقىيەتلىك بولدى',
    dataLoading: 'سانلىق مەلۇمات يۈكلىنىۋاتىدۇ......',
    warmTip: 'ئەسكەرتىش',
    wellcome: 'بۇلۇت تەرتىپلىگۈچىگە خۇش ئەمدىڭىز!',
    timeOut: 'تەرتىپلەش ۋاقتىدىن ئاشتى، قايتا تەرتىپلەمسىز؟',
    warning: 'ئاگاھلاندۇرۇش',
    export: 'چىقىرىش',
    mark: 'بەلگە',
    noMark: 'بەلگە قويما',
    sawArrange: 'ئارا تەرتىپلەش',
    directArrange: 'تىك تەرتىپلەش',
    area: 'يەر مەيدانى',
    plankNum: 'تاختا باركودى',
    loc: 'كابىنەت',
    cutHeight: 'ماتېرىال ئۇزۇنلۇقى',
    cutWidth: 'ماتېرىال كەڭلىكى',
    defaultProduction: 'سۈكۈتتىكى ئىشلەپچىقىرىش لىنىيىسى',
    tip: 'ئەسكەرتىش',
    history: 'تارىخ خاتىرىسى',
    hinge: 'ئىلمەك تېشىكى',
    hingeScrew: 'ئىلمەك ئىشىك تاختىسىنىڭ تېشىكى',
    CCPXHole: 'ئۈچ بىرلەشتۈرۈلگەن تەڭپۇڭسىز تېشىك',
    stretchHinge: 'تىكلىگۈچى ئوچۇقى ۋە ئىلمەك ئىچى تېشىكى',
    stretch: 'تىكلىگۈچ',
    stretcher: 'تىكلىگۈچ ئوچۇقى',
    clothole: 'كىيىم ئېسىش تېشىكى',
    backPlank: 'ئارقا تاختا ئوچۇقى',
    ALS: 'يوشۇرۇن قولتۇق',
    doorSlot: 'ئىشىك مېتال ئوچۇقى',
    drawerSlot: 'سۈرۈندۈك مېتال ئوچۇقى',
    lkSlot: 'لوك ئوچۇقى',
    KC: 'ئىشتىن تېشىك',
    stealth: 'يوشۇرۇن بۆلەك',
    lightSlot: 'چىراق ئوچۇقى',
    allHole: 'ئالاھىدە تاختىدىكى بارلىق تېشىكلەر',
    allSlot: 'ئالاھىدە تاختىدىكى بارلىق ئوچۇقلار',
    millInfo: 'كالىس قولتۇق',
    haiTang: 'ھاي تاغ بۇرجىكى',
    FSDrill: 'بەش/ئالتە يۈزلىك ماتور',
    engraving2: 'ماتېرىال كېسىش ماشىنىسى',
    xyReverse: 'XY گىرادىئېنت ئالماشتۇرۇش',
    xyReverseTip:
      'XY گىرادىئېنت ئالماشتۇرۇش ئىقتىدارى L شەكلىدىكى قېلىنما چىكىنى توغرا ئورۇنلاشتۇرۇشقا ماسلىشمايدۇ، دىققەت قىلىڭ',
    rect: 'تىك تۆت بۇلۇڭ',
    Ltype: 'L شەكلى',
    archive: 'ئارخىپ',
    tellPlaceholder: 'تېلېفون نومۇرىنى كىرگۈزۈڭ',
    knivesStore: 'پىچاق ئامبىرى',
    roll: 'تاختا ئۆرۈلۈش',
    pause: 'ۋاقىتلىق توختىتىش',
    editTrans: 'تەرجىمىنى تەھرىرلەش',
    translateManager: 'تەرجىمە باشقۇرۇش',
    grating: 'تور',
    addressEditErr:
      'كۆپ زاكاز ھازىرچە پروگرامما ئادرېسىنى ئۆزگەرتىشكە يول قويمايدۇ',
    customerEditErr:
      'كۆپ زاكاز ھازىرچە خېرىدار ئۇچۇرىنى ئۆزگەرتىشكە يول قويمايدۇ',
    factoryEditErr:
      'كۆپ زاكاز ھازىرچە فابرىكا زاكاز نومۇرىنى ئۆزگەرتىشكە يول قويمايدۇ',
    roomNameEditErr: 'كۆپ زاكاز ھازىرچە ئۆي ئاتىنى ئۆزگەرتىشكە يول قويمايدۇ',
    remarkEditErr:
      'كۆپ زاكاز ھازىرچە زاكاز ئىزاھاتىنى ئۆزگەرتىشكە يول قويمايدۇ',
    toMaterialList: 'ماتېرىال تىزىملىكىگە يۆتكىلىش',
    deleteFail: 'ئۆچۈرۈش مەغلۇپ بولدى، كېيىن قايتا سىناڭ!',
    batchDelete: 'جەمئىي ئۆچۈرۈش',
    workShop: 'فابرىكا ئىشلەپچىقىرىش',
    FGAdrress: 'پروگرامما ئادرېسى/تۇزۈش نومۇرى',
    cuttingSize: 'كېسىش ئۆلچىمى',
    finishedSize: 'مۇكەممەل ئۆلچىم',
    labelImg: 'بېلگە رەسىم',
    layoutImg: 'تەرتىپلەش رەسىمى',
    file: 'ھۆججەت',
    folder: 'قىسقۇچ',
    reName: 'ئاتنى ئۆزگەرتىش',
    to: 'غا',
    plankUnit: 'تاختا',
    tagTemp: 'بېلگە قېلىپى',
    roomRemark: 'ئۆي ئىزاھاتى',
    noModificationPermission: 'ئۆزگەرتىش ھوقۇقى يوق',
    modificationRecord: 'ئۆزگەرتىش خاتىرىسى',
    page: 'بەت',
    strip: 'قۇر',
    jump: 'سەكرەش',
    freeAgreement: 'يۇمشاق دېتال مەسئۇلىيەتسىزلىك كېلىشىمى',
    preStep: 'ئالدىنقى قەدەم',
    nextStep: 'كېيىنكى قەدەم',
    upload: 'يۈكلەش',
    uploading: 'يۈكلىنىۋاتىدۇ',
    iAgree: 'مەن قوشۇلىمەن',
    pleaseSelectPlank: 'كەم دېگەندە بىر تاختا تاللاڭ',
    saveToStoreTip: 'كەم دېگەندە بىر تاللانمىغان تاختا تاللاڭ',
    fileIsNotExist: 'ھۆججەت مەۋجۇت ئەمەس',
    selectAll: 'ھەممىنى تاللاش',
    pdfTag: 'PDF بېلگە',
    tagImage: 'بېلگە رەسىم',
    projectName: 'بۇلۇت تەرتىپلىگۈچ',
    guigui: 'كابىنەت',
    gratingSlot: 'تور ئوچۇقى',
    deleteTip:
      'بۇ سانلىق مەلۇماتنى ئۆچۈرگەندىن كېيىن قايتا تىكلىنەلمەيدۇ، ئۆچۈرۈشنى جەزملەشتۈرەمسىز؟',
    deleteError: 'نۆلىمىگەن خاتالىق كۆرۈلدى، ئۆچۈرۈش مەغلۇپ بولدى',
    cancelDelete: 'ئۆچۈرۈشتىن ۋاز كەچتى',
    confirmDeleteTip:
      'ئۆچۈرۈلگەندىن كېيىن تاختىلار تەرتىپلەش بۇكىتىگە كىرىدۇ، ئۆچۈرۈشنى جەزملەشتۈرەمسىز؟',
    deleteEmptyTip: 'بۇ چوڭ تاختىنى ئۆچۈرەمسىز؟',
    notCanDelete: 'كېسىلگەن قېلىنما ئامبىرىغا كىرگۈزۈشكە بولمايدۇ',
    new: 'يېڭى',
    innerBluneFailure: 'ئىچكى بۇلۇت تەرتىپلىگۈچ بۇ ئىقتىدارغا ئىناۋەتلىك ئەمەس',
    deleteBigPlankTip:
      'نۆۋەتتىكى ماتېرىال رەڭگى ۋە قېلىنلىقىدا كەم دېگەندە بىر چوڭ تاختىنى ساقلىشىڭىز كېرەك، ئۆچۈرۈشكە بولمايدۇ!',
    continueExport: 'پەرۋا قىلما، ھۆججەت چىقىرىشنى داۋاملاشتۇر',
    toCheck: 'تەكشۈرۈشكە بار',
    pleaseCheck:
      'نۆۋەتتىكى تەرتىپلەش نەتىجىسىدە تېشىك/ئوچۇق توقۇنۇشۇش/چىقىپ كەتكەن كىچىك تاختىلار/چوڭقۇرلۇق غەيرىيلىكى بار، تەكشۈرۈڭ!',
    clampHandCheck:
      'بەزى تاختىلارنىڭ تېشىك/ئوچۇقلىرىنىڭ قولتۇق ئورنىنى بوشىتىشقا بولمايدىغانلىقىنى بايقىدىڭىز، قولدا تەڭشەڭ',
    conflictPartDetail: 'توقۇنۇشۇپ قالغان تاختىلارنىڭ تەپسىلاتى',
    colorNotUndefined: 'رەڭ بوش قالمايدۇ',
    materialNotUndefined: 'ماتېرىال بوش قالمايدۇ',
    newSetting: 'يېڭى ئىقتىدار',
    downImageErrTip:
      'رەسىم چىقىرىش مەغلۇپ بولدى، چوڭ تاختىلارنى ئايرىم قىسقۇچلارغا بۆلۈش تاللانغانلىقتىن، كىچىك تاختا بېلگىسىنىمۇ تاللاش كېرەك',
    downNcErrTip:
      'NC چۈشۈرۈش مەغلۇپ بولدى، چوڭ تاختىلارنى ئايرىم قىسقۇچلارغا بۆلۈش تاللانغانلىقتىن، كىچىك تاختا بېلگىسىنىمۇ تاللاش كېرەك',
    yes: 'ھەئە',
    no: 'ياق',
    checkColorPrint:
      'نۆۋەتتە رەڭلىك بېلگە قوزغىتىلغان، رەڭلىك بېسقۇچنى ئىشلىتىڭ~',
    colorRules:
      'رەڭلىك بېلگە قوزغىتىلغاندىن كېيىن، زاكاز/ئۆي/كابىنەت/ئىشىك تاختىسى رەڭ بويىچە ئايرىلىدۇ، ھازىر رەڭلىك بېلگە بىر خىل سانلىق مەلۇماتقا 12 گىچە قوللايدۇ، تۆۋەندىكى قائىدىلەرگە ئەمەل قىلىڭ:',
    greyPart: 'ئوڭ تەرەپتىكى بوز بۆلەك',
    region: 'رايون',
    notSame: 'مۇختەلەف {regionName} رايونلارنىڭ رەڭگى مۇختەلەف',
    doorTip: 'بوز بۆلەك ئىشىك تاختىسى، ئاق بۆلەك ئىشىك تاختىسى ئەمەس',
    exist: 'بار',
    limitNotEffect:
      'سانلىق مەلۇمات 12 دىن ئاشقاندا، رەڭلىك بېلگە ئىناۋەتلىك بولمايدۇ',
    hoverTip:
      'نۆۋەتتىكى bump كېڭەيتىلگەن رەسىم، كودلاش فورماتى قارا-ئاق، رەڭلىك بېلگە چىقىرىشقا بولمايدۇ',
    lock: 'قۇلۇپلاش',
    unlock: 'قۇلۇپ ئېچىش',
    batchLock: 'جەمئىي قۇلۇپلاش/ئېچىش',
    surplusLock: 'قېلىنما قۇلۇپلاش',
    surplusUnlock: 'قېلىنما قۇلۇپ ئېچىش',
    is: 'ھەئە',
    not: 'ياق',
    savingHistory: 'تارىخىي ھۆججەت ساقلانماقتا',
    saveHistorySuccess: 'تارىخىي ھۆججەت ساقلاش مۇۋەپپەقىيەتلىك بولدى',
    saveHistoryFail: 'تارىخىي ھۆججەت ساقلاش مەغلۇپ بولدى',
    bw: 'قارا-ئاق بېلگە چىقىرىش ئەمەسلىكىنى تاللاڭ',
    modular: 'بىرلىك كابىنەت',
    autoAdjust: 'ئاپتوماتىك تەڭشەش',
    cutOrderTip:
      'سىزنىڭ 【{errorCutOrdersString}】 چوڭ تاختىڭىزنىڭ كېسىش تەرتىپى خاتا، تەھرىرلەپ تەڭشەڭ!',
    lockedCutOrderTip:
      '【{errorCutOrdersString}】 چوڭ تاختا قۇلۇپلانغان، قولدا تەھرىرلەشكە يۆتكىلىڭ',
    autoAdjustWarning:
      'نۆۋەتتىكى تەرتىپلەش نەتىجىسى ئاپتوماتىك تەڭشەشكە قوللىمايدۇ',
    checkNetwork: 'تورنى تەكشۈرۈڭ',
    moreInfo: 'تېخىمۇ كۆپ ئۇچۇرلارنى كۆرۈش',
    continueLayout: 'تەرتىپلەشنى داۋاملاشتۇرۇش',
    adjustPosition: 'تەڭشەش ئورنى',
    specialAndFiveSix:
      'بەش/ئالتە يۈزلىك ماتور ئالاھىدە تاختىلارنى ئىشلەش جەريانىدا، ئالاھىدە تاختىلارنى قوشۇش تاختىنى كېسىشكە ئەگىشىپ قالىشى مۇمكىن، تەرتىپلەشنى داۋاملاشتۇرىسىز؟',
    plankPathErr:
      'تاختا ئىچىدىكى ئالاھىدە سانلىق مەلۇمات خاتا، قورال ئۆلچىمىگە ماس كېلىدىغانلىقىنى تەكشۈرۈڭ! تاختا نومۇرى 【{plankIDList}】',
    edgeCheck:
      'سىستېما چوڭ تاختىلارنىڭ چىگارلىرى ئۆزگەرتكەنلىكىنى بايقىدى، ئىشلەپچىقىرىشنىڭ توغرا بولۇشى ئۈچۈن قايتا تەرتىپلەڭ',
    iKnow: 'مەن بىلدىم',
    versionErrTip: 'كابىنەتنى ئەڭ يېڭى نۇسخىغا يېڭىلاڭ!',
    annualFeeExpired: 'يىللىق ھەققىڭىز ۋاقتى توشتى، تۆلەشنى داۋاملاشتۇرۇڭ!',
  },
  home: {
    head: {
      production: 'مەھسۇلات تونۇشتۇرۇش',
      resolve: 'چەت ئەل ھەل قىلىش',
      about: 'بىز ھەققىدە',
      equipment: 'ماشىنا قوراللىرى',
      tell: 'شىكايەت تېلېفونى',
      enter: 'بۇلۇت تەرتىپلىگۈچكە كىرىش',
    },
    swiper: {
      case1: {
        tag: 'يېڭىلانغان نۇسخا',
        title: 'بۇلۇت تەرتىپلىگۈچ',
        subTitle: 'ماشىنىنىڭ ئەقىللىق مېڭىسى',
        description: 'سەنئەتنى ئۆزگەرتىدىغان بۇلۇت تەرتىپلىگۈچ يۇمشاق دېتال',
      },
      case2: {
        title: 'كابىنەت ئىشلەپچىقىرىش',
        subTitle: 'تېخنىكا يېڭىلىنىشى',
        description:
          'نۇرغۇن خىل بېلگە چاپلاش ماشىنىسى، ماتېرىال كېسىش ماشىنىسى، يان تېشىك ماتورى، بەش يۈزلىك PTP، بەش/ئالتە يۈزلىك ماتورلارنى قوللايدۇ',
      },
      case3: {
        title: 'سانائەت مېڭىسى',
        subTitle: 'چەت ئەل ھەل قىلىش',
        description:
          'ئەقىللىق تەرتىپلەش ئالگورىتىمى تاختىنى تېخىمۇ يۇقىرى پايدىلىنىش نىسبىتىگە ئېرىشتۈرىدۇ',
      },
    },
    statistics: {
      today: 'بۈگۈنكى تەرتىپلەش مىقدارى',
      toMounth: 'بۇ ئاينىڭ تەرتىپلەش مىقدارى',
      outturn: 'ماتېرىال چىقىم نىسبىتى',
      cumulative: 'جەمئىي ئىشلەتكۈچى',
    },
    intro: {
      title:
        'كارخانىلار دەرىجىلىك چوڭ سانلىق مەلۇمات بۇلۇت تەرتىپلىگۈچ يۇمشاق دېتالى',
      subTitle:
        'سانائەت ھالىتىنى چۈشىنىش، خېرىدار ئېھتىياجىغا دىققەت قىلىش، كارخانىلارنى رقابەتلەشتۈرۈشكە ياردەم بېرىش، ئۈنۈملۈك ئىشلەپچىقىرىش ساھەسىدە ئۆزگىرىشلەرنى قوزغىتىش، ئەقىللىق ئالگورىتىم ئارقىلىق كارخانىلارغا تېخىمۇ كۆپ پايدا ئېلىپ كېلىش',
      menue: {
        case1: {
          title: 'توردا تەرتىپلەش',
          subTitle: 'ھەر قانداق ۋاقىتتا تەرتىپلەش',
          description:
            'يۇمشاق دېتال چۈشۈرۈشنىڭ ھاجىتى يوق، ھەر قانداق ۋاقىتتا تەرتىپلەشكە بولىدۇ، كومپيۇتېردىن مۇستەقىل',
        },
        case2: {
          title: 'كۆپ لىنىيە ئىشلەپچىقىرىش',
          subTitle: 'ئىشلەپچىقىرىش لىنىيىسىنى تېز ئالماشتۇرۇش',
          description:
            'بىر ۋاقىتتا نۇرغۇن ئىشلەپچىقىرىش لىنىيىسىنى قوللايدۇ، ئىشلەپچىقىرىش لىنىيىسىنى تېز ئالماشتۇرۇشقا بولىدۇ',
        },
        case3: {
          title: 'نەق تەرتىپلەش',
          subTitle: 'نەق تەرتىپلەش، نەق پارامېتىر تەڭشەش',
          description:
            'نەق تەرتىپلەش، نەق پارامېتىر تەڭشەش، تاختىلارنى تېخىمۇ توغرا ئىشلەپچىقىرىشقا بولىدۇ',
        },
        case4: {
          title: 'خاس ئىشلەپچىقىرىش',
          subTitle: 'خاس بېلگە، بەش/ئالتە يۈزلىك ماتور، قېلىنما شەكلى',
          description:
            'خاس بېلگە، خاس بەش/ئالتە يۈزلىك ماتور، خاس قېلىنما شەكلى، بىرلەشتۈرۈلگەن قورال ئىشلەش',
        },
      },
    },
    fangan: {
      title: 'سانائەت تەجرىبىلىك چەت ئەل ھەل قىلىش',
      subTitle:
        'تۆۋەن خىراجەت، يۇقىرى مەھسۇلات، ئەقىللىق ئالگورىتىم تاختىنى تېخىمۇ يۇقىرى پايدىلىنىش نىسبىتىگە ئېرىشتۈرىدۇ، نۇرغۇن خىل لايىھىلەش يۇمشاق دېتىلىرىنى قوللايدۇ، ئىشلەتكۈچىلەرگە ئەڭ ياخشى ئىشلەپچىقىرىش پىلانىنى تەمىنلەيدۇ',
      menue: {
        case1: {
          title: 'توردا زاكاز قوبۇل قىلىش ۋە ئىشلەپچىقىرىش بىرلەشتۈرۈش',
          description:
            'ئىشلەپچىقىرىش بىلەن بىر ۋاقىتتا زاكاز قوبۇل قىلىش، لايىھىلەش بىلەن ئىشلەپچىقىرىشنى بىر ۋاقىتتا تاماملايدۇ',
        },
        case2: {
          title: 'بىر تۇتاش ئىشلەپچىقىرىش لىنىيىسىنى ئالماشتۇرۇش',
          description:
            'خاس ئىشلەپچىقىرىش لىنىيىسى، نۇرغۇن ئىشلەپچىقىرىش لىنىيىسى، نۇرغۇن خىل قوراللارنى قوللايدۇ',
        },
        case3: {
          title:
            'ئەقىللىق ئالگورىتىم تاختىنى تېخىمۇ يۇقىرى پايدىلىنىش نىسبىتىگە ئېرىشتۈرىدۇ',
          description:
            'ئەنئەنىۋى ئالگورىتىمدە تاختىنى پايدىلىنىش نىسبىتى تۆۋەن، ئەقىللىق ئالگورىتىمدە نەق پارامېتىر تەڭشەش، خاس ئىشلەپچىقىرىش پىلانى',
        },
        case4: {
          title: 'تەجرىبىلىك كېسىش پىلانى',
          description:
            'كېسىش تەرتىپى، كېسىش نۇقتىسىنى ئەڭ ياخشىلاشتۇرۇش، يولنى قىسقارتىش ۋە كېسىشنى مۇقىم قىلىش',
        },
      },
    },
    aboutUs: {
      title1: 'يېڭى گېر ئىنسان تۇرمۇش تېخنىكىسى',
      subTitle: 'ئۆي جىھازلىرى سانائىتىنىڭ ئۆزگىرىشچان قۇرغۇچىسى',
      description:
        'يېڭى گېر كارخانىسى، ئەنئەنىۋى ئۆي جىھازلىرى سانائىتىنى ئىنتېرنېت ئارقىلىق ئۆزگەرتىشكە دىققەت قىلىدۇ',
      culture: {
        case1: {
          title: 'كارخانا مەقسىتى',
          subTitle: 'فابرىكىلارغا ياردەم بېرىش، ئۈچ ھەسسە ئاشۇرۇش',
        },
        case2: {
          title: 'مەدەنىيەت قىممەت قارىشى',
          subTitle:
            'خېرىدارغا مەركەزلەشكەن، كۈرەشچانلارغا ئاساسلانغان، قاتتىق كۈرەش قىلىش، خەلقنىڭ پايدىسىنى قوغداش',
        },
        case3: {
          title: 'يېتەكچى پىكىر',
          subTitle:
            'خەلق يولى، سېتىش كۈچى، مەھسۇلات كۈچى، تەشكىل كۈچىگە ئىگە ئەلا گۇرۇپپا قۇرۇش',
        },
      },
      feature: {
        case1: 'تەجرىبىلىك گۇرۇپپا قوغداش',
        case2: 'دائىم يېڭىلىنىپ تۇرۇش',
        case3: 'بىرەر-بىر ياردەم',
        case4: 'ئىقتىدار خاس تەمىنلەش',
      },
    },
    cooperation: {
      title: 'ئىناق ھەمكارلىق، بىرلىكتە كەلگۈسىنى قۇرۇش',
      subTitle:
        'جۇڭگونىڭ 90% دىن ئارتۇق ئادەتتىكى قوراللىرىنى قوللايدۇ، 107554 تىجارەتچى بىز بىلەن بىللە',
    },
    footer: {
      contact: {
        title: 'بىز بىلەن ئالاقىلىشىڭ',
        adrres:
          'ئادرېس: جياڭسۇ ئۆلكىسى، وۇشى شەھىرى، بىنخۇ رايونى، شىۇشى 50-خەنزۇچە',
        subAdrres: 'K-park مۇلازىمەت مەركىزى 1-بىنا 16-قەۋەت',
        tell: 'تېلېفون',
      },
      business: {
        title: 'يېڭى گېرنىڭ ئاساسلىق تىجارىتى',
        department1: 'دېرەزە-ئىشىك يۇمشاق دېتال بۆلۈمى',
        department2: 'ئۆي جىھازلىرى يۇمشاق دېتال بۆلۈمى',
        department3: 'يىغما بىنا بۆلۈمى',
        department4: 'يېڭى گېر باش شىتابى رەسىمىي تور بېتى',
      },
      brand: {
        title: 'يېڭى گېر تۈرلىرى',
        brand1: 'تېرىلغۇ مالىيە',
        brand2: 'ئىشىك تورى',
        brand3: 'دىڭشىن سېلىم',
        brand4: 'ئۈچ رەسىم تېخنىكىسى',
        brand5: 'شاڭخەي پودۇ تېخنىكىسى',
      },
      organization: {
        title: 'يېڭى گېر دۇنيا بويىچە شۆبەلىرى',
        organization1: 'يېڭى گېر-جەنۇبىي شىتاب (فوشان شۆبىسى)',
        organization2: 'يېڭى گېر-شىمالىي شىتاب (لىنيى شۆبىسى)',
        organization3: 'يېڭى گېر-جەنۇبىي غەربىي شىتاب (چەندۇ شۆبىسى)',
        organization4: 'يېڭى گېر-كابىنەت غەربىي مۇلازىمەت مەركىزى',
        organization5: 'يېڭى گېر-كابىنەت شەرقىي مۇلازىمەت مەركىزى',
        organization6: 'يېڭى گېر-دىللىكلار توپلىمى',
        organization7: 'يېڭى گېر-خەلقئارا سېتىش بۆلۈمى',
      },
      follow: {
        title: '"كابىنەت" نى ئىزلىش',
      },
      friendLink: {
        link1: 'يېڭى گېر يۇمشاق دېتال',
        link2: 'K秀 سېتىش ياردەمچى يۇمشاق دېتال',
        link3: 'ئىشىك تورى',
        link4: 'پۈتۈن ئېلۇمىن كابىنەت يۇمشاق دېتال',
        link5: 'ZOL يۇمشاق دېتال چۈشۈرۈش',
        link6: 'خۇا جون يۇمشاق دېتال باغچىسى',
        link7: 'چۈشۈرۈش ئۆيى',
        link8: 'ZOL يۇمشاق دېتال چۈشۈرۈش',
        link9: 'يېشىل سىرتقى تور',
        link10: 'كېيىم دولىسىغا قوشۇلىش',
        link11: 'ئۆي جىھازلىرىغا قوشۇلىش',
        link12: 'ئامېرىكا ئۆلچىملىك ھامامغا قوشۇلىش',
      },
    },
  },
  login: {
    title: 'كىرىش',
    tell: 'تېلېفون نومۇرى',
    password: 'پارول',
    passwordPlaceholder: 'پارولنى كىرگۈزۈڭ',
    forget: 'پارولۇمنى ئۇنتۇپ قالدۇم',
    registerAccount: 'ھېساباتتىن ئۆتۈش',
    backLog: 'كىرىشكە قايتىش',
    verifyCode: 'دەلىللەش كودى',
    getVerifyCode: 'دەلىللەش كودىنى ئېلىش',
    confimPassword: 'پارولنى جەزملەشتۈرۈش',
    confimPasswordPlaceholder: 'پارولنى جەزملەشتۈرۈڭ',
    editPassword: 'پارولنى ئۆزگەرتىش',
    register: 'تىزىملاش',
    reSend: 'قايتا ئېلىش',
    loginLoadText: 'كىرىش جەريانىدا، سەل تۇرۇڭ...',
    loadingPageTextL: 'مۇۋەپپەقىيەتلىك كىردى، بەت قۇرۇلمىسى يۈكلىنىۋاتىدۇ...',
    loginSuccess: 'مۇۋەپپەقىيەتلىك كىردى',
    tellErr: 'تېلېفون نومۇرى توغرا ئەمەس',
    passwordErr: 'پارولنى كىرگۈزۈڭ',
  },
  main: {
    head: {
      title: 'Bluen',
      subTitle: 'Save time, materials, and effort',
      task: 'ۋەزىپە قوبۇل قىلىش',
      arrange: 'تەرتىپلەش',
      surplus: 'قالدۇق ماتېرىال',
      setting: 'تەڭشەش',
      find: 'ئىزدەش',
      upgrade: 'دەرھال يۇقىرى كۆتۈرۈش',
      editNickName: 'تەخەللۇس ئۆزگەرتىش',
      editPassword: 'پارول ئۆزگەرتىش',
      logout: 'تىزىمدىن چىقىش',
      notice: 'ئۇقتۇرۇش',
      enterOfficial: 'رەسىمىي تور بېتىغا بارىش',
      equipment: 'ماشىنا قوراللىرى',
      help: 'ياردەم',
      enterThinkerOfficial: 'يېڭى گېر رەسىمىي تور بېتىغا كىرىش',
      history: 'تارىخنى ساقلاش',
      plankManager: 'ئەسلى تاختا باشقۇرۇش',
      tagManager: 'بېلگە باشقۇرۇش',
      suplumentManager: 'تاماملىغۇچى ۋە تەرتىپلەش بۇكىتى',
      fileManager: 'ھۆججەت باشقۇرۇش',
      productionLine: 'قوراللار بىرلەشتۈرۈش',
      workeShop: 'قوراللار بىرلەشتۈرۈش',
      useLesson: 'ئىشلىتىش دەرسلىكى',
      videoLesson: 'سىنلىق دەرس',
      prolineManager: 'ئىشلەپچىقىرىش لىنىيىسى باشقۇرۇش',
      materialManager: 'تاختا باشقۇرۇش',
      productManual: 'مەھسۇلات قوللانمىسى',
    },
    sideBar: {
      title: 'ئىشلەپچىقىرىش ئەڭ ياخشىلاش',
      materialList: 'ماتېرىال تىزىملىكى',
      paibanResult: 'تەرتىپلەش پىلانى',
      printTag: 'بېلگە بېسىش',
      history: 'تارىخنى ساقلاش',
      limitDate: 'چەكلىمىسىز',
      surplusManager: 'قالدۇق ماتېرىال باشقۇرۇش',
      translateManager: 'تەرجىمە باشقۇرۇش',
      moreSettings: 'تېخىمۇ كۆپ تەڭشەش',
      deepSeekTitle: 'مەسلىھەتچى',
    },
    allRead: 'ھەممىسى ئوقۇلدى',
    learnMore: 'تېخىمۇ كۆپ بىلىش',
    noticeEmpty: 'ھازىرچە ئۇقتۇرۇش يوق....',
    noticeErr: 'ئۇقتۇرۇش مەزمۇنىنى ئېلىش خاتالىقى',
    feedBack: {
      tab1: 'ئەرز قويۇش',
      tab2: 'ئەرز خاتىرىسى',
      type: 'پىكر تىپى',
      typePlaceholder: 'ئىقتىدارنى تاللاڭ',
      description: 'تەسۋىرلەش',
      descriptionPlaceholder:
        'ئەسسالامۇ ئەلەيكۇم، كەم دېگەندە 15 خەتلىك ئىشلىتىش مۇھىتىنى تەسۋىرلەڭ، بىزگە ياردەم بېرىشىڭىز ئۈچۈن رەھمەت!',
      uploadPic: 'رەسىم قوشۇش (ئەڭ كۆپ 6 دانە، ھەر بىر رەسىم 10M دىن ئاشمايدۇ)',
      tell: 'ئالاقىلىشىش تېلېفونى',
      submit: 'تاپشۇرۇش',
      admin: 'بۇلۇت تەرتىپلىگۈچ باشقۇرغۇچى:',
      picTip: 'رەسىم: (رەسىم تەپسىلاتىنى كۆرۈش ئۈچۈن چېكىڭ)',
      empty: 'ھازىرچە ئەرز سانلىق مەلۇماتى يوق',
      picErr: 'ھەر بىر رەسىم 10M دىن ئاشمايدۇ',
      contentErr: 'خەت سانى 15 دىن 300 گىچە بولۇشى كېرەك',
      necessary: 'بۇ تاللاش زۆرۈر',
    },
  },
  taskPage: {
    tab1: 'ئىشلەپچىقىرىش ۋەزىپىسى',
    tab2: 'جەمئىي ئىشلەپچىقىرىش قۇرۇلمىسى',
    filter: {
      title: 'سۈزگۈچ',
      unArrange: 'تەرتىپلەنمىگەن',
      arranged: 'تەرتىپلەنگەن',
      allArranged: 'ھەممىسى تەرتىپلەنگەن',
      preLayout: 'ئالدىن تەرتىپلەش',
    },
    search: {
      placeholder: 'ئىزدەش مەزمۇنىنى كىرگۈزۈڭ',
      orderName: 'زاكاز ئاتى',
      sender: 'ئەۋەتكۈچى',
    },
    importTask: 'ۋەزىپە ئەكىرىش',
    refuseTask: 'ۋەزىپىنى رەت قىلىش',
    refreshList: 'تىزىملىكنى يېڭىلاش',
    refuseRecord: 'رەت قىلىش خاتىرىسى',
    taskCard: {
      sender: 'ئەكىرىلگەن ماتېرىال تىزىملىكى',
      delivery: 'يەتكۈزۈش ۋاقتى',
      delete: 'ئۆچۈرۈش',
      addToBatch: 'جەمئىيگە قوشۇش',
      save: 'ساقلاش',
      arrange: 'بىۋاستە تەرتىپلەش',
      deleteTitle: 'ۋەزىپىنى ئۆچۈرۈش',
      checkDetail: 'تەپسىلاتىنى كۆرۈش ئۈچۈن چېكىڭ',
      refuse: 'رەت قىلىش',
      refuseTime: 'رەت قىلىش ۋاقتى',
      refuseReason: 'رەت قىلىش سەۋەبى',
      refuseTask: 'ۋەزىپىنى رەت قىلىش',
      refuseTaskPlaceholder: 'رەت قىلىش سەۋەبىنى كىرگۈزۈڭ',
      addErr: 'قوشۇش مەغلۇپ بولدى، كېيىن قايتا سىناڭ!',
    },
    batchNo: 'پارتىيە نومۇرى',
    batchArrange: 'جەمئىي تەرتىپلەش',
    clearBatch: 'قۇرۇلمىنى تازىلاش',
    taskCount: 'نۆۋەتتىكى ۋەزىپە سانى:',
    checkAllTask: 'بارلىق ماتېرىال تىزىملىكىنى كۆرۈش',
    clearSuccess: 'تازىلاش مۇۋەپپەقىيەتلىك بولدى',
    record: {
      batchNo: 'پارتىيە',
      saveDate: 'ساقلاش ۋاقتى',
      materialList: 'ماتېرىال تىزىملىكى',
      arrangedRecord: 'تەرتىپلەش پىلانى',
      searchPlaceholder: 'پارتىيە نومۇرىنى كىرگۈزۈڭ',
      tipText: 'بۇ ساقلاشنى ئۆچۈرەمسىز؟',
    },
    errTip:
      'ئىشلەپچىقىرىشقا بولىدىغان ۋەزىپە سانلىق مەلۇماتى يوق، ۋەزىپە تىزىملىكىگە قوشۇڭ!',
    emptyErr:
      'بۇ ۋەزىپىدە تاختا سانلىق مەلۇماتى يوق، تەرتىپلەپ ئىشلەپچىقىرىشقا بولمايدۇ!',
    operationSuccess: 'مەشغۇلات مۇۋەپپەقىيەتلىك بولدى',
    operationFail: 'مەشغۇلات مەغلۇپ بولدى، كېيىن قايتا سىناڭ!',
    saveSuccess: 'ساقلاش مۇۋەپپەقىيەتلىك بولدى',
    plankMaxLimit: 'كىچىك تاختا سانى ئەڭ يۇقىرى تەرتىپلەش چېكىدىن ئاشتى',
    batchWorning: 'پارتىيە بويىچە ئىشلەپچىقىرىشنى تەشەببۇس قىلىڭ',
    batchEmptyErr:
      'زاكاز تىزىملىكىدە تاختا سانلىق مەلۇماتى يوق، جەمئىي ئىشلەپچىقىرىشقا بولمايدۇ!',
    noRoomTip: 'ئۆي مەۋجۇت ئەمەس، جەمئىي ئىشلەپچىقىرىشقا بولمايدۇ!',
    refuseHistory: 'رەت قىلىش تارىخى',
  },
  materialPage: {
    distanceFromTop: 'يۇقىرىدىن ئارىلىق',
    distanceFromRight: 'ئوڭدىن ئارىلىق',
    hingeData: 'ئىلمەك سانلىق مەلۇماتى',
    labelBackProcTip:
      'بۇ يېڭىلاشتا چوڭ تاختىنىڭ ئارقا تەرىپىدىكى بېلگە ئۇسۇلى ئەڭ ياخشىلانغان، ھازىر بېلگىدىكى ئۇچۇر بويىچە چىگارلاش، تېشىك ئېچىش ۋە ئوچۇق ئېچىش كېرەك!',
    openDir: 'ئىشىك ئېچىش يۆنىلىشى',
    edgeEmptyTip: 'چىگارلاش قىممىتى بوش قالمايدۇ',
    edgeNumberLimit: 'چىگارلاش قىممىتىگە پەقەت رەقەم كىرگۈزۈشكە بولىدۇ',
    chooseSurplusQuantity:
      'ئىشلىتىشكە بولىدىغان قېلىنما {total} خىل، تاللانغان {quantity} خىل',
    noCanSaveInfo: 'ساقلىنىدىغان ئۇچۇر يوق',
    chooseBujianStoreQuantity:
      'جەمئىي {total} دانە تاماملىغۇچى، ئىشلىتىشكە بولىدىغان تاماملىغۇچى تاختىسى {quantity} دانە، تاللانغان {chooseQuantity} دانە',
    chooseAwaitStoreQuantity:
      'جەمئىي {total} دانە تەرتىپلەش بۇكىتىدىكى كىچىك تاختىلار، ئىشلىتىشكە بولىدىغان تاختىلار {quantity} دانە، تاللانغان {chooseQuantity} دانە',
    defaultFold: 'تاختا سانلىق مەلۇماتى سۈكۈتتىكى يىغىلىدۇ',
    startArrange: 'تەرتىپلەشنى باشلاڭ',
    importMaterial: 'ماتېرىال تىزىملىكىنى ئەكىرىش',
    saveToHistory: 'تارىخقا ساقلاش',
    addPlank: 'تاختا قوشۇش',
    deletePlank: 'تاختا ئۆچۈرۈش',
    batchEdit: 'جەمئىي تەھرىرلەش',
    moreSetting: 'تېخىمۇ كۆپ تەڭشەش',
    plankSize: 'تاختا ئۆلچىمى',
    plankSize2: 'ئۆلچەم (ئۇزۇنلۇق*كەڭلىك*قېلىنلىق)',
    searchPlaceholder: 'تاختا ئاتى/تاختا نومۇرى ئىزدەش',
    checkArranged: 'تەرتىپلەشنى كۆرۈش',
    checkErr: 'تەرتىپلەنمىگەن، كۆرەلمەيسىز!',
    downloadSheet: 'نۇمۇنىە چۈشۈرۈش',
    downloadSheetShowScientific: 'نۇمۇنىە چۈشۈرۈش (ئىلمىي كۆرسىتىش)',
    exportHoleSlotPDF: 'تاختا تېشىك-ئوچۇق رەسىمىنى چىقىرىش',
    style1: 'ئۇسلۇب 1',
    style2: 'ئۇسلۇب 2',
    style3: 'ئۇسلۇب 3',
    importNoCover: 'ماتېرىال تىزىملىكى ئەكىرىش قاپلىمايدۇ',
    openBujianPlankRecommend:
      'تاماملىغۇچى كىچىك تاختىنى تەۋسىيە قىلىشنى قوزغىتىش',
    openAwaitStorePlankRecommend:
      'تەرتىپلەش بۇكىتىدىكى كىچىك تاختىنى تەۋسىيە قىلىشنى قوزغىتىش',
    openSurplusPlankRecommend: 'قالدۇق چوڭ تاختىنى تەۋسىيە قىلىشنى قوزغىتىش',
    pushToAwaitStore: 'تەرتىپلەش بۇكىتىغا ساقلاش',
    setTableHead: 'ماتېرىال تىزىملىكى بېشىنى تەڭشەش',
    syncConfirm:
      'كابىنەت بىلەن بۇلۇت تەرتىپلىگۈچتىكى ئەسلى تاختا سانلىق مەلۇماتى ئوخشاش ئەمەس! كابىنەتتىكى ئەسلى تاختا سانلىق مەلۇماتى بىلەن ماسلىشىدۇ؟',
    handleSync: 'ئەسكەرتىش: ئەسلى تاختا باشقۇرۇشتا قولدا ماسلىشتۇرۇشقا بولىدۇ',
    noMoreTip: 'تېخىمۇ ئۇقتۇرۇش بەرمە',
    checkMore: 'تېخىمۇ كۆپ كۆرۈش',
    noHistory: 'ھازىرچە ساقلانغان تارىخ يوق…',
    sameOrder:
      'ئىشلەپچىقىرىش ھۆججىتى چىقىرىلغان زاكاز بار، قايتا ئىشلەپچىقىرىش مەسىلىسىگە دىققەت قىلىڭ!',
    markOrder: 'زاكاز بېتىدە ئىشلەپچىقىرىلغانلىقىنى بەلگىلىمەمسىز؟',
    editErrTip: 'كۆپ زاكاز ھازىرچە ئۇچۇرنى ئۆزگەرتىشكە يول قويمايدۇ',
    autoSaveSuccess: 'ئاپتوماتىك تارىخقا ساقلاندى!',
    goAdd: 'قوشۇشقا بارىش',
    noProductionLine:
      'بۇ خاتىرىدىكى ئىشلەپچىقىرىش لىنىيىسى ئۆچۈرۈلگەن، ماتېرىال تىزىملىكىگە بارىپ قايتا تەرتىپلەڭ',
    fileErr:
      'ھۆججەت مەزمۇنىنى تەھلىل قىلىش مەغلۇپ بولدى، ھۆججەت مەزمۇنىنى جەزملەشتۈرۈڭ!',
    noCheckData: 'سانلىق مەلۇمات تېپىلمىدى!',
    noArrangeTip: 'تەرتىپلەش پىلانىنى بىۋاستە كۆرەلمەيسىز، تەرتىپلەشنى باشلاڭ!',
    tooOld: 'بەش/ئالتە يۈزلىك ماتور تەڭشىكى بەك ئەسكىچە',
    contactUs:
      'سېتىشتىن كېيىنكى مۇلازىمەت خادىمى بىلەن ئالاقىلىشىپ قايتا بىرلەشتۈرۈڭ',
    pathErr:
      'تاختا ئىچىدە غەيرىي سانلىق مەلۇمات بار (تاختا نومۇرى: {errorPathPlankID})، تەكشۈرۈڭ!',
    sizeErr: 'بەك چوڭ تاختا بار! كىچىك تاختا ئۆلچىمى: {width}-{height}',
    noKnife:
      'ماتېرىال كېسىش پىچاق دىئامېتىرى تەڭشەلمىگەن، ماتېرىال كېسىش ماشىنىسى تەڭشىكىگە بارىپ پىچاق تەڭشەڭ!',
    sendFactorySuccess: 'فابرىكىغا ئەۋەتىش مۇۋەپپەقىيەتلىك بولدى',
    sendFactoryErr:
      'فابرىكىغا ئەۋەتىش مەغلۇپ بولدى، سېتىشتىن كېيىنكى مۇلازىمەت خادىمى بىلەن ئالاقىلىشىڭ',
    createTaskCard: 'ماس ۋەزىپە كارتا قۇرامسىز؟',
    surplusStatistics: 'ئىشلىتىشكە بولىدىغان قېلىنما جەمئىي',
    surplusStatistics2: 'تاللانغان',
    suplementStatistic:
      'دانە تاماملىغۇچى، ئىشلىتىشكە بولىدىغان تاماملىغۇچى تاختىسى جەمئىي',
    expand: 'يېيىش',
    packUp: 'يىغىش',
    importErr: 'توغرا ھۆججەت تاللاڭ!',
    sendLoadingText: 'NC ھۆججىتىنى بىر تەرەپ قىلىپ ئەۋەتىۋاتىدۇ......',
    arrangeLoading: 'جەمئىي',
    arrangeLoading2: 'دانە كىچىك تاختا تەرتىپلەشتە......',
    dealing: 'بىر تەرەپ قىلىۋاتىدۇ...',
    surplusHead: {
      shape: 'شەكىل',
      type: 'تىپ',
      remark: 'ئىزاھات',
      area: 'يەر مەيدانى(m²)',
    },
    surplusTip: 'ئىشلىتىشكە بولىدىغان قېلىنما يوق، ئاۋۋال',
    creatSurplus: 'يېڭى قېلىنما قۇرۇش',
    orderInfo: 'زاكاز ئۇچۇرى',
    suplement: {
      statistic:
        'جەمئىي{total}دانە تاماملىغۇچى، ئىشلىتىشكە بولىدىغان تاماملىغۇچى تاختىسى {useable}دانە، تاللانغان{selected}دانە',
      exportSuplement: 'تاماملىغۇچى تىزىملىكىنى چىقىرىش',
      suplementLoading: 'تاماملىغۇچى سانلىق مەلۇماتى يۈكلىنىۋاتىدۇ',
      exportSuplementIng: 'تاماملىغۇچى تىزىملىكى چىقىرىلىۋاتىدۇ',
      noMatchSup: 'ئىشلىتىشكە بولىدىغان تاماملىغۇچى سانلىق مەلۇماتى تېپىلمىدى',
      exportTip: 'كەم دېگەندە بىر تاماملىغۇچى تاللاپ چىقىرىڭ',
    },
    empty: 'ھازىرچە تاختا سانلىق مەلۇماتى يوق',
    paibanSetting: {
      title: 'تەرتىپلەش تەڭشىكى',
      selectProduction: 'ئىشلەپچىقىرىش لىنىيىسى تاللاش',
      arrangeWay: 'تەرتىپلەش پىلانى تاللاش',
      suitText: 'ئىشلىتىش',
      suitEquipmentNormal: 'ئادەتتىكى ماتېرىال كېسىش ماشىنىسى',
      suitEquipmentSpecial: 'ئەينەك كېسىش ماشىنىسى، ئارا ماشىنىسى',
      lessRoll: 'ئاز ئۆرۈلۈش',
      computedSpecial: 'ئالاھىدە ھېسابلاش',
      specialTip: 'تەرتىپلەش سۈرئىتىگە تەسىر قىلىدۇ',
      irregularNesting: 'ئالاھىدە تۈزۈلۈش',
      primaryNesting: 'ئادەتتىكى تۈزۈلۈش',
      specialShapedPanel: 'ئالاھىدە تۈزۈلگەن ئادەتتىكى تاختا',
      limitedTimeTrial: 'چەكلىمىسىز',
      seniorShape: 'ئالى تۈزۈلۈش',
      heteromorphicInlay: 'ئالاھىدە تۈزۈلگەن ئالاھىدە تاختا',
      autoCutSurplus: 'قالدۇق ماتېرىال ئاپتوماتىك كېسىش',
      cutWay: 'كېسىش ئۇسۇلى',
      maxRect: 'ئەڭ چوڭ تۆت تەرەپلىك شەكلىگە كېسىش',
      fixedRect: 'مۇقىم ئۆلچەمگە كېسىش',
      minRectSetting: 'ئەڭ كىچىك ئۆلچەمگە كېسىش',
      firstCutL: 'L شەكلىدىكى قېلىنما ئاۋۋال ھاسىل قىلىش',
      firstCutLTip:
        'قوزغىتىلغاندا، شەرتنى قاندۇرىدىغان تۆت تەرەپلىك تاختىلارنى L شەكلىدىكى قېلىنما شەكلىگە كېسىش ئاۋۋاللىقى بېرىلىدۇ',
      shortLine: 'قىسقا تەرەپ چوڭراق',
      longLine: 'ئۇزۇن تەرەپ چوڭراق',
      surplusTemplate: 'قالدۇق ماتېرىال ئادەتتىكى قېلىپ',
      add: 'قوشۇش',
      templateErr:
        'ئادەتتىكى ئۆلچەمگە كېسىش تاللانغاندا، كەم دېگەندە بىر سانلىق مەلۇمات قېلىپى تاللىشىڭىز كېرەك',
      cutLong: 'كېسىش ئۇزۇنلۇقى',
      cutWidth: 'كېسىش كەڭلىكى',
      baseInfo: 'ئاساسلىق ئۇچۇر',
      continue: 'جەزملەشتۈرۈپ داۋاملاشتۇرۇش',
      standardSelect: 'ئۆلچەملىك چوڭ تاختا تاللاش',
      customPlankOrder: 'خاس چوڭ تاختا تەرتىپلەش تەرتىپى',
      customPlankOrderDetail: 'تەرتىپلەنمىگەن كىچىك تاختا تىزىملىكى',
      customPlankOrderTip:
        'ئىچكى بۇلۇت تەرتىپلىگۈچ بۇ ئىقتىدارغا ئىناۋەتلىك ئەمەس',
      surplusPlank: 'قالدۇق كىچىك تاختا تىزىملىكى',
      overSizePlank: 'چوڭ ئۆلچەملىك تاختا تىزىملىكى',
      plankOrder: 'تەرتىپلەش تەرتىپى',
      plankType: 'تاختا ماتېرىالى تىپى',
      plankSize: 'تاختا ئۆلچىمى',
      plankEdgeOff: 'چىگارلاش قىممىتى',
      amount: 'ماتېرىال سانى',
      amountMax: 'ئەڭ كۆپ ماتېرىال سانى',
      numAndOverSizeTip:
        'چوڭ ئۆلچەملىك تاختىلار يېتەرلىك بولمىغانلىقى ياكى تەرتىپلەشكە بولمايدىغان كىچىك تاختىلار بار',
      numErrorTip: 'چوڭ تاختىلار يېتەرلىك بولمىغانلىقى تەرتىپلەشكە بولمايدۇ',
      overSizeTip: 'چوڭ ئۆلچەملىك تاختىلار بار تەرتىپلەشكە بولمايدۇ',
      restPartList: 'تەرتىپلەنمىگەن كىچىك تاختا تىزىملىكى',
      layoutOrder: 'تەرتىپلەش تەرتىپى',
      arrangeAmount: 'ماتېرىال سانى',
    },
    // ... (剩余部分类似翻译)
  },
  arrangedPage: {
    slotErrTip:
      'نۆۋەتتىكى تاختا بۆلەكلىرى چېگرا ئىشلەنگەن ئويۇقى بار، قايتا تەرتىپلەشكە قوللىمايدۇ!',
    lockedPaibanTip: 'چوڭ تاختا قۇلۇپلانغان، قايتا تەرتىپلەشكە بولمايدۇ',
    batchBujianErrTip:
      'نۆۋەتتىكى تاختا بۆلەكلىرى ماتېرىيال تىزىمىدىكى ئەسلى سانلىق مەلۇماتلاردا يوق، قوشۇمچە بۆلەك/ساقلىنىدىغان تەرتىپلەش ئامبىرىدىن قايتا تەرتىپلەشنى تاللىيالايسىز',
    useFolderIsNot:
      'سىزنىڭ ھۆججەت چۈشۈرۈش ساقلاش ئورنىڭىز "{type}" تېپىلمىدى! بۇ قىسقۇچ ئۆچۈرۈلگەن-يوقلۇقىنى تەكشۈرۈڭ، ئەگەر يۆتكەلىدىغان U دىسكىنى ئىشلەتسىڭىز U دىسكا ئۇلانمىسىنى تەكشۈرۈڭ',
    useFolderIsNot1:
      'ھۆججەت چۈشۈرۈش ئورنىنى تەڭشەمىگەنلىكىڭىزنى بايقىدىم، ئاۋۋال مۇندەرىجىنى تاللاڭ',
    downloadNC: 'NC چۈشۈرۈش',
    downloadNC1: 'NC ھۆججىتىنى چۈشۈرۈش',
    reArrange: 'قايتا تەرتىپلەش',
    saveToHistory: 'تارىخقا ساقلاش',
    moreSetting: 'تېخىمۇ كۆپ',
    followExportTag: 'NC چۈشۈرۈش بىلەن بەلگە ھۆججىتىنى چىقىرىش',
    tagTemplateSelect: 'بەلگە قېلىپىنى تاللاش',
    showCuttingSequence: 'كېسىش تەرتىپىنى كۆرسەت',
    showPlankSize: 'كىچىك تاختا ئۆلچىمىنى كۆرسەت',
    sendToFactory: {
      title: 'سېلىق زاۋۇتىغا يوللاش',
      fileType: 'بەلگە ھۆججەت تىپى',
    },
    checkData: 'سانلىق مەلۇمات تولۇقلىقىنى تەكشۈرۈۋاتىدۇ...',
    dataLoading: 'يۈكلىنىۋاتىدۇ......',
    addToBujianStore: 'قوشۇمچە بۆلەك ئامبىرىغا قوشۇۋاتىدۇ...',
    updateTaskStatus: 'ۋەزىپە ھالىتىنى يېڭىلاۋاتىدۇ...',
    sending: 'سېلىق زاۋۇتىغا يوللىنىۋاتىدۇ',
    waitLoadding: 'ساقلاۋاتىدۇ',
    loadding: 'يۈكلىنىۋاتىدۇ',
    finish: 'تامام',
    loadSetting: 'تەڭشەكنى يۈكلەش',
    loadPreference: 'ئارزۇ تەڭشەكلىرىنى يۈكلەش',
    dealTemplate: 'قېلىپنى بىر تەرەپ قىلىش',
    initTagData: 'بەلگە سانلىق مەلۇماتىنى باشلاش',
    prepareDrawData: 'بەلگە سىزىش سانلىق مەلۇماتىنى تەييارلاش',
    drawDataText: 'بەلگە سىزىش سانلىق مەلۇماتى',
    genPdfTagFile: 'PDF بەلگە ھۆججىتىنى ھاسىل قىلىش',
    genPicTagFile: 'بەلگە رەسىم ھۆججىتىنى ھاسىل قىلىش',
    uploadFile: 'ھۆججەت يۈكلەش',
    dealNCFile: 'NC ھۆججىتىنى بىر تەرەپ قىلىش',
    zipFile: 'يەرلىك قىسقا ھۆججەت ھاسىل قىلىش',
    plankCount: 'چوڭ تاختا سانى',
    rollCount: 'تەتۈرلەش سانى',
    totalRate: 'ئومۇمىي ئۈنۈملۈك نىسبىتى',
    rate: 'ئۈنۈملۈك نىسبىتى',
    useArea: 'ئۈنۈملۈك ئىشلەنگەن يەر',
    materialList: 'ماتېرىيال تىزىمى',
    saveSurplus: 'قالدۇق ماتېرىياللارنى ئامبارغا ساقلاش',
    workeShop: 'سېتىۋېلىش تىزىمى',
    holeSlotErr: 'تاختا بۆلەكلىرىدىن تېشىك/ئويۇق چوڭقۇرلۇقى بۇزۇلۇشى بايقىلدى!',
    knivesErr: 'تاختا بۆلەكلىرىنى ئىشلەش پىچاقلىرى تېپىلمىدى!',
    checkDetail: 'تەپسىلاتىنى كۆرۈش',
    deepErr: {
      title: 'تاختا بۆلەكلىرى تېشىك/ئويۇق چوڭقۇرلۇقى بۇزۇلۇشى',
      errStatistic: '{count} دانە كىچىك تاختا بۇزۇلۇش بار',
      errText: 'بۇزۇلغان بۆلەك',
      markErrPart: 'بۇزۇلغان بۆلەكنى بەلگىلەش',
    },
    warningTip:
      'ئىككى تەرەپلىك ئىشلەنگەن يورۇق تاختا: ئاۋۋال <span class="font-style">يورۇق تەرەپ</span>نى ئىشلەڭ، ئاندىن <span class="font-style">قورۇق تەرەپ</span>نى ئىشلەڭ; بىر تەرەپلىك ئىشلەنگەن يورۇق تاختا: بىۋاسىتە <span class="font-style">قورۇق تەرەپ</span>نى ئىشلەڭ',
    showDetail: {
      tip1: 'بىر تاختا بۆلەكنى تاللاش ئۈچۈن بىر قېتىم چېكىڭ',
      tip2: 'تاختا بۆلەك بەلگىسىنى كۆرۈش',
    },
    arrangeTip: '(ئۆزگىچە شەكىللىك تەرتىپلەشنى قوللايدۇ)',
    plankOpration: 'تاختا بۆلەك مەشغۇلاتى',
    rotate: '90 گرادۇس بۇرۇش',
    notCare: 'تاختا بۆلەكتە تۈزۈلمە بار',
    editRemark: 'ئىزاھاتنى تەھرىرلەش',
    clearRemark: 'ئىزاھاتنى تازىلاش',
    recalcIndex: 'كېسىش نۇقتىسى ۋە كېسىش تەرتىپىنى قايتا ھېسابلاش',
    recalcIndexSuccess:
      'كېسىش نۇقتىسى ۋە كېسىش تەرتىپىنى قايتا ھېسابلاش مۇۋەپپەقىيەتلىك بولدى',
    storageArea: {
      title: 'تاختا بۆلەك ساقلىغۇچى',
      empty: 'تاختا بۆلەك ساقلانمىغان',
      storageTip: 'تاختا بۆلەكنى بۇ يەرگە سۆرەپ ساقلىيالايسىز',
    },
    subtleArrange: 'نەپىس تەرتىپلەش',
    addPlank: 'چوڭ تاختا قوشۇش',
    deletePlank: 'چوڭ تاختا ئۆچۈرۈش',
    deletePlankErr:
      'نۆۋەتتىكى چوڭ تاختىدا كىچىك تاختا بار, چوڭ تاختا ئۆچۈرۈلمەيدۇ!',
    useSurplus: {
      title: 'قالدۇق ماتېرىياللارنى ئىشلەت',
    },
    dragWarning: 'تاختا بۆلەكنى چوڭ تاختىغا سۆرەڭ!',
    areaWarning: 'تاختا بۆلەك قويۇلمىدى, بوش ئورۇن چوڭلۇقىغا دىققەت قىلىڭ',
    subtlePage: {
      cut: 'قالدۇق ماتېرىياللارنى كېسىش',
      cancelCut: 'كېسىشتىن ۋاز كېچىش',
      cutPoint: 'قالدۇق ماتېرىيال كېسىش يېپىشىش نۇقتىسى',
      storageArea: 'تاختا بۆلەك ساقلىغۇچى',
      cutErr: 'پەقەت چوڭ تاختىدا كېسىشكە بولىدۇ, كىچىك تاختىغا تەگكۈزمەڭ!',
      saveErr: 'ئاۋۋال بىر كېسىلگەن قالدۇق ماتېرىيال تاللاڭ',
      cutErr2: 'پەقەت چوڭ تاختىدا كېسىشكە بولىدۇ, چوڭ تاختىدىن چىقىپ كەتمەڭ!',
      selectTip: 'بىر كېسىلگەن قالدۇق ماتېرىيال تاللاڭ',
      noCutPath: 'ئۆزگىچە شەكىللىك بۆلەكلەر تۆت تەرەپلىك كېسىلمەيدۇ',
      cutOperate: 'قالدۇق ماتېرىيال كېسىش مەشغۇلاتى',
      line: 'قولدا سىزىش',
    },
    shortCut: 'تېزلەتمە',
    updownRoll: 'ئۈستە-ئاستا سۆرەش',
    leftRightRoll: 'سول-ئوڭ سۆرەش',
    zoomScale: 'چوڭايتىش-كىچىكلەشتۈرۈش',
    dragCanvas: 'بەتنى سۆرەش',
    mouseRoll: 'چاشقىنەك گۈلدۈرمىسى',
    mouseRight: 'چاشقىنەك ئوڭ كۇنپۇكا',
    doublePlank: 'چوڭ تاختىنى قوش چېكىش',
    keyboard: 'ھەرپ تاختا',
    picSymble: 'رەسىم مىساللىرى',
    funished: 'مەھسۇلات',
    holeSlotConflict1: 'تېشىك/ئويۇق توقۇنۇشى',
    plankErr: 'تاختا بۆلەك توقۇنۇشى',
    frontSlot: 'ئالدىنقى تەرەپ ئويۇقى',
    backSlot: 'ئارقا تەرەپ ئويۇقى',
    frontHole: 'ئالدىنقى تەرەپ تېشىكى',
    backHole: 'ئارقا تەرەپ تېشىكى',
    resetScale: 'چوڭ-كىچىكلىكنى قايتا تەڭشەش',
    maxLong: 'ئەڭ ئۇزۇن',
    tempErr: 'قوشۇش مەغلۇپ بولدى, قېلىپ ئورنى خاتا ياكى قېلىپ بەك چوڭ!',
    sendErr:
      'سېلىق زاۋۇتىغا يوللاش مەغلۇپ بولدى، بەلگە قېلىپى ۋە تاختا بۆلەك سانلىق مەلۇماتىنى تەكشۈرۈپ قايتا يوللاڭ',
    someTips:
      'سىز بۇلۇت تەرتىپلەش ئۆزگىچە شەكىللىك تەرتىپلەش ئىقتىدارىنى ۋاقىتلىق سىناشقا ئېلىپ بارىسىز، ئىشلەپچىقىرىش مەسىلىلىرىدىن ساقلىنىڭ!',
    cutOrderErr:
      'كېسىش تەرتىپى خاتا، كېسىش نۇقتىسى ۋە كېسىش تەرتىپىنى قايتا ھېسابلاڭ!',
    holeSlotConflict:
      'تاختا بۆلەكلىرىدىن تېشىك/ئويۇق توقۇنۇشى ياكى كىچىك تاختىدىن چىقىپ كەتكەنلىكى بايقىلدى!',
    exportSetting: 'چىقىرىش تەڭشەكلىرى',
    specialPaibanErr:
      'تاختا بۆلەكلىرى ئالاھىدە پىروگرامما ياكى چېگرا ئىشلەنگەن ئويۇقلارغا ئىگە، نۆۋەتتە قايتا تەرتىپلەشكە قوللىمايدۇ!',
    moreResult: 'تېخىمۇ كۆپ تەرتىپلەش نەتىجىسى',
    moreResultTip:
      'چوڭ تاختىدا چېگرا ئىشلەنگەن ئويۇق بار بولسا، چوڭ تاختا قايتا تەرتىپلەشكە قاتناشمايدۇ',
    batchBujian: 'بىر قانچە قوشۇمچە بۆلەك',
    disPlaySetting: 'كۆرسىتىش تەڭشەكلىرى',
    stored: 'ئامبارغا ساقلانغان',
    onlySurplus: 'پەقەت قالدۇق ماتېرىياللارنى كۆرسەت',
    UnuseSurplus: 'قالدۇق ماتېرىياللار پايدىلىنىش نىسبىتىگە كىرمەيدۇ',
    usePlankAdsorption: 'تاختا بۆلەك يېپىشىش ئىقتىدارى',
    dataDefaultCollapsed: 'سانلىق مەلۇمات سۈكۈتتىن قاتلايدۇ',
    unit: 'دانە',
    cutSurplus: 'قالدۇق ماتېرىياللارنى كېسىش',
    frontWarningTip:
      'ئىككى تەرەپلىك ئىشلەنگەن يورۇق تاختا: ئاۋۋال <span class="font-style">قورۇق تەرەپ</span>نى ئىشلەڭ، ئاندىن <span class="font-style">يورۇق تەرەپ</span>نى ئىشلەڭ<span>; &nbsp; بىر تەرەپلىك ئىشلەنگەن يورۇق تاختا: بىۋاسىتە </span <span class="font-style">يورۇق تەرەپ</span>نى ئىشلەڭ',
    selectTip: 'تۆۋەندىن قوشۇمچە بۆلەك تاللاڭ!',
    plankKeyWordPlaceholder: 'تاختا بۆلەك نامى ياكى نومۇرىنى كىرگۈزۈڭ',
    surplusStoreSuccess: 'ئامبارغا ساقلاش مۇۋەپپەقىيەتلىك بولدى',
    editCutOrder: 'كېسىش تەرتىپىنى تەھرىرلەش',
    setCutPoint: 'كېسىش نۇقتىسىنى تەڭشەش',
    specialPaibanChangeErr:
      'تاختا بۆلەكلىرى ئالاھىدە پىروگرامما ياكى چېگرا ئىشلەنگەن ئويۇقلارغا ئىگە، نۆۋەتتە تەرتىپلەش پىلانىنى ئالماشتۇرۇشقا قوللىمايدۇ!',
    storedSuccess:
      'قوشۇمچە بۆلەك مۇۋەپپەقىيەتلىك بولدى! تاللانغان تاختا بۆلەكلىرى قوشۇمچە بۆلەك باشقۇرۇشىغا سېلىندى!',
    noSurplus: 'ئىشلىتىشكە بولىدىغان قالدۇق ماتېرىيال تېپىلمىدى',
    selectErrTip:
      'قالدۇق ماتېرىيال تاختا بۆلەكلىرى قوشۇمچە بۆلەك بولالمايدۇ، ئاۋۋال نورمال كىچىك تاختا تاللاڭ',
    noRoomIDTip:
      'بۇ تاختا بۆلەكنىڭ بۆلەك ID سى يوق، قوشۇمچە بۆلەك ئامبارغا ساقلىيالمايدۇ، ئاۋۋال باشقا كىچىك تاختا تاللاڭ',
    noPlank: 'تاختا بۆلەك تېپىلمىدى',
    noPaibanData: 'تەرتىپلەش سانلىق مەلۇماتى يوق، ئىشلەتكىلى بولمايدۇ',
    historyNameErr: 'پىلان نامىنى كىرگۈزۈڭ',
    tempErrTip:
      'بەش/ئالتە تەرەپلىك ئېگىزلىك ماتور قېلىپىنى قوشمىغانلىقىڭىزنى بايقىدىم، شۇڭا بەش/ئالتە تەرەپلىك ئېگىزلىك ماتور ئىشلەش ھۆججىتىنى چىقىرىپ بولمايدۇ. دىققەت: قوشۇپ بولغاندىن كېيىن قايتا تەرتىپلەش زۆرۈر!',
    changeDataTip:
      'دىققەت: سانلىق مەلۇمات ئۆزگەرگەنلىكىنى بايقىدىم، بۇ ئۆزگەرتىش مەزمۇنىنى ساقلايدىغان يېڭى تارىخ ھۆججىتى قۇرامسىز؟',
    upgrade: 'يېڭىلاش',
    upgradeTip: '، تۆۋەندىكى توپچىنى چېكىپ نەشرىنى يېڭىلىيالايسىز',
    plankOverEdge:
      'NC چۈشۈرۈش مەغلۇپ بولدى! تاختا بۆلەكلىرىنىڭ ئۈستىمە-ئۈستىمە ياكى چوڭ تاختىدىن چىقىپ كەتكەنلىكىنى تەكشۈرۈڭ',
    useSurplusTip: 'قالدۇق ماتېرىياللارنى چىقىرىۋېتىشكە رازىمۇ؟',
    noDataExport: 'چىقىرىشقا بولىدىغان تەرتىپلەش سانلىق مەلۇماتى يوق',
    noPlankExport: 'چىقىرىشقا بولىدىغان كىچىك تاختا سانلىق مەلۇماتى يوق',
    lockTip1: 'قۇلۇپنى ئېچىش ئۈچۈن چېكىڭ، قۇلۇپ ئېچىلغاندىن كېيىن',
    lockTip2: 'كىچىك تاختا ئورنى ۋە خاسلىقىنى تەھرىرلەيدۇ',
    unlockTip1: 'قۇلۇپلاش ئۈچۈن چېكىڭ، قۇلۇپلانغاندىن كېيىن',
    unlockTip2: 'كىچىك تاختا ئورنى ۋە خاسلىقىنى تەھرىرلەشمەيدۇ',
    plankLockErr: 'بۇ چوڭ تاختا قۇلۇپلانغان، قويۇشقا بولمايدۇ',
    lockPartEditTip: 'تاختا بۆلەك قۇلۇپلانغان، تەھرىرلەشكە بولمايدۇ',
    glassEquipmentTip:
      'ئىشلەپچىقىرىش لىنىيىسى ئەينەك كېسىش ماشىنىسى بولسا، تاختا بۆلەك يۆتكىلىشكە بولمايدۇ',
    expandedTip:
      'تاختا بۆلەكتە ئويۇق ئالاھىدە پىروگراممىسى بار، تەھرىرلەشكە قوللىمايدۇ!',
    expandedArrangeTip:
      'تاختا بۆلەكتە ئويۇق ئالاھىدە پىروگراممىسى بار، قايتا تەرتىپلەش زۆرۈر!',
    dragErrTip:
      'قويۇلمىدى, تاختا بۆلەك ماتېرىيالى، رەڭگى ۋە قېلىنلىق خاسلىقىنى تەكشۈرۈڭ!',
    subtleSaveTip:
      'نۆۋەتتە تاختا بۆلەك مەشغۇلاتى قىلىنغان ئەمما ساقلانمىغان، چىقىسىز؟',
    rollTip:
      'چوڭ تاختىدا ئۆتۈش تېشىكى/ئويۇقى بار، تەتۈرلەشكە تەۋسىيە قىلمايمىز!',
    glossRollErr: 'يورۇق تاختا تەتۈرلەشكە چەك قويۇلدى',
    lockDeleteErr: 'تاختا بۆلەك قۇلۇپلانغان، ئۆچۈرۈشكە بولمايدۇ',
    deleteConfirmTip: 'ئۆچۈرۈش ھەرىكىتىنى قايتۇرۇشقا بولمايدۇ',
    deleteContinueTip: 'تېخىمۇ داۋاملاشتۇرامسىز؟',
    deleteConfirm: 'ئۆچۈرۈشنى جازالامسىز؟',
    selectPlankTip:
      'بۇ مەشغۇلاتنى ئىجرا قىلىش ئۈچۈن ئاۋۋال تاختا بۆلەكنى تاللاڭ',
    storeFailTip:
      'قالدۇق ماتېرىياللارنى ئامبارغا ساقلاش مەغلۇپ بولدى! تاختا بۆلەكلىرىنىڭ ئۈستىمە-ئۈستىمە ياكى چوڭ تاختىدىن چىقىپ كەتكەنلىكىنى تەكشۈرۈڭ',
    repeatStoreErr:
      'نۆۋەتتىكى كېسىلگەن قالدۇق ماتېرىيال تاختا بۆلەك ئامبارغا ساقلانغان',
    surplusStoreFail:
      'ئامبارغا ساقلاش مەغلۇپ بولدى, تاختا بۆلەك تۆت تەرەپلىك ياكى L شەكىللىك تاختا ئەمەسلىكىنى تەكشۈرۈڭ!',
    plankConfilictErr:
      'بۇ نۇقتىغا قويۇشقا بولمايدۇ, باشقا تاختا بۆلەكلەر بىلەن توقۇنۇشۇپ كېتىدۇ',
    surplusErrTip:
      'بۇ نۇقتىغا قويۇشقا بولمايدۇ, خاتا قالدۇق ماتېرىيال ھاسىل قىلىدۇ',
    changeArrageSuccess: 'تەرتىپلەش پىلانىنى مۇۋەپپەقىيەتلىك ئالماشتۇردى',
    trialProduction: {
      trialProductionSample: 'سىناقلىق ئىشلەپچىقىرىش قابىسى',
      trialProduced: 'مەن سىناقلىق ئىشلەپچىقاردىم',
      trialWarning:
        'سىناقلىق قابا ئىشلەپچىقىرىپ تەكشۈرمىگەنلىكىڭىز سەۋەبىدىن سىزگە، باشقىلارغا ۋە بۇلۇت تەرتىپلەش يۇمشاق دېتالىغا كەلتۈرۈلگەن بارلىق زىيان ۋە يارامسىز ئەھۋاللارغا سىز شەخسىي جىددىي جاۋابكار بولىسىز',
      trialSlotTip:
        'سىناقلىق ئىشلەپچىقىرىشتا ئالدىنقى-ئارقا تەرەپ تېشىك/ئويۇق چوڭ-كىچىكلىكى، چوڭقۇرلۇقى، ئورنى ۋە ئورۇنلاشقان تەرەپنى ئىنچىكە تەكشۈرۈڭ،',
      trialSlotTip1: 'قابا سۈرەتنىڭ ئەكس ئەھۋالىدىن ساقلىنىڭ',
      trialSlotTip2: 'مەسىلىسى',
      pleaseUploadTrial: 'سىناقلىق ئىشلەپچىقىرىلغان قابا رەسىمىنى يۈكلەڭ',
      uploadTrial: 'قابا رەسىمىنى يۈكلەش',
    },
    awaitPlankStore: {
      store: 'تاختا بۆلەك ساقلىغۇچى',
      notTip: 'ئامبارغا ساقلانمىغانلىقىنى كۆرسەتمە',
      justLookCurrent: 'پەقەت نۆۋەتتىكى زاكاز كىچىك تاختىنى كۆرسەت',
      notInPushStorage: 'ئامبارغا ساقلانمىغان',
      beInStorage: 'ئامبارغا ساقلانغان',
      searchPlaceholder:
        'زاكاز ئىزدەپ تەرتىپلەش ئامبىرىدىكى كىچىك تاختىنى تېپىڭ',
      plankUploading: 'تاختا بۆلەك ساقلانىۋاتىدۇ',
      oneClickStorage: 'بىر كۇنپۇكا بىلەن تەرتىپلەش ئامبىرىغا ساقلاش',
      notUsePlank: 'ھازىرچە ئىشلىتىشكە بولىدىغان كىچىك تاختا يوق',
      notPlank: 'تاختا بۆلەك ساقلانمىغان',
      dragPlankInHere: 'تاختا بۆلەكنى بۇ يەرگە سۆرەپ ساقلىيالايسىز',
      surplusCannotBeStorage: 'قالدۇق ماتېرىياللار ئامبارغا ساقلانمايدۇ',
      plankHasBeenDeduction:
        'كىچىك تاختا چىقىرىۋېتىلگەن ياكى ئەسلى سانلىق مەلۇماتى يوق، ئامبارغا ساقلاش مۇمكىن ئەمەس',
      plankLoadErr: 'تاختا بۆلەك سانلىق مەلۇماتىنى يۈكلەش مەغلۇپ بولدى',
      taskTitle: 'تەرتىپلەش ئامبىرىدىكى تاختا بۆلەكنى يۈكلەش',
      dataGenErr: 'سانلىق مەلۇمات ھاسىل قىلىش خاتالىقى',
      pushSuccess: 'ئامبارغا ساقلاش مۇۋەپپەقىيەتلىك بولدى',
      pushErr: 'نۆۋەتسىز خاتالىق كۆرۈلدى، ئامبارغا ساقلاش مەغلۇپ بولدى',
      awaitBoxExistPlank:
        'تەرتىپلەش ئامبىرىدا ئامبارغا ساقلانمىغان كىچىك تاختا بار، ئامبارغا ساقلىغاندىن كېيىن مەشغۇلات قىلىش تەۋسىيە قىلىنىدۇ',
      checkPlankStatus: 'تاختا بۆلەك ھالىتىنى تەكشۈرۈۋاتىدۇ...',
    },
    batchSetCut: 'كېسىش نۇقتىسىنى بىر قانچە تاللاش',
    applyTo: 'ئىشلىتىش دائىرىسى',
    ncDownloadLifeText: {
      checkData: 'سانلىق مەلۇماتنى تەكشۈرۈۋاتىدۇ',
      checkDone: 'سانلىق مەلۇمات تەكشۈرۈش تامام',
      checkDataText: 'سانلىق مەلۇمات تەكشۈرۈش',
      loadSetting: 'تەڭشەكنى يۈكلەۋاتىدۇ',
      settingLoadDone: 'تەڭشەك يۈكلەش تامام',
      loadSettingText: 'تەڭشەك يۈكلەش',
      downloadNc: 'NC نى چۈشۈرۈۋاتىدۇ',
      ncDownloadDone: 'NC چۈشۈرۈش تامام',
      downloadText: 'NC چۈشۈرۈش',
      loadPreferenceSetting: 'ئارزۇ تەڭشەكلىرىنى يۈكلەۋاتىدۇ',
      PreferenceSettingLoadDone: 'ئارزۇ تەڭشەكلىرى يۈكلەش تامام',
      loadPreferenceText: 'ئارزۇ تەڭشەكلىرىنى يۈكلەش',
      dealTemp: 'قېلىپنى بىر تەرەپ قىلىۋاتىدۇ',
      TempDealDone: 'قېلىپ بىر تەرەپ قىلىش تامام',
      dealTempText: 'قېلىپ بىر تەرەپ قىلىش',
      beBeingGenTagFile: '{fileType} ھۆججىتى ھاسىل قىلىنىۋاتىدۇ',
      genTagFileDone: '{fileType} ھۆججىتى ھاسىل قىلىش تامام',
      genTagFileText: '{fileType} ھۆججىتى ھاسىل قىلىش',
      zip: 'يەرلىك قىسقا ھۆججەت ھاسىل قىلىنىۋاتىدۇ',
      zipDone: 'يەرلىك قىسقا ھۆججەت ھاسىل قىلىش تامام',
      genZipText: 'قىسقا ھۆججەت ھاسىل قىلىش',
      fileTypeText: 'رەسىم',
    },
    surplusCommonTemp: 'قالدۇق ماتېرىياللارنىڭ كۆپ ئىشلىتىلىدىغان قېلىپى',
    plankErrTip:
      'نۆۋەتتىكى ماتېرىيال پىلانىدا تەرتىپلەشكە بولىدىغان چوڭ تاختا يوق كىچىك تاختا بار!',
    overSizeTip: 'چوڭ تاختا سانى يېتەرسىز ياكى چوڭلۇقى چەكتىن ئاشتى',
    uselessPart: 'نۆۋەتتىكى چوڭ تاختىدا ئىشلىتىشكە بولىدىغان كىچىك تاختا يوق',
    splitArranged:
      'كىچىك تاختا سانى بەك كۆپ، بىر قانچە قېتىم تەرتىپلەش تەۋسىيە قىلىنىدۇ',
    arrangedErrTip:
      'تەرتىپلەش مەغلۇپ بولدى، چوڭ تاختا سانى يېتەرسىز ياكى كىچىك تاختا چوڭلۇقى چەكتىن ئاشقانلىقىنى بايقىدى',
    knivesModalTitle: 'تېشىك/ئويۇقنى ئىشلەش پىچاقلىرى تېپىلمىدى',
    cannotDownloadByKnivesErr:
      'NC چۈشۈرۈشكە بولمايدۇ، سىستېما تۆۋەندىكى تېشىك/ئويۇقنى ئىشلەش پىچاقلىرىنى تېپىلمىدى',
    viewPart: 'مەسىلىلىك كىچىك تاختىنى كۆرۈش',
    deductionModalTitle: 'چىقىرىۋېتىش نىشانىنى تاللاڭ',
    bujianDeduction: 'قوشۇمچە بۆلەك كىچىك تاختىسىنى چىقىرىۋېتىش',
    paibanDeduction: 'ساقلىغۇچىدىكى كىچىك تاختىنى چىقىرىۋېتىش',
    surplusDeduction: 'قالدۇق ماتېرىيال چوڭ تاختىسىنى چىقىرىۋېتىش',
    unlockPaiban: 'تەرتىپلەشكە قاتناشقان قالدۇق ماتېرىياللارنىڭ قۇلۇپىنى ئېچىش',
    maintain:
      'تەرتىپلەشكە قاتناشقان قالدۇق ماتېرىياللارنىڭ قۇلۇپىنى ساقلاپ قىلىش',
    lockModalText:
      'ئىشلىتىلىۋاتقان قالدۇق ماتېرىيال چوڭ تاختىسى باشقا زاكازغا ئىگە، قايتا تەرتىپلىسەن؟',
    lockNewSModalText:
      'نۆۋەتتە ئىشلىتىلىۋاتقان قالدۇق ماتېرىيال چوڭ تاختىسى باشقا زاكازغا ئىگە، NC نى داۋاملىق چۈشۈرەمسىز؟',
    rePaibanWarn:
      'ئىشلىتىلىۋاتقان قالدۇق ماتېرىيال چوڭ تاختىسى باشقا زاكازغا ئىگە، ئىشلىتىشكە بولىدىغان چوڭ تاختا يېتەرسىز، ماتېرىيال تىزىمىغا بېرىپ قايتا تەرتىپلەڭ',
    togglePlankTip:
      'تاختا بۆلەكتە ئويۇق ئالاھىدە پىروگراممىسى بار، ئالدىنقى-ئارقا تەرەپ ئالماشتۇرۇشقا قوللىمايدۇ!',
  },
  historyPage: {
    download: 'يەرلىككە چۈشۈرۈش',
    allType: 'ھەممىنى ساقلاش',
    handleSave: 'قولدا ساقلاش',
    autoSave: 'ئاپتوماتىك ساقلاش',
    allArrange: 'ھەممە تەرتىپلەش',
    searchPlaceholder: 'زاكاز نامى / تەرتىپلەش تۈرىنى ئىزدەش',
    trailProduction: {
      title: 'سىناقلىق ئىشلەپچىقىرىش قابىسى',
      lastTime: 'ئەڭ ئاخىرقى سىناقلىق ئىشلەپچىقىرىش ۋاقتى',
      recordList: 'سىناقلىق ئىشلەپچىقىرىش خاتىرىسى',
      start: 'سىناقلىق ئىشلەپچىقىرىشنى باشلاش',
      date: 'ۋاقىت',
      outputFrom: 'چىقىرىش ئورنى',
      empty: 'ھازىرچە سانلىق مەلۇمات يوق',
      record: 'يۈكلەنگەن سىناقلىق ئىشلەپچىقىرىش قابا رەسىم خاتىرىسى',
      tip1: 'دىققەت: نۆۋەتتىكى سىناقلىق زاكاز سۈكۈتتىكى ئىشلەپچىقىرىش سانلىق مەلۇماتىنى ئىشلىتىۋاتىدۇ',
      tip2: 'دىققەت: نۆۋەتتىكى سىناقلىق زاكاز ئەڭ يېڭى پىروگرامما تەڭشەكلىرىنى ئىشلىتىۋاتمىدى، سىناقلىق ئۆيگە بېرىپ قايتا ساقلىسىڭىز بولىدۇ!',
      download: 'ئىشلەش ھۆججىتىنى چۈشۈرۈش',
    },
    deleteTip: 'تۆۋەندىكى تارىخ خاتىرىسىنى ئۆچۈرۈشنى جازالامسىز؟',
    startDate: 'باشلانغان ۋاقىت',
    endDate: 'ئاخىرلاشقان ۋاقىت',
    lastWeek: 'ئەڭ يېقىنقى بىر ھەپتە',
    lastMonth: 'ئەڭ يېقىنقى بىر ئاي',
    lastSeason: 'ئەڭ يېقىنقى ئۈچ ئاي',
    toArrangePage: 'تەرتىپلەش بېتىگە يۆتكىلىۋاتىدۇ',
    notArrangedTip:
      'بۇ خاتىرىدە تەرتىپلەش ئەمەلگە ئاشمىغان، ئاۋۋال ماتېرىيال تىزىمىغا بېرىڭ!',
    toMaterialPage: 'ماتېرىيال تىزىمى بېتىگە يۆتكىلىۋاتىدۇ',
    lessSelectTip:
      'بۇ مەشغۇلاتنى ئىجرا قىلىش ئۈچۈن ئەلۋەتتە بىر خاتىرىنى تاللاڭ',
    downloadNc: 'ئىشلەش ھۆججىتىنى چۈشۈرۈش',
  },
  workShopPage: {
    warningTip:
      'بۇ ئىقتىدار ۋاقىتلىق سىناشقا ئېرىشىشكە بولىدۇ، زاكازلىرىڭىزنى مۇناسىۋەتلىك تەرتىپلەڭ!',
    fileManager: 'ئىشلەپچىقىرىش ھۆججەت باشقۇرۇش',
    selected: 'تاللانغان',
    suplement: 'قوشۇمچە بۆلەك',
    size: 'ئۆلچەم',
    saveToStore: 'قوشۇمچە بۆلەك ئامبىرىغا قوشۇش',
    enterArrange: 'تەرتىپلەشكە كىرىشۋاتىدۇ...',
    deleteTip:
      'بۇ سانلىق مەلۇماتنى ئۆچۈرگەندىن كېيىن قايتۇرۇشقا بولمايدۇ، ئۆچۈرۈشنى جازالامسىز؟',
    arrangeTip:
      'قوشۇمچە بۆلەك سانلىق مەلۇماتىنى تەھلىل قىلىۋاتىدۇ، سەل ساقلاڭ...',
    store: 'قوشۇمچە بۆلەك ئامبىرى',
    downloadFile: 'ئىشلەپچىقىرىش ھۆججىتىنى چۈشۈرۈش',
    notBujianFile: 'قوشۇمچە بۆلەك ھۆججىتى تېپىلمىدى',
    productLineDelete:
      'بۇ ئىشلەپچىقىرىش لىنىيىسى ئۆچۈرۈلگەن، NC ھۆججىتىنى چۈشۈرەلمەيسىز!',
  },
  basePlankManager: {
    saveTip: 'ئەسكەرتىش: ساقلاش ئۈچۈن Enter كۇنپۇكىنى بېسىڭ',
    offsetSize: 'چېگرا كېسىش ئۆلچىمى',
    overSizeTip:
      'ئۆلچەم بەك چوڭ بولسا، ئاۋۋال ئۆلچىمى چوڭراق چوڭ تاختا تاللىنىپ تەرتىپلىنىدۇ',
    addTip:
      'ئەگەر تاختا بۆلەك ئۆلچىمى چەكتىن ئاشسا، ئالاھىدە چوڭ تاختا قوشۇش سۆزلىشىش كۆزنىكى ئېچىلىدۇ',
    onlyLayoutTooBig:
      'چەكتىن ئاشقان چوڭ تاختا پەقەت چەكتىن ئاشقان كىچىك تاختىلارنى تەرتىپلەيدۇ',
    settingRemark:
      'ئەسكەرتىش: بۇ يەردىكى ئىقتىدار ئوڭ تەرەپتىكى تەڭشەككە يۆتكەلدى',
    searchPlaceholder: 'تاختا ماتېرىيالى/رەڭگىنى ئىزدەش',
    syncData: 'كابىنېت سانلىق مەلۇماتىنى ماس قىلىش',
    syncDataM: 'ئىشىك سانلىق مەلۇماتىنى ماس قىلىش',
    deletePlank: 'ئالاھىدە چوڭ تاختا ئۆچۈرۈش',
    addPlank: 'ئالاھىدە تاختا قۇرۇش',
    matCodePlaceholder: 'تاختا ماتېرىيالىنى تاللاڭ',
    thickPlaceHolder: 'قېلىنلىقىنى تاللاڭ',
    widthErr: 'تاختا كەڭلىكى بوش قالمايدۇ',
    heightErr: 'تاختا ئۇزۇنلۇقى بوش قالمايدۇ',
    matCodeErr: 'تاختا ماتېرىيالى بوش قالمايدۇ',
    colorErr: 'تاختا رەڭگى بوش قالمايدۇ',
    editPlank: 'تاختا تەھرىرلەش',
    deleteTip:
      'بۇ سانلىق مەلۇماتنى ئۆچۈرگەندىن كېيىن قايتۇرۇشقا بولمايدۇ، ئۆچۈرۈشنى جازالامسىز؟',
    plankKnifeSelect: 'ئەسلى تاختا كېسىش پىچاق تاللاش',
    knifeSelectTip: 'ئەسكەرتىش: ئىچكى تەرتىپلەش بۇ ئىقتىدارنى قوللىمايدۇ',
    knife: 'كېسىش پىچاقلىرى',
    matcodeInput: 'تاختا ماتېرىيالىنى كىرگۈزۈڭ',
    thickInput: 'تاختا قېلىنلىقىنى كىرگۈزۈڭ',
    default: 'سىستېما سۈكۈتى',
    unessential: 'بۇ تىزىملىك: تولۇق كىرگۈزۈش شەرت ئەمەس',
    emptyTip:
      'ئەسكەرتىش: بۇ جەدۋەلنى تولدۇرمىسىڭىز، ئەسلى تاختا ماسلىشىشتا بۇ تۈرگە پەرۋا قىلمايدۇ!',
    defaultUse: 'سۈكۈتتىكى تاللاش',
    editMatcode: 'ماتېرىيال تەھرىرلەش',
    syncTip:
      '{fromMc} دىكى سانلىق مەلۇماتنى ئېلىپ، بۇلۇت تەرتىپلەشتىكى ئەسلى تاختا باشقۇرۇش سانلىق مەلۇماتىنى ئۆزگەرتىدۇ، ماس قىلامسىز؟',
    sameErr: 'ئوخشاش سانلىق مەلۇمات مەۋجۇت',
    cancelDelete: 'ئۆچۈرۈشتىن ۋاز كەچتى',
    syncErr: 'ماس قىلىش مەغلۇپ بولدى',
    syncSuccess: 'ماس قىلىش مۇۋەپپەقىيەتلىك بولدى',
    syncDataTip: '{fromMc} سانلىق مەلۇماتىنى ماس قىلىش',
    editErrTip: 'تولدۇرۇلمىغان ئۇچۇر بار، تەكشۈرۈڭ!',
    numberCheck: '{type} پەقەت رەقەم كىرگۈزۈشكە بولىدۇ!',
    deleteStandardPlate: 'ئۆلچەملىك تاختا ئۆچۈرۈش',
    createStandardPlate: 'ئۆلچەملىك تاختا قۇرۇش',
    editStandardPlate: 'ئۆلچەملىك تاختا تەھرىرلەش',
    trimmingDimension: 'چېگرا كېسىش ئۆلچىمى',
    front: 'ئۈستى',
    back: 'ئاستى',
    left: 'سول',
    right: 'ئوڭ',
    frontTrim: 'ئۈستى چېگرا كېسىش',
    backTrim: 'ئاستى چېگرا كېسىش',
    leftTrim: 'سول چېگرا كېسىش',
    rightTrim: 'ئوڭ چېگرا كېسىش',
    customTrim: 'ئىختىيارى چېگرا كېسىش قىممىتى',
    trimValue: 'چېگرا كېسىش قىممىتى',
    notEmpty: '{type} بوش قالمايدۇ!',
    edgeDir: 'چېگرا كېسىش ({type})',
  },
  bujianAndStore: {
    bujianStore: 'قوشۇمچە بۆلەك ئامبىرى',
    awaitStore: 'تەرتىپلەشكە ساقلىنىدىغان تاختا بۆلەك ئامبىرى',
    depositAwaitStore: 'ساقلىنىدىغان تەرتىپلەش ئامبىرىغا ساقلاش',
    pushToAwaitStore: 'ساقلىنىدىغان تەرتىپلەش ئامبىرىغا قويۇش',
    useTwoTableArrange: 'ئىككى جەدۋەل ئارقىلىق تەرتىپلەش',
    confirmUseTwoTableTip:
      'تاللانغان كىچىك تاختىلار قوشۇمچە بۆلەك ئامبىرى ۋە ساقلىنىدىغان تەرتىپلەش ئامبىرىدىكى كىچىك تاختىلارنى ئۆز ئىچىگە ئالىدۇ، تەرتىپلەشنى داۋاملاشتۇرامسىز؟',
    confirmAddPlankToAwaitStore:
      'تاللانغان تاختا بۆلەكلەرنى ساقلىنىدىغان تەرتىپلەش ئامبىرىغا قوشۇشنى جازالامسىز؟',
    pushStoreSuccess: 'ئامبارغا ساقلاش مۇۋەپپەقىيەتلىك بولدى',
    dataGenErr: 'سانلىق مەلۇمات ھاسىل قىلىش خاتالىقى',
    unknownErr: 'نۆۋەتسىز خاتالىق كۆرۈلدى، ئامبارغا ساقلاش مەغلۇپ بولدى',
  },
  surplusPage: {
    surplusMaterial: 'قالدۇق ماتېرىيال تىزىملىكى',
    surplusStore: 'قالدۇق ماتېرىيال ئامبىرى',
    surplusShape: 'قالدۇق ماتېرىيال شەكلى',
    batchTransfer: 'بىر قانچە يۆتكەش',
    createNewSurplusStore: 'يېڭى قالدۇق ماتېرىيال ئامبىرى قۇرۇش',
    pushStoreSetting: 'قالدۇق ماتېرىيال ئامبارغا ساقلاش تەڭشەكلىرى',
    surplusStoreName: 'قالدۇق ماتېرىيال ئامبىرى ئىسمى',
    surplusStoreName2: 'قالدۇق ماتېرىيال ئامبىرى ئىسمى',
    addSurplusStore: 'قالدۇق ماتېرىيال ئامبىرى قوشۇش',
    inventoryCeiling: 'ئامبار چېكى',
    autoTransferTip:
      'ئۆچۈرۈلگەندىن كېيىن قالدۇق ماتېرىيال ئامبىرىدىكى قالدۇقلار ئاپتوماتىك ھالدا قالدۇق ماتېرىيال تىزىملىكىگە يۆتكىلىدۇ',
    asIndexPushStore: 'ئامبار ساقلاش ئەھۋالى بويىچە تەرتىپلىك ساقلاش',
    pushToSurplusMaterial: 'قالدۇق ماتېرىيال تىزىملىكىگە ساقلاش',
    surplusStoreTable: {
      number: 'نومۇرى',
      name: 'قالدۇق ماتېرىيال ئامبىرى ئىسمى',
      quantity: 'ساقلانغان قالدۇق ماتېرىيال سانى(دانە)',
      capacity: 'قالدۇق ماتېرىيال ئامبىرى چېكى(دانە)',
      rules: 'قالدۇق ماتېرىيال ئامبارغا ساقلاش قائىدىسى',
      remark: 'ئىزاھات',
      operation: 'مەشغۇلات',
    },
    editSurplusStore: 'قالدۇق ماتېرىيال ئامبىرىنى تەھرىرلەش',
    addSurplus: 'قالدۇق ماتېرىيال قوشۇش',
    deletSurplus: 'قالدۇق ماتېرىيال ئۆچۈرۈش',
    editSurplus: 'قالدۇق ماتېرىيالنى تەھرىرلەش',
    surplusNoRoll: 'قالدۇق ماتېرىيال تەتۈرلەشمەسلىك ئىقتىدارىنى قوزغىتىش',
    type: {
      all: 'ھەممە شەكىل',
    },
    allMatcode: 'ھەممە ماتېرىيال',
    allThick: 'ھەممە قېلىنلىق',
    surplusName: 'قالدۇق ماتېرىيال ئىسمى',
    rectSurplus: 'تۆت تەرەپلىك قالدۇق ماتېرىيال',
    LTypeSurplus: 'L شەكىللىك قالدۇق ماتېرىيال',
    noMatchSup: 'ئۇچۇر تېپىلمىدى',
    jumpTo: 'بۇ يەرگە سەكرەش',
    page: 'بەت',
    newMatcode: 'يېڭى ماتېرىيال قۇرۇش',
    newColor: 'يېڭى رەڭ قۇرۇش',
    autoCreateColor: 'Enter كۇنپۇكىنى بېسىپ رەڭ خىتىنى ئاپتوماتىك قۇرالايسىز',
    nameLimit: 'قالدۇق ماتېرىيال ئىمى ئەڭ كۆپ 10 ھەرپ كىرگۈزۈشكە بولىدۇ',
    nameLimitNameErr: 'قالدۇق ماتېرىيال ئىسمى بوش قالمايدۇ',
    tempLongTip: 'تاختا ئۇزۇنلۇقى پەقەت رەقەم كىرگۈزۈشكە بولىدۇ',
    minLongLimit: 'قىسقا تەرەپ ئۇزۇنلۇقى پەقەت رەقەم كىرگۈزۈشكە بولىدۇ',
    minLongEmptyTip: 'قىسقا تەرەپ ئۇزۇنلۇقى بوش قالمايدۇ',
    minLongLimitMax: 'قىسقا تەرەپ ئۇزۇنلۇقى تاختا ئۇزۇنلۇقىدىن ئاشمايدۇ',
    widthLimit: 'تاختا كەڭلىكى پەقەت رەقەم كىرگۈزۈشكە بولىدۇ',
    minWidthLimit: 'قىسقا تەرەپ كەڭلىكى پەقەت رەقەم كىرگۈزۈشكە بولىدۇ',
    minWidthEmptyLimit: 'قىسقا تەرەپ كەڭلىكى بوش قالمايدۇ',
    minWidthLimitMax: 'قىسقا تەرەپ كەڭلىكى تاختا كەڭلىكىدىن ئاشمايدۇ',
    thickLimit: 'تاختا قېلىنلىقى پەقەت رەقەم كىرگۈزۈشكە بولىدۇ',
    thickEmptyLimit: 'تاختا قېلىنلىقى بوش قالمايدۇ',
    countInputLimit: 'سان پەقەت پۈتۈن سان كىرگۈزۈشكە بولىدۇ',
    countEmptyLimit: 'سان بوش قالمايدۇ',
    remarkMaxlimit: 'ئىزاھات ئەڭ كۆپ 50 ھەرپ كىرگۈزۈشكە بولىدۇ',
    orderAddressMaxlimit:
      'مەنبەلىك زاكاز ئادرېسى ئەڭ كۆپ 100 ھەرپ كىرگۈزۈشكە بولىدۇ',
    orderIdMaxlimit: 'مەنبەلىك زاكاز نومۇرى ئەڭ كۆپ 100 ھەرپ كىرگۈزۈشكە بولىدۇ',
    createTime: 'قوشۇلغان ۋاقىت',
    branchName: 'تەۋە بولغان قالدۇق ماتېرىيال ئامبىرى',
    orderAddress: 'مەنبەلىك زاكاز ئادرېسى',
    orderId: 'مەنبەلىك زاكاز نومۇرى',
    lockStatus: 'قۇلۇپلاش ھالىتى',
    lockOrder: 'زاكازنى قۇلۇپلاش',
    enableSurplusLock: 'قالدۇق ماتېرىيال قۇلۇپلاش ئىقتىدارىنى قوزغىتىش',
    isCloseSurplusLock:
      'ئىقتىدار تاقىلغاندىن كېيىن بارلىق قالدۇق ماتېرىياللارنىڭ قۇلۇپى ئېچىلىدۇ، داۋاملاشتۇرامسىز',
    confirm: 'جازالاش',
    surplusTransfer: 'قالدۇق ماتېرىيال يۆتكەش',
    targetSurplusStore: 'نىشان قالدۇق ماتېرىيال ئامبىرى',
    enableSurLockTip:
      'قوزغىتىلغاندىن كېيىن، ھەر قېتىملىق تەرتىپلەشتە ئىشلىتىلگەن قالدۇق ماتېرىياللار قۇلۇپلىنىدۇ، باشقا زاكازلارنىڭ ئىشلىتىشىدىن ساقلىنىدۇ',
    addMainRule: 'ئاساسىي قائىدە قوشۇش',
    surplusRules: 'قالدۇق ماتېرىيال ئامبارغا ساقلاش قائىدىسى',
    mainRulesTip:
      'ئاساسىي قائىدىلەر ياكى مۇناسىۋىتىدە، يەنى بىر ئاساسىي قائىدىنى قاندۇرغاندا، قالدۇق ماتېرىيال ئامبارغا ساقلانىدۇ',
    mainRulesTip1:
      'قائىدە قوشقاندىن كېيىن، قائىدىگە ماس كەلگەن قالدۇق ماتېرىيال ئاۋۋال بۇ ئامبارغا ساقلانىدۇ، بۇ ئامبار تولغاندىن كېيىن باشقا بوش قالدۇق ماتېرىيال ئامبىرىغا ساقلانىدۇ',
    rule: 'قائىدە',
    addSubRule: 'تارماق قائىدە قوشۇش',
    subRuleTip:
      'تارماق قائىدىلەر ۋە مۇناسىۋىتىدە، يەنى ئاساسىي قائىدىدىكى بارلىق تارماق قائىدىلەرنى قاندۇرغاندا، ئامبارغا ساقلانىدۇ',
    bigPlankMatCode: 'چوڭ تاختا ماتېرىيالى',
    bigPlankThick: 'چوڭ تاختا قېلىنلىقى',
    bigPlankColor: 'چوڭ تاختا رەڭگى',
    greaterEqual: 'چوڭ ياكى تەڭ',
    lessEqual: 'كىچىك ياكى تەڭ',
    capNumberCheck: 'ئامبار چېكى پەقەت پۈتۈن سان كىرگۈزۈشكە بولىدۇ!',
    capEmptyCheck: 'ئامبار چېكى بوش قالمايدۇ!',
    surNameEmptyCheck: 'قالدۇق ماتېرىيال ئامبىرى ئىسمى بوش قالمايدۇ!',
    mainRuleLimit: 'ئاساسىي قائىدە چېكىگە يەتكەن',
    delMainRuleTip: 'بۇ ئاساسىي قائىدىنى ئۆچۈرەمسىز',
    matCodeEmptyCheck: 'چوڭ تاختا ماتېرىيالى بوش قالمايدۇ!',
    thickEmptyCheck: 'چوڭ تاختا قېلىنلىقى بوش قالمايدۇ!',
    textureEmptyCheck: 'چوڭ تاختا رەڭگى بوش قالمايدۇ!',
  },
  printPage: {
    leftAlign: 'سولغا توغرىلاش',
    rightAlign: 'ئوڭغا توغرىلاش',
    topAlign: 'ئۈستىگە توغرىلاش',
    bottomAlign: 'ئاستىغا توغرىلاش',
    horizontalAlign: 'توغرا ئوتتۇرىغا توغرىلاش',
    verticalAlign: 'تىك ئوتتۇرىغا توغرىلاش',
    horizontalSplit: 'توغرا تەڭ بۆلۈش',
    verticalSplit: 'تىك تەڭ بۆلۈش',
    deleteWarning: 'ئەسكەرتىش',
    print: 'بېسىپ چىقىرىش',
    defaultTemplate: 'سۈكۈتتىكى قېلىپ (60 x 40)',
    editTemplate: 'قېلىپ تەھرىرلەش',
    deleteTip:
      'بۇ قېلىپنى ئۆچۈرگەندىن كېيىن قايتۇرۇشقا بولمايدۇ، ئۆچۈرۈشنى جازالامسىز؟',
    tagSetting: 'بەلگە تەڭشەكلىرى',
    rememberSetting: 'ئۆزگەرتىلگەندىن كېيىن تەڭشەكلەر ساقلانىدۇ',
    genCutingTag: 'كېسىلگەن تاختا بەلگىسى ھاسىل قىلىش',
    genSurplusTag: 'قالدۇق ماتېرىيال بەلگىسى ھاسىل قىلىش',
    genPlankTag: 'چوڭ تاختا بەلگىسى ھاسىل قىلىش',
    genPartTag: 'كىچىك تاختا بەلگىسى ھاسىل قىلىش',
    tagPosition: 'بەلگە قويۇش ئورنى',
    tagPositionTip:
      '(بەلگە قويغۇچ ماشىنىلا تۆۋەندىكى X/Y كوئوردىناتلىرىنى تولدۇرۇش زۆرۈر)',
    Xposition: 'X كوئوردىناتى',
    Yposition: 'Y كوئوردىناتى',
    genPlankSuffix: 'چوڭ تاختا بەلگىسى ھاسىل قىلغاندا ھۆججەت نامى قوشۇمچىسى',
    arrangeMirror: 'بەلگە رەسىمىنى ئەكس ئەتتۈرۈش',
    drillPlankBase: 'بەش/ئالتە تەرەپلىك ئېگىزلىك ماتور تاختا ئاساسى',
    suitAuto: 'بەلگە تەرتىپى ئاپتوماتىك يۈكلىمىگە ماسلىشىدۇ:',
    colorLabel: 'رەڭلىك بەلگە ھاسىل قىلىش',
    up: 'ئۈستىگە',
    down: 'ئاستىغا',
    default: 'سۈكۈتتىكى',
    printSetting: 'بېسىپ چىقىرىش تەڭشەكلىرى',
    oneToMore: 'بىر بەتتە كۆپ بەت',
    perPage: 'ھەر بەتكە',
    coloumn: 'ئىستون',
    tagMargin: 'بەلگە ئارىلىقى',
    coloumnMargin: 'ئىستون ئارىلىقى',
    specialMark: 'ئالاھىدە بەلگە',
    opacity: 'شەپسىزلىك',
    suite: 'ئۆزلۈكىدىن ماسلىشىش',
    svgListTip:
      'كىچىك تاختا بەلگىسى ھاسىل قىلىش قوزغىتىلمىغان، سول تەرەپتە تاللاڭ',
    size: 'چوڭ-كىچىكلىكى',
    lightSide: 'يورۇتۇش چېگرىسى',
    locFrontSide: 'قابا ئالدىنقى چېگرىسى',
    openSide: 'ئېچىش يۆنىلىشى',
    extraTip:
      'ئەسكەرتىش: بىرىنچى قېتىم بىۋاسىتە بېسىپ چىقىرىشتا، بېسىپ چىقىرىش تەڭشەكلىرىدە بۇ ئۆلچەمدە ۋە ئۇزۇنلۇقى، كەڭلىكى ئالماشتۇرۇلغان يېڭى قەغەز قوشۇڭ، ئاندىن يېڭى قوشۇلغان قەغەز بىلەن بېسىپ چىقىرىڭ (مىسال: ئەسلى قەغەز ئۆلچىمى 60×40 بولسا، يېڭى قەغەز ئۆلچىمى 40×60 قىلىپ قوشۇڭ)',
    noMore: 'تېخىمۇ كۆپ يوق',
    prepareData: 'سانلىق مەلۇمات تەييارلىنىۋاتىدۇ...',
    genTagFile:
      'بەلگە ھاسىل قىلىنىۋاتىدۇ، بەتنى ئالماشتۇرماڭ، تور كۆرگۈچنى كىچىكلەتمەڭ، بەلگىنى ئۈستە-ئاستا قىلىپ سۈرەپ قويماڭ!',
    waitGen: 'بەلگە يۈكلەنگەندىن كېيىن چۈشۈرۈڭ',
    tempLoading: 'بەلگە قېلىپى يۈكلىنىۋاتىدۇ، سەل ساقلاڭ',
    front: 'ئالدى',
    open: 'ئېچىش',
    toEdit: 'تەھرىرلەشكە يۆتكەلەڭ',
    simplifyOneCodeTip:
      'ئەسكەرتىش: بۇ بەلگە قېلىپىدا بەلگىلەنگەن سىزىق كود خاسلىقى "ئاددى سىزىق كود" ئىقتىدارىنى قوللىمايدۇ، سىزىق كود خاسلىقىنى ئۆزگەرتىڭ (تەۋسىيە قىلىنغان سىزىق كود خاسلىقى: CODE128)',
    plankTemp: 'كىچىك تاختا بەلگە قېلىپى',
    surplusTemp: 'قالدۇق ماتېرىيال بەلگە قېلىپى',
    tempNamePlaceHolder: 'قېلىپ نامىنى كىرگۈزۈڭ',
    editTip:
      'ئۆز ئېھتىياجىڭىزغا قاراپ بەلگە قېلىپىنى تەھرىرلەش ئۈچۈن سول تەرەپتىىكى توپچىلارنى بەلگە قەغىزىگە سۆرەپ قويالايسىز',
    oneCode: 'سىزىق كود',
    image: 'رەسىم',
    editPage: {
      digitLimitation: 'سان چەكلىمىسى',
      title: 'بەلگە قېلىپى تەھرىرلەش',
      reset: 'سۈكۈتتىكىگە قايتۇرۇش',
      exit: 'تەھرىرلەشتىن چىقىش',
      tagInfo: 'بەلگە ئۇچۇرى',
      tagName: 'بەلگە ئىسمى',
      tagSize: 'بەلگە ئۆلچىمى',
      tagData: 'بەلگە سانلىق مەلۇماتى',
      dragTip:
        'چاۋاك چاشقىنەكنى باسىۋالغان ھالدا سانلىق مەلۇمات بەلگىسىنى سۆرەپ قويۇڭ',
      dataTip: 'ئوڭ تەرەپتىكى خاسلىق بىلەتلىكىدىن سانلىق مەلۇمات تاللاڭ',
      text: 'تېكىست',
      dataSource: 'سانلىق مەلۇمات مەنبەسى',
      graph: 'گرافىك',
      typograpyPic: 'تەرتىپ رەسىمى',
      barCode: 'سىزىق كود',
      QRCode: 'QR كود',
      plankMark: 'تاختا بەلگىسى',
      holeSlotPic: 'تېشىك/ئويۇق رەسىمى',
      holeSlotPicZoom: 'تېشىك/ئويۇق رەسىمىنى زوم قىلىش',
      rotateDeg: 'بۇرۇش پەللىسى',
      colorDeep: 'رەڭ چوڭقۇرلۇقى',
      surplusMark: 'قالدۇق ماتېرىيال بەلگىسى',
      surplusSizePic: 'قالدۇق ماتېرىيال ئۆلچەم رەسىمى',
      dataSize: 'سانلىق مەلۇمات ئۆلچىمى',
      extraList: 'قوشۇمچە تىزىملىك',
      QRCodeFormat: 'QR كود فورماتى',
      formatQR: 'QR فورماتى',
      formatDM: 'DM فورماتى',
      locIndex: 'قابا تەرتىپ نومۇرى',
      plankFrontSign: 'ئالدىنقى تەرەپ بەلگىسى',
      genNCSuffix: 'NC قوشۇمچىسى ھاسىل قىلىش',
      table: 'جەدۋەل',
      inputPlaceholder: 'تېكىست كىرگۈزۈش ئۈچۈن قوش چېكىڭ',
      attribute: 'خاسلىق',
      fontFamily: 'خەت نۇسخا تىپى',
      fontSize: 'خەت نۇسخا چوڭلۇقى',
      fontWeight: 'خەت نۇسخا قېلىنلىقى',
      autoRow: 'ئاپتوماتىك قۇر قويۇش',
      rowMargin: 'قۇر ئارىلىقى',
      autoFontSize: 'چىقىپ كەتكەندە خەت نۇسخا چوڭلۇقىنى ئاپتوماتىك تەڭشەش',
      statisticsDimension: 'ستاتىستىكا ئۆلچىمى',
      noLimit: 'چەكلىمىسىز',
      sameOrderNo: 'بىر زاكاز',
      sameRoom: 'بىر ئۆي',
      sameWardrobe: 'بىر قابا',
      alignWay: 'توغرىلاش ئۇسۇلى',
      JustifyLeft: 'سولغا توغرىلاش',
      JustifyRight: 'ئوڭغا توغرىلاش',
      JustifyCenter: 'ئوتتۇرىغا توغرىلاش',
      otherOpration: 'باشقا مەشغۇلات',
      attributeReset: 'پارامېتىرنى قايتا تەڭشەش',
      barCodeSize: 'سىزىق كود ئۆلچىمى',
      installPic: 'ئورنىتىش رەسىمى',
      plankCode2: 'تاختا سىزىق كودى (noMark)',
      frontCode: 'ئالدىنقى تەرەپ سىزىق كودى',
      backCode: 'ئارقا تەرەپ سىزىق كودى',
      orderRemark: 'زاكاز ئىزاھاتى',
      NCFileName: 'كېسىش ھۆججەت ئىسمى',
      NCFileNameTip:
        'كېسىش ھۆججەت ئىسمىدە خەنزۇچە بولماسلىقى ۋە ئۇزۇنلۇقى 10 ھەرپتىن ئاشماسلىقى كېرەك',
      showOpenSide: 'ئېچىش يۆنىلىشىنى كۆرسەت',
      limitFixed: 'دېتال نۇقتىسىنى چەكلەش',
      showFrontHS: 'ئالدىنقى تەرەپ تېشىك/ئويۇقىنى كۆرسەت',
      showBackHS: 'ئارقا تەرەپ تېشىك/ئويۇقىنى كۆرسەت',
      showPlankSize: 'تاختا ئۆلچىمىنى كۆرسەت',
      tempNamePlaceholder: 'بەلگە ئىسمىنى كىرگۈزۈڭ',
      deleteAreaTip: 'ئۆچۈرۈلىدىغان رايوننى تاللاڭ',
      noHoleSlot: 'تېشىك/ئويۇق بولمىسىمۇ سىزىق كودنى كۆرسەت',
      rotate: 'سىزىق كودنى بۇرۇش',
      barcodeAtrribute: 'سىزىق كود خاسلىقى',
      imgSize: 'رەسىم ئۆلچىمى',
      noEdgeDisplay: 'چېگرا كېسىش 0 بولسا كۆرسەتمە',
      allEdgeNoDisplay: 'چېگرا كېسىشنى كۆرسەتمە',
      imgRotate: 'رەسىمنى بۇرۇش',
      circleD: 'دۆڭگىرەك چەمبەر قۇرۇش',
      roomNo: 'ئۆي نومۇرى',
      roomOrder: 'قابا تەرتىپ نومۇرى (ئۆي بويىچە تەرتىپلەش)',
      alphabetically: 'ھەرپ تەرتىپى بويىچە',
      numberOrder: 'سان تەرتىپى بويىچە',
      specialMarkup: 'ئالاھىدە بەلگە بەلگىسى',
      boldSettingTip: 'بەلگە PDF قېلىن خەت نۇسخا تەڭشىكىنى قوللىمايدۇ',
    },
    autoSaveToHistory: 'ئاپتوماتىك تارىخقا ساقلاندى!',
    firstPrintTip:
      'بىرىنچى قېتىم بىۋاسىتە بېسىپ چىقىرىشتا، بېسىپ چىقىرىش تەڭشەكلىرىدە بۇ ئۆلچەمدە ۋە ئۇزۇنلۇقى، كەڭلىكى ئالماشتۇرۇلغان يېڭى قەغەز قوشۇڭ، ئاندىن يېڭى قوشۇلغان قەغەز بىلەن بېسىپ چىقىرىڭ',
    tagDownloading: 'بەلگە چۈشۈرۈلىۋاتىدۇ...',
  },
  fileManagerPage: {
    notOpeFolderPermission: 'بۇ مۇندەرىجىگە ئىشلىتىش ھوقۇقى يوق',
    notFindFolder: 'نۆۋەتتىكى كلېنتتا بۇ مۇندەرىجىنى تەكشۈرەلمىدى',
    productLineUniversal: 'بارلىق ئىشلەپچىقىرىش لىنىيىلىرىگە قوللىنىدۇ',
    effectCondition: 'بۇ تەڭشەك ئۆزگەرتىلگەندىن كېيىن قايتا تەرتىپلەش زۆرۈر',
    addPath: 'يول قوشۇش',
    downloadPositionChangeByType: 'ھۆججەت چۈشۈرۈش ئورنى {type} غا ئۆزگەرتىلدى',
    isDeleteFolder: 'ئىشلىتىلگەن ھۆججەت ساقلاش ئورنىنى ئۆچۈرۈشنى جازالامسىز?',
    netWorkIsNotSafe:
      'تور مۇھىتىڭىز بىخەتەر ئەمەسكەن، بۇ ئىقتىدارنى قوزغىتالمايمىز، بىزگە ئۇقتۇرۇپ بېرەلەيسىز',
    NotSupportBrowse:
      'تور كۆرگۈچىڭىز بۇ ئىقتىدارنى قوللىمايدۇ، ئەڭ يېڭى <a href="https://www.microsoft.com/zh-cn/edge/download" target="_blank" >Edge</a> تور كۆرگۈچىنى چۈشۈرۈپ ئىشلىتىڭ',
    notSupportDisk:
      'سىستېما دەرىجىلىك مۇندەرىجىلەرنى تاللىيالمايسىز، مەسىلەن 【ئۈستەل】【C دىسك】',
    useFolderDownload: 'ئىشلەپچىقىرىش ھۆججىتىنى پىششىقلانمىغان ھالدا چۈشۈرۈش',
    downloadPosition: 'ھۆججەت چۈشۈرۈش ئورنى',
    changeUseFolder: 'ئۆزگەرتىش',
    folderNameSetting: 'مۇندەرىجە ئاتى تەڭشىكى',
    mainFolder: 'بىرىنچى دەرىجىلىك مۇندەرىجە',
    exportNameSetting: 'ئىشلەش ھۆججىتىنى زاكاز نومۇرى بويىچە ئاتاش',
    fileMax: 'ھۆججەت ئىسمى ئەڭ كۆپ',
    char: 'ھەرپ',
    overTip:
      'چەكتىن ئاشقان قىسمى كۆرسەتمەيدۇ (بەلگە رەسىم قىسقا ھۆججىتى ۋە json ھۆججىتى بىرىنچى دەرىجىلىك مۇندەرىجە ئاتى بويىچە ئاتىلىدۇ)',
    example: 'مىسال',
    engravingFolder: 'ئويما ماشىنىسى مۇندەرىجىسى',
    electronicSawFolder: 'ئېلېكترون ماكا مۇندەرىجىسى',
    labellingFolder: 'بەلگە قويغۇچ ماشىنا مۇندەرىجىسى',
    sideHoleDrillFolder: 'يان تېشىك ماتور مۇندەرىجىسى',
    fiveDrillFolder: 'بەش تەرەپلىك ماتور مۇندەرىجىسى',
    sixDrillFolder: 'ئالتە تەرەپلىك ماتور مۇندەرىجىسى',
    PTPFolder: 'PTP مۇندەرىجىسى',
    labellingTagFolder: 'بەلگە رەسىم مۇندەرىجىسى',
    folderMergeSetting: 'مۇندەرىجە بىرلەشتۈرۈش تەڭشىكى',
    folderMerge: 'مۇندەرىجە بىرلەشتۈرۈش',
    mergeName: 'بىرلەشتۈرۈلگەن نام',
    subMergeFolder:
      'ماتېرىيال رەڭ قېلىنلىقى بويىچە تارماق مۇندەرىجىگە بىرلەشتۈرۈش',
    subFolderName: 'تارماق مۇندەرىجە ئىسمى',
    splitChar: 'ئايرىغۇچ',
    splitTip: '( / : ? < > | . ” * قاتارلىق ھەرپلەرنى كىرگۈزۈشكە بولمايدۇ)',
    folderAutoAdd: 'مۇندەرىجە سانى ئاپتوماتىك ئاشۇرۇش باشلانغۇچ سانى',
    resetTip: '(ھەر كۈنى 00:00 دا باشلانغۇچ سانغا قايتۇرىدۇ)',
    emptyErrTip: 'ئۇچۇر بوش قالمايدۇ',
    repeatErrTip: 'ھۆججەت ئىسمى قايتىلانغان',
    mergeDataTip:
      'تاختا ماتېرىيالى رەڭ قېلىنلىقى ئۇزۇنلۇقى كەڭلىكى ياكى ھۆججەت سانى ئاپتوماتىك ئاشۇرۇش مەۋجۇت بولۇشى كېرەك',
  },
  moreSettings: {
    generalSetting: 'ئادەتتىكى تەڭشەك',
    outSyncTip:
      'بۇلۇت تەرتىپلەش ئەسلى تاختا سانلىق مەلۇماتى كابىنېت بىلەن ماسلىشمايدىكەن ئەسكەرتىش سۆزلىشىش كۆزنىكى ھاسىل قىلىش',
    secondarySwitchShow:
      'ئويما ماشىنىسى تەڭشىكىدىكى كونترول قىلغۇچنىڭ ئىككىنچى دەرىجىلىك مەزمۇنى كونترول قىلغۇچ تاقىلغاندىمۇ كۆرسىتىلىدۇ',
    exPaibanTip:
      'ئۆزگىچە شەكىللىك تەرتىپلەش ئىشلىتىلگەندە ئەسكەرتىش سۆزلىشىش كۆزنىكى ھاسىل قىلىش',
    surpTagThresholdTextHalf1:
      'قالدۇق ماتېرىيال (كېسىلمىگەن قالدۇق) يەر كۆلىمى',
    surpTagThresholdTextHalf2:
      'دىكىن چىقىرىلغاندا قالدۇق ماتېرىيال بەلگىسى ھاسىل قىلمايدۇ',
  },
  lineManager: {
    add: 'ئىشلەپچىقىرىش لىنىيىسى قوشۇش',
    engraving: 'كېسىش ئۈسكۈنىسى',
    holeSlotEquip: 'تېشىك/ئويۇق ئىشلەش ئۈسكۈنىسى',
    workSetting: 'ئىشلەش تەڭشىكى',
    noUse: 'قوزغىتىلمىغان',
    warningTip:
      '* دىققەت: تۆۋەندىكى ئىقتىدارلارنى تەڭشەپ بولغاندىن كېيىن، كابىنېت كلېنت ئۈسكۈنىسىنى باغلاش بېتىدە ساقلاشنى قايتا قىلماڭ، پارامېتىرلارنىڭ قايتا تەڭشىلىشىدىن ساقلىنىڭ!',
    engravingSetting: 'كېسىش ئۈسكۈنىسى تەڭشىكى',
    workWay: 'ئىشلەش ئۇسۇلى',
    cutedSpecial: 'ئىشلەنگەن ئالاھىدە تېشىك/ئويۇق:',
    noWork:
      'بۇ تاللاش بەش/ئالتە تەرەپلىك ماتور سۈكۈتتىكى قېلىپىغا تەسىر قىلمايدۇ',
    onlyCut: 'پەقەت كېسىش',
    onlyFrontHoleSlot: 'پەقەت تاختا ئالدىنقى تەرەپ تېشىك/ئويۇقىنى ئىشلەش',
    onlyHoleSlot: 'پەقەت تاختا ئالدىنقى-ئارقا تەرەپ تېشىك/ئويۇقىنى ئىشلەش',
    sideHoleSetting: 'يان تېشىك ماتور تەڭشىكى',
    cutHoleSlot: 'ئىشلەنگەن تېشىك/ئويۇق',
    minSize: 'قىسقا تەرەپ ئۆلچىمى',
    maxSize: 'ئۇزۇن تەرەپ ئۆلچىمى',
    minSize2:
      'mm دىكى تاختىدىكى بارلىق تېشىك/ئويۇق ۋە ئۆزگىچە شەكىللىك بۆلەكلەر',
    singleHole: 'يەككە تېشىك',
    singleSlot: 'يەككە ئويۇق',
    diameterRange: 'تېشىك دىئامېتىر دائىرىسى',
    hDeepRange: 'تېشىك چوڭقۇرلۇق دائىرىسى',
    widthRange: 'ئويۇق كەڭلىك دائىرىسى',
    sDeepRange: 'ئويۇق چوڭقۇرلۇق دائىرىسى',
    to: 'غىچە',
    specialHS: 'ئالاھىدە تېشىك/ئويۇق ۋە ئۆزگىچە شەكىللەر',
    allowRoll:
      'كېسىش ئۈسكۈنىسىنىڭ ئالاھىدە تېشىك/ئويۇقنى تەتۈرلەپ ئىشلەشىگە يول قويۇش',
    engravingPath:
      'كېسىش ئۈسكۈنىسىدىكى تاختا ئۆزگىچە شەكىللىك بۆلەكلەرنى ئىشلەش',
    drillSetting: 'بەش/ئالتە تەرەپلىك ماتور تەڭشىكى',
    engravingCuted:
      'كېسىش ئۈسكۈنىسىدىكى ئىشلەنگەن تېشىك/ئويۇق بەش/ئالتە تەرەپلىك ماتوردا',
    noGen: 'ھاسىل قىلمايدۇ',
    genCut: 'ھاسىل قىلىپ ئىشلەيدۇ',
    genNoCut: 'ھاسىل قىلىدۇ ئەمما ئىشلىمەيدۇ',
    cutTip:
      'ئەسكەرتىش: بۇ تاللاش پەقەت ئىختىيارى بەش/ئالتە تەرەپلىك ماتور ھۆججىتىگە تەسىر قىلىدۇ',
    maxShortSize: 'ئەڭ چوڭ قىسقا تەرەپ ئۆلچىمى',
    maxLongSize: 'ئەڭ چوڭ ئۇزۇن تەرەپ ئۆلچىمى',
    normalSlot: 'نورمال ئويۇق',
    specialSlot: 'ئالاھىدە ئويۇق',
    combinSetting: 'بىرلەشتۈرۈلگەن ئۈسكۈنىلەر تەڭشىكى',
    allowShortSize: 'قىسقا تەرەپ ئۆلچىمىگە يول قويۇش',
    equipment1: 'ئۈسكۈنى 1',
    equipment2: 'ئۈسكۈنى 2',
    noSameTip: 'ئەسكەرتىش: ئىككى ئۈسكۈنىنى بىر خىل تاللىيالمايسىز',
    workRange: 'ئۈسكۈنى 1 ئىشلەيدىغان تېشىك/ئويۇق دائىرىسى',
    rangeWarning:
      'ئەسكەرتىش: پەقەت ئۈسكۈنى 1 نىڭ ئىشلەش دائىرىسىنى تەڭشەيدۇ، ئۈسكۈنى 1 نىڭ ئىشلەش دائىرىسىدىن سىرتتىكىلەرنىڭ ھەممىسى ئۈسكۈنى 2 دىكى ئىشلىنىدۇ، دىققەتلىك ئىشلەڭ!',
    deleteWarning:
      'بۇ ئىشلەپچىقىرىش لىنىيىسىنى ئۆچۈرگەندىن كېيىن قايتۇرۇشقا بولمايدۇ (بۇ لىنىيەدىكى بارلىق تەڭشەكلەر ئۆچۈرۈلىدۇ)، ئۆچۈرۈشنى جازالامسىز؟',
    top: 'ئۈستى',
    inputCheck: '{msg} ئەڭ كىچىك قىممەت ئەڭ چوڭ قىممەتتىن چوڭ بولالمايدۇ',
    lockTip: 'ئىشلەپچىقىرىش لىنىيىسى قۇلۇپلانغان، مەشغۇلاتقا چەك قويۇلدى!',
    reNameTip:
      'سۈكۈتتىكى ئىشلەپچىقىرىش لىنىيىسىنىڭ ئىسمىنى ئۆزگەرتىشكە بولمايدۇ',
    permissonTip:
      'كىملىك ئۇچۇرى ئەسلىگە كەلگەن، كابىنېتتىن بۇ بەتنى قايتا ئېچىڭ!',
    permissonErr: 'ھازىرچە بۇ ھوقۇق يوق، ھېسابات نەشرىنى يۇقىرى كۆتۈرۈڭ!',
    permissionOneMacErr:
      'ھازىر ئىشلىتەلەيدىغان بىرلىك ماشىنا ئىشلەپچىقىرىش لىنىيىسى سانى {idx}، ئەگەر كۆپەيتىشنىز زۆرۈر بولسا، سېتىش خادىمى بىلەن ئالاقىلىشىڭ!',
    newLine: 'يېڭى ئىشلەپچىقىرىش لىنىيىسى {idx}',
    deleteCheck: 'ھەممىنى ئۆچۈرەلمەيسىز',
    deving: 'ئىجاد قىلىنىۋاتىدۇ',
    combinTip: 'تۆۋەندىكى بىرلەشتۈرۈلگەن ئۈسكۈنىلەر تەڭشىكىنى چېكىپ تەڭشەڭ',
    noUseEquip: 'ئىشلەپچىقىرىش ئۈسكۈنىسى قوزغىتىلمىغان',
    equipmentSelectTip:
      'بىرلەشتۈرۈلگەن ئۈسكۈنىلەر كېسىش ئۈسكۈنىسىنى تاللىغاندا، كېسىش ئۈسكۈنىسى ئېلېكترون ماكانى تاللىيالمايدۇ',
    lineLoading: 'سانلىق مەلۇمات يۈكلىنىۋاتىدۇ',
    strenchType: 'توغرا ئويۇق تىپى',
    aioProcessTip:
      'سىز "بىرلىك ماشىنا" ئۈسكۈنىسىنى قوزغىتىپ قويدىڭىز، ئىشلەش ئۇسۇلى سۈكۈتتىكى تاختا ئالدىنقى-ئارقا تەرەپ تېشىك/ئويۇقىنى ئىشلەش',
    aioProcessTip2:
      'بىرلىك ماشىنىسى قوزغىتىلغاندا ئويما قىسمىدىكى ئىشلەنگەن ئالاھىدە تېشىك/ئويۇق',
    aioProcessTip3:
      'دىققەت: بۇ ئىقتىدار XY ئوچنى ئالماشتۇرۇلغان ئويما ماشىنىسى بىلەن ماسلىشمايدۇ، دىققەتلىك ئىشلىتىڭ',
    specialHoleTip:
      'دىققەت: يۇقىرىدىكى "ئىشلەنگەن ئالاھىدە تېشىك/ئويۇق" ئىقتىدارىنى ئىشلەتكەندە، "ئۆتۈش تېشىكى ئېچىش ئۇسۇلى" ۋە "ئۆتۈش ئويۇقى ئېچىش ئۇسۇلى" نى بىر قېتىمدا ئېچىش قىلىپ تەڭشەڭ',
    specialHoleTip2:
      'دىققەت: يۇقىرىدىكى "ئىشلەنگەن ئالاھىدە تېشىك/ئويۇق" ئىقتىدارىنى ئىشلەتكەندە، ئويما ماشىنىسىدىكى "ئۆتۈش تېشىكى ئېچىش ئۇسۇلى" ۋە "ئۆتۈش ئويۇقى ئېچىش ئۇسۇلى" نى بىر قېتىمدا ئېچىش قىلىپ تەڭشەڭ',
    fiveSixNotTake:
      'بۇ تاللاش بەش/ئالتە تەرەپلىك ماتور سۈكۈتتىكى قېلىپىغا تەسىر قىلمايدۇ',
    onlyFrontSide: 'پەقەت ئالدىنقى تەرەپنى ئىشلەش',
    onlyBackSide: 'پەقەت ئارقا تەرەپنى ئىشلەش',
    less: 'كىچىك',
    equal: 'تەڭ',
    greater: 'چوڭ',
    or: 'ياكى',
    and: 'ۋە',
    bothFrontBack: 'ئالدىنقى ۋە ئارقا تەرەپنى ئىشلەش',
    shareDataErrTip:
      'بۇ سانلىق مەلۇماتنى {operatorPhone} ئىشلەتكەن، IP: {actionIp}',
    restoreParams: 'پارامېتىرنى قايتا تەڭشەش',
    loadMore: 'تېخىمۇ كۆپ يۈكلەش',
  },
  cuttingDock: {
    title: 'قېپىش مۇناسىۋىتى',
    autoStockCode: 'ئاپتوماتىك ماتېرىيال كودى',
    stationStart: 'باشلانغۇچ پوچتا',
    stationStartCode1: 'پوچتا 1 باشلانغۇچ كودى',
    stationStartCode1Placeholder: 'پوچتا 1 باشلانغۇچ كودىنى كىرگۈزۈڭ',
    stationStartCode2: 'پوچتا 2 باشلانغۇچ كودى',
    stationStartCode2Placeholder: 'پوچتا 2 باشلانغۇچ كودىنى كىرگۈزۈڭ',
    stationEnd: 'پوچتا ئاخىرلاشقۇچ',
    stationEndCode1: 'پوچتا 1 ئاخىرلاشقۇچ كودى',
    stationEndCode1Placeholder: 'پوچتا 1 ئاخىرلاشقۇچ كودىنى كىرگۈزۈڭ',
    stationEndCode2: 'پوچتا 2 ئاخىرلاشقۇچ كودى',
    stationEndCode2Placeholder: 'پوچتا 2 ئاخىرلاشقۇچ كودىنى كىرگۈزۈڭ',
    frontBackStart: 'ئالدى-ئارقا باشلانغۇچ',
    frontBackEnd: 'ئالدى-ئارقا ئاخىرلاشقۇچ',
    frontStartCode: 'ئالدى باشلانغۇچ كودى',
    frontStartCodePlaceholder: 'ئالدى باشلانغۇچ كودىنى كىرگۈزۈڭ',
    frontEndCode: 'ئالدى ئاخىرلاشقۇچ كودى',
    frontEndCodePlaceholder: 'ئالدى ئاخىرلاشقۇچ كودىنى كىرگۈزۈڭ',
    backStartCode: 'ئارقا باشلانغۇچ كودى',
    backStartCodePlaceholder: 'ئارقا باشلانغۇچ كودىنى كىرگۈزۈڭ',
    backEndCode: 'ئارقا ئاخىرلاشقۇچ كودى',
    backEndCodePlaceholder: 'ئارقا ئاخىرلاشقۇچ كودىنى كىرگۈزۈڭ',
    plankStart: 'تاختا ئۆرۈلۈش باشلانغۇچ',
    plankEnd: 'تاختا ئۆرۈلۈش ئاخىرلاشقۇچ',
    plankRollCode1: 'پوچتا 1 ئۆرۈلۈش كودى',
    plankRollCode1Placeholder: 'پوچتا 1 ئۆرۈلۈش كودىنى كىرگۈزۈڭ',
    plankRollCode2: 'پوچتا 2 ئۆرۈلۈش كودى',
    plankRollCode2Placeholder: 'پوچتا 2 ئۆرۈلۈش كودىنى كىرگۈزۈڭ',
    autoBaitingCode: 'ئاپتوماتىك ماتېرىيال چۈشۈرۈش كودى',
    autoBaitingCode1: 'پوچتا 1 ماتېرىيال چۈشۈرۈش كودى',
    autoBaitingCode1Placeholder: 'پوچتا 1 ماتېرىيال چۈشۈرۈش كودىنى كىرگۈزۈڭ',
    autoBaitingCode2: 'پوچتا 2 ماتېرىيال چۈشۈرۈش كودى',
    autoBaitingCode2Placeholder: 'پوچتا 2 ماتېرىيال چۈشۈرۈش كودىنى كىرگۈزۈڭ',
    preStartEquipment: 'ئالدىن قوزغاتقۇچ قورال',
    firstKnifePreCode: 'بىرىنچى پىچاق ئالدىن قوزغاتقۇچ كودى',
    secondKnifePreCode:
      'ئىككىنچى پىچاق ۋە كېيىنكى پىچاقلار ئالدىن قوزغاتقۇچ كودى',
    rowInsert: 'قۇر قىستۇرۇش',
    pumpPause: 'ۋاكۇم پومپا توختىتىش',
    pumpPauseTip:
      'دىققەت: بىر پوچتالىق قوراللاردا پوچتا 1 ۋە پوچتا 2 گە ئوخشاش "ئاپتوماتىك ماتېرىيال كودى" ۋە "ئىككى تەرەپلىك ئىشلەش ئالدى ئىزاھات كودى" نى تولدۇرۇش كېرەك',
    pumpPause2Tip:
      'دىققەت: بىر پوچتالىق قوراللاردا پوچتا 1 ۋە پوچتا 2 گە ئوخشاش "ۋاكۇم پومپا توختىتىش كودى" نى تولدۇرۇش كېرەك',
    pumpPauseCode1: 'پوچتا 1 ۋاكۇم پومپا توختىتىش كودى',
    pumpPauseCode1Placeholder: 'پوچتا 1 ۋاكۇم پومپا توختىتىش كودىنى تولدۇرۇڭ',
    pumpPauseCode2: 'پوچتا 2 ۋاكۇم پومپا توختىتىش كودى',
    pumpPauseCode2Placeholder: 'پوچتا 2 ۋاكۇم پومپا توختىتىش كودىنى تولدۇرۇڭ',
    annotationCode1: 'پوچتا 1 ئىككى تەرەپلىك ئىشلەش ئالدى ئىزاھات كودى',
    annotationCode1Tip:
      'ئاپتوماتىك ماتېرىيال ئالدى ئوخشاش بولمىغان بۇيرۇقلارغا ئىشلىتىلىدۇ',
    annotationCode1Placeholder:
      'پوچتا 1 ئىككى تەرەپلىك ئىشلەش ئالدى ئىزاھات كودىنى كىرگۈزۈڭ',
    annotationCode2: 'پوچتا 2 ئىككى تەرەپلىك ئىشلەش ئالدى ئىزاھات كودى',
    annotationCode2Placeholder:
      'پوچتا 2 ئىككى تەرەپلىك ئىشلەش ئالدى ئىزاھات كودىنى كىرگۈزۈڭ',

    upSpeedTip: 'ئورتاق چېگرا تېزلىكىنى تولدۇرۇڭ',
    cutThickTip: 'ئورتاق چېگرا قېلىنلىقىنى تولدۇرۇڭ',
    sizeErrTip: 'بەلگە قويغۇچتا تەڭشەلگەن چوڭلۇقنى توغرا تەڭشەڭ!',
    offEdgeKnifeDiameter: 'چېگرا پىچاق دىئامېتىرىنى كىرگۈزۈڭ',
    slowDownSpeedTip: 'قېپىش بۇلۇڭ تۆۋەندىتىش كىرگۈزۈش پارامېتىرى خاتا',
    permissionErr: 'ھازىر ھەقسىز نۇسخا، ساقلىيالمايدۇ',
    labellingTempErr: 'بەلگە قويغۇچ قېلىپىنى تاللاڭ',
    autoRollInputTip:
      'بەلگە ئاپتوماتىك ئۆرۈلۈشىنى قوزغىتىش ئۈچۈن، ئىناۋەتلىك قىممەت كىرگۈزۈڭ',
    offlineTempFormat:
      'نۆۋەتتىكى بەلگە قويغۇچ ھۆججەت فورماتى ئۈزۈلگەن، جەزملەڭ',
    autoStockCode1: 'پوچتا 1 ئاپتوماتىك ماتېرىيال كودى',
    autoStockCode1PlaceHolder: 'پوچتا 1 ئاپتوماتىك ماتېرىيال كودىنى كىرگۈزۈڭ',
    autoStockCode2PlaceHolder: 'پوچتا 2 ئاپتوماتىك ماتېرىيال كودىنى كىرگۈزۈڭ',
    autoStockCode2: 'پوچتا 2 ئاپتوماتىك ماتېرىيال كودى',
    pauseCode: 'توختىتىش كودى',
    pauseCodePlaceHolder: 'توختىتىش كودىنى كىرگۈزۈڭ',

    cuttingParams: {
      title: 'قېپىش پارامېتىرلىرى',
      holeSlotMainSide: 'تەشىك-ئارقا مەركەز تەرەپ',
      holeMainSide: 'تەشىك مەركەز تەرەپ',
      slotMainSide: 'ئارقا مەركەز تەرەپ',
      workPriority:
        '(فۇنكسىيە ئەھمىيەت تەرتىپى: ئالاھىدە تەشىك-ئارقا ئىشلەش تەرەپ ئىختىيارى>ئېچىلغان تەشىك-ئارقالار ئۇ تاختىدا ئېچىلمىغان تەشىك-ئارقالارغا ئەگەشىدۇ>تەشىك-ئارقا تارقىتىش تەڭشىكىدىكى ئالاھىدە تەشىك-ئارقالار چوڭ تاختىنىڭ ئالدى تەرىپىگە ئالدىنقى قاتاردا تەڭشەيدۇ>ئارقا مەركەز تەرەپ>تەشىك مەركەز تەرەپ)',
      holeSlotSet: 'ئېچىلغان تەشىك-ئارقا ئىشلەش تەڭشىكى',
      hingeRollWork:
        'تۇتاشتۇرغۇچ ۋە سانجىق كۆزى تەشىكىنى چوڭ تاختىنىڭ ئارقا تەرىپىگە قويۇش',
      followWork:
        'ئېچىلغان تەشىك-ئارقالار ئۇ تاختىدا ئېچىلمىغان تەشىك-ئارقالارغا ئەگەشىدۇ',
      autoMatchSide: 'نىسبەت بويىچە ئالدى-ئارقا تەشىك-ئارقالارنى تەقسىملەش',
      backHSRate: 'ئارقا تەرەپ تەشىك-ئارقا نىسبىتى',
      matchTip:
        'يورۇق تاختا ياكى ئالاھىدە شەكىللىك تەڭشەلگەن تەڭشەكلەر بۇ تەڭشەكنى ئىشلەتمەيدۇ',
      specIalHoleAndSlotSurface: 'ئالاھىدە تەشىك-ئارقا ئىشلەش تەرەپ ئىختىيارى',
      specialRuleTip: 'ئالاھىدە تەشىك-ئارقالارنى تاللاڭ',
      specialSlotsHoles: 'ئالاھىدە تەشىك-ئارقالار',
      FixedmachSurface: 'مۇقىم ئىشلەش تەرەپ',
      deleteRuleTip: 'بۇ قائىدىنى ئۆچۈرەمسىز',
      blankFront: 'چوڭ تاختىنىڭ ئالدى تەرىپى',
      blankBack: 'چوڭ تاختىنىڭ ئارقا تەرىپى',
      addRules: 'قائىدە قوشۇش',
      addRulesTip:
        'بىر تاختا بىر قانچە قائىدىگە ئۇچرىغاندا، تەرتىپ نومۇرى ئەڭ ئالدىدا بولغان قائىدە ئىشلىنىدۇ، ئىستىگە ئەگەشىپ ھەر قانداق قۇرنى ئۆزگەرتسىڭىز بولىدۇ',
      highGlossSetting: 'يورۇق تاختا تەڭشىكى',
      heighGlossFront: 'تەڭشەيدىغاندا تاختىنىڭ يورۇق تەرىپى چوڭ تاختىنىڭ',
      sideSlotPut: 'چېگرا تاختا ئارقىسىنى قويۇش',
      optimize: 'فۇنكسىيە ئۈنۈملۈك تەڭشەشتە',
      sysDefault: 'سىستېما سۈكۈتتىكى',
      plankInSide: 'چوڭ تاختىنىڭ ئىچكى تەرىپى',
      plankOutSide: 'چوڭ تاختىنىڭ تاشقى تەرىپى',
      specialNotWork: 'بۇ فۇنكسىيە ئالاھىدە شەكىللىك تاختىلارغا ئىشلەتمەيدۇ',
      commonSide: 'ئورتاق چېگرا قېپىش',
      commonSetting: 'ئورتاق تەڭشەش',
      plankThickRange: 'تاختا قېلىنلىقى كىچىك ياكى تەڭ',
      useCommonSide: 'بولغاندا ئورتاق چېگرا قېپىش ئىشلىتىلىدۇ',
      zeroTip:
        'تەڭشەش بوشلۇقىنى قولدا 0 گە ئۆزگەرتەڭ، قېپىلغان چېگرىغا يەتكەندە پىچاقنى كۆتۈرۈپ ئۇ چېگرىدىن ئۆتۈڭ، بۇ فۇنكسىيە قوزغىتىلغاندىن كېيىن، ئېگىل پىچاق بىلەن ئىشلەش قوللىنىلمايدۇ، دىققەت قىلىڭ!',
      useTip:
        'بۇ فۇنكسىيە قوزغىتىلغاندىن كېيىن، ئېگىل پىچاق بىلەن ئىشلەش قوللىنىلمايدۇ، دىققەت قىلىڭ!',
      commonSpeed: 'ئورتاق تېزلىتىش',
      highSpeed: 'يۇقىرى سۈرئەتلىك سىلىشتۇرۇش سۈرئىتى',
      min: 'مىنۇت',
      notWorkTip:
        'تاختا ماتېرىيالى ئورتاق چېگرا قېپىش شەرتلىرىگە توغرا كەلگەندە، ئورتاق تېزلىتىش ئىشلەتمەيدۇ',
      changeStartPoint: 'ئىككى پوچتا باشلانغۇچ نۇقتا ئالماشتۇرۇش',
      changeStartPointTip:
        'ئىككى پوچتا باشلانغۇچ نۇقتا ئالماشتۇرۇشنى قوزغىتىش بىلەن، XY ئوكۇل ئالماشتۇرۇش فۇنكسىيىسى بىلەن ماسلاشمايدۇ، دىققەت قىلىڭ',
      flatOffset: 'ماشىنا يۈزى ئۆزگەرتىش',
      xRowChange: 'X ئوكۇل كوئوردىنات ئاساسىنى ئالماشتۇرۇش',
      bigplankPointChange:
        'چوڭ تاختىنىڭ ئارقا تەرىپى كوئوردىنات ئاساسىنى ئالماشتۇرۇش',
      reverseXRowChange: 'X كوئوردىنات ئالماشتۇرۇش',
      changebigplankPointTips:
        'بۇ فۇنكسىيەنى قوزغىتىش بىلەن "XY ئوكۇل ئالماشتۇرۇش، ئىككى پوچتا باشلانغۇچ نۇقتا ئالماشتۇرۇش" فۇنكسىيىسى بىلەن ماسلاشمايدۇ، دىققەت قىلىڭ',
      reverseYRowChange: 'Y كوئوردىنات ئالماشتۇرۇش',
      plankMirrorTip:
        'ئۆيۈچۈدە ئىشلەنگەن تاختىلار ئەكس ئەتەۋاتقاندا بۇ فۇنكسىيەنى ئىشلىتىڭ',
      notSameSize: 'ئىككى پوچتا يۈزى ئوخشاش بولمىغان چوڭلۇقتا',
      maxSize1: 'پوچتا 1 ئەڭ چوڭ چوڭلۇقى',
      notSameSizeTip:
        'ئىككى پوچتا يۈزى ئوخشاش بولمىغان چوڭلۇقنى قوزغىتىش بىلەن، ئىككى پوچتا ھۆججەت بىرلەشتۈرۈش فۇنكسىيىسى بىلەن ماسلاشمايدۇ، دىققەت قىلىڭ',
      maxSize2: 'پوچتا 2 ئەڭ چوڭ چوڭلۇقى',
      autoSelectTip:
        'ئىككى پوچتالىق قوراللارنىڭ ئىككى يۈزىنىڭ چوڭلۇقى ئوخشاش بولمىغاندا بۇ فۇنكسىيەنى ئىشلەتسىڭىز، ئۇ ئوخشاش بولمىغان پوچتىلارغا ئەگەشىپ چوڭ تاختىلارنى ئاپتوماتىك تاللايدۇ',
      bigFlatLimit:
        'چوڭ پوچتا پەقەت كىچىك پوچتا ئىشلەيەلمەيدىغان چوڭ تاختىلارنى ئىشلەيدۇ:',
      surplusSetting: 'قالدۇق ماتېرىيال تەڭشىكى',
      surplusPosition: 'قالدۇق ماتېرىيالنى قويۇش ئورنى',
      cutHoleWay: 'قېپىش-تەشىك ئېچىش ئۇسۇلى',
      knifeFlat: 'پىچاق ئاساسى يۈزى',

      flat: 'يۈز',
      plankFlat: 'چوڭ تاختىنىڭ يۈزى',
      freeHeightZ: 'بوش ۋاقىتتىكى Z ئېگىزلىكى',
      relativeHeight: 'چوڭ تاختىنىڭ ئۈستى يۈزىگە نىسبەتەن ئېگىزلىك',
      cutDeepZ: 'ئېچىش چوڭلۇقى Z چوڭلۇقى',
      cutDeepH: 'ئېچىلغان تەشىك ئېچىش ئۇسۇلى',
      onceThrough: 'بىر قېتىم ئېچىش',
      rollThrough: 'ئۆرۈلۈپ ئىككى قېتىم ئېچىش',
      cutDeepS: 'ئېچىلغان ئارقا ئېچىش ئۇسۇلى',
      plankCutDirection: 'كىچىك تاختا قېپىش يۆنىلىشى',
      clockDirSwitch:
        'مىل تەشىك/ئارقا/ئىچكى ئالاھىدە شەكىللىك قېپىش يۆنىلىشىنى ئۆزگەرتىش',
      millHoleSlotDir: 'مىل تەشىك/ئارقا يۆنىلىشى',
      millInternalCurveDir: 'ئىچكى ئالاھىدە شەكىللىك قېپىش يۆنىلىشى',
      sDirection: 'سائەت يۆنىلىشى',
      nDirection: 'سائەت يۆنىلىشىگە تەتۈر',
      curveReplenish: 'دۆڭگىلەك قېپىش بۇيرۇقى بىلەن دۆڭگىلەك تاختىلارنى قېپىش',
      curveReplenishTip:
        'دۆڭگىلەك تاختىلار قېپىشتە تىشچىلىق پەيدا بولسا بۇ فۇنكسىيەنى تاللاڭ',
      noGen: 'G17/G18/G19 يۈز بۇيرۇقىنى ئەمەلدىن قالدۇرماڭ',
      arcInstruction: 'دۆڭگىلەك قېپىش بۇيرۇقى ئالدى قوشۇمچىسى',
      example: 'مىسال: G17G03 X100 Y100',
      curveCutTip: 'دۆڭگىلەك قېپىشتە I، J بىلەن ئىپادىلەيدۇ',
      coverRate: 'مىل ئارقىسى قېپىشتە قېپىش قاپلىشىش نىسبىتى:',
      roughTip:
        'پىچاق مىل ئارقىسى قېپىشتە ئارقىدا قالدۇق پىچاق قالسا بۇ فۇنكسىيەنى ئىشلىتىڭ',
      clearCurve:
        'مىل ئارقىسى قېپىشتە تارتىلمىغان ئارقالارنى ئاپتوماتىك تازىلاش:',
      curveUseTip:
        'مىل ئارقىسى قېپىشتە دۆڭگىلەك بۇلۇڭلارنى تازىلاش ئۈچۈن بۇ فۇنكسىيەنى تاللاڭ، دىققەت: بۇ فۇنكسىيە باغلانما ئۈچۈن ئىشلەتمەيدۇ',
      slotCutWay: 'مىل ئارقىسى قېپىش ئۇسۇلى',
      outToIn: 'تاشقىدىن ئىچكىگە مىل ئارقىسى قېپىش',
      inToOut: 'ئىچكىدىن تاشقىغا مىل ئارقىسى قېپىش',
      zShapProcess:
        'ئېچىلغان ئارقالار ئۈستى-ئاستى Z شەكىللىك مىل ئارقىسى قېپىش',
      zShapProcessTip:
        'پەقەت ئالدىن-ئارقا تارتىلغان ئېچىلغان ئارقالار مىل ئارقىسى قېپىشتە ئىشلىتىلىدۇ، پىچاق يولى قاپلىشىش نىسبىتى سۈكۈتتىكى پىچاق رادىئۇسى',
      zSlotCuttingOverlapRate:
        'Z شەكىللىك مىل ئارقىسى قېپىشتە قاپلىشىش نىسبىتى',
      slotWorkLimit:
        '(پەقەت مىل ئارقىسى قېپىش قاتلام سانى>1 بولغاندا ئىشلىنىدۇ)',
      workSpeedOp: 'ئىشلەش سۈرئىتى ئۈنۈملۈك تەڭشەش',
      obliqueCut: 'قېپىشتە ئېگىل پىچاق',
      backOblique: 'ئارقا چېگرا ئېگىل پىچاق',
      obliqueSpeed: 'ئېگىل پىچاق سۈرئىتى',
      arcSpeed: 'قىيپىس ئىشلەش سۈرئىتى',
      cuttingPreDistance: 'پىچاق چۈشۈش ئالدىدىن ماسىپا',
      backKnife: 'قېپىش پىچاق تۆۋەندىتىش',
      openKnifeSpeed: 'پىچاق تۆۋەندىتىش سۈرئىتى',
      cutCornerSlow: 'قېپىش بۇلۇڭ تۆۋەندىتىش',
      addRow: 'يېڭى قۇر قوشۇش',
      bettwenDistance: 'بۇلۇڭ ئالدى-ئارقا ماسىپا',
      cuttingSpeed: 'قېپىش سۈرئىتى',
      stepCutting: 'قاتلام قېپىش',
      splitSlot: 'ئارقا قېپىش ئىككى قېتىم',
      splitPlank: 'كىچىك تاختا قېپىش ئىككى قېتىم',
      maxWH: 'ئۇزۇن ياكى كەڭلىكى كىچىك',
      areaLimit: 'ھەمدە يۈزى كىچىك',
      secondDeep: 'ئىككىنچى قېتىم قېپىش چوڭلۇقى',
      splitPlankThickSetting: 'تاختا قېپىش قاتلام قېپىش تەڭشىكى',
      saveLayeredCuttingTip:
        'تاختا قېپىش قاتلام قېپىشتە ئۈچ قاتلام قېپىشنىڭ جەمئىي تاختىنىڭ قېلىنلىقىغا تەڭ بولۇشى كېرەك',
      saveLayeredRepeatTip:
        'تاختا قېپىش قاتلام قېپىشتە بىر ماتېرىيال يورۇق خاسلىقى قېلىنلىقىغا ئوخشاش بولمىغان قاتلام سانلىق مەلۇماتلىرىنى تەڭشەش تەۋسىيە قىلىنمايدۇ، قوشۇمچە سانلىق مەلۇماتلارنى ئۆچۈرۈڭ!',
      splitPlankThick: 'ئوخشاش بولمىغان تاختا قېلىنلىقى قاتلام قېپىش',
      movePlankSetting: 'تاختا قاچىشىنىڭ ئالدىنى ئېلىش تەڭشىكى',
      newCutSequence: 'يېڭى قېپىش ئالگورىتمى',
      newCutSequenceText:
        'سۈكۈتتىكى چوڭ تاختىنىڭ مەركىزى ۋاكۇم ئېلىش كۈچى ئەڭ كۈچلۈك، بۇ رايوندىكى كىچىك تاختا ئاخىرىدا قېپىلىدۇ',
      positionTip: 'ئالاقىدار رايوننى تاللاپ تاللاش ئېلىپ بارالايدۇ',
      suctionPosition: 'ۋاكۇم كۈچلۈك ئېلىش ئورنى',
      strongArea: 'كۈچلۈك ئېلىش رايونى',
      minSuctionPlank: 'ئەڭ كىچىك تۇتۇشقا بولىدىغان تاختا',
      safeWidth: 'كەڭلىك',
      safeHeight: 'ئۇزۇنلۇق',
      safeTip: 'تۇتالمايدىغان تاختا ئالدى بىلەن قېپىلىدۇ',
      scattered_layout: 'كىچىك تاختا تارقالغان تەڭشەش',
      specifyTheMaterial: 'ماتېرىيالنى بەلگىلەش',
      addMaterial: 'ماتېرىيال قوشۇش',
      plankMaterial: 'تاختا ماتېرىيالى',
      specifyTheMaterialTip:
        'قوزغىتىلغاندا جەدۋەل ماتېرىيالىغا توغرا كېلىدىغان تاختىلار ئىككىنچى قېتىم قېپىش فۇنكسىيىسىنى ئىشلەيدۇ',
      dispersePaibanTipOne:
        'قوزغىتىلغاندا ھەر بىر چوڭ تاختىدا ئەڭ كىچىك تۇتۇشقا بولىدىغان چوڭلۇقتىكى بىر تاختا بولىدۇ',
      dispersePaibanTipTwo: '2، ئىشلىتىلگەندە چوڭ تاختا مىقدارى ئاشىشى مۇمكىن',

      totalThickLimit:
        'ئۈچ قاتلام قېپىشنىڭ جەمئىي تاختىنىڭ قېلىنلىقىغا تەڭ بولۇشى كېرەك',
      offsetFollowStep: 'چېگرا قېپىشمۇ قاتلام قېپىشكە ئەگەشىدۇ',

      longPlankTip1:
        '1، بۇ فۇنكسىيەنى قوزغىتىش ئۈچۈن مەجبۇرىي 【ئۆيۈچۈ-قېپىش مۇناسىۋىتى-ۋاكۇم پومپا توختىتىش】 دىكى ۋاكۇم پومپا توختىتىش كودىنى تەڭشەڭ، بولمىسا ئۇزۇن تاختا قېپىش ئېگىلگەن مەسىلىنى ھەل قىلالمايدۇ',
      longPlankTip2:
        '2، بۇ فۇنكسىيەنى قوزغىتىش ياكى ئۆچۈرۈش تارىخىدىكى تەڭشەلگەن تەڭشەكلەرنى NC نى چۈشۈرۈش ئۈچۈن قايتا تەڭشەش كېرەك',
      secondCutting: 'ئىككىنچى قېتىم قېپىش نىشانى',
      normalPlank: 'نورمال تاختا',
      normalPlankTip:
        'نورمال تاختا: قابنىڭ ئىچىدىكى توغرا-توغرا بولمىغان تاختا ۋە قاب ئىچىگە قوشۇلغان تاختا',
      plankLongLimit: 'ئۇزۇن تاختا ئۇزۇن چېگرىسى ئاشقاندا',
      plankShortLimit: 'قىسقا چېگرىسى ئاشقاندا',
      cuttingSplit: 'ئىككى قېتىم قېپىش',
      longExpand: 'بىرىنچى قېتىم قېپىش كېڭەيتىش: ئۇزۇن چېگرا كېڭەيتىش',
      firstExpand: 'بىرىنچى قېتىم قېپىش كېڭەيتىش',
      longExpandTip: 'ئۇزۇن چېگرا كېڭەيتىش',
      longLimit: 'ئۇزۇن چېگرا ئاشقاندا',
      cuttingSplitTip: 'ئۇزۇن تاختا ئىككى قېتىم قېپىش شەرتى',
      shortExpand: 'قىسقا چېگرا كېڭەيتىش',
      expandTip:
        'ئۇزۇن/قىسقا چېگرا چوڭلۇقى ھەر بىرى X mm غا كېڭىيىدۇ، ئالاھىدە شەكىللىك ئۇزۇن تاختىلارنىڭ ئۇزۇن/قىسقا چېگرا كېڭەيتىش قىممىتى ئۇزۇن چېگرا كېڭەيتىش قىممىتى بىلەن ئوخشاش',
      pumpOffWay: 'ۋاكۇم ئۆچۈرۈش ئۇسۇلى',
      afterOnePlank: 'بىر ئۇزۇن تاختا قېپىلغاندىن كېيىن',
      afterAllPlank: 'پۈتۈن چوڭ تاختا قېپىلغاندىن كېيىن',
      numberFixed: 'ئونلۇق سان ساقلاش بېتى',
      bufferCode: 'پىچاق چۈشۈش توختىتىش بۇيرۇقى تەڭشىكى',
      layoutGap: 'تەڭشەش بوشلۇقى تەڭشىكى',
      layoutGapTip:
        'دىققەت: تەڭشەش بوشلۇقى 0 گە تەڭشەلگەندە تەڭشەش قاپلىشىشى مۇمكىن',
      wasteCutting: 'خورا ماتېرىيال ئاپتوماتىك كېسىش',
      zkPointCustomize:
        'Z قۇلۇق ئېگىل چېگرا ئىشلەش ئۆزگەرتىش ئاساس نۇقتىسى ئىختىيارى',
      sawSetting: 'ئارقا كېسىش تەڭشىكى',
      cuttingLimit: 'كېسىش چوڭلۇقى چوڭ',
      waste: 'خورا ماتېرىيال',
      wasteSizeLimit: 'ئەڭ كىچىك چوڭلۇقى 100mm دىن كىچىك بولماسلىقى كېرەك',
      topLeft: 'سول، ئۈستى',
      topLeftD: 'سول ئۈستى بۇلۇڭ',
      bottomLeft: 'سول، ئاستى',
      bottomLeftD: 'سول ئاستى بۇلۇڭ',
      topRight: 'ئوڭ، ئۈستى',
      topRightD: 'ئوڭ ئۈستى بۇلۇڭ',
      bottomRight: 'ئوڭ، ئاستى',
      bottomRightD: 'ئوڭ ئاستى بۇلۇڭ',
      firstTime: 'بىرىنچى قېتىم',
      secondTime: 'ئىككىنچى قېتىم',
      thirdTime: 'ئۈچىنچى قېتىم',
      startCutting: 'قېپىش باشلانغۇچ',
      thickRange: 'قېلىنلىق ≥',
      longCut: 'ئۇزۇن تاختا ئىشلەش',
      edgeSetting: 'چېگرا تەڭشىكى',
      cuttingOrder: 'قېپىش تەرتىپى:',
      cuttingOrderTip: 'بۇ ئۇسۇل تاختا قاچىش خەتەرلىك، دىققەت قىلىڭ',
      plankFirst: 'ئالدى بىلەن كىچىك تاختا قېپىش',
      wasteFirst: 'ئالدى بىلەن خورا ماتېرىيال قېپىش',
      offTip: 'ئۆچۈرۈلگەندە قېپىش پارامېتىرلىرىدىكى چېگرا تەڭشىكىگە ئەگەشىدۇ',
      edgeCutDirection: 'چېگرا قېپىش يۆنىلىشى',
      lGapTip:
        'L شەكىللىك قالدۇق ماتېرىيال چوڭ تاختىسىدىكى بوشلۇق ئورنى ئۆز-ئارا بېسىش سىلىندىرىسىنىڭ قارشى تەرەپكىچە ئاپتوماتىك تەڭشىلىدۇ، يورۇق قالدۇق ماتېرىياللار بۇنىڭ سىرتىدا، ھەمدە xy ئوكۇل ئالماشتۇرۇش فۇنكسىيىسى بىلەن ماسلاشمايدۇ',
      cylinderDir: 'بېسىش سىلىندىرىسى يۆنىلىشى',
      onlyLong: 'پەقەت ئۇزۇن چېگرىنى چېگرىلاش',
      backCutOrder: 'ئارقا چېگرا قېپىش تەرتىپى',
      backFirst: 'ئارقا ئەڭ ئالدى بىلەن چېگرىلاش',
      backSecond: 'ئارقا تەشىك-ئارقا ئىشلەنگەندىن كېيىن چېگرىلاش',
      frontReCut: 'ئالدى قايتا چېگرىلاش',
      frontReCutTip: 'بېسىش سىلىندىرىسى چېگرىسىنى تەڭشەپ، ئىز قالدۇرماسلىق',
      useNewCut: 'يېڭى چېگرا قائىدىسىنى قوزغىتىش',
      newCutTip:
        'ئىككى تەرەپلىك ئىشلەنگەن چوڭ تاختىنىڭ ئالدى تەرىپىدە بىر چېگرا چوڭلۇقىدىن ئارتۇق چېگرىلاش كېرەك بولغاندا بۇ فۇنكسىيەنى ئىشلىتىڭ',
      fixedTip:
        'دىققەت: ئونلۇق سان ساقلاش بېتى 0 ياكى 1 بولغاندا تەڭشەش قاپلىشىشى مۇمكىن',
      cuttingSlow: 'ماتېرىيال چۈشۈرۈلگەن تاختا يۈزىدىكى توختىتىش',
      holeSlow: 'تەشىك ئېچىلغان تاختا يۈزىدىكى توختىتىش',
      slotSlow: 'ئارقا تارتىش/مىل تەشىك/مىل ئارقا تاختىسى يۈزىدىكى توختىتىش',
      slowCode: 'توختىتىش بۇيرۇقى ئىشلىتىش',
      code: 'بۇيرۇق',
      zkRotatePoint: 'ئۆزگەرتىش ئاساس نۇقتىسى',
      zkRotateDir: 'ئۆزگەرتىش بۇلۇڭى ئاشقۇچ',
      restSizeLimit: 'قالدۇق چوڭلۇقى كىچىك',
      useSaw: 'ئارقا كېسىش پىچىقىنى ئىشلىتىش',
      unGenSawPath: 'بويۇن كېسىش ئارقا كېسىش يولىنى ئەمەلدىن قالدۇرماڭ',
      suitMachine: 'پۈتۈن ئالىومىن پىچىلغان تاختا ئىشلەشكە ماس كېلىدۇ',
      plankBack: 'چوڭ تاختىنىڭ ئارقا تەرىپى',
      plankLeft: 'چوڭ تاختىنىڭ سول تەرىپى',
      plankRight: 'چوڭ تاختىنىڭ ئوڭ تەرىپى',
      plankFront: 'چوڭ تاختىنىڭ ئالدى تەرىپى',
      frontSide: 'ئالدى تەرىپ',
      backSide: 'ئارقا تەرىپ',
      rightSide: 'ئوڭ تەرىپ',
      leftSide: 'سول تەرىپ',
      singleMachineLimit:
        'بىر تەرەپلىك ئىشلەنگەن تاختىنىڭ ئالدى تەرىپىدە چېگرا يولى ھاسىل قىلىنىدۇ ۋە ئالدى بىلەن چېگرىلىنىدۇ',
      singleMachineLimitTip:
        'بىرلىك ماشىنا، ئارقا كېسىش قېپىش بۇ فۇنكسىيەنى ئىشلەتمەيدۇ',
      setBatchPositionWaring:
        'ئەسكەرتىش: ئەگەر پىچاق چۈشۈش نۇقتىسىنى تېز ئۆزگەرتىش ئۇسۇلىنى ئەمەلدىن قالدۇرماقچى بولسىڭىز، ئوڭ تەرەپتىكى پىچاق چۈشۈش نۇقتىسىنى ئۆزگەرتىش كۇنۇپكىسىنى بېسىپ چىقىڭ',
      setBatchPosition: 'پىچاق چۈشۈش نۇقتىسىنى بىر تۇتاش ئۆزگەرتىش',
      setBatchPositionAs: 'پىچاق چۈشۈش يۆنىلىشىنى ئۆزگەرتىش:',
      activeBatchPosition: '{activePartCount} دانە كىچىك تاختا تاللاندى',
      specialFrontSide:
        'تەشىك-ئارقا تارقىتىش تەڭشىكىدىكى ئالاھىدە تەشىك-ئارقالار چوڭ تاختىنىڭ ئالدى تەرىپىگە ئالدىنقى قاتاردا تەڭشەيدۇ',
      rollCountTip:
        'تەشىك-ئارقا تارقىتىشنى قوزغىتىش بىلەن ئۆيۈچۈدە ئىشلەنگەن ئارقا تەرەپ تەشىك-ئارقالارنىڭ ئەمەلىي سانىغا ئاساسەن ئۆرۈلۈش سانى ھېسابلىنىدۇ',
      specialFrontSideTip:
        'نۆۋەتتىكى ئىشلەش سېپى «تەشىك-ئارقا تارقىتىش تەڭشىكىدىكى ئالاھىدە تەشىك-ئارقالار چوڭ تاختىنىڭ ئالدى تەرىپىگە ئالدىنقى قاتاردا تەڭشەيدۇ» ياكى «تەشىك-ئارقا تارقىتىشنى قوزغىتىش بىلەن ئۆيۈچۈدە ئىشلەنگەن ئارقا تەرەپ تەشىك-ئارقالارنىڭ ئەمەلىي سانىغا ئاساسەن ئۆرۈلۈش سانى ھېسابلىنىدۇ» فۇنكسىيىسىنى قوزغىتىپ قويغانلىقى ئۈچۈن، ئىشلەش سېپىنى ئالماشتۇرالمايدۇ',
      doubleTrim: 'ئىككى تەرەپلىك تاختا چېگرىلاش',
      trim: 'چېگرىلاش',
      trimTwoSide: 'ئىككى چېگرىنى چېگرىلاش',
      trimFourSide: 'تۆت چېگرىنى چېگرىلاش',
      rightInmShow: 'ئوڭدىكى رەسىمدە كۆرسىتىلگەندەك',
      currentTrimDir: 'نۆۋەتتىكى تاللانغان چېگرىلاش يۆنىلىشى',
      cylinderTip: 'بېسىش سىلىندىرىسى',
      onlyShort: 'پەقەت قىسقا چېگرىنى چېگرىلاش',
      singleTrim: 'بىر تەرەپلىك تاختا چېگرىلاش يولى ھاسىل قىلىش',
      hierarchyCutTwice: 'ئارقا تارتىش/مىل ئارقا قاتلام قېپىش',
      knifeDia: 'پىچاق دىئامېتىرى كىچىك ياكى تەڭ',
      slotDeep: 'ھەمدە ئارقا چوڭلۇقى چوڭ ياكى تەڭ',
      cutTwiceSlot: 'بولغاندا، ئارقا قاتلام قېپىش',
      twiceSlotDeep: 'ئىككىنچى قاتلام قېپىش چوڭلۇقى',
      cutTwiceCondition: 'قېپىش شەرتى',
      zkPointCustomizeTip:
        'xy ئوكۇل ئالماشتۇرۇشنى قوزغىتىش ئىشلەش يۆنىلىشى ۋە ئاساس نۇقتىسىنىڭ قارشى بولۇشىغا سەۋەپ بولىدۇ',
      zkPointCustomizeTip1:
        'xy ئوكۇل ئالماشتۇرۇشنى قوزغىتقان قوراللار بۇ فۇنكسىيەنى ئىشلەتمەيدۇ',
      isCustom: 'ئىختىيارى تەڭشەشنى قوزغىتىش',
      useCutCon: 'ئارقا كېسىش شەرتىنى ئىشلىتىش',
    },
    labelingSettings: {
      title: 'بەلگە قويۇش تەڭشىكى',
      output: 'ئاپتوماتىك بەلگە قويۇش ھۆججىتىنى چىقىرىش',
      specification: 'بەلگە قويۇش ھۆججەت فورماتى',
      codeFrontGenerate:
        'ئاپتوماتىك ماتېرىيال كودى بەلگە قويۇش كودىدىن بۇرۇن ھاسىل قىلىنىدۇ',
      templateOffline: 'ئىشلىتىلگەن قېلىپ ئۈزۈلگەن!',
      rollLabelling: 'ئۆرۈلۈش بەلگە قويۇش تەڭشىكى',
      flipSetting: {
        title: 'ئۆرۈلۈش تەڭشىكى',
        engravingFlipProcess: 'ئۆيۈچۈ ئۆرۈلۈش ئىشلەش بەلگە قويۇش ئۇسۇلى',
        flipTip: 'ئۆيۈچۈ ئۆرۈلۈپ ئىشلەش زۆرۈر بولغاندا بۇ فۇنكسىيەنى قوزغىتىڭ',
      },
      rotateSetting: {
        title: 'بەلگە ئۆرۈلۈش تەڭشىكى',
        rotateTip:
          'ئېھتىياجغا ئاساسەن ئۆرۈلۈش شەرتىنى قوزغىتىڭ، ئەگەر بىر ۋاقىتتا قوزغىتىلسا، تاللانغان قىممەت ئالدىنقى قاتاردا ئىشلىنىدۇ',
        horizontal: 'تاختا كەڭ چېگرىسى',
        vertical: 'تاختا ئۇزۇن چېگرىسى',
        either: 'تاختا كەڭ ياكى ئۇزۇن چېگرىسى',
      },
      countertopSetting: {
        title: 'يۈز تەڭشىكى',
        startPoint: 'پوچتا باشلانغۇچ نۇقتىسى',
        upLeft: 'سول ئۈستى بۇلۇڭ',
        upRight: 'ئوڭ ئۈستى بۇلۇڭ',
        downLeft: 'سول ئاستى بۇلۇڭ',
        downRight: 'ئوڭ ئاستى بۇلۇڭ',
        baseExchange: 'كوئوردىنات ئاساسىنى ئالماشتۇرۇش',
        exchangeX: 'x ئوكۇلنى ئالماشتۇرۇش',
        exchangeY: 'y ئوكۇلنى ئالماشتۇرۇش',
      },
      offset: 'بەلگە قويۇش كوئوردىنات ئۆزگەرتىش',
      offsetTip:
        'ئادەتتە بەلگە قويۇش كوئوردىناتى كىچىك تاختا مەركىزىدە، بەزى قوراللار تەڭشەشكە موھتاج',
      label_rotate_offset: 'بەلگە ئۆرۈلگەندىن كېيىن كوئوردىنات ئۆزگەرتىش',
      label_rotate_offset_tip:
        'بەلگە ئۆرۈلۈشتىن بۇرۇن ۋە كېيىن ئورنى ئوخشاش بولمىغاندا تەڭشەش كېرەك',
      label_rotate_offset_err_tip:
        'بەلگە ئۆرۈلگەندىن كېيىن كوئوردىنات ئۆزگەرتىشنى قوزغىتىش بىلەن، X ۋە Y ئوكۇل كوئوردىنات ئۆزگەرتىش مىقدارى تولدۇرۇش كېرەك',
      imgSetting: {
        title: 'بەلگە قويۇش رەسىم تەڭشىكى',
        suffix: 'بەلگە قويۇش رەسىم كېڭەيتىلمىسى:',
        code: 'رەسىم كودلاش ئۇسۇلى:',
        codeTip:
          'png، jpeg تەڭشەلگەن رەسىم كودلاش فورماتىنى ئۆزگەرتىدۇ، ھەمدە ئىچكى بۇيرۇق بىلەن تەڭشەلگەن تەڭشەكلەر بۇ فۇنكسىيەنى ئىشلەتمەيدۇ',
        imgRatio: 'بەلگە قويۇش رەسىم چوڭلۇقى:',
        slowDown: 'بەلگە رەسىملىرىنى چىقىرىش تېزلىكىنى تۆۋەنلەتەلەيدۇ',
        color: 'رەڭلىك',
        bit: 'قارا-ئاق',
        resolution: 'بەلگە قويۇش رەسىم چوڭلۇقى',
        resolutionPlaceholder: 'چوڭلۇق',
        labelPool: 'سانلىق مەلۇمات بەلگە سۇ ئامبىرى',
        showKey: 'كۆرسىتىش ئورنى',
        dragTip: 'بەلگە سۇ ئامبىرىدىكى بەلگىلەرنى سۆرەپ تاشلىسىڭىز بولىدۇ',
        labelImgName: 'بەلگە قويۇش رەسىم ئىسمى',
        labelImgNameErrTip: 'بەلگە رەسىم ئىسمى بوش بولسا بولمايدۇ',
        labelTip1:
          'ئەسكەرتىش: 1، ئىختىيارى تەڭشەشتە 【تاختا باركود】 ياكى 【بەلگە تەرتىپ نومۇرى】 نىڭ بىرىنى مەجبۇرىي ئىشلىتىش كېرەك، بولمىسا رەسىملەرنىڭ ئۈستىگە يېزىلىشى مۇمكىن',
        labelTip2: '2، بەلگە قويغۇچ سىستېما قېلىپى بۇ فۇنكسىيەنى ئىشلەتمەيدۇ',
        delimiter: 'ئايرىغۇچ',
        inputTip: 'كىرگۈزۈشكە بولمايدىغان ھەرپلەر: / : ? < > | . ” *',
        numStart: 'بەلگە تەرتىپ نومۇرى باشلانغۇچ قىممىتى',
        example: 'ئىختىيارى بەلگە قويۇش رەسىم ئىسمى (مىسال)',
        stockNumOffset: 'چوڭ تاختا تەرتىپ نومۇرىنى تولدۇرۇش بېتى',
        divideFolders:
          'بەلگە رەسىملىرىنى چوڭ تاختا بويىچە ئوخشاش بولمىغان قىسقۇچلارغا بۆلۈش',
        foldersTip1:
          'ئەسكەرتىش: 1، بۇ فۇنكسىيە «ئۆيۈچۈ ھۆججەت بىرلەشتۈرۈش» فۇنكسىيىسى بىلەن بىرلىكتە ئىشلىتىلمەيدۇ',
        foldersTip2:
          '2، بۇ فۇنكسىيە ئىچكى بۇيرۇق بىلەن تەڭشەلگەن تەڭشەكلەرگە ئىشلەتمەيدۇ',
        stickIntoFolders:
          'بەلگە قويۇش ھۆججىتىنى ئوخشاش چوڭ تاختىدىكى بەلگە رەسىم قىسقۇچىغا قىستۇرۇش',
        stickTip1:
          'دىققەت: 1، بۇ فۇنكسىيە «NC نى چۈشۈرۈش بىلەن بەلگىلەرنى چىقىرىش» فۇنكسىيىسى بىلەن بىرلىكتە ئىشلىتىلىشى كېرەك',
        stickTip2:
          '2، ئىشلىتىلگەن بەلگە قويغۇچ قېلىپىدىكى ھۆججەت ھاسىل قىلىش قائىدىسى «بىر ھۆججەت ھاسىل قىلىش» نى تاللىسىڭىز بۇ فۇنكسىيە ئىشلىنىدۇ؛',
        stickTip3:
          '3، بۇ فۇنكسىيە ئىچكى بۇيرۇق بىلەن تەڭشەلگەن تەڭشەكلەرگە ئىشلەتمەيدۇ',
      },
      paibanImgSetting: {
        title: 'تەڭشەلگەن رەسىم تەڭشىكى',
        reparation: 'تەڭشەلگەن رەسىم تەرتىپ نومۇرىنى تولدۇرۇش',
        figure: 'خانە',
        inputTip: '1-99 گىچە سان كىرگۈزۈڭ',
        paibanImg: {
          export: 'تەڭشەلگەن رەسىم چىقىرىش',
          prefix: 'تەڭشەلگەن رەسىم ئالدى قوشۇمچە',
          size: 'تەڭشەلگەن رەسىم چوڭلۇقى',
          format: 'تەڭشەلگەن رەسىم فورماتى',
          directional: 'تەڭشەلگەن رەسىم يۆنىلىشى',
          vertical: 'تىك',
          horizontal: 'توغرا',
        },
      },
      autolabel: 'ئاپتوماتىك بەلگە قويۇش تەرتىپى تەڭشىكى',
      customTemp: 'پەقەت ئىختىيارى قېلىپلارغا ئىشلىتىلىدۇ',
      cuttingLabelOder: 'قېپىش تەرتىپى بويىچە بەلگە قويۇش',
      labelToUp: 'تەڭشەلگەن نەتىجە بويىچە تۆۋەندىن يۇقىرىغا بەلگە قويۇش',
      labelToDown: 'تەڭشەلگەن نەتىجە بويىچە يۇقىرىدىن تۆۋەنگە بەلگە قويۇش',
      labelDodge: 'بەلگە ئاپتوماتىك چېكىنىش',
      settingValue: 'تاللانغان قىممەت',
      enabelTip:
        'قوزغىتىلغاندا، كىچىك تاختا تۆۋەندىكى تاللانغان قىممەتكە توغرا كەلگەندە، بەلگە ئۆرۈلىدۇ',
      fixedHori:
        'بەلگە كەڭ چېگرىسى بىلەن كىچىك تاختا مۇقىم چېگرىسىنىڭ پاراللېللىقىنى تاللاڭ',
      fixedHoriTip:
        'پەقەت تېكىستسىز كىچىك تاختىلارغا ئىشلىتىلىدۇ، قوزغىتىلغاندا، بەلگە قويۇشتا، بەلگە كەڭ چېگرىسى بىلەن كىچىك تاختا مۇقىم چېگرىسى پاراللېل بولۇش ئۈچۈن ئۆرۈلىدۇ',
      tagHori: 'بەلگە كەڭ چېگرىسى',
    },
    drillSettings: {
      title: 'تەشىك تەڭشىكى',
      enable: 'تەشىك بوغچىسىنى قوزغىتىش',
      toolTable: {
        use: 'قوزغىتىش',
        number2: 'پوچتا 2 تەشىك نومۇرى',
        number: 'تەشىك نومۇرى',
        diameter: 'تەشىك دىئامېتىرى',
        xOffset: 'X ئۆزگەرتىش',
        yOffset: 'Y ئۆزگەرتىش',
        moveZF: 'Z ئوكۇل تېز يۆتكىلىش سۈرئىتى',
        feedRate: 'Z ئوكۇل ئىشلەش سۈرئىتى',
        moveF: 'XY ئوكۇل تېز يۆتكىلىش سۈرئىتى',
        benchmarkDrill: 'ئاساسلىق تەشىك',
        addKnife: 'پىچاق قوشۇش',
        deleteKnife: 'پىچاق ئۆچۈرۈش',
      },
      prefix: 'تەشىك ئالدى قوشۇمچە',
      suffix: 'تەشىك كېڭەيتىلمىسى',
      startCode: 'تەشىك باشلانغۇچ كودى',
      endCode: 'تەشىك ئاخىرلاشقۇچ كودى',
      combineKnife: 'تەشىك بىرلەشتۈرۈپ چۈشۈرۈش',
      combineTable: {
        prefix: 'تەشىك نومۇرى ئالدى قوشۇمچە',
        suffix: 'تەشىك نومۇرى كېڭەيتىلمىسى',
        mainHole: 'ئاساسلىق تەشىك',
        extraHole: 'قوشۇمچە تەشىك',
        notes: 'بىرلەشتۈرۈش ئىزاھاتى',
        firstUseNotes: 'بىرىنچى قېتىم قوزغىتىش ئىزاھاتى',
        addExtraHole: 'قوشۇمچە تەشىك قوشۇش',
        deleteExtraHole: 'قوشۇمچە تەشىك ئۆچۈرۈش',
        example: 'مىسال',
      },
      processSetting: {
        title: 'ئىشلەش تەڭشىكى',
        mode: 'تەشىك ئىشلەش ئۇسۇلى',
        processTip:
          'بۇ ئۇسۇلنى تاللىسىڭىز تەشىك بىرلەشتۈرۈپ چۈشۈرۈش فۇنكسىيىسى ئىشلەتمەيدۇ',
        recentHole: 'يېقىن تەشىك (تېز پىچاق ئالماشتۇرۇش)',
        groupHole:
          'تۈر بويىچە تەشىك (بىر تۈر تەشىك تاماملىغاندىن كېيىن پىچاق ئالماشتۇرۇش)',
        drill_benchmark: 'بىرلەشتۈرۈپ چۈشۈرۈش ئاساسى',
        drill_benchmark_Tip:
          '(دىققەت: بۇ ئۇسۇلنى تاللىسىڭىز تەشىك بىرلەشتۈرۈپ چۈشۈرۈش فۇنكسىيىسى ئىشلەتمەيدۇ)',
        mainHole: 'ئاساسلىق تەشىك',
        secondaryHole: 'قوشۇمچە تەشىك (ئاخىرقى تەشىك)',
      },
      changeBenchDrillTip: 'تەشىك نومۇرىنى كىرگۈزۈپ بۇ تاللاشنى ئېلىپ بارىڭ',
    },
    NCExportSettings: {
      title: 'NC چىقىرىش تەڭشىكى',
      tab1: 'ئۆيۈچۈ ھۆججەت ئىسمى تەڭشىكى',
      tab2: 'ئۆيۈچۈ ھۆججەت بىرلەشتۈرۈش',
      tab3: 'ئۆيۈچۈ ھۆججىتىدىكى ئىزاھات',
      tab4: 'ئۆيۈچۈ ھۆججىتىدىكى تۈر',
      tab5: 'ئۆيۈچۈ قىسقۇچ',
      tab6: 'ئىشلەش بۇيرۇقى تەڭشىكى',
      tab7: 'ھۆججەت فورماتى تەڭشىكى',
      fileNameSettings: {
        twoStationAutoSort: 'ئىككى پوچتا ئاپتوماتىك تەرتىپلەش',
        reparation: 'چوڭ تاختا تەرتىپ نومۇرىنى تولدۇرۇش',
        figure: 'خانە',
        labelerBegin: 'بەلگە قويغۇچ باشلانغۇچ',
        machineCenter: 'ئىشلەش مەركىزى باشلانغۇچ',
        front: 'ئالدى:',
        back: 'ئارقا:',
        customizeName: 'ئىختىيارى ھۆججەت ئىسمى (فورمات):',
        example: 'ئىختىيارى ھۆججەت ئىسمى (مىسال):',
        emptyTip: 'ئالدى، ئارقا ھۆججەت بەلگىسى بىر ۋاقىتتا بوش بولسا بولمايدۇ',
        labelPool: 'سانلىق مەلۇمات بەلگە سۇ ئامبىرى',
        dragTip:
          'سانلىق مەلۇمات بەلگە سۇ ئامبىرىدىكى بەلگىلەرنى سۆرەپ تاشلىسىڭىز ياكى كۆچۈرۈش كۇنۇپكىسىنى بېسىپ كىرگۈزۈش رامكىسىغا كۆچۈرەلەيسىز',
      },
      fileMerge: {
        engravingMerge: 'كېسىش ماشىنىسى ھۆججەت بىرلەشتۈرۈش',
        zfMerge: 'ئالدى-كەينى بىرلەشتۈرۈش',
        singleMerge: 'يەككە ئورۇن بىرلەشتۈرۈش',
        doubleMerge: 'قوش ئورۇن بىرلەشتۈرۈش',
        fEndCode: 'كەينى يۈزى ئاخىرلىشىش كودى',
        zBeginCode: 'ئالدى يۈزى باشلىنىش كودى',
        displayfbInfo: 'ھۆججەت ئىسمى ئالدى-كەينى يۈزى ئۇچۇرىنى كۆرسىتىش',
        fBeginCode: 'كەينى يۈزى باشلىنىش كودى',
        zEndCode: 'ئالدى يۈزى ئاخىرلىشىش كودى',
      },
      marginalNotes: {
        addPlankInfo: 'كېسىش ھۆججىتى بېشىغا چوڭ تاختاي ئۇچۇرىنى قوشۇش:',
        plankInfo: 'چوڭ تاختاي ئۇچۇرى:',
        addStationCodeFirst: 'كېسىش ھۆججىتى بېشىغا ئورۇن كودىنى قوشۇش:',
        addStationCodeSecond:
          'كېسىش ھۆججىتى پىچاق ئالماشتۇرۇشتىن بۇرۇن ئورۇن كودىنى قوشۇش:',
      },
      fileType: {
        zAddtion: 'Z كوئوردېناتى تولۇقلاش:',
        addSemicolon: 'ھەر قۇر NC نىڭ ئاخىرىغا چېكىت پەش قوشۇش:',
        semicolonTip:
          'تاللانغاندا كالا مۈڭگۈزى تۇتقۇچنىڭ ئىشلەش ھۆججىتىگىمۇ ئۈنۈملۈك بولىدۇ',
        fileName: 'ھۆججەت ئىسمى',
        defaultName: 'سۈكۈتتىكى ھۆججەت ئىسمى',
        dedicatedName: 'شىڭخۇي ئۈسكۈنىسىگە خاس ھۆججەت ئىسمى',
        xhNotes: 'شىڭخۇي E2-9 ئىزاھات',
        format: 'ئويما ماشىنا ھۆججەت فورماتى',
        example: 'ھۆججەت مىسالى: NC ھۆججەت',
        stillGenerate:
          'كەينى يۈزى ئىشلەنمىگەندە يەنىلا كەينى يۈزى ئاخىرلىشىش كودى بار كەينى يۈزى NC ھۆججىتىنى ھاسىل قىلىش',
        generateTip:
          'بۇ ئىقتىدار ھۆججەت بىرلەشتۈرۈش ئىقتىدارى بىلەن بىللە ئىشلىتىلمەيدۇ',
        fileNameErrTip:
          'ھۆججەت ئىسمى تۆۋەندىكى ھەرقانداق ھەرپنى ئۆز ئىچىگە ئالالمايدۇ: / : * ? "',
      },
      categorize:
        'چوڭ تاختاينىڭ رەڭگى، قېلىنلىقى ۋە ماتېرىيالى بويىچە ھۆججەت قىسقۇچىغا ئايرىش (بەلگە چاپلاش ھۆججىتى بىللە):',
      simplifyG: 'G كودىنى ئاددىيلاشتۇرۇش',
      ZToW: 'Z ئوقىدىن W ئوقىغا ئالماشتۇرۇش',
      knives: 'تەرتىپلىك تۆشۈك پىچاقلىرى',
      lineBreakSetting: 'قۇر ئالماشتۇرۇش بەلگىسى فورماتى',
      codeFormatSetting: 'ھۆججەت كودلاش فورماتى',
      utf8bom: 'BOM لىق UTF-8',
      oneCodeSetting: 'تاختاي شىفىر تەڭشىكى',
      simplifyOneCode: 'ئاددىي فورماتلىق بەلگە شىفىرى ھاسىل قىلىش',
      codeLocation: 'كود ئورنى تەڭشىكى',
      generateLocation:
        'خىزمەت ئورنى ئاخىرلىشىش كودى ئارقا يۈز ئاخىرلىشىش كودىنىڭ ئالدىدا ھاسىل قىلىنىدۇ',
      emergencySetting: 'جىددىي تەڭشەك',
      show: 'جىددىي تەڭشەكنى كۆرسىتىش',
      emergencyCode: 'جىددىي كود',
      params: 'پارامېتىر',
      emergencyTip:
        'سېتىشتىن كېيىنكى خىزمەتچىلەر بىلەن بىرلىكتە ۋاقىتلىق بەزى كىچىك ئىقتىدارلارنى ئەمەلگە ئاشۇرغىلى بولىدۇ',
      holeSetting: 'تۆشۈك ئېچىش ھۆججىتى تەڭشىكى',
      holeFileCategorize:
        'تۆشۈك ئېچىش ھۆججىتىنى ئوخشىمىغان بۇيرۇتما بويىچە ئايرىم ھۆججەت قىسقۇچىغا قويۇش',
      holeTip:
        'ئىچكى ئورنىتىلغان ئىشكاپ تەرتىپلەش كىلىيېنتىنىڭ بۇلۇت تەرتىپلىشىگە ئۈنۈم كۆرسەتمەيدۇ',
      specialCodeErr: 'ئالاھىدە ھەرپلەرنى كىرگۈزۈشنى قوللىمايدۇ!',
      copySuccess: 'كۆچۈرۈش مۇۋەپپەقىيەتلىك بولدى!',
      copyErr: 'كۆچۈرۈش مەغلۇپ بولدى، بەلگىنى سۆرەپ سىناڭ!',
      lengthLimit: 'پەقەت 15 خانە كىرگۈزۈشنى قوللايدۇ!',
      plankInfo: 'تاختاي ئۇچۇرى',
      otherInfo: 'باشقا ئۇچۇرلار',
      uniqueBarCode: 'تاختاي شىفىرى يەككە',
      uniqueBarCodeForPack: 'تاختاي شىفىرى يەككە (بوغچىلاشقا خاس)',
      machineOrder: 'كېسىش ھۆججىتى ئىسمى ئىشلەش تەرتىپ نومۇرى ھاسىل قىلىدۇ',
      folderSetting:
        'ئوخشاش چوڭلۇقتىكى چوڭ تاختاي كېسىش ھۆججىتى ئوخشاش ھۆججەت قىسقۇچىغا قويۇلىدۇ',
      labelImageINCFolder:
        'بەلگە چاپلاش رەسىمى بەلگە چاپلاش ھۆججىتى بىلەن NC ھۆججەت قىسقۇچىسىغا قويۇلىدۇ',
      custom: 'خاسلاشتۇرۇش',
      folderSettingTip:
        'دىققەت: ئىچكى ئورنىتىلغان تەرتىپلەش بۇ ئىقتىدارنى قوللىمايدۇ',
      settingMoveTip: 'ئەسلىدىكى "{func}" ئىقتىدارى "{module}" بەتكە يۆتكەلدى!',
      fileManagerFunction:
        'تۆشۈك ئېچىش ھۆججىتى ئوخشىمىغان بۇيرۇتما ھۆججىتى بويىچە ئايرىم ھۆججەت قىسقۇچىغا قويۇلىدۇ',
      gotoSetting: 'تەڭشەككە بېرىش',
      singleFileTitle: 'ئايرىم ھۆججەت ئىشلەش تەڭشىكى',
      singleFileSetting:
        'يانتۇ چەت چاپلاش تۈرىدىكى گۈل بۇلۇڭ ۋە يانتۇ چەت تۇتقۇچنى ئايرىم ھۆججەتتە ئىشلەش',
    },
    aioMachineSetting: {
      openFunction: 'ئۇلىنىشقا تۆۋەندىكى ئىقتىدارلارنى ئېچىش كېرەك',
      openCarving: 'ئويما ماشىنىسىنى ئېچىش',
      xyReverseFunction: 'XY ئوقى ئالماشتۇرۇش ئىقتىدارى',
      carvingStartSet: 'ئويما ماشىنىسى خىزمەت ئورنى باشلىنىش نۇقتىسى',
      rightTop: 'ئوڭ ئۈستى بۇلۇڭ',
      carvingEdgeSet: 'ئويما ماشىنىسى ئالدى يۈزى قايتا تۈزىتىش قىممىتى',
      finalEdgeSame: 'ئومۇمىي تۈزىتىش قىممىتى بىلەن ئوخشاش',
      beginCode: 'پروگرامما باشلىنىش كودى',
      endCode: 'پروگرامما ئاخىرلىشىش كودى',
      drillHole: 'تۆشۈك تېشىش',
      pullTrough: 'يوچۇق تارتىش',
      showDataTemp: 'سانلىق مەلۇمات ئۈلگىسىنى كۆرسىتىش',
      dataTemp: 'سانلىق مەلۇمات ئۈلگىسى',
      title: 'بىر گەۋدىلىك ماشىنا تەڭشىكى',
      outputFile: 'بىر گەۋدىلىك ماشىنا ئىشلەش ھۆججىتىنى چىقىرىش',
      outputFileTips:
        'قىسمەن ماركىلىق بىر گەۋدىلىك ماشىنا XY ئالماشتۇرۇش، ھۆججەت بىرلەشتۈرۈش، غەيرى شەكىللىك تۆشۈكنى تېشىپ ئۆتمەسلىك بىلەن ماسلاشمايدۇ، دىققەت قىلىڭ',
      fileType: 'ھۆججەت فورماتى',
      select: 'تاللاڭ',
      diameter: 'تۈزىتىش پىچىقىنىڭ دىئامېتىرى',
      jidongTip:
        'جىدوڭ بىر گەۋدىلىك ماشىنىنى ئىشلەتكەندە ئويما ماشىنىسى خىزمەت ئورنى باشلىنىش نۇقتىسىنى ئوڭ ئۈستى بۇلۇڭغا تەڭشەپ، A ئىقتىدارىنى ئېچىڭ',
      skill: 'ئىقتىدار',
      clampHandLength: 'تۇتقۇچ ئۇزۇنلۇقى(L)',
      clampHandWidth: 'تۇتقۇچ كەڭلىكى(W)',
      clampHandMargin1: 'تۇتقۇچ1 گىرۋەكتىن ئارىلىقى(H1)',
      clampHandMargin2: 'تۇتقۇچ2 گىرۋەكتىن ئارىلىقى(H2)',
      duchClampHand: 'تۆشۈك-يوچۇق تۇتقۇچ ئورنىدىن ساقلىنىش',
      clampHandDirection: 'تۇتقۇچ يۆنىلىشى',
      left: 'سول تەرەپ',
      right: 'ئوڭ تەرەپ',
      top: 'ئۈستى تەرەپ',
      bottom: 'ئاستى تەرەپ',
    },
    oneDragTwo: {
      title: 'بىرنى سۆرەپ ئىككى ئويما ماشىنىسى',
      outputMachineFile:
        'بىرنى سۆرەپ ئىككى ئويما ماشىنىسى ئىشلەش ھۆججىتىنى چىقىرىش',
      outputMachineFileTips:
        'بىرنى سۆرەپ ئىككى ئويما ماشىنىسىنى قوزغاتقاندىن كېيىن كېسىش ماشىنىسى ھۆججەت بىرلەشتۈرۈش ئىقتىدارى بىلەن ماسلاشمايدۇ، دىققەت قىلىڭ',
      fileType: 'ھۆججەت فورماتى',
      select: 'تاللاڭ',
      machineStart: 'خىزمەت ئورنى باشلىنىش نۇقتىسى',
      positionClose:
        'تاقالغاندا كېسىش پارامېتىرىدىكى خىزمەت ئورنى باشلىنىش نۇقتىسى تەڭشىكىگە ئەگىشىدۇ',
      engravingPlus: 'تېز ئويما ماشىنىسى',
      a: '',
    },

    sawEngraving: {
      title: 'ھەرە ئويما ماشىنىسى',
      preLayNav: 'ھەرە ئويما ماشىنىسى تەڭشىكى',
      preLayKnifeInput: 'ھەرە تىغى توغرىسىغا كېسىش پىچىقىنىڭ دىئامېتىرى',
      enableSaw: 'ھەرە ئويما ماشىنىسىنى قوزغىتىش',
      cutSet: 'كېسىش تەڭشىكى',
      sawUsing: 'ھەرە تىغى ئىشلىتىلىشى',
      trim: 'تۈزىتىش',
      cutBlank: 'كېسىپ چۈشۈرۈش',
      sawUseCond: 'ھەرە تىغى ئىشلىتىش شەرتى',
      hasUsing: 'ئىشلەتكىلى بولىدىغان يول بولسا ئىشلىتىش',
      onlyUse: 'ۋاقىتتا ئىشلىتىش',
      cutMethod: 'كېسىش ئۇسۇلى',
      oneKnifePull: 'بىر پىچاق بىلەن چوڭ تاختاينى كېسىپ ئۆتۈش',
      onlyOneKnifePull: 'ۋاقىتتا بىۋاسىتە بىر پىچاق بىلەن كېسىپ ئۆتۈش',
      enableSawThick: 'ھەرە تىغى قېلىنلىقى تەرتىپلەشنى قوزغىتىش',
      edgedDir: 'چەت چاپلاپ بولۇپ قايتا كەسمەيدىغان چوڭ تاختاي گىرۋىكى',
      top: 'ئۈستى گىرۋەك',
      bottom: 'ئاستى گىرۋەك',
      left: 'سول گىرۋەك',
      right: 'ئوڭ گىرۋەك',
      dockTip:
        'پىششىقلانغان تاختاينى ئىشلەشتە تۆت تەرەپ چەت چاپلاش ۋە ئومۇمىي تۈزىتىش ئوخشاش بولۇشى، ھەمدە قولدا بىر قۇر تەرتىپلەش كېرەك',
      tempPaiabanTip:
        'ھەرە تىغى كېسىشنى قوزغاتقاندىن كېيىن تەرتىپلەشتە بىۋاسىتە غەيرى شەكىللىك تاختاي قوشۇشنى قوللىمايدۇ، قوشماقچى بولسىڭىز كېسىش تىزىملىكىگە بېرىپ قوشۇپ تەرتىپلەڭ!',
      generateCutSurplusPath: 'قالدۇق ماتېرىيالنى ئالدى بىلەن كېسىش يولى',
    },
    firstSave:
      'ئىشلەتكۈچىنىڭ تۇنجى قېتىم بۇلۇت تەرتىپلەشنى ئىشلىتىۋاتقانلىقى بايقالدى، تەڭشەكنى ساقلىغاندىن كېيىن ھۆججەتتىكى تۈزىتىش يۆنىلىشىنىڭ توغرا-خاتالىقىغا دىققەت قىلىڭ، بىھۇدە زىياننىڭ ئالدىنى ئېلىڭ',
    // ئويما ماشىنىسى-كېسىش ئۇلىنىشى-پىچاق ئامبىرى
    knives: {
      xOffset1: 'X كوئوردېنات يۆتكىلىشى',
      yOffset1: 'Y كوئوردېنات يۆتكىلىشى',
      zOffset1: 'Z كوئوردېنات يۆتكىلىشى',
      insert: 'قۇر قوشۇش',
      delete: 'قۇر ئۆچۈرۈش',
      tip: '(دىققەت: پىچاق ئامبىرى ئۇچۇرىنى ئۆزگەرتىش ياكى تەڭشەكنى تەڭشىگەندىن كېيىن، ئىشكاپ تەرتىپلەش ئىشلەپچىقىرىشتىكى ئىشلەتكۈچىلەر ئىشكاپ كىلىيېنتىنى قايتا قوزغىتىشى كېرەك، ئەڭ يېڭى ئۆزگەرتىشنى قوللىنىش ئۈچۈن!)',
      number: 'نومۇر',
      name: 'ئىسىم',
      diameter: 'پىچاق دىئامېتىرىΦ',
      cutting: 'كېسىش',
      use: 'ئىشلىتىش',
      moveZF: 'Z ئوقى تېز يۆتكىلىش سۈرئىتى',
      cutZF: 'Z ئوقى ئىشلەش سۈرئىتى',
      moveF: 'XY ئوقى تېز يۆتكىلىش سۈرئىتى',
      cutF: 'XY ئوقى ئىشلەش سۈرئىتى',
      startCode: 'پىچاق ئالماشتۇرۇش باشلىنىش كودى',
      stopCode: 'پىچاق ئالماشتۇرۇش ئاخىرلىشىش كودى',
      startCode2: 'خىزمەت ئورنى2 پىچاق ئالماشتۇرۇش باشلىنىش كودى',
      stopCode2: 'خىزمەت ئورنى2 پىچاق ئالماشتۇرۇش ئاخىرلىشىش كودى',
      preStartCode: 'ئالدىن قوزغىتىش كودى',
      punchHoles: 'تۆشۈك تېشىش',
      pullSlots: 'يوچۇق تارتىش',
      millHoles: 'تۆشۈك ئويۇش',
      millSlots: 'يوچۇق ئويۇش',
      sawBlade: 'ھەرە تىغى',
      doubleSaw:
        'قوش خىزمەت ئورنى ئوخشىمىغان پىچاق ئالماشتۇرۇش باشلىنىش كودىنى قوزغىتىش',
      singleStation: 'يەككە خىزمەت ئورنى',
      doubleStation: 'قوش خىزمەت ئورنى',
      sawBladeTip:
        'بۇ ئىقتىدار بىرنى سۆرەپ ئىككى ئويما ماشىنىسى ۋە بىر گەۋدىلىك ماشىنىغا ئۈنۈم كۆرسەتمەيدۇ',
    },
    other: {
      title: 'باشقىلار',
      tip: '(دىققەت: يەككە خىزمەت ئورنى ئۈسكۈنىسى خىزمەت ئورنى1 ۋە خىزمەت ئورنى2 گە ئوخشاش ئاپتوماتىك ماتېرىيال يۈكلەش كودىنى تولدۇرۇشى كېرەك)',
    },
  },
  sideHoleMachine: {
    moreSetting: 'تېخىمۇ كۆپ تەڭشەك',
    sideStartCode: 'ئالدى يۈزى ئىشلەشتە يۈز ئالماشتۇرۇش كودى ھاسىل قىلىش',
    useArcCode: 'يېرىم چەمبەر تولۇقلاش بۇيرۇقى بىلەن يېرىم چەمبەر كېسىش',
    coordSwitch: 'كوئوردېنات ئاساسى ئالماشتۇرۇش',
    updownCoordSwitch: 'كۆتۈرۈش-چۈشۈرۈش كوئوردېنات ئاساسى ئالماشتۇرۇش',
    knifes: {
      number: 'نومۇر',
      diameter: 'پىچاق كەڭلىكى',
      type: 'تىپى',
      moveSpeed: 'تېز يۆتكىلىش سۈرئىتى',
      doSpeed: 'ئىشلەش سۈرئىتى',
      startCode: 'پىچاق ئالماشتۇرۇش باشلىنىش كودى',
      endCode: 'پىچاق ئالماشتۇرۇش ئاخىرلىشىش كودى',
    },
    ymSetting: 'ئاسان ياغاچ ئۇلىنىش تەڭشىكى',
    flanking: 'يان تەرەپ ئىشلەش',
    frontProcess: 'ئالدى يۈزى ئىشلەش',
    oldSetting: 'كونا نەشرى تەڭشىكى',
    equipParamsSetting: 'ئۈسكۈنە پارامېتىر تەڭشىكى',
    holeSetting: 'تۆشۈك تېشىش تەڭشىكى',
    raminoSetting: 'رامىنو ئۇلىنىش تەڭشىكى',
    mortiseTenonSetting: 'ئۇلاش-چاپلاش تەڭشىكى',
    straight: 'تۈز تىپى',
    eccentric: 'مەركەزدىن قاچقان تىپى',
    pbSize: 'ئۈستى-ئاستى تاختا سۇندۇرۇش ئۆلچىمى',
    coordinateValue: 'كوئوردېنات قىممىتى',
    codeFormat: 'ھۆججەت كودلاش فورماتى',
    suffixSetting: 'ھۆججەت قوشۇمچە نامى تەڭشىكى',
    insertRow: 'قۇر قوشۇش',
    deleteRow: 'قۇر ئۆچۈرۈش',
    equipmentParams: {
      equipPauseCode: 'ئۈسكۈنە توختاش كودى',
      wiggleCode: 'تەۋرىنىش كودى',
      equipStartCode: 'ئۈسكۈنە قوزغىتىش كودى',
      flipPauseCode: 'يۈز ئۆرۈش توختاش كودى',
      equipEndCode: 'ئۈسكۈنە ئاخىرلىشىش كودى',
      equipStartCodePlaceholder: 'ئۈسكۈنە قوزغىتىش كودىنى كىرگۈزۈڭ',
      retractingCode: 'پىچاق يىغىش بۇيرۇق كودى',
      retractingCodePlaceholder: 'پىچاق يىغىش بۇيرۇق كودىنى كىرگۈزۈڭ',
      flipPauseCodePlaceholder: 'يۈز ئۆرۈش توختاش كودىنى كىرگۈزۈڭ',
      equipEndCodeTip: 'ئۈسكۈنە ئاخىرلىشىش كودىنى كىرگۈزۈڭ',
      sideChangeSetting: 'يان تەرەپ يۈز ئالماشتۇرۇش تەڭشىكى',
      workTip:
        'سۈكۈتتىكى ھالەتتە سول تەرەپ ①دىن باشلاپ سائەت ئىستىرېلكىسىغا قارشى يۆنىلىشتە ئىشلەش',
      frontTurnCode: 'ئۈستى يۈزى باشلىنىش كودى',
      frontTurnEndCode: 'ئۈستى يۈزى ئاخىرلىشىش كودى',
      frontTurnCodePlaceholder: 'ئۈستى يۈزى باشلىنىش كودىنى كىرگۈزۈڭ',
      frontTurnEndCodePlaceholder: 'ئۈستى يۈزى ئاخىرلىشىش كودىنى كىرگۈزۈڭ',
      leftTurnCode: 'سول يۈزى باشلىنىش كودى',
      leftTurnEndCode: 'سول يۈزى ئاخىرلىشىش كودى',
      leftTurnCodePlaceholder: 'سول يۈزى باشلىنىش كودىنى كىرگۈزۈڭ',
      leftTurnEndCodePlaceholder: 'سول يۈزى ئاخىرلىشىش كودىنى كىرگۈزۈڭ',
      downTurnCode: 'ئاستى يۈزى باشلىنىش كودى',
      downTurnEndCode: 'ئاستى يۈزى ئاخىرلىشىش كودى',
      downTurnCodePlaceholder: 'ئاستى يۈزى باشلىنىش كودىنى كىرگۈزۈڭ',
      downTurnEndCodePlaceholder: 'ئاستى يۈزى ئاخىرلىشىش كودىنى كىرگۈزۈڭ',
      rightTurnCode: 'ئوڭ يۈزى باشلىنىش كودى',
      rightTurnEndCode: 'ئوڭ يۈزى ئاخىرلىشىش كودى',
      rightTurnCodePlaceholder: 'ئوڭ يۈزى باشلىنىش كودىنى كىرگۈزۈڭ',
      rightTurnEndCodePlaceholder: 'ئوڭ يۈزى ئاخىرلىشىش كودىنى كىرگۈزۈڭ',
      topFlipCode: 'يۈز ئۆرۈش كودى - ئۈستى',
      downFlipCode: 'يۈز ئۆرۈش كودى - ئاستى',
      leftFlipCode: 'يۈز ئۆرۈش كودى - سول',
      rightFlipCode: 'يۈز ئۆرۈش كودى - ئوڭ',
      cylinderOutCode: 'سىلىندىر ئىتتىرىش كودى',
      cylinderbackCode: 'سىلىندىر قايتۇرۇش كودى',
      cylinderbackDirective: 'سىلىندىر قايتۇرۇش بۇيرۇقى',
      moveDirective: 'يۆتكەش بۇيرۇقى',
      moveAxisCode: 'يۆتكىلىش ئوقى',
      pushAxisCode: 'سىلىندىر ئىتتىرىش ئوقى',
      swingAxisCode: 'تەۋرىنىش ئوقى',
      moveCode: 'يۆتكىلىش كودى',
      punchCode: 'ئىشلەش كودى',
      free: 'قۇرۇق يۈرۈش',
      punchPart: 'ئىشلەش قىسمى',
      cutFaceCode: 'ئىشلەش تەكشىلىك كودى',
      horVerCutFace: 'توغرىسىغا-بويىغا تەكشىلىكى',
      horCutFace: 'توغرىسىغا-كۆتۈرۈش تەكشىلىكى',
      verCutFace: 'بويىغا-كۆتۈرۈش تەكشىلىكى',
    },
    tagPool: 'سانلىق مەلۇمات بەلگە ئامبىرى',
    dragTip: 'بەلگە ئامبىرىدىكى بەلگىلەرنى سۆرەپ يۆتكىگىلى بولىدۇ',
    knives: 'ئىشلەش پىچىقى:',
    directives: 'ئىشلەش بۇيرۇقى:',
    slot: 'تۈز يوچۇق ئىشلەش',
    cirWork: 'يېرىم چەمبەر ئىشلەش',
    cirInter: 'يېرىم چەمبەر تولۇقلاش',
    cirOption1: 'سائەت ئىستىرېلكىسى بويىچە:G03 سائەت ئىستىرېلكىسىغا قارشى:G02',
    cirOption2: 'سائەت ئىستىرېلكىسى بويىچە:G02 سائەت ئىستىرېلكىسىغا قارشى:G03',
    coordinate: {
      xPointSymbol: 'توغرىسىغا كوئوردېناتى:',
      yPointSymbol: 'بويىغا كوئوردېناتى:',
      zPointSymbol: 'كۆتۈرۈش كوئوردېناتى:',
      upright: 'مۇسبەت قىممەت',
      negative: 'مەنپىي قىممەت',
    },
    encodeFormat: {
      format: 'ھۆججەت كودلاش فورماتى:',
      wrap: 'قۇر ئالماشتۇرۇش بەلگىسى فورماتى تەڭشىكى:',
      formatOption1: 'UTF-8 فورماتى',
      formatOption2: 'ANSI فورماتى',
    },
    suffix: 'ھۆججەت قوشۇمچە نامى بەلگىلەش:',
    enableFP: 'ئالدى يۈزى ئىشلەشنى قوزغىتىش',
    slotSetting: 'يوچۇق تارتىش تەڭشىكى',
    slotMillSetting: 'يوچۇق تارتىش/ئويۇش تەڭشىكى',
    millSlot: 'يوچۇق ئويۇشنى قوللاش',
    millingSlotOverlapRate: 'يوچۇق ئويۇش قاپلىشىش نىسبىتى',
    takeUpDirective: 'پىچاق يىغىش بۇيرۇقى',
    workSeparately: 'ئالدى/كەينى يۈزى ئۆتمە يوچۇقنى ئىككى قىسىمغا بۆلۈپ ئىشلەش',
    secondSlotLong: 'ئىككىنچى قىسىم يوچۇق ئۇزۇنلۇقى',
    enableOld: 'كونا نەشرى يان تۆشۈك ماشىنىسى تەڭشىكىنى قوزغىتىش',
    default: 'سۈكۈتتىكى تەڭشەك',
    customizable1: 'ئۆزى بەلگىلەش بىر',
    customizable2: 'ئۆزى بەلگىلەش ئىككى',
    customizable3: 'ئۆزى بەلگىلەش ئۈچ',
    customizable4: 'ئۆزى بەلگىلەش تۆت',
    knifeSetting: 'پىچاق تەڭشىكى',
    soltTip: '(تۆشۈك ئويۇش/يوچۇق ئويۇشنى ئۆز ئىچىگە ئالىدۇ)',
    specialSetting: 'ئالاھىدە تەڭشەك',
    ramino: 'رامىنو',
    sun: 'سۇن ماۋ',
    please: 'تەقەززا بولۇڭ',
    designatedKnives: 'بەلگىلەنگەن پىچاق',
    designated: 'بەلگىلەش',
    workOrder: 'ئىشلەش بۇيرۇقى',
    other: 'باشقىلار',
    nameSetting: 'قوشۇمچە نام تەڭشىكى',
    repeatErr: 'نومۇر تەكرارلانغان، قايتا تەڭشەڭ',
  },
  electronicSaw: {
    importMaterial: 'ماتېرىيال تىزىملىكىنى كىرگۈزۈش',
    formSetting: 'ئېلېكترونلۇق ھەرە جەدۋەل تەڭشىكى',
    fileFormat: 'كېسىش تىزىملىكى ھۆججەت فورماتى چىقىرىش:',
    isSmallFile: 'كىچىك تاختا رەسىم ھۆججىتىنى چىقىرىش:',
    fileGroupRule:
      'ئېلېكترونلۇق ھەرە ھۆججىتى رەڭ، ماتېرىيال، قېلىنلىق بويىچە ئوخشىمىغان جەدۋەلگە ئايرىلىدۇ:',
    roomsByCategory:
      'ئېلېكترونلۇق ھەرە ھۆججىتى ئۆي بويىچە ئوخشىمىغان جەدۋەلگە ئايرىلىدۇ:',
    delimiter: 'ئايرىغۇچ بەلگە:',
    fileName: 'كېسىش تىزىملىكى ھۆججەت نامى:',
    fineNameTip: 'كېسىش تىزىملىكى ھۆججەت نامى قۇرۇق قالسا بولمايدۇ!',
    fileNamePlaceholder: 'ئۆزى بەلگىلىگەن كېسىش تىزىملىكى',
    outputFileEncoding: 'چىقىرىش تىزىملىكى كودلاش فورماتى:',
    utf8: 'BOM لىق UTF-8',
    cellTab: 'كاتەكچە بەلگىسى ھاسىل قىلىش:',
    texture: 'تېكىستۇرا كۆرسىتىش:',
    special: 'ئالاھىدە شەكىل كۆرسىتىش:',
    notSpecial: 'ئالاھىدە شەكىل ئەمەس',
    genSimpleLabelBarcode: 'ئاددىي فورماتلىق بەلگە شتىرىخ كودى ھاسىل قىلىش',
    uniqueBarCode: 'تاختا شتىرىخ كودى يەككە',
    uniqueBarCodeTip:
      'قوزغاتقاندىن كېيىن ئاددىي فورماتلىق بەلگە شتىرىخ كودى ھاسىل قىلىش ئىقتىدارى ئۈنۈمسىز',
    customeForm: 'ئۆزى بەلگىلىگەن جەدۋەل',
    tagPool: 'سانلىق مەلۇمات بەلگە ئامبىرى',
    tagPoolTips:
      'بەلگە ئامبىرىدىكى بەلگىلەرنى سۆرەپ ياكى چېكىپ كۆچۈرگىلى بولىدۇ',
    displayHead: 'جەدۋەل بېشىنى كۆرسەتمەسلىك',
    addTableHead: 'جەدۋەل بېشى قوشۇش',
    deleteTableHead: 'جەدۋەل بېشىنى ئۆچۈرۈش',
    generateExample: 'چىقىرىش جەدۋىلى مىسالى ھاسىل قىلىش',
    importExample: 'جەدۋەل مىسالى كىرگۈزۈش',
    importExampleTip:
      'كىرگۈزۈلگەن جەدۋەل مىسالى پەقەت ئۆزى بەلگىلىگەن ئېلېكترونلۇق ھەرە ئۈچۈن پايدىلىنىش',
    importExampleErrTip:
      'تېخى ماتېرىيال تىزىملىكى كىرگۈزۈلمىدى، ماتېرىيال تىزىملىكىنى كىرگۈزۈڭ',
    outputExample: 'چىقىرىش جەدۋىلى مىسالى',
    outputExampleTip: 'ئۆزى بەلگىلىگەن جەدۋەلنىڭ چىقىرىش مىسالى',
    outputExampleErrTip:
      'تېخى چىقىرىش جەدۋىلى مىسالى ھاسىل قىلىنمىدى، چىقىرىش جەدۋىلى مىسالى ھاسىل قىلىڭ',
    defaultTableHead: 'سۈكۈتتىكى جەدۋەل بېشى',
    deleteHeadErrTip: 'ئۆچۈرگىلى بولىدىغان جەدۋەل بېشى يوق!',
    genNonString: 'تېكىست فورماتى بولمىغان سانلىق مەلۇمات تىپى ھاسىل قىلىش',
    genNonStringTip:
      'ھاسىل قىلىنغان جەدۋەل سانلىق مەلۇماتىنى ئېلېكترونلۇق ھەرە تونۇيالمىغاندا قوزغىتىپ سىناپ بېقىڭ',
  },
  // قۇلۇپلاش كۆزنەك كومپونېنتى
  lockModal: {
    passwordInput: 'پارولنى كىرگۈزۈڭ',
    lockPasswordInput: 'قۇلۇپ ئېچىش پارولىنى كىرگۈزۈڭ',
    passwordInputPlaceholder: 'تۆت خانىلىق پارولنى كىرگۈزۈڭ',
    editPassword: 'پارولنى ئۆزگەرتىش',
    confirmPassword: 'پارولنى جەزملەش',
    setLockPwd: 'قۇلۇپ ئېچىش پارولىنى بەلگىلەش',
    pwdFormatErr: 'پارول فورماتى خاتا',
    sendMessageTo: 'قىسقا ئۇچۇر دەلىللەش كودى ئەۋەتىلىدىغان نومۇر',
    captchaPlaceholder: 'دەلىللەش كودىنى كىرگۈزۈڭ',
    sendCaptcha: 'دەلىللەش كودىنى ئەۋەتىش',
    sendSeccess: 'قىسقا ئۇچۇر ئەۋەتىلدى',
    sendFail: 'قىسقا ئۇچۇر ئەۋەتىش مەغلۇپ بولدى، قايتا سىناڭ',
    updateSeccess: 'پارول تەڭشەش مۇۋەپپەقىيەتلىك بولدى',
    editSuccess: 'پارول ئۆزگەرتىش مۇۋەپپەقىيەتلىك بولدى',
    lockSeccess: 'قۇلۇپ ئېچىش مۇۋەپپەقىيەتلىك بولدى',
    lock: 'قۇلۇپ ئېچىش',
    lockSeccessTip: 'قۇلۇپلاش مۇۋەپپەقىيەتلىك بولدى',
    inputErrLimit:
      'نۆۋەتتىكى خاتالىق سانى چەكتىن ئېشىپ كەتتى، قايتا قۇلۇپ ئېچىش پارولىنى بەلگىلەڭ!',
  },
  // بەش-ئالتە يۈزلۈك تۆشۈك ماشىنىسى
  dock56Drill: {
    equipType: 'ئۈسكۈنە تىپى',
    equipment: 'ئۈسكۈنە',
    fiveDock: 'بەش يۈزلۈك تۆشۈك ماشىنىسى',
    sixDock: 'ئالتە يۈزلۈك تۆشۈك ماشىنىسى',
    fileType: 'ھاسىل قىلىنغان ھۆججەت تىپى',
    templateOffline: 'ئىشلىتىلگەن قېلىپ توردىن چۈشۈرۈلدى!',
    showExample: 'سانلىق مەلۇمات مىسالىنى كۆرسىتىش',
    conNoticeTip: 'ئۇلاش دىققەت قىلىدىغان ئىشلار:',
    conNoticeTip1:
      '1、"XY ئوقى ئالماشتۇرۇش" قوزغىتىلغان نەقىشلەش ماشىنىسى ئالدى-كەينى يۈزى ئالماشتۇرۇلغان بەش/ئالتە يۈزلۈك تۆشۈك ماشىنىسى مەخسۇس قېلىپىنى ئىشلىتىشى كېرەك',
    conNoticeTip2:
      '2、بەش-ئالتە يۈزلۈك تۆشۈك ماشىنىسىدا تاختاينىڭ ئالاھىدە شەكىللىك قىسمىنى كۆرسىتىش ياكى ئىشلەش ئۈچۈن قېلىپتا ئالاھىدە شەكىللىك قىسىمنىڭ سانلىق مەلۇماتىنى سەپلەش كېرەك',
    conNoticeTip3:
      '3、تۇنجى قېتىم بەش-ئالتە يۈزلۈك تۆشۈك ماشىنىسىنى ئىشلىتىش ياكى قېلىپنى ئالماشتۇرغاندىن كېيىن <span style="color:#f00">ئىشكاپ سىناق ئىشلەپچىقىرىشى</span> ئېلىپ بېرىڭ، خاتالىق يوقلۇقىنى جەزملىگەندىن كېيىن ئاندىن نورمال ئىشلەپچىقىرىشنى باشلاڭ',
    conNoticeTip4:
      '4、سىناق ئىشلەپچىقىرىشتا ئالدى-كەينى يۈزىدىكى تۆشۈك ۋە يوچۇقلارنىڭ چوڭ-كىچىكلىكى، چوڭقۇرلۇقى، ئورنى ۋە جايلىشىشىنىڭ توغرا-خاتالىقىنى تەپسىلىي تەكشۈرۈڭ، <span style="color:#f00">ئىشكاپ كۆزگۈ سۈرىتى</span> مەسىلىسىنىڭ كېلىپ چىقىشىدىن ساقلىنىڭ',
    example: 'سانلىق مەلۇمات مىسالى',
    commonSetting: 'ئورتاق پارامېتىر تەڭشىكى',
    throughHoleSetting: 'ئۆتمە تۆشۈكنى ئىككى يۈزگە بۆلۈپ تېشىش',
    throughSlotSetting: 'ئۆتمە يوچۇقنى ئىككى يۈزگە بۆلۈپ تارتىش',
    nonHolesNotFiles:
      'تۆت بۇلۇڭلۇق تاختايدا تۆشۈك-يوچۇق بولمىسا ھۆججەت چىقارماسلىق:',
    frontFileIdentifier: 'ئالدى يۈزى ھۆججەت بەلگىسى:',
    oppositeFileIdentifier: 'كەينى يۈزى ھۆججەت بەلگىسى:',
    oppositeFileIdentifierTips:
      'ھاسىل قىلىش ئۇسۇلى: ئەسلى 13 خانىلىق تاختاي كودى + بەلگە ھەرپى',
    arcDescription: 'يېرىم چەمبەر تەسۋىرلەش ئۇسۇلى',
    linePoints: 'سىزىق شەكىللەندۈرۈش تەسۋىرى',
    arcCommand: 'يېرىم چەمبەر بۇيرۇقى تەسۋىرى',
    slotWidthSplit: 'كەڭ يوچۇقنى كۆپ تار يوچۇققا بۆلۈپ ئىشلەش:',
    slotSplitWidth: 'بۆلۈنگەندىن كېيىنكى تاق يوچۇق كەڭلىكى:',
    mm: 'دىققەت: بۆلۈش نىشانى ئادەتتىكى تۆت بۇلۇڭلۇق يوچۇق',
    isNotMergeSlot:
      'يانداش تەڭ كەڭلىك تەڭ چوڭقۇرلۇقتىكى يوچۇقنى بىرلەشتۈرۈپ تارتىش:',
    slotPunchTip:
      'بۇ ئىقتىدار پەقەت ئۆزى بەلگىلىگەن قېلىپتىكى ئارقا تاختا يوچۇقى ۋە تارتما تېگى تاختىسى يوچۇقىغا ئۈنۈملۈك',
    sizeRange: 'يانداش ئۆلچەم كىچىك ياكى تەڭ',
    enableWorningTip:
      'بۇ ئىقتىدار بىلەن ئالدى گىرۋەك قويۇش يۆنىلىشىنى تەڭلا قوزغاتقىلى بولمايدۇ',
    longDirection: 'تاختاي ئۇزۇن تەرىپىنىڭ قويۇلۇش يۆنىلىشى',
    horizontal: 'توغرىسىغا',
    vertical: 'بويىغا',
    slotDirection:
      'بەش/ئالتە يۈزلۈك تۆشۈك ماشىنىسى يوچۇقى ياكى ئالاھىدە شەكىل قويۇلۇش يۆنىلىشى',
    default: 'سىستېما سۈكۈتتىكى',
    frontLeft: 'ئالدى ۋە سول',
    frontRight: 'ئالدى ۋە ئوڭ',
    backLeft: 'كەينى ۋە سول',
    backRight: 'كەينى ۋە ئوڭ',
    frontDirection: 'ئالدى گىرۋەك قويۇلۇش يۆنىلىشى',
    enableTip: 'دىققەت: پەقەت بۇلۇتلۇق تەرتىپلەشكە ئۈنۈملۈك',
    fixedOn: 'مۇقىملاشتۇرۇش ئورنى:',
    front: 'ئالدى تەرەپ',
    back: 'كەينى تەرەپ',
    left: 'سول تەرەپ',
    right: 'ئوڭ تەرەپ',
    coordinateValue: 'كوئوردېنات قىممىتى ساقلاش خانە سانى:',
    decimalPlaces: 'خانە',
    specialParameter: 'ئالاھىدە پارامېتىر تەڭشىكى',
    output5FDrillSlotKnifeCode: 'XML2 يوچۇق ئويۇش پىچاق نومۇرى',
    output5FDrillShapeKnifeCode: 'XML2 شەكىل ئويۇش پىچاق نومۇرى',
    plankTexture: 'XML2 تاختاي تېكىستۇرىسى',
    offset: 'XML2 شەكىل ئويۇش پىچاق تولۇقلىمىسى',
    mprConHoleDir:
      'بەش/ئالتە يۈزلۈك تۆشۈك ماشىنىسى MPR ھۆججىتى يان تۆشۈكنىڭ Z كوئوردېنات ئاساسى يۈزى',
    plankTop: 'تاختاي ئۈستى يۈزى',
    plankBottom: 'تاختاي ئاستى يۈزى',
    codeInfo: 'ban فورماتلىق ھۆججەت Code ئۇچۇرىنى ئۆز ئىچىگە ئالىدۇ:',
    bomFile: 'bom فورماتلىق ھۆججەت چىقىرىش:',
    plankHeight: 'تاختاي ئېگىزلىكى چۈشەندۈرۈلۈشى:',
    plankNum: 'تاختاي نومۇرى چۈشەندۈرۈلۈشى:',
    backZpoint: 'كەينى يۈزى يوچۇق Z كوئوردېناتى:',
    plankThick: 'تاختاي قېلىنلىقى',
    slotDeep: 'يوچۇق چوڭقۇرلۇقى',
    outputMprFile: 'ھۆججەت تىپى',
    oldMPR: 'كونا نەشرى MPR',
    newMPR: 'يېڭى نەشرى MPR',
    mprFileZside:
      'بەش/ئالتە يۈزلۈك تۆشۈك ماشىنىسى MPR ھۆججىتى يان تۆشۈكنىڭ Z كوئوردېنات ئاساسى يۈزى',
    fileTypeOffline:
      'نۆۋەتتە تاللانغان بەش-ئالتە يۈزلۈك تۆشۈك ماشىنىسى ھۆججەت تىپى توردىن چۈشۈرۈلدى، جەزملەڭ!',
    permissionErr: 'كىملىك ئۇچۇرى يوقالدى، بۇ بەتنى قايتا ئىشكاپتىن ئېچىڭ!',
    banFileSetting: 'ban فورماتلىق ھۆججەت تەڭشىكى',
    banFileCodeInfo: 'ban فورماتلىق ھۆججەت Code ئۇچۇرىنى ئۆز ئىچىگە ئالىدۇ:',
    huahuaLine: 'خۇاخۇا ئاپتوماتىك لىنىيەسى',
    plankPutWay: 'تاختاي قويۇش ئۇسۇلى',
    autoHandle: 'تۇتقۇچتىن ئاپتوماتىك ساقلىنىش',
    manualHandle: 'قولدا ساقلىنىش تەڭشىكى',
    fixedFront: 'ئالدى گىرۋەكنى مۇقىملاشتۇرۇش',
    onlyBluen: 'پەقەت بۇلۇتلۇق تەرتىپلەشكە ئۈنۈملۈك',
    handDirection: 'تۇتقۇچ ئورنى يۆنىلىشى',
    handleMachineTip:
      'ئايلاندۇرۇش ئارقىلىق تۆشۈك-يوچۇق ۋە ئالاھىدە شەكىللەرنى تۇتقۇچ تەرەپتىن ئاپتوماتىك ساقلىنىش، تاختاينى ئىشلەشكە قۇلايلىق يارىتىش',
    handleWidth: 'تۇتقۇچ كەڭلىكى',
    handleLength: 'تۇتقۇچ ئۇزۇنلۇقى',
    handleMaxWidth: 'ئۈسكۈنە ئىشلىيەلەيدىغان ئەڭ چوڭ كەڭلىك',
    usefulRange: 'ئۈنۈم دائىرىسى',
    plankLongSide: 'تاختاي ئۇزۇن تەرىپى',
    usefulRangeTip:
      'تاختاي ئۇزۇن تەرىپىنى قويۇش قوزغىتىلغان ۋە تاختاينىڭ ئۇزۇن تەرىپى بۇ ئۈنۈم دائىرىسى ئىچىدە بولغاندا، يوچۇق ۋە ئالاھىدە شەكىل قويۇلۇش ئورنىنى ئالدىن بەلگىلەيدۇ',
  },
  // PTP
  PTP: {
    title: 'PTP',
    outputFileFormat: 'ھۆججەت فورماتى چىقىرىش',
    outputDefaultFileFormat: 'سۈكۈتتىكى فورماتلىق ھۆججەت چىقىرىش',
    outputMPRFormat: 'MPR فورماتلىق ھۆججەت چىقىرىش',
    fileFormatSettings: 'ھۆججەت فورماتى تەڭشىكى',
    wrapSettings: 'قۇر ئالماشتۇرۇش بەلگىسى فورماتى تەڭشىكى:',
    enCodeSettings: 'ھۆججەت كودلاش فورماتى تەڭشىكى:',
    utf8bom: 'BOM لىق UTF-8',
    partCoordinate: 'كىچىك تاختاي كوئوردېناتى',
    xPointSymbol: 'توغرىسىغا كوئوردېناتى:',
    yPointSymbol: 'بويىغا كوئوردېناتى:',
    zPointSymbol: 'كۆتۈرۈش كوئوردېناتى:',
    upRight: 'مۇسبەت قىممەت',
    negative: 'مەنپىي قىممەت',
    plankMirroring: 'تاختاي كۆزگۈ سۈرىتى',
    plankMirrorSetting: 'تاختاي كۆزگۈ سۈرىتى تەڭشىكى:',
    xAxis: 'X ئوقى كۆزگۈ سۈرىتى',
    yAxis: 'Y ئوقى كۆزگۈ سۈرىتى',
    mirrorTip: '(تاختاي كۆزگۈ سۈرىتى ۋاقتىدا بۇ ئىقتىدارنى ئىشلىتىڭ)',
    knifeTable: {
      type: 'تىپى',
      diameter: 'دىئامېتىرى Φ',
      offsetX: 'X يۆتكىلىش',
      offsetY: 'Y يۆتكىلىش',
      offsetZ: 'Z يۆتكىلىش',
      offsetNo: 'يۆتكىلىش نومۇرى',
      drillVelocity: 'پىچاق چۈشۈرۈش [F]mm/min',
      cutVelocity: 'ئىشلەش [F]mm/min',
      rotationSeep: 'ئايلىنىش سۈرئىتى [S]r/min',
      insertRow: 'قۇر قوشۇش',
      deleteRow: 'قۇر ئۆچۈرۈش',
    },
    labelPool: 'سانلىق مەلۇمات بەلگە ئامبىرى',
    dragTip:
      'بەلگە ئامبىرىدىكى بەلگىلەرنى كىرگۈزۈش رامكىسىغا سۆرەپ ياكى چېكىپ كۆچۈرگىلى بولىدۇ',
    procedure: 'پروگرامما',
    beginCode: 'پروگرامما باشلاش كودى:',
    endCode: 'پروگرامما ئاخىرلىشىش كودى:',
    zzdKnifeBeginCode: 'باش ئوق پىچىقى ئالماشتۇرۇش باشلاش كودى:',
    spindleCutter: 'باش ئوق پىچىقى',
    zzdKnifeEndCode: 'باش ئوق پىچىقى ئالماشتۇرۇش ئاخىرلىشىش كودى:',
    verticalDrill: 'تىك تۆشۈك',
    czzBeginCode: 'تىك تۆشۈك قوزغىتىش كودى:',
    czzEndCode: 'تىك تۆشۈك ئاخىرلىشىش كودى:',
    czzKnifeBeginCode: 'تىك تۆشۈك پىچىقى ئالماشتۇرۇش باشلاش كودى:',
    czzKnifeEndCode: 'تىك تۆشۈك پىچىقى ئالماشتۇرۇش ئاخىرلىشىش كودى:',
    sidetrack: 'يان تۆشۈك',
    czBeginCode: 'يان تۆشۈك قوزغىتىش كودى:',
    czEndCode: 'يان تۆشۈك ئاخىرلىشىش كودى:',
    czKnifeBeginCode: 'يان تۆشۈك پىچىقى ئالماشتۇرۇش باشلاش كودى:',
    czKnifeEndCode: 'يان تۆشۈك پىچىقى ئالماشتۇرۇش ئاخىرلىشىش كودى:',
    pauseCode: 'توختاش كودى:',
    zSafeHeight: 'بىخەتەر ئارىلىق',
    spindleKnife: 'باش ئوق پىچىقى ۋە تىك تۆشۈك Z ئوقى بىخەتەر ئېگىزلىكى',
    sideDrillZ: 'يان تۆشۈك Z ئوقى بىخەتەر ئېگىزلىكى',
    sideDrillXY: 'يان تۆشۈك X/Y ئوقى بىخەتەر ئارىلىقى',
    auxiliaryFunction: 'ياردەمچى ئىقتىدار',
    xhDedicatedInfo: 'شىڭخۇي ئۈسكۈنىسى مەخسۇس ئۇچۇرىنى ھاسىل قىلىش',
    xhCheckBox: 'سانلىق مەلۇمات:',
    outputSettings: 'چىقىرىش تەڭشىكى:',
    partExegesis: 'ھۆججەتتە كىچىك تاختاي ئۆلچىمى چۈشەندۈرۈشىنى ھاسىل قىلىش',
    knifeNo: 'پىچاق نومۇرى',
    specailKnifeNo: 'ئالاھىدە شەكىل ئىشلەش پىچاق نومۇرى:',
    slotKnifeNo: 'يوچۇق ئېچىش پىچاق نومۇرى:',
    fileNamePlaceholder: 'ئۆزى بەلگىلىگەن كېسىش تىزىملىكى',
    slotDrill: 'يوچۇق تارتىش پىچىقى',
  },
  // ئەينەك كېسىش ماشىنىسى
  glassEquip: {
    title: 'ئەينەك كېسىش ماشىنىسى',
    fileType: 'ئىشلەش ھۆججەت تىپى:',
    sizeReparation: 'تاختاي ئۆلچىمى تولۇقلىمىسى:',
    cutPreSpace: 'تاختاي كېسىش قالدۇرۇش ئارىلىقى:',
    divideIntoFolders:
      'ئەينەكنىڭ رەڭگى، ماتېرىيالى، قېلىنلىقى بويىچە ئوخشىمىغان قىسقۇچقا ئايرىش:',
  },
  translate: {
    search: 'خەنزۇچە ياكى رەسمىي تەرجىمىنى كىرگۈزۈپ تۈر ئىزدەڭ',
    en: 'ئىنگلىزچە',
    kz: 'قازاقچە',
    vi: 'ۋيېتنامچە',
    th: 'تايلاندچە',
    id: 'ھىندونېزىيەچە',
    ug: 'ئۇيغۇر تىلى',
    customTranslate: 'چەكسىڭىز ئۆزى بەلگىلىگەن تەرجىمە قوشالايسىز',
    customText: 'ئۆزى بەلگىلىگەن تەرجىمە',
    text: 'خەنزۇچە',
    targetLang: 'رەسمىي تەرجىمە',
  },
  // ئالدىن تەرتىپلەش تەڭشىكى
  preLayoutSetting: {
    title: 'ئالدىن تەرتىپلەش تەڭشىكى',
    titleTip:
      'ئالدىن تەرتىپلەش پارامېتىرلىرىنى تەڭشەپ، ئاۋۋال تەرتىپلەپ، ئاندىن ئىشلەپچىقىرىش لىنىيەسىنى تاللىيالايسىز',
    settingTip:
      'ئىشلەپچىقىرىش لىنىيەسىدىكى پارامېتىرلار تۆۋەندىكى ئالدىن تەرتىپلەش پارامېتىرلىرى بىلەن ئوخشاش بولغاندىلا ئىشلەپچىقىرىش ھۆججىتىنى چىقارغىلى بولىدۇ، پەقەت كېسىش ماشىنىسىغا ئۈنۈملۈك',
    emptyKnife: 'پىچاق تولدۇرۇلمىغان',
    noModify: 'تەڭشەك ئۆزگەرتىلمىگەن، ساقلاش ھاجەتسىز',
    reSet: 'ئەسلىگە قايتۇرۇش',
    gayPosition:
      'كۈلرەڭ كاتەكچە قالدۇق ماتېرىيال قويۇش ئورنىنى ئالماشتۇرالايدۇ',
    surplusPosition: 'نۆۋەتتىكى قالدۇق ماتېرىيال قويۇلۇش ئورنى',
    click: 'چېكىش',
    grayStart: 'كۈلرەڭ كاتەكچە خىزمەت ئورنى باشلىنىش نۇقتىسىنى ئالماشتۇرالايدۇ',
    currentStart: 'نۆۋەتتىكى خىزمەت ئورنى باشلىنىش نۇقتىسى',
    holeSlotSetting: 'تۆشۈك-يوچۇقنى ئۆتكۈزۈپ ئىشلەش تەڭشىكى',
    knifeDiameter: 'كېسىش پىچىقى دىئامېتىرى',
    currentVersion: 'نۆۋەتتىكى نەشرى',
    on: 'قوزغىتىش',
    off: 'تاقاش',
    firstShort: 'تۇنجى كېسىش-قىسقا تەرەپ قويۇش',
    firstLong: 'تۇنجى كېسىش-ئۇزۇن تەرەپ قويۇش',
    cuttingSplitLong:
      'ئۇزۇن تاختاينى ئىككى قېتىم كېسىش-ئۇزۇن تەرىپى ئېشىپ كەتسە',
    cuttingSplitShort:
      'ئۇزۇن تاختاينى ئىككى قېتىم كېسىش-قىسقا تەرىپى ئېشىپ كەتسە',
    plankThick: 'نورمال تاختاي قېلىنلىقى ≥ ',
    normalPlank: 'نورمال تاختاي',
    confilictTip: 'تەڭشەك توقۇنۇش تۈرى مەۋجۇت: {confilictTotal} دانە',
    selectLine: 'ئىشلەپچىقىرىش لىنىيەسىنى تاللاش',
    selectLinePlaceholder: 'ئىشلەپچىقىرىش لىنىيەسىنى تاللاڭ',
    lineConfilictTip:
      'تاللانغان ئىشلەپچىقىرىش لىنىيەسى بىلەن {line} توقۇنۇشىدۇ',
    preWay: 'ئالدىن تەرتىپلەش پىلانى',
    paibanErrTip:
      'ئۇزۇن تاختاي ئىشلەشتىكى【ئۇزۇن، قىسقا تەرەپ قويۇش】ئۆزگەرگەندە، تەرتىپلەش مەغلۇپ بولۇشى مۇمكىن',
    paibanTip:
      'بۇ قېتىملىق تەرتىپلەشتە چوڭ تاختاي ماتېرىيالى بەلگىلەنگەن كېسىش پىچىقى بار، قايتا تەرتىپلەش كېرەك',
    reLayout: 'نۆۋەتتىكى ئىشلەپچىقىرىش لىنىيەسى بويىچە قايتا تەرتىپلەش',
    fetchErr: 'ئالدىن تەرتىپلەش تەڭشىكىنى ئېلىش مەغلۇپ بولدى',
    resetSuccess: 'ئەسلىگە قايتۇرۇش مۇۋەپپەقىيەتلىك بولدى',
    resetConfirm: 'تارىخىي نەشرىگە ئەسلىگە قايتۇرۇشنى جەزملەمسىز؟',
    prolineName: 'ئىشلەپچىقىرىش لىنىيەسى نامى',
    layoutGap: 'تەرتىپلەش ئارىلىقى',
    generalThickness: 'ئىككىنچى قېتىم كېسىش نىشانى-نورمال تاختاي قېلىنلىقى',
    cutGeneralPlank: 'ئىككىنچى قېتىم كېسىش نىشانى-نورمال تاختاي',
    cutSingleDoor: 'ئىككىنچى قېتىم كېسىش نىشانى-ئىشىك تاختىسى',
    changeProline: 'ئىشلەپچىقىرىش لىنىيەسىنى ئالماشتۇرۇش',
    backTip: 'ئومۇمىي تەرتىپلەشكە قايتىپ ئىشلەپچىقىرىش لىنىيەسىنى تاللاڭ',
    preLayout: 'ئالدىن تەرتىپلەش پىلانى بويىچە تەرتىپلەش',
    preWayEmpty: '(قۇرۇق)ئالدىن تەرتىپلەش پىلانى',
    specialConfilictTip:
      'يېرىم چەمبەر تېگى ئويۇش تېخنىكىسى تەرتىپلەش نەتىجىسىگە تەسىر قىلدى، قايتا تەرتىپلەش كېرەك',
  },
  lunbo: {
    tips: 'مەشغۇلات كىچىك ئامال',
    introduction: 'ئىقتىدار تونۇشتۇرۇش',
    qtDownloadText:
      'ئەسكەرتىش: ھۆججەت تولۇق چۈشمىگەن ئەھۋال كۆرۈلسە، بۇلۇتلۇق تەرتىپلەش تور بېتىگە بېرىپ چۈشۈرۈڭ!',
  },
}
