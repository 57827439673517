/** ======================= 板件绘制 ===================== */
import { DrawPlankColor } from '@/data/plank'
import { CurveHolesType, PartType } from '@/partTypes'

import { toDecimal } from '../commonFuncs'
import { dealCurveHoles, getThroughPathObj } from '../drawPlankFuncs'

/**
 * 为啥要使用类来设计这部分代码呢，主要是应对未来的一些需求，保证扩展性，比如
 * 当增加需求当鼠标移动到牛角槽上的时候，牛角槽需要变色，那么只需要在牛角槽的绘制类中完成相关
 * 逻辑而不需要去增加一些函数
 *
 * 目前只是转移的之前的代码逻辑，找到时间后来优化这里的写法以及相关逻辑
 */
export interface PlankDrawing {
  ctx: CanvasRenderingContext2D
  // 默认缩放倍数
  defaultScale: number
  // 动态缩放倍数
  scalePercent: number
  draw: (...args: any) => void
}

/** 基类 实现了绘制类的一些基本功能 */
class BasePlankDrawing implements PlankDrawing {
  public rectSize!: { width: number; height: number }
  constructor(
    public ctx: CanvasRenderingContext2D,
    public plank: PartType,
    public startX: number,
    public startY: number,
    public defaultScale: number,
    public scalePercent: number
  ) {
    this.rectSize = this.getPlankRectSize()
    if (!this.ctx) {
      const canvas = document.createElement('canvas')
      canvas.width = this.rectSize.width
      canvas.height = this.rectSize.height
      this.ctx = canvas.getContext('2d')!
    }
  }
  private getPlankRectSize() {
    const { plank } = this
    return {
      width: this.transformSizeFromScale(plank.rect.width),
      height: this.transformSizeFromScale(plank.rect.height),
    }
  }
  draw() {
    throw new Error('Method not implemented')
  }
  transformSizeFromScale(size: number) {
    return (size / this.defaultScale) * this.scalePercent
  }
}

/** 第一版现将现在逻辑进行分离，如果后续还是不满足需求则再次重构 */
/** 绘制一张小板 */
export interface DrawPlankOptions {
  plankLineWidth?: number
}
export class Plank extends BasePlankDrawing {
  // 可自定义的参数
  private customFillStyle: null | string = null
  private customStrokeStyle: null | string = null
  private customLineWidth: null | number = null
  private strategy: PlankDrawing[] = []
  // 板件绘制中需要携带一起绘制的参数
  constructor(
    public ctx: CanvasRenderingContext2D,
    public plank: PartType & { isClampHand?: boolean },
    public drawStartX: number,
    public drawStartY: number,
    public defaultScale: number,
    public scalePercent: number,
    public options?: DrawPlankOptions
  ) {
    super(ctx, plank, drawStartX, drawStartY, defaultScale, scalePercent)
    this.options = {
      plankLineWidth: options?.plankLineWidth || 1,
    }
  }
  draw() {
    this.ctx.fillStyle = this.getFillStyle()
    this.ctx.lineWidth = this.getLineWidth()
    this.ctx.strokeStyle = this.getStrokeStyle()
    this.plank.path ? this.drawPolygon() : this.drawRect()
    // 如果存在其他绘制则都完成绘制
    if (!this.strategy.length) return
    this.strategy.forEach((strategy) => {
      strategy.draw()
    })
  }
  private drawPolygon() {
    const { plank } = this
    // 记录板件轮廓的点位顺逆时针
    const area = window.ClipperLib.JS.AreaOfPolygon(plank.path[0])
    const firstDirection = area > 0 ? false : true
    this.ctx.beginPath()
    // 打穿长圆孔的加入异形的绘制
    const finalCurveHoles = dealCurveHoles(plank.curveHoles)
    const throughCurveHoles = getThroughPathObj(finalCurveHoles, plank.thick)
    const newArr = [
      ...plank.path,
      ...throughCurveHoles
        .map((it: CurveHolesType) => it.path)
        .filter((it: CurveHolesType) => it),
      // ...throughMillInfo.map((it) => it.shape),
    ]

    for (let i = 0; i < newArr.length; ++i) {
      const current = newArr[i]

      if (i > 0) {
        const area2 = window.ClipperLib.JS.AreaOfPolygon(current)
        const direction = area2 < 0
        if (direction === firstDirection) {
          current.reverse()
        }
      }
      for (let k = 0; k < current.length; ++k) {
        const x = this.startX + this.transformSizeFromScale(current[k].x)
        const y = this.startY + this.transformSizeFromScale(current[k].y)
        if (k == 0) {
          this.ctx.moveTo(x, y)
        } else {
          this.ctx.lineTo(x, y)
        }
      }
      this.ctx.closePath()
    }
    this.ctx.stroke()
    // 采用奇偶环绕规则进行填充
    this.ctx.fill()
  }
  private drawRect() {
    this.ctx.fillRect(
      this.startX,
      this.startY,
      this.rectSize.width,
      this.rectSize.height
    )
    this.ctx.strokeRect(
      this.startX,
      this.startY,
      this.rectSize.width,
      this.rectSize.height
    )
  }
  setFillStyle(color: string) {
    this.customFillStyle = color
  }
  /** 只处理常规颜色，特殊需求需自行处理 */
  private getFillStyle() {
    if (this.customFillStyle) return this.customFillStyle
    const { plank } = this
    // 余料板件颜色
    if (plank.specialType) return DrawPlankColor.surplus
    // 孔槽冲突板件颜色
    if (plank.holeSlotMerge) return DrawPlankColor.holeSlotMerge
    // 板件冲突板件颜色
    if (plank.plankMerge) return DrawPlankColor.plankMerge
    // 夹手颜色
    if (plank.isClampHand) return DrawPlankColor.clampHand
    // 正常板件颜色
    return DrawPlankColor.normal
  }
  setLineWidth(width: number) {
    this.customLineWidth = width
  }
  private getLineWidth() {
    return this.customLineWidth ?? this.options?.plankLineWidth ?? 1
  }
  setStrokeStyle(color: string) {
    this.customStrokeStyle = color
  }
  private getStrokeStyle() {
    return this.customStrokeStyle ?? '#0008'
  }
  // 添加其他的绘制
  addStrategy(strategy: PlankDrawing | PlankDrawing[]) {
    const draws = Array.isArray(strategy) ? strategy : [strategy]
    this.strategy.push(...draws)
  }
}

/** 孔绘制类 */
export class DrawHoles extends BasePlankDrawing {
  constructor(
    public ctx: CanvasRenderingContext2D,
    public plank: PartType,
    public drawStartX: number,
    public drawStartY: number,
    public defaultScale: number,
    public scalePercent: number
  ) {
    super(ctx, plank, drawStartX, drawStartY, defaultScale, scalePercent)
  }
  draw() {
    if (!this.plank.holes?.length) return
    this.plank.holes.forEach((hole) => {
      this.ctx.strokeStyle = hole.side == 1 ? '#f008' : '#00f8'
      const circle = {
        x: this.startX + this.transformSizeFromScale(hole.center.x),
        y: this.startY + this.transformSizeFromScale(hole.center.y),
        r: this.transformSizeFromScale(hole.diameter / 2),
      }
      this.ctx.beginPath()
      this.ctx.arc(circle.x, circle.y, circle.r, 0, Math.PI * 2, true)
      this.ctx.closePath()
      this.ctx.stroke()
    })
  }
  transformSizeFromScale(size: number) {
    return (size / this.defaultScale) * this.scalePercent
  }
}
/** 槽绘制类 一个需要优化的类 */
export class DrawSlots extends BasePlankDrawing {
  constructor(
    public ctx: CanvasRenderingContext2D,
    public plank: PartType,
    public drawStartX: number,
    public drawStartY: number,
    public defaultScale: number,
    public scalePercent: number
  ) {
    super(ctx, plank, drawStartX, drawStartY, defaultScale, scalePercent)
  }
  draw() {
    const slots = [this.plank.slots, this.plank.handleSlopes]
    slots.forEach((item, index) => {
      if (item && item.length > 0) {
        for (let i = 0; i < item.length; ++i) {
          const slot = item[i]
          if (slot.side == 1) {
            this.ctx.strokeStyle = '#f008'
          } else {
            this.ctx.strokeStyle = '#00f8'
          }
          const slotInfo: any = {}
          // 拉槽两点pt1, pt2为拉槽平行边的中点, 所以计算起始点需要减去拉槽的宽度的一半
          // 如果拉槽两点的横坐标相等 则为竖拉槽
          slotInfo.width = this.transformSizeFromScale(slot.width)
          const { pt1, pt2 } = slot
          if (Math.abs(pt1.x - pt2.x) <= 0.0001) {
            // 找出更小的纵坐标, 因为canvas是从左上角绘制的
            slotInfo.x =
              this.startX +
              this.transformSizeFromScale(slot.pt1.x) -
              this.transformSizeFromScale(slot.width) / 2
            slotInfo.long = Math.abs(
              this.transformSizeFromScale(slot.pt1.y - slot.pt2.y)
            )
            if (slot.pt1.y > slot.pt2.y) {
              slotInfo.y = this.startY + this.transformSizeFromScale(slot.pt2.y)
            } else {
              slotInfo.y = this.startY + this.transformSizeFromScale(slot.pt1.y)
            }
            const { x, y, width, long } = slotInfo
            if (x + width > this.startX + this.rectSize.width && index == 1) {
              this.ctx.strokeRect(x - width / 2, y, width, long)
            } else if (x < this.startX && index == 1) {
              this.ctx.strokeRect(x + width / 2, y, width, long)
            } else {
              this.ctx.strokeRect(x, y, width, long)
            }
          }
          // 如果拉槽两点的纵坐标相等, 则为横拉槽
          if (Math.abs(pt1.y - pt2.y) <= 0.0001) {
            // 找出更小的横坐标
            slotInfo.y =
              this.startY +
              this.transformSizeFromScale(slot.pt1.y) -
              this.transformSizeFromScale(slot.width) / 2
            slotInfo.long = Math.abs(
              this.transformSizeFromScale(slot.pt1.x - slot.pt2.x)
            )
            if (slot.pt1.x > slot.pt2.x) {
              slotInfo.x = this.startX + this.transformSizeFromScale(slot.pt2.x)
            } else {
              slotInfo.x = this.startX + this.transformSizeFromScale(slot.pt1.x)
            }
            const { x, y, width, long } = slotInfo
            if (y < this.startY && index == 1) {
              this.ctx.strokeRect(x, y + width / 2, long, width)
            } else if (
              y + width > this.startY + this.rectSize.height &&
              index == 1
            ) {
              this.ctx.strokeRect(x, y - width / 2, long, width)
            } else {
              this.ctx.strokeRect(x, y, long, width)
            }
          }
        }
      }
    })
  }
  transformSizeFromScale(size: number) {
    return (size / this.defaultScale) * this.scalePercent
  }
}

/** 下刀点绘制类 一个需要优化的类 */
export interface DrawCutDotOption {
  dotWidth?: number
}
export class DrawCutDot extends BasePlankDrawing {
  constructor(
    public ctx: CanvasRenderingContext2D,
    public plank: PartType,
    public drawStartX: number,
    public drawStartY: number,
    public defaultScale: number,
    public scalePercent: number,
    public options?: DrawCutDotOption
  ) {
    super(ctx, plank, drawStartX, drawStartY, defaultScale, scalePercent)
    this.options = {
      dotWidth: options?.dotWidth || 5,
    }
  }
  draw() {
    if (!this.plank.cutOrigin) return
    this.ctx.fillStyle = '#9845d9'
    const position = this.plank.cutOrigin
    const cutOrigin = {
      r: 2 * this.scalePercent,
      x: 0,
      y: 0,
    }
    const { dotWidth } = this.options as any
    switch (position) {
      case 'leftTop':
        cutOrigin.x = this.startX + dotWidth
        cutOrigin.y = this.startY + dotWidth
        break
      case 'leftBottom':
        cutOrigin.x = this.startX + dotWidth
        cutOrigin.y = this.startY + this.rectSize.height - dotWidth
        break
      case 'rightTop':
        cutOrigin.x = this.startX + this.rectSize.width - dotWidth
        cutOrigin.y = this.startY + dotWidth
        break
      case 'rightBottom':
        cutOrigin.x = this.startX + this.rectSize.width - dotWidth
        cutOrigin.y = this.startY + this.rectSize.height - dotWidth
        break
    }
    this.ctx.beginPath()
    this.ctx.arc(cutOrigin.x, cutOrigin.y, cutOrigin.r, 0, Math.PI * 2, true)
    this.ctx.closePath()
    this.ctx.fill()
  }
}
/** 下刀顺序绘制类 */
export interface DrawCutOrderOptions {
  textWidth: number
  fontSize: number
}
export class DrawCutOrder extends BasePlankDrawing {
  constructor(
    public ctx: CanvasRenderingContext2D,
    public plank: PartType,
    public drawStartX: number,
    public drawStartY: number,
    public defaultScale: number,
    public scalePercent: number,
    public options?: DrawCutOrderOptions
  ) {
    super(ctx, plank, drawStartX, drawStartY, defaultScale, scalePercent)
    this.options = {
      textWidth: options?.textWidth ?? 3,
      fontSize: options?.fontSize ?? 14,
    }
  }
  draw() {
    if (!this.plank.priority) return
    this.ctx.fillStyle = '#333'
    const { textWidth, fontSize } = this.options!
    const fs = fontSize * this.scalePercent
    this.ctx.font = `bold ${fs}px 'PingFangSC-Regular, PingFang SC'`
    // 文字的绘制, 是以起始点开始, 向上绘制(不知道为什么这个是反的)
    const text = {
      x: this.startX + textWidth,
      y: this.startY + textWidth + fs,
    }
    this.ctx.fillText(String(this.plank.priority), text.x, text.y)
  }
}

/** 异形孔绘制类 */
export class DrawCurveHoles extends BasePlankDrawing {
  constructor(
    public ctx: CanvasRenderingContext2D,
    public plank: PartType,
    public drawStartX: number,
    public drawStartY: number,
    public defaultScale: number,
    public scalePercent: number
  ) {
    super(ctx, plank, drawStartX, drawStartY, defaultScale, scalePercent)
  }
  draw() {
    if (!this.plank.curveHoles?.length) return
    const thick = this.plank.thick ?? 0
    // const finalCurveHoles = this.dealCurveHoles(this.plank.curveHoles)
    const noThroughCurveHoles = getThroughPathObj(
      this.plank.curveHoles,
      thick,
      false
    ) as CurveHolesType[]
    noThroughCurveHoles.forEach((hole) => {
      if (!hole.path) return
      if (hole.side == 1) {
        this.ctx.strokeStyle = '#f008'
      } else {
        this.ctx.strokeStyle = '#00f8'
      }
      this.ctx.beginPath()
      for (let i = 0; i < hole.path.length; i++) {
        const x = this.startX + this.transformSizeFromScale(hole.path[i].x)
        const y = this.startY + this.transformSizeFromScale(hole.path[i].y)
        if (i == 0) {
          this.ctx.moveTo(x, y)
        } else {
          this.ctx.lineTo(x, y)
        }
      }
      this.ctx.stroke()
      this.ctx.closePath()
    })
  }
  transformSizeFromScale(size: number) {
    return (size / this.defaultScale) * this.scalePercent
  }
}

/** 牛角槽绘制类 */
export class DrawMillInfo extends BasePlankDrawing {
  constructor(
    public ctx: CanvasRenderingContext2D,
    public plank: PartType,
    public drawStartX: number,
    public drawStartY: number,
    public defaultScale: number,
    public scalePercent: number
  ) {
    super(ctx, plank, drawStartX, drawStartY, defaultScale, scalePercent)
  }
  draw() {
    if (!this.plank.millInfo?.length) return
    this.plank.millInfo.forEach((slot) => {
      if (!slot.shape) return
      if (slot.side == 1) {
        this.ctx.strokeStyle = '#f008'
      } else {
        this.ctx.strokeStyle = '#00f8'
      }
      this.ctx.beginPath()
      for (let i = 0; i < slot.shape.length; i++) {
        const x = this.startX + this.transformSizeFromScale(slot.shape[i].x)
        const y = this.startY + this.transformSizeFromScale(slot.shape[i].y)
        if (i == 0) {
          this.ctx.moveTo(x, y)
        } else {
          this.ctx.lineTo(x, y)
        }
      }
      this.ctx.stroke()
      this.ctx.closePath()
    })
  }
  transformSizeFromScale(size: number) {
    return (size / this.defaultScale) * this.scalePercent
  }
}

/** 小板尺寸绘制 */
export interface DrawLittlePlankSizeOptions {
  textWidth: number
}
export class DrawLittlePlankSize extends BasePlankDrawing {
  constructor(
    public ctx: CanvasRenderingContext2D,
    public plank: PartType,
    public drawStartX: number,
    public drawStartY: number,
    public defaultScale: number,
    public scalePercent: number,
    public options: DrawLittlePlankSizeOptions
  ) {
    super(ctx, plank, drawStartX, drawStartY, defaultScale, scalePercent)
    this.options = {
      textWidth: options?.textWidth || 3,
    }
  }
  draw() {
    const realWidth = toDecimal(this.plank.realRect.width, 2)
    const realHeight = toDecimal(this.plank.realRect.height, 2)
    const fontSize = 14 * this.scalePercent
    // 宽度的字体宽度
    const widthTextW = this.ctx.measureText(String(realWidth)).width
    // 高度的字体宽度
    const heightTextW = this.ctx.measureText(String(realHeight)).width
    const { textWidth } = this.options
    // 高度文字的x、y
    const partHeight = {
      x: this.startX + textWidth,
      y: this.startY + this.transformSizeFromScale(realHeight / 2),
    }
    // 宽度文字的x、y
    const partWidth = {
      x:
        this.startX +
        this.transformSizeFromScale(realWidth / 2) -
        widthTextW / 2,
      y: this.startY + this.transformSizeFromScale(realHeight),
    }
    const specialPartWidth = {
      x:
        this.startX +
        this.transformSizeFromScale(realWidth / 2) -
        widthTextW / 2,
      y:
        this.startY +
        this.transformSizeFromScale(realHeight / 2) -
        fontSize / 2,
    }
    const specialPartHeight = {
      x:
        this.startX +
        this.transformSizeFromScale(realWidth / 2) -
        heightTextW / 2,
      y: this.startY + this.transformSizeFromScale(realHeight / 2) + fontSize,
    }
    // 普通矩形
    if (!this.plank.path) {
      // 绘制宽度文字底色
      this.drawPartSizeBg(
        partWidth.x,
        partWidth.y - fontSize,
        widthTextW,
        fontSize
      )
      // 绘制高度文字底色
      this.drawPartSizeBg(
        partHeight.x,
        partHeight.y - fontSize,
        heightTextW,
        fontSize
      )
      this.ctx.fillStyle = '#333'
      this.ctx.font = `bold ${fontSize}px 'PingFangSC-Regular, PingFang SC'`
      this.ctx.fillText(`${realWidth}`, partWidth.x, partWidth.y)
      this.ctx.fillText(`${realHeight}`, partHeight.x, partHeight.y)
    } else {
      // 绘制宽度文字底色
      this.drawPartSizeBg(
        specialPartWidth.x,
        specialPartWidth.y - fontSize,
        widthTextW,
        fontSize
      )
      // 绘制高度文字底色
      this.drawPartSizeBg(
        specialPartHeight.x,
        specialPartHeight.y - fontSize,
        heightTextW,
        fontSize
      )
      this.ctx.fillStyle = '#333'
      this.ctx.font = `bold ${fontSize}px 'PingFangSC-Regular, PingFang SC'`
      this.ctx.fillText(`${realWidth}`, specialPartWidth.x, specialPartWidth.y)
      this.ctx.fillText(
        `${realHeight}`,
        specialPartHeight.x,
        specialPartHeight.y
      )
    }
  }
  private drawPartSizeBg(
    startX: number,
    startY: number,
    width: number,
    height: number
  ) {
    this.ctx.beginPath()
    this.ctx.fillStyle = '#fff'
    this.ctx.rect(startX, startY, width, height)
    this.ctx.fill()
    this.ctx.closePath()
  }
}
