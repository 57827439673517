/**
 * 印尼语
 */
export default {
  common: {
    checkPlankIsConflict:
      'Status pelat sedang diuji. Status pelat sedang diuji. Status pelat sedang diuji. Status pelat sedang diuji.',
    preferenceSaveErr: 'Exception in saving preferences',
    guimen: 'Pintu kabinet',
    preferenceLoadTip: 'Memuat preferensi Anda',
    preferenceLoadErrTip:
      'Preferensi gagal memuat, silakan menyegarkan dan mencoba lagi untuk menghindari menyebabkan kesalahan preferensi Anda!',
    slotKnifeErr:
      'Untuk slot yang hilang, alat pemrosesan yang ditentukan, silakan periksa pengaturan jalur produksi! 【{Msg}】',
    localFileIsBeingWrite: 'File lokal sedang ditulis',
    taskMonitor: 'Pemantauan tugas',
    downloadByType: 'Unduh {type}',
    anewChooseCatalog: 'Pilih direktori',
    downloadSetting: 'Unduh pengaturan',
    attention: 'Melihat',
    errpb: 'Kesalahan terjadi, coba lagi',
    unit: 'bagian',
    defaultUse: 'Digunakan secara default',
    defaultChooseUse: 'Dipilih secara default',
    requestFail: 'Permintaan gagal',
    downloadDoneByType: '{type} unduh selesai',
    sysTip: 'Permintaan sistem',
    pleaseChoose: 'Pilih',
    confirmDelete: 'Konfirmasi penghapusan',
    notDataTipByType: 'Belum ada data, tolong {msg} pertama',
    paginationTotalTip: '{current}-{page}/total {total}',
    chooseAmountData: '{number} Data yang dipilih',
    moreOperate: 'Lagi',
    transferByType: 'Transfer {type}',
    clickCopy: 'Klik untuk menyalin {type}',
    currentLine: 'Baris saat ini',
    remark: 'Komentar',
    customFunction: 'Fitur Kustom',
    limitedTimeTrial: 'Terbatas untuk dikecualikan',
    startDate: 'tanggal mulai',
    endDate: 'Tanggal akhir',
    startTime: 'Waktu mulai',
    endTime: 'Waktu akhir',
    lastMonth: 'Bulan lalu',
    lastSixMonth: 'Enam bulan terakhir',
    lastYear: 'Tahun lalu',
    searchByType: 'Harap masukkan {type} untuk meminta permintaan',
    addTime: 'Tambahkan waktu',
    startArrange: 'Mulai tipografi',
    select: 'memilih',
    name: 'nama',
    plankNo: 'Nomor Dewan',
    plankName: 'Nama Dewan',
    orderNo: 'Nomor pesanan',
    order: 'Memesan',
    plankRemark: 'Catatan pelat',
    room: 'Ruang',
    orderAddress: 'Galeri Proyek',
    matCode: 'Bahan',
    matCode2: 'Bahan',
    plankMatCode: 'Bahan pelat',
    count: 'kuantitas',
    customer: 'klien',
    customerInfo: 'Informasi pelanggan',
    factoryOrderNo: 'Nomor pesanan pabrik',
    roomName: 'Ruang',
    color: 'warna',
    plankColor: 'Warna piring',
    plankThick: 'Ketebalan pelat',
    plankHighGloss: 'Sorot atribut',
    thick: 'ketebalan',
    front: 'depan',
    back: 'Sebaliknya',
    slot: 'alur',
    hole: 'lubang',
    hsInfo: '{hole} hole {slot} slot',
    diameter: 'diameter',
    finishedWidth: 'Lebar produk jadi',
    finishedHeight: 'Panjang produk jadi',
    texture: 'Tekstur',
    normal: 'Garis vertikal',
    reverse: 'Garis horizontal',
    notCare: 'Tidak ada tekstur',
    holeSlotsStastic: 'Jumlah slot lubang',
    edge: {
      title: 'Segel tepi',
      info: 'Informasi pemblokiran tepi',
      front: 'Segel tepi depan',
      back: 'Segel tepi belakang',
      left: 'Blok sisi kiri',
      right: 'Blok sisi kanan',
      up: 'Segel tepi atas',
      down: 'Segel tepi berikutnya',
    },
    isSpecial: 'Asing',
    notSpecial: 'Tidak berbentuk non-ekstra',
    isDoorPlank: 'Panel pintu',
    notDoorPlank: 'Panel non-pintu',
    width: 'lebar',
    long: 'panjang',
    plankWidth: 'Lebar piring',
    plankHeight: 'Panjang piring',
    shortLong: 'Panjang sisi pendek',
    shortWidth: 'Lebar sisi pendek',
    plankShape: 'Bentuk piring',
    detail: 'Detail',
    cancel: 'Membatalkan',
    confirm: 'mengonfirmasi',
    deep: 'kedalaman',
    other: 'lainnya',
    heighGloss: 'Menyorot',
    heighGlossPlank: 'Papan Sorotan',
    notHeighGloss: 'Non-highlight',
    notHeighGlossPlank: 'Papan non-highlight',
    saveSuccess: 'Simpan dengan sukses',
    deleteSuccess: 'Hapus dengan sukses',
    addSuccess: 'Ditambahkan dengan sukses',
    editSuccess: 'Modifikasi berhasil',
    engraving: 'Mesin ukiran',
    electronicSaw: 'Gergaji elektronik',
    glassEquip: 'Mesin pemotong kaca',
    labelingMachine: 'Mesin pelabelan',
    copy: 'menyalin',
    paste: 'Pasta',
    undo: 'Menarik kembali',
    move: 'bergerak',
    PTP: 'PTP',
    dock56FDrill: 'Berlian lima sisi',
    sideHoleDrill: 'Mesin lubang samping',
    combination: 'Peralatan kombinasi',
    integration: 'Mesin all-in-one',
    standardPlank: 'Papan besar standar',
    standardMaterial: 'Lembar Standar',
    standardMaterialTip:
      'CATATAN: Setelah menyelesaikan penyesuaian informasi papan besar, pengguna yang berada dalam produksi tata letak kabinet perlu memulai kembali klien kabinet untuk menerapkan perubahan terbaru!',
    specialMaterial: 'Piring khusus',
    specialPlank: 'Dewan Besar Khusus',
    singlePlank: 'Panel tunggal',
    previous: 'Sebelumnya',
    plankInfo: 'Detail Papan Besar',
    moreResult: 'Lebih banyak solusi pengaturan huruf',
    moreResultTip:
      'Ada alur untuk menggiling di pelat besar, dan pelat besar tidak akan berpartisipasi dalam tata letak jenis ulang.',
    ok: 'Tentu',
    dispersePaibanTip:
      'Terdeteksi bahwa beberapa papan besar tidak dapat disebarkan dalam tata letak. Anda dapat secara manual menyesuaikan atau mengganti rencana tata letak untuk menghindari menjalankan papan!',
    next: 'Berikutnya',
    rate: 'Tingkat pemanfaatan',
    paibanBatch: 'Batch pengukur huruf',
    return: 'kembali',
    FGNo: 'Nomor kontrak',
    refresh: 'menyegarkan',
    inde: 'Nomor seri',
    productionLine: 'Jalur produksi',
    operation: 'beroperasi',
    colorPlaceHolder: 'Tekan Enter untuk secara otomatis membuat kartu warna',
    saveAndContinue: 'Simpan dan lanjutkan',
    reset: 'Mengatur ulang',
    check: 'Pertanyaan',
    inputPlaceholder: 'Harap masuk',
    filter: 'menyaring',
    all: 'semua',
    exportPDF: 'Ekspor PDF',
    exportPic: 'Ekspor gambar',
    add: 'Tambahkan ke',
    edit: 'edit',
    delete: 'menghapus',
    save: 'menyimpan',
    saveSetting: 'Simpan Pengaturan',
    isHeightGloss: 'Pilih apakah itu papan highlight',
    noSync: 'Absen',
    sync: 'sinkronis',
    formatSuccess: '{type} sukses',
    dataLoading: 'Data sedang memuat ...',
    warmTip: 'Tips yang baik',
    wellcome: 'Selamat Datang di Tata Letak Cloud',
    timeOut: 'Mengatur waktu pengaturan waktu, apakah akan mengetik ulang',
    warning: 'memperingatkan',
    export: 'Ekspor',
    mark: 'tanda',
    noMark: 'Tidak ditandai',
    sawArrange: 'Jenis pemotongan melihat',
    directArrange: 'Jenis Potong Lurus',
    area: 'daerah',
    plankNum: 'Barcode papan',
    loc: 'Kabinet',
    cutHeight: 'Panjang pemotongan',
    cutWidth: 'Lebar pemotongan',
    defaultProduction: 'Jalur produksi default',
    tip: 'petunjuk',
    history: 'Sejarah',
    hinge: 'Lubang engsel',
    hingeScrew: 'Lubang pemandu panel berengsel',
    stretch: 'Pelurus',
    stretcher: 'Slot pelurus',
    clothole: 'Pakaian melalui lubang',
    backPlank: 'Slot pelat belakang',
    ALS: 'Slot pegangan gelap',
    doorSlot: 'Alur logam panel pintu',
    drawerSlot: 'Slot logam laci',
    KC: 'Hole Tarik Celana',
    stealth: 'Bagian yang tidak terlihat',
    lkSlot: 'Slot kunci',
    lightSlot: 'Palung cahaya',
    allHole: 'Semua lubang di papan berbentuk khusus',
    allSlot: 'Semua slot di papan berbentuk khusus',
    haiTang: 'Tanduk Begonia',
    millInfo: 'Pegangan tanduk',
    FSDrill: 'Berlian lima/enam sisi',
    engraving2: 'Mesin pemotong',
    xyReverse: 'XY Axis Interchange',
    xyReverseTip:
      'Fungsi pertukaran sumbu xy tidak kompatibel dengan lekukan bahan residu tipe-L ditempatkan dengan benar, silakan gunakan dengan hati-hati',
    rect: 'persegi panjang',
    Ltype: 'Berbentuk l',
    archive: 'Arsip',
    tellPlaceholder: 'Harap masukkan nomor ponsel Anda',
    knivesStore: 'Perpustakaan Alat',
    roll: 'Piring terbalik',
    pause: 'berhenti sebentar',
    editTrans: 'Edit dan Terjemahkan',
    translateManager: 'Manajemen Terjemahan',
    gratingSlot: 'Slot gril',
    addressEditErr:
      'Modifikasi alamat proyek tidak didukung untuk beberapa pesanan',
    customerEditErr:
      'Beberapa pesanan tidak mendukung memodifikasi informasi pelanggan',
    factoryEditErr:
      'Modifikasi nomor pesanan pabrik tidak didukung untuk beberapa pesanan',
    roomNameEditErr:
      'Memodifikasi nama kamar tidak didukung untuk beberapa pesanan',
    remarkEditErr: 'Beberapa pesanan belum mendukung modifikasi pesanan',
    toMaterialList: 'Pergi ke Bill of Material',
    deleteFail: 'Penghapusan gagal, coba lagi nanti!',
    batchDelete: 'Hapus Batch',
    workShop: 'Produksi lokakarya',
    FGAdrress: 'Alamat Proyek/Nomor Kontrak',
    cuttingSize: 'Ukuran pemotongan',
    finishedSize: 'Ukuran produk jadi',
    labelImg: 'Label gambar',
    layoutImg: 'Penyusunan huruf',
    file: 'dokumen',
    folder: 'Folder',
    reName: 'Ganti nama',
    to: 'ke',
    plankUnit: 'membuka',
    tagTemp: 'Tag Template',
    roomRemark: 'Catatan kamar',
    noModificationPermission: 'Tidak ada izin modifikasi',
    modificationRecord: 'Ubah catatan',
    page: 'Halaman',
    strip: 'mengupas',
    jump: 'Melompat',
    freeAgreement: 'Penafian Perangkat Lunak',
    preStep: 'Sebelumnya',
    nextStep: 'Langkah selanjutnya',
    upload: 'Mengunggah',
    uploading: 'Mengunggah',
    iAgree: 'Saya setuju',
    pleaseSelectPlank: 'Pilih setidaknya satu papan',
    saveToStoreTip: 'Pilih setidaknya satu bagian yang tidak berpotongan',
    fileIsNotExist: 'File tidak ada',
    selectAll: 'Pilih semua',
    pdfTag: 'Tag PDF',
    tagImage: 'Gambar tag',
    projectName: 'Ketik cloud',
    guigui: 'Kabinet',
    deleteTip:
      'Itu tidak dapat dipulihkan setelah menghapus data. Apakah Anda yakin bahwa data dihapus?',
    deleteError: 'Terjadi kesalahan yang tidak diketahui, penghapusan gagal',
    cancelDelete: 'Undelete',
    confirmDeleteTip:
      'Setelah menghapus papan, papan akan memasuki "pelat yang akan diketik perpustakaan-tidak dimasukkan" di sudut kanan bawah halaman. Apakah Anda pasti akan menghapus data?',
    deleteEmptyTip: 'Apakah akan menghapus papan ini',
    notCanDelete: 'Bahan residu yang dipotong tidak mendukung penyimpanan',
    new: 'Baru',
    innerBluneFailure: 'Tata letak awan tertanam tidak berlaku.',
    deleteBigPlankTip:
      'Piring besar harus disimpan di bawah ketebalan warna bahan saat ini dan tidak dapat dihapus!',
    continueExport: 'Abaikan, terus output file',
    toCheck: 'Periksa',
    pleaseCheck:
      'Hasil tata letak saat ini memiliki konflik slot lubang/terbang dari piring kecil/kelainan dalam, silakan periksa!',
    colorNotUndefined: 'Warnanya tidak bisa kosong',
    materialNotUndefined: 'Materi tidak bisa kosong',
    newSetting: 'Fitur baru',
    downImageErrTip:
      'Gambar ekspor gagal karena gambar pelabelan dibuka dan dibagi menjadi folder yang berbeda sesuai dengan papan besar. Anda perlu memeriksa label papan kecil untuk mengekspornya bersama.',
    downNcErrTip:
      'Unduh NC gagal karena gambar pelabelan dibagi menjadi folder yang berbeda sesuai dengan papan besar. Anda perlu memeriksa label papan kecil untuk mengekspornya bersama.',
    yes: 'Ya',
    no: 'TIDAK',
    checkColorPrint:
      'Label warna saat ini dihidupkan, silakan gunakan printer warna untuk mencetak ~',
    colorRules:
      'Setelah membuka tag warna yang dihasilkan, pesanan/kamar/kabinet/panel pintu akan dibedakan sesuai dengan warnanya. Saat ini, batas atas tag warna untuk jenis data yang sama adalah 12. Aturan spesifiknya adalah sebagai berikut:',
    greyPart: 'Bagian abu -abu dalam label di sebelah kanan adalah',
    region: 'daerah',
    notSame:
      'Daerah yang berbeda dengan warna {regionName} yang berbeda berbeda',
    doorTip: 'Panel pintu abu-abu, putih adalah panel non-pintu',
    exist: 'ada',
    limitNotEffect: 'Lebih dari 12 data, label warna tidak berlaku',
    hoverTip:
      'Gambar saat ini memiliki akhiran BMP, format pengkodean berwarna hitam dan putih, dan gambar label warna tidak bisa output.',
    lock: 'mengunci',
    unlock: 'Membuka kunci',
    batchLock: 'Batch Lock/Buka Kunci',
    surplusLock: 'Kunci bahan yang tersisa',
    surplusUnlock: 'Bahan yang tersisa tidak dikunci',
    is: 'Ya',
    not: 'TIDAK',
    savingHistory: 'Menyimpan file historis',
    saveHistorySuccess: 'Menyimpan file historis dengan sukses',
    saveHistoryFail: 'Gagal menyimpan file historis',
    bw: 'Apakah akan mengekspor label hitam putih',
    modular: 'Kabinet unit',
    autoAdjust: 'Penyesuaian otomatis',
    cutOrderTip:
      'Kesalahan dalam urutan pemotongan [{{errorCutordersString} sheet Anda terdeteksi! Harap lakukan penyesuaian sebelum mengunduh file NC',
    lockedCutOrderTip:
      '【{ErrorCutordersString} papan besar】 terkunci, silakan ubah secara manual',
    autoAdjustWarning:
      'Hasil tata letak saat ini tidak mendukung penyesuaian otomatis',
    checkNetwork: 'Silakan periksa jaringannya',
    moreInfo: 'Lihat informasi lebih lanjut',
    continueLayout: 'Lanjutkan mengetik',
    adjustPosition: 'Sesuaikan Pengaturan',
    specialAndFiveSix:
      'Saat pengeboran pada pengeboran lima/enam sisi untuk memproses pelat dengan bentuk khusus, menyalakan bentuk khusus yang dapat menyebabkan pemotongan ke pelat. Harap konfirmasi apakah akan terus mengetik?',
    plankPathErr:
      'Data bentuk khusus internal papan tidak benar. Silakan periksa apakah itu memenuhi spesifikasi alat! Nomor papan [{plankidlist}]',
  },
  home: {
    head: {
      production: 'PENDAHULUAN PRODUK',
      resolve: 'Larutan',
      about: 'tentang kami',
      equipment: 'Mesin dan peralatan',
      tell: 'Nomor telepon keluhan',
      enter: 'Masukkan tata letak cloud',
    },
    swiper: {
      case1: {
        tag: 'Versi yang ditingkatkan baru',
        title: 'Ketik cloud',
        subTitle: 'Otak mesin yang cerdas',
        description:
          'Mereformasi perangkat lunak optimasi tata letak online cloud industri',
      },
      case2: {
        title: 'Produksi Kabinet',
        subTitle: 'Inovasi Teknologi',
        description:
          'Mendukung beberapa mesin pelabelan, mesin pemotong, mesin lubang samping, PTP lima sisi, koneksi pengeboran lima sisi',
      },
      case3: {
        title: 'Otak Industri',
        subTitle: 'Larutan',
        description:
          'Algoritma pengaturan huruf yang cerdas untuk mendapatkan tingkat pemanfaatan dewan yang lebih tinggi',
      },
    },
    statistics: {
      today: 'Volume shift hari ini',
      toMounth: 'Volume tata letak bulanan',
      outturn: 'Hasil material',
      cumulative: 'Pengguna Kumulatif',
    },
    intro: {
      title: 'Perangkat Lunak Tata Letak Online Data Besar Level Enterprise',
      subTitle:
        'Wawasan tentang tren industri, fokus pada kebutuhan pelanggan, memberdayakan perusahaan untuk mengubah dan meningkatkan secara digital, dan mempromosikan transformasi industri otomatisasi produksi furnitur internet. Menanggapi skenario produksi industri furnitur panel yang ada, algoritma yang dibantu AI diluncurkan untuk terus menyumbangkan pertumbuhan baru ke perusahaan',
      menue: {
        case1: {
          title: 'Tipografi online',
          subTitle: 'Ketik kapan saja',
          description:
            'Tidak perlu mengunduh perangkat lunak, itu dapat diketik kapan saja, remote control tidak harus bergantung pada komputer',
        },
        case2: {
          title: 'Produksi multi-line',
          subTitle: 'Sakelar cepat dari jalur produksi',
          description:
            'Mendukung beberapa jalur produksi untuk diproses dengan cepat pada saat yang sama, dan dapat dengan cepat beralih di antara jalur produksi',
        },
        case3: {
          title: 'Produksi yang baik',
          subTitle: 'Tata letak halus, pengaturan parameter halus',
          description:
            'Tata letak halus dan pengaturan parameter halus untuk membuat produksi papan lebih akurat',
        },
        case4: {
          title: 'Produksi Kustom',
          subTitle:
            'Label khusus, latihan lima dan enam sisi, bentuk bahan residu',
          description:
            'Label Kustom, Latihan Lima dan Enam Sisi Kustom, Bentuk Bahan Residual Kustom, Pemrosesan Peralatan Gabungan',
        },
      },
    },
    fangan: {
      title: 'Solusi Profesional di Industri',
      subTitle:
        'Biaya rendah, nilai output tinggi, algoritma cerdas meningkatkan pemanfaatan papan, kompatibel dengan berbagai perangkat lunak desain, memberikan solusi produksi terbaik kepada pengguna',
      menue: {
        case1: {
          title: 'Integrasi Produksi Pengambilan Pesanan Online',
          description:
            'Operasi online jarak jauh, hambatan lintas ruang, pesanan output kapan saja, dan desain lengkap dan sinkronisasi produksi',
        },
        case2: {
          title: 'Jalur produksi switching satu klik',
          description:
            'Jalur produksi khusus, mendukung beberapa jalur produksi, dan docking peralatan multi-tipe',
        },
        case3: {
          title: 'Algoritma cerdas meningkatkan pemanfaatan dewan',
          description:
            'Pemanfaatan Dewan Algoritma Tradisional Rendah, Pengaturan Parameter Baik Algoritma Cerdas, Rencana Produksi Kustomisasi',
        },
        case4: {
          title: 'Rencana pemotongan profesional',
          description:
            'Optimalkan urutan pemotongan dan titik pemotongan untuk membuat jalur lebih pendek dan lebih stabil',
        },
      },
    },
    aboutUs: {
      title1: 'Teknologi Tempat Tinggal Manusia Xingeer',
      subTitle: 'Pemimpin dalam transformasi industri produksi furnitur',
      description:
        'Xingeer Enterprise berfokus pada mewujudkan transformasi internet industri produksi furnitur tradisional',
      culture: {
        case1: {
          title: 'Tujuan perusahaan',
          subTitle: 'Membantu pabrik tumbuh tiga kali lipat',
        },
        case2: {
          title: 'Nilai Budaya',
          subTitle:
            'Berpusat pada pelanggan, berjuang untuk orang-orang, bekerja keras dan bermanfaat bagi orang-orang',
        },
        case3: {
          title: 'Ideologi membimbing',
          subTitle:
            'Jalur massal, membangun tim elit dengan penjualan, produk, dan kekuatan organisasi',
        },
      },
      feature: {
        case1: 'Pemeliharaan Tim Profesional',
        case2: 'Pembaruan dan iterasi berkelanjutan',
        case3: 'Layanan purna jual satu-ke-satu',
        case4: 'Layanan Kustomisasi Fungsional',
      },
    },
    cooperation: {
      title: 'Bekerja sama dengan tulus untuk menciptakan masa depan bersama',
      subTitle:
        'Merek peralatan docking mencakup 90% peralatan umum yang digunakan di Cina, dan 107.554 pedagang bepergian bersama kami',
    },
    footer: {
      contact: {
        title: 'Hubungi kami',
        adrres:
          'Alamat: No. 50 Xiuxi, Distrik Binhu, Kota Wuxi, Provinsi Jiangsu',
        subAdrres: 'Lantai 16, Bangunan 1, Pusat Layanan K-Park',
        tell: 'Telepon',
      },
      business: {
        title: 'Xingele Core Business',
        department1: 'Departemen Bisnis Pintu dan Windows Perangkat Lunak',
        department2: 'Departemen bisnis perangkat lunak furnitur',
        department3: 'Departemen Majelis dan Konstruksi',
        department4: 'Situs Resmi Markas Xingele',
      },
      brand: {
        title: 'Merek di bawah Xingele',
        brand1: 'Dewa Bahan',
        brand2: 'Jaringan peta pintu',
        brand3: 'Investasi Dingxin',
        brand4: 'Tiga gambar teknologi',
        brand5: 'Teknologi Shanghai Pink',
      },
      organization: {
        title: 'Xingere Global Branch',
        organization1: 'Xingele-South China Bureau (Cabang Foshan)',
        organization2: 'Xingele-Northern Bureau (cabang Linyi)',
        organization3: 'Biro Xingele-Southwest (Cabang Chengdu)',
        organization4: 'Xingele-Coffee Cabinet Western Service Center',
        organization5: 'Xingele-Coffer Eastern Service Center',
        organization6: 'Xingele-Agent Cluster',
        organization7: 'Departemen Pemasaran Xingele-International',
      },
      follow: {
        title: 'Pindai "Ikuti Kabinet"',
      },
      friendLink: {
        link1: 'Perangkat Lunak Xingele',
        link2: 'K Perangkat Lunak Panduan Belanja',
        link3: 'Jaringan peta pintu',
        link4: 'Perangkat Lunak Kabinet All-Aluminium',
        link5: 'Unduh Perangkat Lunak Zol',
        link6: 'Taman Perangkat Lunak Huajun',
        link7: 'Unduh rumah',
        link8: 'Unduh Perangkat Lunak Zol',
        link9: 'Jaringan Sumber Daya Hijau',
        link10: 'Waralaba Lemari',
        link11: 'Waralaba rumah',
        link12: 'Waralaba kamar mandi standar Amerika',
      },
    },
  },
  login: {
    title: 'Masuk',
    tell: 'Nomor telepon',
    password: 'kata sandi',
    passwordPlaceholder: 'Harap masukkan kata sandi Anda',
    forget: 'Lupakan kata sandinya',
    register: 'daftar',
    backLog: 'Kembali ke login',
    verifyCode: 'Kode verifikasi',
    getVerifyCode: 'Dapatkan kode verifikasi',
    confimPassword: 'Konfirmasi Kata Sandi',
    confimPasswordPlaceholder: 'Harap konfirmasi kata sandi Anda',
    editPassword: 'Ubah Kata Sandi',
    reSend: 'Acrequire setelahnya',
    loginLoadText: 'Masuk, tunggu ...',
    loadingPageTextL: 'Login berhasil, struktur halaman sedang memuat ...',
    loginSuccess: 'Login berhasil',
    tellErr: 'Format nomor ponsel yang salah',
    passwordErr: 'Harap masukkan kata sandi Anda',
  },
  main: {
    head: {
      title: 'Bluen',
      subTitle: 'Save time, materials, and effort',
      task: 'Mengambil tugas',
      arrange: 'penyusunan huruf',
      surplus: 'Bahan yang tersisa',
      setting: 'mendirikan',
      find: 'Menemukan',
      upgrade: 'Tingkatkan sekarang',
      editNickName: 'Ubah nama panggilan',
      editPassword: 'Ubah Kata Sandi',
      logout: 'Keluar',
      notice: 'pengumuman',
      enterOfficial: 'Kunjungi situs web resmi',
      equipment: 'Mesin dan peralatan',
      help: 'membantu',
      enterThinkerOfficial: 'Masukkan situs web resmi Xingele',
      history: 'Simpan riwayat',
      plankManager: 'Manajemen Film Asli',
      tagManager: 'Manajemen tag',
      suplumentManager: 'Perpustakaan Pengisian dan Tata Letak',
      fileManager: 'Manajemen file',
      productionLine: 'Docking peralatan',
      workeShop: 'Perangkat online',
      useLesson: 'Tutorial Penggunaan',
      videoLesson: 'Tutorial video',
      prolineManager: 'Manajemen Jalur Produksi',
      materialManager: 'Manajemen Dewan',
    },
    sideBar: {
      title: 'Optimasi produksi',
      materialList: 'Daftar materi',
      paibanResult: 'Rencana Pengesahan Penetapan',
      printTag: 'Label cetak',
      history: 'Simpan riwayat',
      limitDate: 'Terbatas untuk dikecualikan',
      surplusManager: 'Manajemen materi yang tersisa',
      translateManager: 'Manajemen Terjemahan',
      moreSettings: 'Lebih banyak pengaturan',
    },
    allRead: 'Semua dibaca',
    learnMore: 'Pelajari lebih lanjut',
    noticeEmpty: 'Belum ada pengumuman ...',
    noticeErr: 'Kesalahan mendapatkan konten pengumuman terperinci',
    feedBack: {
      tab1: 'Kirimkan umpan balik',
      tab2: 'Catatan umpan balik',
      type: 'Jenis komentar',
      typePlaceholder: 'Pilih suatu fungsi',
      description: 'menggambarkan',
      descriptionPlaceholder:
        'Hai ~ Harap isi deskripsi skenario penggunaan terperinci tidak kurang dari 15 kata sehingga kami dapat membantu Anda dengan lebih baik. Terima kasih atas kerja sama Anda!',
      uploadPic:
        'Tambahkan gambar, hingga 6 gambar dapat ditambahkan, dan ukuran satu gambar tidak boleh melebihi 10m)',
      tell: 'Nomor kontak',
      submit: 'kirim',
      admin: 'Administrator pengaturan huruf cloud:',
      picTip: 'Gambar: (klik untuk memperluas untuk melihat detail gambar)',
      empty: 'Belum ada data umpan balik',
      picErr: 'Ukuran satu gambar tidak boleh melebihi 10m',
      contentErr: 'Jumlah kata harus antara 15 dan 300',
      necessary: 'Opsi ini diperlukan',
    },
  },
  taskPage: {
    tab1: 'Tugas produksi',
    tab2: 'Antrian produksi massal',
    filter: {
      title: 'menyaring',
      unArrange: 'Tidak diketik',
      arranged: 'Diketik',
      allArranged: 'Semua telah diketik',
      preLayout: 'Pra-tipe',
    },
    search: {
      placeholder: 'Harap masukkan konten pencarian',
      orderName: 'Nama pesanan',
      sender: 'Pengirim',
    },
    importTask: 'Impor Tugas',
    refuseTask: 'Tolak tugas',
    refreshList: 'Segarkan daftar',
    refuseRecord: 'Tolak catatan',
    taskCard: {
      sender: 'Daftar materi yang diimpor',
      delivery: 'Tanggal pengiriman',
      delete: 'menghapus',
      addToBatch: 'Tambahkan ke batch',
      save: 'Arsip',
      arrange: 'Tata letak langsung',
      deleteTitle: 'Hapus tugas',
      checkDetail: 'Klik untuk melihat detailnya',
      refuse: 'menolak',
      refuseTime: 'Tolak waktu',
      refuseReason: 'Alasan penolakan',
      refuseTask: 'Tolak tugas',
      refuseTaskPlaceholder: 'Harap masukkan alasan penolakan',
      addErr: 'Menambahkan gagal, coba lagi nanti!',
    },
    batchNo: 'Nomor batch',
    batchArrange: 'Tata letak batch',
    clearBatch: 'Hapus antriannya',
    taskCount: 'Jumlah tugas saat ini:',
    checkAllTask: 'Lihat semua daftar material',
    clearSuccess: 'Berhasil dibersihkan',
    record: {
      batchNo: 'kelompok',
      saveDate: 'Hemat waktu',
      materialList: 'Daftar materi',
      arrangedRecord: 'Rencana Pengesahan Penetapan',
      searchPlaceholder: 'Harap masukkan nomor batch',
      tipText: 'Haruskah saya menghapus arsip?',
    },
    errTip:
      'Tidak ada data tugas untuk diproses, silakan buka daftar tugas untuk menambahkannya!',
    emptyErr:
      'Pesanan tugas ini tidak memiliki data papan dan tidak dapat diketik dan diproduksi!',
    operationSuccess: 'Operasi berhasil',
    operationFail: 'Operasi gagal, coba lagi nanti!',
    saveSuccess: 'Arsip berhasil',
    plankMaxLimit: 'Jumlah papan kecil melebihi batas tata letak maksimum',
    batchWorning: 'Silakan lakukan produksi dalam batch',
    batchEmptyErr:
      'Daftar pesanan tidak memiliki data papan dan tidak dapat diproduksi secara massal!',
    noRoomTip: 'Tidak ada ruang dan tidak bisa diproduksi secara massal!',
    refuseHistory: 'Menolak sejarah',
  },
  materialPage: {
    edgeEmptyTip: 'Nilai batas tidak bisa kosong',
    edgeNumberLimit: 'Hanya angka yang dapat dimasukkan dalam nilai finishing',
    chooseSurplusQuantity:
      'Ada {total} jenis bahan residu yang tersedia, dan {kuantitas} tipe telah dipilih',
    noCanSaveInfo: 'Tidak ada informasi untuk disimpan',
    chooseBujianStoreQuantity:
      'Ada {total} blok untuk pengisian ulang, dan papan dapat diisi ulang dalam blok total {kuantitas}, dan blok {{{choosequantity} telah dipilih.',
    isConfirmPushToAwaitStore:
      'Apakah Anda pasti akan menambahkan papan yang dipilih ke perpustakaan untuk diketik?',
    enterAutoCreateMatCode: 'Tekan Enter untuk membuat materi secara otomatis',
    chooseAwaitStoreQuantity:
      'Ada {total} blok secara total. Papan yang akan diketik adalah blok {kuantitas}. Blok {pilihan}} telah dipilih.',
    defaultFold: 'Data papan ditutup secara default',
    startArrange: 'Mulai tipografi',
    importMaterial: 'Impor Bill of Material',
    saveToHistory: 'Simpan ke Sejarah',
    addPlank: 'Tambahkan papan',
    deletePlank: 'Hapus papan',
    batchEdit: 'Modifikasi batch',
    moreSetting: 'Lebih banyak pengaturan',
    plankSize: 'Ukuran piring',
    plankSize2: 'Dimensi (panjang*lebar*ketebalan)',
    searchPlaceholder: 'Pencarian nama papan/nomor papan',
    checkArranged: 'Lihat tata letaknya',
    checkErr: 'Tidak diketik, tidak bisa dilihat!',
    downloadSheet: 'Unduh Tabel Sampel',
    downloadSheetShowScientific:
      'Unduh Tabel Sampel (Menampilkan Metode Notasi Ilmiah)',
    exportHoleSlotPDF: 'Diagram slot lubang pelat ekspor',
    style1: 'Gaya satu',
    style2: 'Gaya 2',
    style3: 'Gaya tiga',
    importNoCover: 'Tidak menimpa impor',
    openBujianPlankRecommend: 'Disarankan untuk membuka papan suplemen',
    openAwaitStorePlankRecommend:
      'Buka papan kecil untuk rekomendasi perpustakaan tata letak',
    openSurplusPlankRecommend:
      'Disarankan untuk membuka pelat material yang tersisa',
    pushToAwaitStore: 'Simpan di perpustakaan yang to-type',
    setTableHead: 'Menyiapkan header Bill of Material',
    syncConfirm:
      'Data kabinet kabinet dan tata letak cloud film asli tidak konsisten! Apakah data asli blok kabinet?',
    handleSync:
      'Catatan: Sinkronisasi manual dapat dilakukan dalam manajemen film asli',
    noMoreTip: 'Tidak ada lagi yang diminta',
    checkMore: 'Lihat lebih banyak',
    noHistory: 'Tidak ada sejarah untuk dilestarikan ...',
    sameOrder:
      'Ada pesanan yang telah mengekspor file produksi, harap perhatikan masalah produksi duplikat!',
    markOrder: 'Apakah produksi ditandai pada halaman pesanan?',
    editErrTip: 'Beberapa pesanan tidak mendukung informasi modifikasi',
    autoSaveSuccess: 'Secara otomatis disimpan ke sejarah!',
    goAdd: 'Pergi ke Add',
    noProductionLine:
      'Jalur produksi catatan ini telah dihapus. Silakan buka daftar materi untuk mengetikkan ulang.',
    fileErr: 'Parsing konten file gagal, harap konfirmasi konten file!',
    noCheckData: 'Tidak ada data yang ditemukan!',
    noArrangeTip:
      'Jika Anda tidak dapat melihat paket tata letak secara langsung, silakan klik untuk memulai tata letak!',
    tooOld: 'Berlian lima dan enam sisi terlalu tua',
    contactUs:
      'Silakan hubungi staf layanan purna jual untuk terhubung kembali dengan',
    pathErr:
      'Ada data abnormal di dalam papan (nomor papan: {errorpathplankid}), silakan periksa!',
    sizeErr:
      'Piring besar yang berlebihan! Ukuran papan kecil: {width}-{height}',
    noKnife:
      'Diameter alat pemotong tidak diatur, silakan pergi ke mesin ukiran untuk mengatur alat!',
    sendFactorySuccess: 'Berhasil dikirim ke lokakarya',
    sendFactoryErr:
      'Lokakarya pengiriman gagal, silakan hubungi staf setelah penjualan untuk bantuan',
    createTaskCard: 'Apakah kartu tugas yang sesuai dibuat?',
    surplusStatistics: 'Total bahan residu dapat digunakan',
    surplusStatistics2: 'Terpilih',
    suplementStatistic: 'Pengisian blok, dapat digunakan secara keseluruhan',
    expand: 'Memperluas',
    packUp: 'Menutup',
    importErr: 'Pilih file yang benar!',
    sendLoadingText: 'Memproses dan mengirim file NC ...',
    arrangeLoading: 'total',
    arrangeLoading2: 'Zhang Xiaoban mengoptimalkan tata letak ...',
    dealing: 'Pengolahan...',
    surplusHead: {
      shape: 'membentuk',
      type: 'jenis',
      remark: 'Komentar',
      area: 'Area (m²)',
    },
    surplusTip:
      'Tidak ada bahan yang tersisa yang tersedia, Anda bisa pergi dulu',
    creatSurplus: 'Bahan baru yang tersisa',
    orderInfo: 'Informasi pesanan',
    suplement: {
      statistic:
        'Total {total} blok digunakan, dan total {} blok} digunakan. {Pilihan} blok dipilih.',
      exportSuplement: 'Ekspor daftar suplemen',
      suplementLoading: 'Data suplemen sedang memuat',
      exportSuplementIng: 'Daftar suplemen sedang diekspor',
      noMatchSup: 'Gagal mencocokkan data suplemen yang tersedia',
      exportTip: 'Pilih setidaknya satu pengisian sebelum mengekspor',
    },
    empty: 'Belum ada data papan',
    paibanSetting: {
      title: 'Penyusunan huruf',
      selectProduction: 'Pemilihan lini produksi',
      arrangeWay: 'Pemilihan Solusi Pengesahan',
      suitText: 'Berlaku untuk',
      suitEquipmentNormal: 'Mesin ukiran konvensional',
      suitEquipmentSpecial: 'Mesin pemotong kaca, mesin pemotong pisau gergaji',
      lessRoll: 'Lebih sedikit cetak ulang',
      computedSpecial: 'Hitung bentuknya',
      specialTip: 'Mempengaruhi kecepatan tata letak',
      irregularNesting: 'Bersarang khusus',
      primaryNesting: 'Bersarang normal',
      specialShapedPanel: 'Papan biasa tertanam berbentuk khusus',
      limitedTimeTrial: 'Terbatas untuk dikecualikan',
      seniorShape: 'Sarang lanjutan',
      heteromorphicInlay: 'Bentuk khusus tertanam',
      autoCutSurplus: 'Pemotongan bahan residu otomatis',
      cutWay: 'Metode penanaman',
      maxRect: 'Dipotong ke persegi panjang terbesar',
      fixedRect: 'Potong ke ukuran tetap',
      minRectSetting: 'Ukuran minimum untuk pemangkasan',
      firstCutL: 'Potong bahan residu berbentuk L',
      firstCutLTip:
        'Setelah membuka, kombinasi pelat persegi panjang yang memenuhi kondisi akan dipotong menjadi bahan residu berbentuk L.',
      shortLine: 'Sisi pendek lebih besar dari',
      longLine: 'Sisi panjangnya lebih besar dari',
      surplusTemplate: 'Template umum untuk bahan yang tersisa',
      add: 'Tambahkan ke',
      templateErr:
        'Pilih tanaman ke ukuran umum dan perlu memilih setidaknya satu templat data',
      cutLong: 'Panjang tanaman',
      cutWidth: 'Lebar tanaman',
      baseInfo: 'Informasi dasar',
      continue: 'Konfirmasi dan lanjutkan',
      standardSelect: 'Pilihan Papan Besar Standar',
      customPlankOrder: 'Kustomisasi urutan tata letak papan besar',
      customPlankOrderDetail: 'Tidak terdaftar di papan kecil',
      customPlankOrderTip: 'Tata letak awan tertanam tidak berlaku.',
      surplusPlank: 'Daftar papan kecil yang tersisa',
      overSizePlank: 'Daftar bagian papan ukuran super',
      plankOrder: 'Pesanan pengukur huruf',
      plankType: 'Jenis pelat',
      plankSize: 'Ukuran piring',
      plankEdgeOff: 'Nilai perbatasan',
      amount: 'Kuantitas memotong',
      amountMax: 'Jumlah maksimum pelepasan',
      numAndOverSizeTip:
        'Ada papan kecil yang melebihi penggaris dan papan besar tidak cukup untuk mengetik',
      numErrorTip:
        'Penggunaan papan besar yang tidak cukup tidak dapat diketik',
      overSizeTip: 'Ada papan kecil yang tidak bisa diketik',
      restPartList: 'Tidak terdaftar di papan kecil',
      layoutOrder: 'Pesanan pengukur huruf',
      arrangeAmount: 'Kuantitas memotong',
    },
    filter: {
      title: 'Filter pelat',
      search: 'mencari',
      all: 'Pilih semua',
      resetFilter: 'Batalkan filter',
    },
    edit: {
      title: 'Modifikasi batch',
      target: 'Ubah objek',
      onlineSelectRow: 'Hanya baris yang dipilih',
      allRow: 'Semua',
      editKey: 'Perlu memodifikasi konten',
      editValue: 'Dimodifikasi menjadi',
      warningTip:
        'Catatan: Setelah modifikasi, slot lubang dan bentuk khusus akan secara otomatis dihapus.',
      allEdge: 'Semua segel perbatasan',
    },
    add: {
      title: 'Tambahkan papan',
      continue: 'Konfirmasi dan lanjutkan',
      matCodeErr: 'Materi tidak bisa kosong',
      thickErr: 'Ketebalan tidak bisa kosong',
      colorErr: 'Warnanya tidak bisa kosong',
      longErr: 'Panjang produk jadi tidak bisa kosong',
      widthErr: 'Lebar produk jadi tidak bisa kosong',
      countErr: 'Angka setidaknya 1',
    },
    delete: {
      tip: 'Itu tidak dapat dipulihkan setelah menghapus data. Apakah Anda yakin bahwa data dihapus?',
      errTip: 'Pilih setidaknya satu papan sebelum melakukan operasi ini!',
      errTip1: 'Pilih setidaknya satu papan dan ketik',
      confirm: 'Konfirmasi penghapusan',
    },
    save: {
      errTip: 'Tidak ada informasi untuk disimpan!',
      title: 'Simpan rencananya',
      subTitle: '(Catatan: Sejarah hanya disimpan selama satu tahun)',
      name: 'Nama program',
      historyName: 'Rencana Sejarah',
      successTip: 'Simpan dengan sukses!',
      fieldTip: 'Menyimpan Gagal!',
    },
    exportErr: 'Belum ada papan, ekspor tidak dapat dilakukan',
    exportPicErr:
      'Kesalahan yang tidak diketahui ditemui saat mengekspor diagram slot lubang pelat, silakan hubungi kami untuk menyelesaikannya',
    plankNotFound:
      'Tidak ada papan yang relevan yang ditemukan! Harap konfirmasi apakah akan menggambar papan!',
    noPlankName: 'Tidak ada nama papan yang ditemukan',
    plankDataErr:
      'Kesalahan data papan, silakan periksa dengan cermat untuk menghindari kerugian produksi',
    exportThreeViewErr:
      'Tiga tampilan slot lubang gagal, silakan hubungi layanan purna jual',
    heighGlossWarning:
      'CATATAN: Ketika materi adalah highlighter, papan tidak akan dapat mengembalikannya ke stabilo setelah memodifikasi materi!',
    reset: 'Kembalikan default',
    settingHead: {
      title: 'Menyiapkan header Bill of Material',
      head: 'Header',
      example: 'Mencicipi',
      tip: 'Tip Operasi: Anda dapat menyeret posisi label data tabel kiri dan kanan',
    },
    copyLine: 'Klik untuk menyalin baris saat ini di bawah ini',
    copySuccess: 'Menyalin {name} Data berhasil',
    arranging:
      'Total {Count} Zhang Xiaoban sedang dioptimalkan dan diketik ...',
    overSize: {
      title: 'Penguasa super untuk piring',
      subTitle: 'Daftar bagian papan ukuran super',
      overSizeTip: 'Tips untuk piring dengan penguasa tambahan',
      nonWorkInfo: 'Detail pelat yang belum diproses',
      conflictWorkInfo: 'Detail Dewan Konflik',
      warning:
        'Terdeteksi bahwa papan kecil terlalu panjang, yang membuatnya tidak mungkin untuk mengetik! Harap sesuaikan ukuran papan kecil atau tambahkan lembar asli ukuran yang sesuai',
      checkInfo: 'Lihat detail papan',
      tipContent:
        'Sistem memeriksa apakah ada papan super berukuran di papan tata letak. Di bawah ini adalah saran untuk menambahkan papan khusus. Anda dapat memodifikasi panjang dan lebar papan secara manual dan mengklik tombol <span class = "Bold Color-0"> "satu klik tambah" di bagian bawah tabel. Setelah dengan cepat menambahkan, Anda dapat terus mengetik. Anda juga dapat menambahkan papan secara manual di <span class = "Bold Color-0"> Papan khusus lembar-lembar </span>',
      add: 'Tambahkan dengan satu klik',
      addPlank: 'Tambahkan film asli',
      error: 'Dewan adalah penguasa super, tata letak gagal',
      successTip: 'Dewan ditambahkan dengan sukses! Diketik ulang',
      warningTip: 'Silakan isi panjang dan lebar papan!',
    },
    arrangeErr:
      'Ada kelainan dalam data internal papan dan tidak dapat diketik dan diproduksi',
    nonDelete: 'Tidak dapat dihapus',
    addOverSizeTip: 'Ada penguasa tambahan, tambahkan lagi',
    addPlankSuccess: 'Menambahkan papan khusus dengan sukses',
    addPlankFail: 'Gagal menambahkan papan khusus',
    openDir: 'Arah pembukaan pintu',
  },
  arrangedPage: {
    slotErrTip:
      'Papan saat ini memiliki alur untuk pemrosesan pegangan, dan tidak mendukung tata letak jenis ulang!',
    batchBujianErrTip:
      'Papan saat ini tidak ada dalam data asli lembar material. Anda dapat memilih tipe ulang di perpustakaan suplemen/tipe.',
    useFolderIsNot:
      'Lokasi unduhan dan penghematan file Anda terdeteksi oleh "{type}" tidak ada! Silakan periksa apakah foldernya telah dihapus. Jika Anda menggunakan drive USB seluler, silakan periksa apakah drive USB terhubung dengan benar.',
    useFolderIsNot1:
      'Terdeteksi bahwa Anda belum mengatur lokasi unduhan file, silakan pilih direktori terlebih dahulu.',
    downloadNC: 'Unduh NC',
    downloadNC1: 'Unduh File NC',
    reArrange: 'Jenis ulang',
    saveToHistory: 'Simpan ke Sejarah',
    moreSetting: 'Lagi',
    followExportTag: 'Unduh NC dan Ekspor File Tag',
    tagTemplateSelect: 'Pilihan Tag Template',
    showCuttingSequence: 'Tampilkan pesanan pemotongan',
    showPlankSize: 'Tampilkan ukuran papan kecil',
    sendToFactory: {
      title: 'Kirim ke lokakarya',
      fileType: 'Jenis File Tag',
    },
    checkData: 'Memeriksa integritas data ...',
    dataLoading: 'Bekerja untuk memuat ...',
    addToBujianStore: 'Menambahkan ke gudang pengisian ...',
    updateTaskStatus: 'Perbarui status tugas ...',
    sending: 'Kirim ke lokakarya',
    waitLoadding: 'Tunggu',
    loadding: 'Sedang berlangsung',
    finish: 'Menyelesaikan',
    loadSetting: 'Pengaturan Memuat',
    loadPreference: 'Preferensi pemuatan',
    dealTemplate: 'Templat pemrosesan',
    initTagData: 'Inisialisasi data tag',
    prepareDrawData: 'Siapkan Data Gambar Label',
    drawDataText: 'Tag Menggambar Data',
    genPdfTagFile: 'Menghasilkan file tag pdf',
    genPicTagFile: 'Hasilkan file gambar tag',
    uploadFile: 'Unggah file',
    dealNCFile: 'Memproses file NC',
    zipFile: 'Menghasilkan file terkompresi lokal',
    plankCount: 'Jumlah papan besar',
    rollCount: 'Jumlah cetak ulang',
    totalRate: 'Tingkat optimasi keseluruhan',
    rate: 'Tingkat optimasi',
    useArea: 'Area yang dioptimalkan',
    materialList: 'Daftar materi',
    saveSurplus: 'Bahan yang tersisa ke gudang',
    workeShop: 'Pesanan pembelian',
    holeSlotErr: 'Kedalaman lubang pelat yang abnormal terdeteksi!',
    knivesErr: 'Alat pemrosesan terdeteksi bahwa pelat tidak ditemukan!',
    checkDetail: 'Periksa detailnya',
    deepErr: {
      title: 'Kedalaman abnormal slot lubang pelat',
      errStatistic: 'Ada kelainan di papan kedua',
      errText: 'Bagian pengecualian',
      markErrPart: 'Kotak bagian pengecualian',
    },
    warningTip:
      'Pelat Double-Sided High-Gloss: Proses Pertama <Span class = "Font-style"> Permukaan gloss tinggi </span>, lalu proses <span class = "font-style"> permukaan matte </span>; Pelat Klos Tinggi Single-Side: Proses Langsung <Span Class = "Font-Style"> Permukaan Matte </span>',
    showDetail: {
      tip1: 'Klik untuk memilih satu papan',
      tip2: 'Lihat label papan',
    },
    arrangeTip: '(Mendukung pengaturan huruf slinging berbentuk lebar)',
    plankOpration: 'Operasi pelat',
    rotate: 'Putar 90 derajat',
    notCare: 'Tidak ada tekstur di papan tulis',
    editRemark: 'Edit Catatan',
    clearRemark: 'Catatan yang jelas',
    recalcIndex: 'Hitung ulang titik potong dan pemotongan',
    recalcIndexSuccess:
      'Menghitung ulang titik potong dan pemotongan dengan sukses',
    storageArea: {
      title: 'Area penyimpanan sementara',
      empty: 'Bagian tanpa papan disimpan sementara',
      storageTip:
        'Anda dapat menyeret pelat di sini untuk penyimpanan pelat sementara',
    },
    subtleArrange: 'Tata letak yang bagus',
    addPlank: 'Tambahkan papan besar',
    deletePlank: 'Hapus papan besar',
    deletePlankErr:
      'Ada papan kecil saat ini, dan papan besar tidak dapat dihapus!',
    useSurplus: {
      title: 'Gunakan bahan residu',
    },
    dragWarning: 'Harap seret piring ke pelat besar!',
    areaWarning:
      'Tidak dapat menempatkan papan, perhatikan ukuran ruang cadangan',
    subtlePage: {
      cut: 'Pemotongan bahan yang tersisa',
      cancelCut: 'Akhiri potongannya',
      cutPoint: 'Titik adsorpsi pemotongan bahan yang tersisa',
      storageArea: 'Bagian pelat yang harus diatur',
      cutErr:
        'Anda hanya bisa memotong piring besar dan tidak bisa menyentuh piring kecil!',
      saveErr: 'Pilih bahan potong',
      cutErr2:
        'Cut hanya bisa dilakukan di piring besar dan tidak bisa melebihi pelat besar!',
      selectTip: 'Pilih potongan cadangan',
      noCutPath: 'Bentuknya tidak dapat dipotong menjadi persegi panjang',
      cutOperate: 'Operasi pemotongan material yang tersisa',
      line: 'Stroke manual',
    },
    shortCut: 'Kunci Pintasan',
    updownRoll: 'Menggesek ke atas dan ke bawah',
    leftRightRoll: 'Geser ke kiri dan kanan',
    zoomScale: 'Zoom masuk dan keluar',
    dragCanvas: 'Seret halaman',
    mouseRoll: 'Roda gulir tikus',
    mouseRight: 'Tombol mouse kanan',
    doublePlank: 'Klik dua kali di papan besar',
    keyboard: 'keyboard',
    picSymble: 'legenda',
    funished: 'Produk jadi',
    holeSlotConflict1: 'Konflik lubang',
    plankErr: 'Konflik piring',
    frontSlot: 'Slot tarik depan',
    backSlot: 'Slot tarik terbalik',
    frontHole: 'Posisi wajah depan',
    backHole: 'Posisi wajah terbalik',
    resetScale: 'Setel ulang zoom',
    maxLong: 'terpanjang',
    tempErr:
      'Gagal menambahkan, posisi template yang salah atau template terlalu besar!',
    sendErr:
      'Lokakarya pengiriman gagal. Silakan periksa apakah template label dan data papan sudah benar sebelum mengirimnya lagi.',
    someTips:
      'Anda mencoba fungsi tata letak berbentuk tipe cloud untuk waktu yang terbatas, berhati-hatilah untuk menghindari masalah produksi!',
    cutOrderErr:
      'Pesanan pemotongan tidak benar, silakan klik untuk menghitung ulang titik pemotongan dan pesanan pemotongan!',
    holeSlotConflict:
      'Terdeteksi bahwa konflik lubang dan slot pelat terbang keluar dari pelat kecil',
    exportSetting: 'Pengaturan Ekspor',
    specialPaibanErr:
      'Bagian berlapis memiliki proses khusus atau alur pemrosesan tepi, dan diketik ulang belum didukung!',
    moreResult: 'Lebih banyak hasil pengaturan huruf',
    moreResultTip:
      'Ada alur untuk menggiling di pelat besar, dan pelat besar tidak akan berpartisipasi dalam tata letak jenis ulang.',
    batchBujian: 'Pengisian Batch',
    disPlaySetting: 'Tampilkan Pengaturan',
    stored: 'Telah dimasukkan ke dalam gudang',
    onlySurplus: 'Lihat saja bahan yang tersisa',
    UnuseSurplus:
      'Bahan yang tersisa tidak berpartisipasi dalam perhitungan tingkat pemanfaatan',
    usePlankAdsorption: 'Fungsi adsorpsi plat',
    dataDefaultCollapsed: 'Data ditutup secara default',
    unit: 'bagian',
    cutSurplus: 'Potong bahan yang tersisa',
    frontWarningTip:
      'Pelat Double-Side High-Gloss: Proses Pertama Permukaan Matte, lalu proses pelat gloss tinggi; Pelat gloss tinggi satu sisi: Langsung memproses permukaan gloss tinggi',
    selectTip: 'Pilih papan kecil di bawah ini untuk membutuhkan suplemen!',
    plankKeyWordPlaceholder: 'Harap masukkan nama papan atau nomor papan',
    surplusStoreSuccess: 'Berhasil memasuki gudang',
    editCutOrder: 'Ubah Pesanan Pemotongan',
    setCutPoint: 'Atur titik cut-down',
    specialPaibanChangeErr:
      'Bagian berlapis memiliki proses khusus atau alur pemrosesan bekas, dan skema tata letak switching belum didukung!',
    storedSuccess:
      'Pengisian yang sukses! Dewan yang dipilih telah ditempatkan di manajemen pengisian!',
    noSurplus: 'Tidak ada bahan yang tersisa yang ditemukan',
    selectErrTip:
      'Pelat yang tersisa tidak diizinkan untuk diisi ulang, silakan pilih piring kecil normal terlebih dahulu',
    noRoomIDTip:
      'Papan ini tidak memiliki ID kamar dan tidak dapat diisi ulang. Pilih papan lain terlebih dahulu',
    noPlank: 'Tidak ada papan yang ditemukan',
    noPaibanData: 'Tidak ada data pengaturan huruf, tidak dapat dilakukan',
    historyNameErr: 'Harap masukkan nama rencana',
    tempErrTip:
      'Terdeteksi bahwa Anda tidak menambahkan templat yang digunakan untuk bor lima/enam sisi, sehingga file pemrosesan bor lima/enam sisi tidak dapat diekspor. Catatan: Anda perlu mengetik ulang setelah penambahan selesai.',
    changeDataTip:
      'Catatan: Apakah Anda membuat file historis baru untuk menyimpan konten perubahan ini ketika Anda mendeteksi bahwa data Anda telah berubah?',
    upgrade: 'meningkatkan',
    upgradeTip:
      ', Anda dapat mengklik tombol di bawah ini untuk meningkatkan versi',
    plankOverEdge:
      'Unduh NC Gagal! Silakan periksa apakah pelat yang tumpang tindih atau pelat berada di luar pelat besar',
    useSurplusTip: 'Apakah akan mengurangi bahan yang tersisa',
    noDataExport: 'Tidak ada data tipografi yang diekspor',
    noPlankExport: 'Tidak ada data papan kecil yang dapat diekspor',
    lockTip1: 'Klik untuk membuka kunci, setelah membuka kunci',
    lockTip2: 'Edit Posisi dan Properti Papan',
    unlockTip1: 'Klik untuk mengunci, itu tidak dapat dikunci setelah mengunci',
    unlockTip2: 'Pengeditan posisi dan atribut papan',
    plankLockErr: 'Piring besar telah dikunci dan tidak dapat ditempatkan',
    lockPartEditTip: 'Piring terkunci dan tidak dapat diedit',
    glassEquipmentTip:
      'Saat jalur produksi adalah mesin pemotong kaca, pelat tidak dapat dipindahkan',
    dragErrTip:
      'Itu tidak dapat ditempatkan, silakan periksa sifat material, warna dan ketebalan piring!',
    subtleSaveTip:
      'Dewan telah dioperasikan tetapi belum disimpan. Apakah itu keluar?',
    rollTip:
      'Ada melalui lubang/palung di piring besar, dan tidak disarankan untuk mencetak ulang!',
    glossRollErr: 'Papan gloss tinggi dilarang',
    lockDeleteErr: 'Piring terkunci dan tidak dapat dihapus',
    deleteConfirmTip: 'Hapus operasi tidak dipulihkan',
    deleteContinueTip: 'Apakah Anda ingin melanjutkan?',
    deleteConfirm: 'Harap konfirmasi apakah akan menghapus',
    selectPlankTip: 'Pilih papan terlebih dahulu sebelum melakukan operasi ini',
    storeFailTip:
      'Bahan yang tersisa gagal memasuki gudang! Silakan periksa apakah pelat yang tumpang tindih atau pelat berada di luar pelat besar',
    repeatStoreErr:
      'Pelat material residu yang saat ini dipotong telah dimasukkan ke dalam gudang',
    surplusStoreFail:
      'Jika penyimpanan gagal, silakan periksa apakah pelatnya persegi panjang atau piring berbentuk L!',
    plankConfilictErr:
      'Itu tidak didukung untuk menempatkan poin di sini, yang akan bertentangan dengan piring lain.',
    surplusErrTip:
      'Tidak didukung untuk menempatkan poin di sini, bahan residu kesalahan akan dihasilkan',
    changeArrageSuccess: 'Beralih Skema Tata Letak dengan sukses',
    trialProduction: {
      trialProductionSample: 'Kabinet sampel produksi percobaan',
      trialProduced: 'Saya sudah mencoba produksi',
      trialWarning:
        'Anda akan menanggung semua kerugian dan konsekuensi yang merugikan bagi diri Anda sendiri, orang lain dan perangkat lunak tata letak cloud karena kegagalan Anda untuk melakukan verifikasi produksi kabinet sampel.',
      trialSlotTip:
        'Selama produksi uji coba, silakan periksa apakah ukuran, kedalaman, permukaan, dan lokasi slot wajah depan dan belakang sudah benar.',
      trialSlotTip1: 'Hindari mirroring kabinet',
      trialSlotTip2: 'Masalahnya',
      pleaseUploadTrial:
        'Harap unggah foto kabinet sampel produksi percobaan yang dirakit',
      uploadTrial: 'Unggah Kabinet Sampel',
    },
    awaitPlankStore: {
      store: 'Bagian papan yang akan diatur di Perpustakaan Dewan',
      notTip: 'Tidak ada lagi yang diminta yang belum dimasukkan',
      justLookCurrent: 'Hanya lihat papan pesanan saat ini',
      notInPushStorage: 'Tidak termasuk dalam gudang',
      beInStorage: 'Telah dimasukkan ke dalam gudang',
      searchPlaceholder:
        'Masukkan pesanan untuk mencari papan kecil dari perpustakaan yang berkepentingan',
      plankUploading: 'Dewan diselamatkan',
      oneClickStorage: 'Penyimpanan satu klik untuk diurutkan',
      notUsePlank: 'Tidak ada papan kecil yang tersedia',
      notPlank: 'Bagian tanpa papan disimpan sementara',
      dragPlankInHere:
        'Anda dapat menyeret pelat di sini untuk penyimpanan pelat sementara',
      surplusCannotBeStorage: 'Bahan yang tersisa tidak dapat disimpan',
      plankHasBeenDeduction:
        'Dewan telah dikurangkan atau tidak ada data asli dan tidak dapat disimpan dalam database.',
      plankLoadErr: 'Informasi papan gagal memuat',
      taskTitle: 'Unggah papan di perpustakaan untuk diketik',
      dataGenErr: 'Kesalahan pembuatan data',
      pushSuccess: 'Berhasil memasuki gudang',
      pushErr:
        'Terjadi kesalahan yang tidak diketahui dan gagal memasuki database',
      awaitBoxExistPlank:
        'Perpustakaan tata letak tidak termasuk dalam papan kecil, dan disarankan untuk mengoperasikannya setelah memasuki perpustakaan.',
      checkPlankStatus: 'Memverifikasi status papan ...',
    },
    batchSetCut: 'Atur titik cut-down dalam batch',
    applyTo: 'Berlaku untuk',
    ncDownloadLifeText: {
      checkData: 'Data sedang terdeteksi',
      checkDone: 'Deteksi data selesai',
      checkDataText: 'Deteksi Data',
      loadSetting: 'Pengaturan Memuat',
      settingLoadDone: 'Pengaturan pemuatan selesai',
      loadSettingText: 'Mengatur pemuatan',
      downloadNc: 'Mengunduh NC',
      ncDownloadDone: 'NC Download selesai',
      downloadText: 'Unduh NC',
      loadPreferenceSetting: 'Preferensi pemuatan',
      PreferenceSettingLoadDone: 'Preferensi Memuat Lengkap',
      loadPreferenceText: 'Preferensi pemuatan',
      dealTemp: 'Bekerja dengan template',
      TempDealDone: 'Pemrosesan template selesai',
      dealTempText: 'Templat pemrosesan',
      beBeingGenTagFile: 'Menghasilkan file {filetype}',
      genTagFileDone: 'Menghasilkan file {filetype} untuk menyelesaikan',
      genTagFileText: 'Menghasilkan file {filetype}',
      zip: 'Menghasilkan file terkompresi lokal',
      zipDone: 'Menghasilkan file terkompresi lokal untuk diselesaikan',
      genZipText: 'Menghasilkan file terkompresi',
      fileTypeText: 'gambar',
    },
    surplusCommonTemp: 'Template umum untuk bahan yang tersisa',
    plankErrTip:
      'Ada papan kecil dan tidak ada papan besar yang dapat digunakan untuk tata letak!',
    overSizeTip:
      'Jumlah papan besar yang tidak mencukupi atau melebihi penguasa',
    uselessPart: 'Saat ini tidak ada papan kecil yang tersedia di papan besar',
    splitArranged:
      'Ada terlalu banyak papan kecil, jadi disarankan untuk mengetiknya dalam batch',
    arrangedErrTip:
      'Pengesahan huruf gagal, tidak ada cukup banyak papan besar atau papan kecil yang melebihi penguasa',
    knivesModalTitle: 'Alat tidak dapat ditemukan di slot lubang',
    cannotDownloadByKnivesErr:
      'NC tidak dapat diunduh, sistem mendeteksi bahwa lubang berikut ada, alat tidak dapat ditemukan',
    viewPart: 'Lihat papan pertanyaan',
    deductionModalTitle: 'Pilih objek pengurangan',
    bujianDeduction: 'Kurangi papan suplemen',
    paibanDeduction: 'Mengurangi area penyimpanan sementara papan kecil',
    surplusDeduction: 'Mengurangi pelat besar bahan residu',
    unlockPaiban:
      'Buka kunci bahan yang tersisa yang terlibat dalam tata letak',
    maintain:
      'Pertahankan penguncian bahan residu yang terlibat dalam pengaturan huruf',
    lockModalText:
      'Ada sepiring besar bahan residu yang digunakan untuk ditempati oleh pesanan lain. Apakah ini diketik ulang?',
    lockNewSModalText:
      'Saat ini menggunakan pelat besar bahan residu ditempati oleh pesanan lain. Haruskah saya terus mengunduh NC?',
    rePaibanWarn:
      'Piring besar bahan residu ditempati oleh pesanan lain, dan pelat besar tidak mencukupi. Silakan pergi ke daftar materi untuk mengaturnya kembali.',
    sawRePaiban:
      'Catatan: Anda menggunakan pemotongan bilah gergaji, dan tata letak pelat berbentuk khusus telah ditambahkan. Anda perlu mengetik ulang file NC untuk menjadi output.',
  },
  historyPage: {
    download: 'Unduh secara lokal',
    allType: 'Simpan semua',
    handleSave: 'Simpan secara manual',
    autoSave: 'Simpan secara otomatis',
    allArrange: 'Semua pengaturan huruf',
    searchPlaceholder: 'Cari Nama Pesanan / Batching',
    trailProduction: {
      title: 'Kabinet sampel produksi percobaan',
      lastTime: 'Tanggal Produksi Percobaan Terakhir',
      recordList: 'Catatan Produksi Percobaan',
      start: 'Mulai produksi percobaan',
      date: 'waktu',
      outputFrom: 'Lokasi Output',
      empty: 'Belum ada data',
      record:
        'Catatan gambar yang diunggah dari Kabinet Sampel Produksi Percobaan',
      tip1: 'Catatan: Urutan produksi uji coba saat ini menggunakan data produksi default',
      tip2: 'Catatan: Urutan produksi uji coba saat ini tidak menggunakan parameter pengaturan proses terbaru, sehingga Anda dapat pergi ke ruang produksi uji coba untuk menyimpannya lagi!',
      download: 'Unduh file pemrosesan',
    },
    deleteTip: 'Apakah Anda pasti akan menghapus sejarah berikut?',
    startDate: 'tanggal mulai',
    endDate: 'Tanggal akhir',
    lastWeek: 'Minggu terakhir',
    lastMonth: 'Bulan lalu',
    lastSeason: 'Maret lalu',
    toArrangePage: 'Pergi ke halaman tata letak',
    notArrangedTip:
      'Catatan ini belum diketik, silakan pergi ke daftar materi terlebih dahulu!',
    toMaterialPage: 'Pergi ke halaman Bill of Material',
    lessSelectTip:
      'Pilih setidaknya satu catatan sebelum melakukan operasi ini',
    downloadNc: 'Unduh file pemrosesan',
  },
  workShopPage: {
    warningTip:
      'Fungsi ini adalah uji coba waktu terbatas, atur produksi pesanan Anda secara wajar!',
    fileManager: 'Manajemen File Produksi',
    selected: 'Terpilih',
    suplement: 'Penambahan',
    size: 'ukuran',
    saveToStore: 'Tambahkan ke gudang pengisian',
    enterArrange: 'Memasuki tipografi ...',
    deleteTip:
      'Itu tidak dapat dipulihkan setelah menghapus data. Apakah Anda yakin bahwa data dihapus?',
    arrangeTip: 'Resolusi data suplemen, harap tunggu ...',
    store: 'Gudang Pengisian',
    downloadFile: 'Unduh file produksi',
    notBujianFile: 'File suplemen tidak dapat ditemukan',
    productLineDelete:
      'Baris produksi telah dihapus dan file NC tidak dapat diunduh!',
  },
  basePlankManager: {
    saveTip: 'Catatan: Tekan Enter untuk menyimpan',
    offsetSize: 'Ukuran trim',
    overSizeTip:
      'Ketika ukurannya terlalu besar, papan ukuran dan ukuran yang lebih besar akan dipilih secara otomatis untuk tata letak.',
    addTip:
      'Jika papan dikuasai selama tata letak, jendela pop-up papan besar khusus akan ditambahkan secara otomatis ke pop up.',
    onlyLayoutTooBig:
      'Piring besar ukuran ekstra hanya berbaris dengan piring kecil ukuran ekstra',
    settingRemark:
      'Catatan: Fungsi asli di sini disesuaikan dengan pengaturan yang tepat',
    searchPlaceholder: 'Cari materi/warna papan',
    syncData: 'Sinkronisasi data kabinet',
    syncDataM: 'Sinkronisasi Data Pintu Kabinet',
    deletePlank: 'Hapus papan khusus',
    addPlank: 'Dewan Khusus Baru',
    matCodePlaceholder: 'Pilih piring',
    thickPlaceHolder: 'Pilih ketebalannya',
    widthErr: 'Lebar pelat tidak bisa kosong',
    heightErr: 'Panjang pelat tidak bisa kosong',
    matCodeErr: 'Bahan papan tidak bisa kosong',
    colorErr: 'Warna pelat tidak bisa kosong',
    editPlank: 'Edit papan',
    deleteTip:
      'Itu tidak dapat dipulihkan setelah menghapus data. Apakah Anda yakin bahwa data dihapus?',
    plankKnifeSelect: 'Pilihan pisau pemotongan lembar asli',
    knifeSelectTip:
      'Catatan: Fitur ini belum didukung dalam pengaturan huruf tertanam',
    knife: 'Alat pemotongan',
    matcodeInput: 'Harap masukkan materi papan',
    thickInput: 'Harap masukkan ketebalan papan',
    default: 'Default Sistem',
    unessential: 'Kolom ini adalah: tidak diperlukan',
    emptyTip:
      'Catatan: Jika Anda tidak mengisi item ini, Anda tidak akan membedakan item ini saat mencocokkan karya asli!',
    defaultUse: 'Dipilih secara default',
    editMatcode: 'Edit materi',
    syncTip:
      'Data dalam {frommc} akan diperoleh dan menimpa data tata letak cloud zhongyuan yang ada. Apakah itu disinkronkan?',
    sameErr: 'Data yang sama sudah ada',
    cancelDelete: 'Undelete',
    syncErr: 'Sinkronisasi gagal',
    syncSuccess: 'Sinkronisasi berhasil',
    syncDataTip: 'Menyinkronkan data {frommc}',
    editErrTip: 'Ada informasi yang tidak terisi, silakan periksa!',
    numberCheck: '{type} hanya bisa memasukkan angka!',
    deleteStandardPlate: 'Hapus papan standar',
    createStandardPlate: 'Papan Standar Baru',
    editStandardPlate: 'Edit papan standar',
    trimmingDimension: 'Ukuran trim',
  },
  bujianAndStore: {
    bujianStore: 'Gudang Pengisian',
    awaitStore: 'Papan untuk diketik perpustakaan',
    depositAwaitStore: 'Simpan di perpustakaan yang to-type',
    pushToAwaitStore: 'Masukkan ke dalam perpustakaan untuk diketik',
    useTwoTableArrange: 'Gunakan dua bentuk untuk mengetik',
    confirmUseTwoTableTip:
      'Papan kecil yang saat ini dipilih berisi perpustakaan tambahan dan perpustakaan yang toype. Akankah papan kecil terus mengetik?',
    confirmAddPlankToAwaitStore:
      'Apakah Anda pasti akan menambahkan papan yang dipilih ke perpustakaan untuk diketik?',
    pushStoreSuccess: 'Berhasil memasuki gudang',
    dataGenErr: 'Kesalahan pembuatan data',
    unknownErr:
      'Terjadi kesalahan yang tidak diketahui dan gagal memasuki database',
  },
  surplusPage: {
    surplusMaterial: 'Daftar bahan yang tersisa',
    surplusStore: 'Gudang bahan yang tersisa',
    surplusShape: 'Bentuk bahan yang tersisa',
    batchTransfer: 'Transfer batch',
    createNewSurplusStore: 'Gudang Bahan Surplus Baru',
    pushStoreSetting: 'Pengaturan penyimpanan material yang tersisa',
    surplusStoreName: 'Nama silo',
    surplusStoreName2: 'Nama Gudang Bahan Surplus',
    addSurplusStore: 'Tambahkan sisa gudang material',
    inventoryCeiling: 'Tutup stok',
    autoTransferTip:
      'Setelah dihapus, bahan residu di gudang bahan residu akan secara otomatis ditransfer ke daftar bahan residu.',
    asIndexPushStore: 'Masukkan gudang agar penyimpanan',
    pushToSurplusMaterial: 'Simpan daftar materi yang tersisa',
    surplusStoreTable: {
      number: 'nomor seri',
      name: 'Nama Gudang Bahan Surplus',
      quantity: 'Jumlah bahan yang tersisa (potongan)',
      capacity: 'Batas atas inventaris material yang tersisa (potongan)',
      remark: 'Komentar',
      operation: 'beroperasi',
    },
    editSurplusStore: 'Edit Perpustakaan Surplus',
    addSurplus: 'Tambahkan bahan yang tersisa',
    deletSurplus: 'Hapus bahan yang tersisa',
    editSurplus: 'Ubah bahan yang tersisa',
    surplusNoRoll: 'Aktifkan bahan residu tanpa mencetak ulang',
    type: {
      all: 'Semua bentuk',
    },
    allMatcode: 'Semua bahan',
    allThick: 'Semua ketebalan',
    surplusName: 'Nama bahan yang tersisa',
    rectSurplus: 'Bahan residu persegi panjang',
    LTypeSurplus: 'Bahan residu berbentuk L.',
    noMatchSup: 'Tidak ada data yang relevan yang ditemukan',
    jumpTo: 'Melompat ke',
    page: 'Halaman',
    newMatcode: 'Bahan bangunan baru',
    newColor: 'Buat Warna Baru',
    autoCreateColor: 'Tekan Enter untuk secara otomatis membuat kartu warna',
    nameLimit: 'Masukkan hingga 10 kata dengan nama materi yang tersisa',
    nameLimitNameErr: 'Nama materi yang tersisa tidak bisa kosong',
    tempLongTip: 'Hanya angka yang dapat dimasukkan dalam panjang papan',
    minLongLimit: 'Hanya angka yang dapat dimasukkan dalam panjang sisi pendek',
    minLongEmptyTip: 'Panjang sisi pendek tidak bisa kosong',
    minLongLimitMax: 'Panjang sisi pendek tidak dapat melebihi panjang papan',
    widthLimit: 'Hanya angka yang dapat dimasukkan dalam lebar papan',
    minWidthLimit: 'Hanya angka yang dapat dimasukkan dalam lebar sisi pendek',
    minWidthEmptyLimit: 'Lebar sisi pendek tidak bisa kosong',
    minWidthLimitMax: 'Lebar sisi pendek tidak dapat melebihi lebar papan',
    thickLimit: 'Hanya angka yang dapat dimasukkan dalam ketebalan papan',
    thickEmptyLimit: 'Ketebalan pelat tidak bisa kosong',
    countInputLimit: 'Jumlahnya hanya bisa menjadi bilangan bulat positif',
    countEmptyLimit: 'Kuantitasnya tidak bisa kosong',
    remarkMaxlimit: 'Masukkan hingga 50 kata dalam catatan',
    orderAddressMaxlimit: 'Masukkan hingga 100 kata dari alamat pesanan sumber',
    orderIdMaxlimit: 'Masukkan hingga 100 kata dari nomor pesanan sumber',
    createTime: 'Tambahkan waktu',
    branchName: 'Gudang bahan yang tersisa',
    orderAddress: 'Alamat Pesanan Sumber',
    orderId: 'Nomor pesanan sumber',
    lockStatus: 'Keadaan terkunci',
    lockOrder: 'Pesanan kunci',
    enableSurplusLock: 'Aktifkan fungsi penguncian bahan residu',
    isCloseSurplusLock:
      'Setelah fungsi dimatikan, semua bahan residu akan dibuka. Apakah akan melanjutkan',
    confirm: 'Konfirmasi untuk menutup',
    surplusTransfer: 'Transfer materi yang tersisa',
    targetSurplusStore: 'Gudang bahan residual target',
    enableSurLockTip:
      'Setelah menyala, setiap tata letak akan mengunci bahan yang tersisa yang digunakan untuk mencegah pesanan lain dari menempati.',
  },
  printPage: {
    deleteWarning: 'Tips yang baik',
    print: 'Mencetak',
    defaultTemplate: 'Template default (60 x 40)',
    editTemplate: 'Edit Template',
    deleteTip:
      'Itu tidak dapat dipulihkan setelah menghapus template. Apakah Anda pasti akan menghapus template?',
    tagSetting: 'Pengaturan Tag',
    rememberSetting: 'Setelah modifikasi, pengaturan akan diingat',
    genCutingTag: 'Hasilkan label kliping',
    genSurplusTag: 'Menghasilkan label bahan residu',
    genPlankTag: 'Menghasilkan label papan besar',
    genPartTag: 'Menghasilkan tag papan kecil',
    tagPosition: 'Lokasi Pelabelan',
    tagPositionTip: '(Mesin pelabelan perlu mengisi koordinat x/y di bawah)',
    Xposition: 'X Koordinat',
    Yposition: 'Koordinat y',
    genPlankSuffix: 'Label papan besar menghasilkan akhiran nama file',
    arrangeMirror: 'Tag Image Mirror',
    drillPlankBase: 'Pengeboran lima sisi dan referensi pelat',
    suitAuto:
      'Penyortiran tag beradaptasi dengan pemuatan dan pembongkaran otomatis:',
    colorLabel: 'Menghasilkan tag warna',
    up: 'ke atas',
    down: 'turun',
    default: 'bawaan',
    printSetting: 'Pengaturan cetak',
    oneToMore: 'Beberapa halaman per gambar',
    perPage: 'Setiap',
    coloumn: 'Daftar',
    tagMargin: 'Label Gap',
    coloumnMargin: 'Jarak kolom',
    specialMark: 'Tanda khusus',
    opacity: 'transparansi',
    suite: 'Adaptif',
    svgListTip:
      'Tag papan generasi tidak diaktifkan, silakan periksa di sebelah kiri',
    size: 'ukuran',
    lightSide: 'Tepi cahaya',
    locFrontSide: 'Depan kabinet',
    openSide: 'Arah pembukaan pintu',
    extraTip:
      'Kiat: Saat Anda menggunakan pencetakan langsung untuk pertama kalinya, silakan tambahkan selembar kertas dengan ukuran dan panjang dan lebar yang sama seperti yang digunakan dalam pengaturan printer, dan kemudian gunakan kertas baru untuk dicetak (Contoh: Ukuran kertas asli 60 × 40, ukuran kertas baru 40 × 60)',
    noMore: 'Tidak lebih',
    prepareData: 'Siapkan data ...',
    genTagFile:
      'Saat pembuatan tag sedang dihasilkan, tolong jangan lakukan switching halaman, meminimalkan browser, menggulir tag ke atas dan ke bawah, dll.!',
    waitGen: 'Harap tunggu tag dimuat sebelum mengunduh',
    tempLoading: 'Template tag sedang dimuat, harap tunggu',
    front: 'maju',
    open: 'membuka',
    toEdit: 'Pergi ke Edit',
    simplifyOneCodeTip:
      'Catatan: Atribut barcode yang ditetapkan oleh template tag ini tidak mendukung penggunaan fungsi "barcode sederhana". Harap Ubah Atribut Barcode (Atribut Barcode yang Disarankan: Code128)',
    plankTemp: 'Template label papan kecil',
    surplusTemp: 'Template label material yang tersisa',
    tempNamePlaceHolder: 'Harap masukkan nama template',
    editTip:
      'Anda dapat menyelesaikan pengeditan template label dengan menyeret tombol kiri ke kertas label kanan sesuai dengan kebutuhan Anda',
    oneCode: 'Barcode',
    image: 'gambar',
    editPage: {
      title: 'Pengeditan Tag Template',
      reset: 'Kembalikan default',
      exit: 'EXIT EDIT',
      tagInfo: 'Informasi tag',
      tagName: 'Nama tag',
      tagSize: 'Ukuran label',
      tagData: 'Tag data',
      dragTip: 'Tahan tombol kiri mouse untuk menyeret keluar label data',
      dataTip: 'Pilih informasi data di bilah properti di sebelah kanan',
      text: 'teks',
      dataSource: 'Informasi data',
      graph: 'Grafik',
      typograpyPic: 'Penyusunan huruf',
      barCode: 'Barcode',
      QRCode: 'Kode QR',
      plankMark: 'Tanda pelat',
      holeSlotPic: 'Diagram slot lubang',
      holeSlotPicZoom: 'Zoom Diagram Slot Lubang',
      rotateDeg: 'Sudut rotasi',
      colorDeep: 'Kedalaman warna',
      surplusMark: 'Tanda materi yang tersisa',
      surplusSizePic: 'Diagram ukuran bahan yang tersisa',
      dataSize: 'Ukuran data',
      extraList: 'Daftar tambahan',
      QRCodeFormat: 'Format Kode QR',
      formatQR: 'Format QR',
      formatDM: 'Format DM',
      locIndex: 'Nomor Seri Kabinet',
      plankFrontSign: 'Penanda panel',
      genNCSuffix: 'Menghasilkan akhiran NC',
      table: 'lembaran',
      inputPlaceholder: 'Harap klik dua kali untuk memasukkan teks',
      attribute: 'milik',
      fontFamily: 'Jenis font',
      fontSize: 'Ukuran font',
      fontWeight: 'Font yang lebih tebal',
      autoRow: 'Bungkus garis otomatis',
      rowMargin: 'Jarak garis',
      autoFontSize: 'Ubah Ulang Font Ubah Ulang Secara Otomatis',
      statisticsDimension: 'Dimensi statistik',
      noLimit: 'Tidak ada batasan',
      sameOrderNo: 'Pesanan yang sama',
      sameRoom: 'Kamar yang sama',
      sameWardrobe: 'Kabinet yang sama',
      alignWay: 'Penyelarasan',
      JustifyLeft: 'Sejajarkan kiri',
      JustifyRight: 'Sejajar dengan benar',
      JustifyCenter: 'Pusat Align',
      otherOpration: 'Operasi Lainnya',
      attributeReset: 'Parameter Reset',
      barCodeSize: 'Ukuran barcode',
      installPic: 'Diagram instalasi',
      plankCode2: 'Papan Barcode (Nomark)',
      frontCode: 'Kode mie positif',
      backCode: 'Kode mie terbalik',
      orderRemark: 'Catatan pesanan',
      NCFileName: 'Memotong nama file',
      NCFileNameTip:
        'Jangan termasuk Cina dalam nama file dan panjang tidak boleh melebihi 10 digit.',
      showOpenSide: 'Tunjukkan arah pembukaan pintu',
      limitFixed: 'Batasi jumlah titik desimal',
      showFrontHS: 'Tampilkan slot wajah depan',
      showBackHS: 'Tunjukkan slot wajah terbalik',
      showPlankSize: 'Ukuran panel tampilan',
      tempNamePlaceholder: 'Harap masukkan nama tag',
      deleteAreaTip: 'Pilih blok yang akan dihapus',
      noHoleSlot: 'Tidak ada slot lubang juga menampilkan barcode',
      rotate: 'Barcode berputar',
      barcodeAtrribute: 'Atribut Barcode',
      imgSize: 'Ukuran gambar',
      noEdgeDisplay: 'Tepi adalah 0 dan tidak akan ditampilkan',
      allEdgeNoDisplay: 'Pemblokiran tepi tidak ditampilkan',
      imgRotate: 'Rotasi Gambar',
      circleD: 'Diameter bingkai lingkaran',
      roomNo: 'Nomor kamar',
      roomOrder: 'Nomor kabinet (diurutkan berdasarkan kamar)',
      alphabetically: 'Urutkan berdasarkan huruf',
      numberOrder: 'Urutkan berdasarkan nomor',
      specialMarkup: 'Simbol tanda khusus',
    },
    autoSaveToHistory: 'Secara otomatis disimpan ke sejarah!',
    firstPrintTip:
      'Saat Anda menggunakan pencetakan langsung untuk pertama kalinya, tambahkan selembar kertas dengan ukuran dan panjang dan lebar yang sama seperti yang digunakan dalam pengaturan printer, dan kemudian cetak dengan kertas baru.',
    tagDownloading: 'Tag sedang diunduh ...',
  },
  fileManagerPage: {
    notOpeFolderPermission: 'Operasi Direktori ini yang tidak sah',
    notFindFolder: 'Direktori ini tidak dapat dideteksi pada klien saat ini',
    productLineUniversal: 'Semua jalur produksi bersifat universal',
    effectCondition:
      'Pengaturan ini perlu diperbaiki sebelum dapat berlaku setelah mengubahnya',
    addPath: 'Tambahkan jalur',
    downloadPositionChangeByType:
      'Lokasi unduhan file yang dihasilkan telah diubah menjadi {type}',
    isDeleteFolder:
      'Apakah Anda pasti akan menghapus lokasi penyimpanan file yang digunakan?',
    netWorkIsNotSafe:
      'Lingkungan jaringan Anda tampaknya tidak aman dan kami tidak dapat mengaktifkan fitur ini, Anda dapat memberi umpan balik kepada kami',
    NotSupportBrowse:
      'Browser Anda belum mendukung fitur ini. Silakan unduh versi terbaru <a href = "https://www.microsoft.com/zh-cn/edge/download" target = "_ blank"> edge </a> Untuk menggunakan browser',
    notSupportDisk:
      'Direktori tingkat sistem tidak dapat dipilih, seperti [desktop] [C drive]',
    useFolderDownload: 'File produksi diunduh dalam bentuk dekompresi',
    downloadPosition: 'Unduh lokasi file',
    changeUseFolder: 'Mengubah',
    folderNameSetting: 'Pengaturan Penamaan Folder',
    mainFolder: 'Folder Level 1',
    exportNameSetting:
      'Ekspor memproses file untuk menyebutkan nama dengan nomor pesanan',
    fileMax: 'Nama file maksimum adalah',
    char: 'karakter',
    overTip:
      'Bagian berlebih tidak akan ditampilkan (paket kompresi gambar tag dan file JSON akan mengikuti nama folder tingkat pertama)',
    example: 'Mencicipi',
    engravingFolder: 'Folder Mesin Ukiran',
    electronicSawFolder: 'Folder gergaji elektronik',
    labellingFolder: 'Folder Mesin Pelabelan',
    sideHoleDrillFolder: 'Folder mesin lubang samping',
    fiveDrillFolder: 'Folder bor lima sisi',
    sixDrillFolder: 'Folder bor enam sisi',
    PTPFolder: 'Folder PTP',
    labellingTagFolder: 'Folder gambar label',
    folderMergeSetting: 'Pengaturan gabungan folder',
    folderMerge: 'Gabungan folder',
    mergeName: 'Gabungkan nama',
    subMergeFolder:
      'Gabungkan menjadi subfolder dengan ketebalan warna material',
    subFolderName: 'Nama subfolder',
    splitChar: 'Karakter pemisahan',
    splitTip: '(Karakter input seperti /:? <> |. ” * Tidak didukung)',
    folderAutoAdd: 'Jumlah folder ditingkatkan dengan nilai awal',
    resetTip: '(Setel ulang ke nilai awal setiap hari)',
    emptyErrTip: 'Informasi tidak bisa kosong',
    repeatErrTip: 'Nama file duplikat',
    mergeDataTip:
      'Warna, ketebalan, panjang dan lebar bahan papan atau jumlah file harus ditingkatkan secara otomatis.',
  },
  moreSettings: {
    generalSetting: 'Pengaturan Umum',
    outSyncTip:
      'Hasilkan jendela pop-up saat Data Film Asli Tata Letak Cloud tidak disinkronkan dengan kabinet kabinet',
    secondarySwitchShow:
      'Kandungan sekunder dari kontrol sakelar di pengaturan mesin ukiran masih ditampilkan setelah mematikan sakelar.',
    exPaibanTip:
      'Menghasilkan pop-up prompt saat menggunakan pengaturan huruf bersarang lawan jenis',
  },
  lineManager: {
    add: 'Tambahkan jalur produksi',
    engraving: 'Peralatan pemotongan',
    holeSlotEquip: 'Peralatan pemrosesan slot lubang',
    workSetting: 'Pengaturan pemrosesan',
    noUse: 'Tidak diaktifkan',
    warningTip:
      '* Catatan: Setelah mengatur fungsi -fungsi berikut, jangan simpan di halaman docking perangkat klien kabinet di masa depan untuk menghindari mengatur ulang parameter!',
    engravingSetting: 'Pengaturan mesin pemotong',
    workWay: 'Metode pemrosesan',
    cutedSpecial: 'Slot lubang khusus untuk diproses:',
    noWork: 'Opsi ini tidak berlaku pada template default bor lima/enam sisi.',
    onlyCut: 'Potong saja',
    onlyFrontHoleSlot: 'Hanya alur wajah depan piring',
    onlyHoleSlot: 'Hanya alur wajah depan dan belakang dari bagian pelat',
    sideHoleSetting: 'Pengaturan mesin lubang samping',
    cutHoleSlot: 'Alur lubang olahan',
    minSize: 'Ukuran sisi pendek',
    maxSize: 'Ukuran sisi panjang',
    minSize2: 'Semua slot lubang dan bagian berbentuk khusus di pelat mm',
    singleHole: 'Lubang tunggal',
    singleSlot: 'Slot tunggal',
    diameterRange: 'Rentang Diameter Lubang',
    hDeepRange: 'Kisaran kedalaman lubang',
    widthRange: 'Kisaran lebar slot',
    sDeepRange: 'Kisaran kedalaman alur',
    to: 'tiba',
    specialHS: 'Slot lubang khusus dan bentuk khusus',
    allowRoll:
      'Memungkinkan mesin pemotong untuk mencetak ulang dan memproses alur lubang khusus',
    engravingPath: 'Memproses bagian pelat pada mesin pemotong',
    drillSetting: 'Pengaturan bor lima sisi',
    engravingCuted:
      'Slot lubang yang diproses pada mesin pemotong dibor di sisi kelima/keenam',
    noGen: 'Tidak dihasilkan',
    genCut: 'Menghasilkan dan memproses',
    genNoCut: 'Menghasilkan tetapi tidak diproses',
    cutTip:
      'Catatan: Opsi ini hanya efektif untuk file bor lima dan enam sisi khusus',
    maxShortSize: 'Ukuran sisi pendek maksimum',
    maxLongSize: 'Ukuran sisi panjang maksimum',
    normalSlot: 'Slot normal',
    specialSlot: 'Slot khusus',
    combinSetting: 'Pengaturan Perangkat Kombinasi',
    allowShortSize: 'Izinkan ukuran sisi pendek',
    equipment1: 'Peralatan satu',
    equipment2: 'Peralatan 2',
    noSameTip:
      'Catatan: Hal yang sama tidak dapat dipilih untuk kedua perangkat.',
    workRange: 'Rentang slot lubang mesin peralatan',
    rangeWarning:
      'Catatan: Hanya rentang pemrosesan peralatan yang dapat ditetapkan, dan semua rentang pemrosesan peralatan yang akan diproses pada peralatan dua. Harap beroperasi dengan hati -hati!',
    deleteWarning:
      'Setelah menghapus jalur produksi, itu tidak dapat dipulihkan (semua pengaturan di bawah jalur produksi akan dihapus). Apakah Anda pasti akan menghapus jalur produksi?',
    top: 'atas',
    inputCheck:
      '{msg} nilai minimum tidak boleh lebih besar dari nilai maksimum',
    lockTip: 'Jalur produksi terkunci, operasi dilarang!',
    reNameTip: 'Nama jalur produksi default tidak dapat dimodifikasi',
    permissonTip:
      'Informasi identitas telah kedaluwarsa, buka halaman ini dari kabinet lagi!',
    permissonErr: 'Belum ada izin seperti itu, silakan tingkatkan versi akun!',
    permissionOneMacErr:
      'Jumlah lini produksi all-in-one yang dapat Anda gunakan adalah {idx}. Jika Anda perlu menambahkannya, silakan hubungi tenaga penjualan!',
    newLine: 'Buat jalur produksi baru {idx}',
    deleteCheck: 'Tidak bisa menghapus semua',
    deving: 'Dalam pengembangan',
    combinTip:
      'Silakan klik yang berikut untuk menggabungkan pengaturan perangkat untuk mengatur',
    noUseEquip: 'Pemrosesan peralatan tidak diaktifkan',
    equipmentSelectTip:
      'Ketika mesin pemotong dipilih untuk peralatan gabungan, mesin pemotong tidak dapat memilih gergaji elektronik.',
    lineLoading: 'Data sedang memuat',
    strenchType: 'Jenis slot pelurus',
    aioProcessTip:
      'Anda telah mengaktifkan perangkat "all-in-one", dan metode pemrosesan adalah memproses alur wajah depan dan belakang dari bagian pelat secara default',
    aioProcessTip2:
      'Alur lubang khusus yang diproses di mesin ukiran setelah mesin all-in-one diaktifkan',
    aioProcessTip3:
      'Catatan: Fungsi ini tidak kompatibel dengan mesin ukiran yang memungkinkan pertukaran sumbu XY. Harap gunakan dengan hati -hati.',
    specialHoleTip:
      'CATATAN: Saat menggunakan fungsi di atas dari "slot lubang khusus yang diproses", silakan atur "melalui metode pukulan lubang" dan "melalui metode meninju slot" untuk ditinju pada satu waktu.',
    specialHoleTip2:
      'Catatan: Saat menggunakan fungsi "slot lubang khusus yang diproses di atas, silakan atur mesin ukiran" melalui metode pengeboran lubang "dan" melalui metode pengeboran alur "untuk ditinju pada satu waktu.',
    fiveSixNotTake:
      'Opsi ini tidak berlaku pada template default bor lima/enam sisi.',
    onlyFrontSide: 'Proses hanya depan',
    onlyBackSide: 'Memproses sisi terbalik saja',
    less: 'Kurang dari',
    equal: 'setara',
    greater: 'Lebih besar dari',
    or: 'atau',
    and: 'Dan',
    bothFrontBack: 'Memproses bagian depan dan sebaliknya',
    shareDataErrTip:
      'Bagian data ini diterapkan oleh {OperatorPhone}, IP: {actip}',
    restoreParams: 'Pulihkan parameter',
    loadMore: 'Memuat lebih banyak',
  },
  cuttingDock: {
    title: 'Memotong docking',
    other: {
      title: 'lainnya',
      tip: '(Catatan: Peralatan stasiun tunggal memerlukan kode pemuatan otomatis yang sama untuk mengisi stasiun 1 dan stasiun 2)',
    },
    autoStockCode: 'Kode pemuatan dan pembongkaran otomatis',
    stationStart: 'Workstation dimulai',
    stationStartCode1: 'Workstation 1 Mulai Kode',
    stationStartCode1Placeholder: 'Masukkan Kode Mulai Workstation 1',
    stationStartCode2: 'Workstation 2 Mulai Kode',
    stationStartCode2Placeholder: 'Masukkan Kode Mulai Workstation 2',
    stationEnd: 'Workstation berakhir',
    stationEndCode1: 'Workstation 1 Kode Akhir',
    stationEndCode1Placeholder: 'Masukkan Kode Akhir Workstation 1',
    stationEndCode2: 'Workstation 2 Kode Akhir',
    stationEndCode2Placeholder: 'Masukkan Kode Akhir Workstation 2',
    frontBackStart: 'Mulailah di depan dan belakang',
    frontBackEnd: 'Ujung depan dan belakang',
    frontStartCode: 'Kode Mulai Depan',
    frontStartCodePlaceholder: 'Masukkan kode start depan',
    frontEndCode: 'Kode ujung depan',
    frontEndCodePlaceholder: 'Masukkan kode ujung depan',
    backStartCode: 'Kode Mulai Terbalik',
    backStartCodePlaceholder: 'Masukkan kode start terbalik',
    backEndCode: 'Kode akhir terbalik',
    backEndCodePlaceholder: 'Masukkan kode akhir terbalik',
    plankStart: 'Piring dihidupkan',
    plankEnd: 'Piring dibatalkan',
    plankRollCode1: 'Workstation 1 Kode Flip',
    plankRollCode1Placeholder: 'Masukkan stasiun kerja 1 kode flip',
    plankRollCode2: 'Workstation 2 Flip Code',
    plankRollCode2Placeholder: 'Masukkan stasiun kerja 2 kode flip',
    autoBaitingCode: 'Kode pelepasan otomatis',
    autoBaitingCode1: 'Kode pemotongan workstation 1',
    autoBaitingCode1Placeholder: 'Masukkan stasiun kerja 1 kode pemotongan',
    autoBaitingCode2: 'Kode pemotongan workstation 2',
    autoBaitingCode2Placeholder: 'Masukkan stasiun kerja 2 kode pelepasan',
    preStartEquipment: 'Perangkat pra-awal',
    firstKnifePreCode: 'Kode pra-awal pisau pertama terlebih dahulu',
    secondKnifePreCode:
      'Pisau kedua dan kode pra-awal pisau berikutnya adalah sebelumnya',
    rowInsert: 'Penyisipan baris',
    pumpPause: 'Jeda pompa vakum',
    pumpPauseTip:
      'Catatan: Peralatan stasiun tunggal membutuhkan "kode pemuatan otomatis" yang sama dan "kode komentar frontal pemrosesan dua sisi" untuk mengisi stasiun 1 dan stasiun 2.',
    pumpPause2Tip:
      'Catatan: Peralatan stasiun tunggal perlu mengisi stasiun 1 dan stasiun 2 dengan "kode suspensi pompa vakum" yang sama',
    pumpPauseCode1: 'Kode suspensi pompa vakum 1 workstation 1',
    pumpPauseCode1Placeholder:
      'Silakan isi kode suspensi pompa vakum stasiun 1',
    pumpPauseCode2: 'Kode Suspensi Workstation 2 Vakum',
    pumpPauseCode2Placeholder: 'Harap isi kode suspensi pompa vakum stasiun 2',
    annotationCode1: 'Workstation 1 Kode Anotasi Frontal Pemrosesan Sisi Ganda',
    annotationCode1Tip:
      'Cocok untuk peralatan dengan instruksi yang berbeda di bagian depan makan otomatis',
    annotationCode1Placeholder:
      'Masukkan kode anotasi frontal pemrosesan dua sisi untuk workstation 1',
    annotationCode2: 'Kode anotasi frontal pemrosesan dua sisi pemrosesan',
    annotationCode2Placeholder:
      'Masukkan kode anotasi depan untuk pemrosesan dua sisi workstation 2',
    upSpeedTip: 'Silakan isi kecepatan akselerasi dari tepi yang sama',
    cutThickTip: 'Harap isi ketebalan pemotongan tepi yang umum',
    sizeErrTip:
      'Harap atur ukuran tata letak ekspor yang benar di mesin pelabelan!',
    offEdgeKnifeDiameter: 'Harap masukkan diameter alat pemangkasan',
    slowDownSpeedTip: 'Parameter input deselerasi sudut pemotongan salah',
    permissionErr: 'Versi gratis saat ini, tidak dapat disimpan',
    labellingTempErr: 'Pilih template mesin pelabelan',
    autoRollInputTip:
      'Nyalakan tag untuk berputar secara otomatis, dan Anda harus memasukkan nilai yang valid',
    offlineTempFormat:
      'Format file mesin pelabelan saat ini telah offline, harap konfirmasi',
    autoStockCode1: 'Stasiun 1 kode pemuatan otomatis',
    autoStockCode1PlaceHolder: 'Masukkan kode pemuatan workstation 1 otomatis',
    autoStockCode2PlaceHolder: 'Masukkan kode pemuatan otomatis workstation 2',
    autoStockCode2: 'Stasiun 2 kode pemuatan otomatis',
    pauseCode: 'Kode jeda',
    pauseCodePlaceHolder: 'Masukkan kode jeda',
    cuttingParams: {
      title: 'Parameter pemotongan',
      holeSlotMainSide: 'Slot lubang permukaan terkonsentrasi',
      holeMainSide: 'Permukaan konsentrasi lubang',
      slotMainSide: 'Groove permukaan terkonsentrasi',
      workPriority:
        '(Fungsi Prioritas: Permukaan di mana slot berlubang terletak mengikuti slot yang tidak diperkuat pada pelat kecil> pelurus dan lubang cangkir engsel ditempatkan di belakang pelat besar untuk memproses> permukaan konsentrasi alur> permukaan konsentrasi lubang)',
      hingeRollWork:
        'Lubang pelurus dan cangkir engsel ditempatkan di bagian belakang piring besar untuk diproses',
      followWork:
        'Permukaan slot berlubang mengikuti slot yang tidak diperoleh pada pelat kecil.',
      autoMatchSide:
        'Mendistribusikan alur wajah positif dan negatif secara proporsional',
      backHSRate: 'Proporsi slot wajah terbalik',
      matchTip:
        '(Pengaturan ini tidak akan berlaku ketika papan highlight atau skema tata letak yang memungkinkan perhitungan bentuk)',
      highGlossSetting: 'Sorotan pengaturan papan',
      heighGlossFront:
        'Saat mengetik, papan kecil memiliki permukaan gloss tinggi dan diatur di papan besar',
      sideSlotPut: 'Tempatkan alur pelat tepi',
      optimize: 'Optimasi fungsional',
      sysDefault: 'Default Sistem',
      plankInSide: 'Di dalam piring besar',
      plankOutSide: 'Di luar piring besar',
      specialNotWork: 'Fungsi ini tidak berlaku pada piring berbentuk khusus',
      commonSide: 'Pemotongan tepi umum',
      plankThickRange: 'Ketebalan pelat kurang dari atau sama',
      useCommonSide: 'Gunakan pemotongan tepi yang umum',
      zeroTip:
        '(Kesenjangan tata letak perlu diubah secara manual menjadi 0. Saat memproses sampai tepi telah dipotong, angkat pisau untuk melewatkan tepi)',
      useTip:
        'Setelah fungsi ini dihidupkan, pemotongan ke bawah tidak akan didukung, harap perhatikan!',
      commonSpeed: 'Percepatan tepi umum',
      highSpeed: 'Kecepatan penggilingan berkecepatan tinggi',
      min: 'menit',
      notWorkTip:
        '(Ketika pemrosesan pelat memenuhi pemotongan tepi yang umum, akselerasi tepi umum tidak akan berlaku)',
      changeStartPoint: 'Beralih dari titik awal stasiun ganda',
      changeStartPointTip:
        'Setelah mengaktifkan sakelar titik awal stasiun ganda, tidak sesuai dengan fungsi pertukaran sumbu XY. Harap gunakan dengan hati -hati',
      flatOffset: 'Offset Pesawat Mesin',
      xRowChange: 'Penyelarasan referensi koordinat sumbu x',
      bigplankPointChange:
        'Penyesuaian referensi koordinat terbalik dari pelat besar',
      reverseXRowChange: 'X Kontras koordinat',
      changebigplankPointTips:
        'Setelah mengaktifkan fungsi ini, tidak kompatibel dengan fungsi "XY Axis Interchange, Dual-Station Start Start Switching". Harap gunakan dengan hati -hati',
      reverseYRowChange: 'Koordinat y',
      plankMirrorTip:
        'Gunakan fungsi ini saat mirroring plate yang diproses pada pengumpan',
      notSameSize: 'Countertops stasiun ganda ukuran berbeda',
      maxSize1: 'Workstation satu ukuran maksimal',
      notSameSizeTip:
        '(Setelah mengaktifkan berbagai ukuran meja stasiun ganda, tidak sesuai dengan fungsi penggabungan file stasiun ganda, silakan gunakan dengan hati-hati)',
      maxSize2: 'Workstation 2 ukuran maksimum',
      autoSelectTip:
        '(Jika dua meja dari peralatan stasiun ganda berbeda dalam ukuran yang sama, fungsi ini akan dipilih secara otomatis untuk memproses pelat besar di stasiun yang sesuai)',
      bigFlatLimit:
        'Stasiun besar hanya memproses pelat besar yang tidak dapat diproses oleh stasiun kecil:',
      surplusSetting: 'Pengaturan materi yang tersisa',
      surplusPosition: 'Penempatan bahan yang tersisa',
      cutHoleWay: 'Metode pemotongan dan meninju',
      knifeFlat: 'Tatap muka',
      flat: 'Mesa',
      plankFlat: 'Permukaan pelat besar',
      freeHeightZ: 'Z Tinggi Dalam Idle',
      relativeHeight: 'Relatif terhadap ketinggian permukaan atas pelat besar',
      cutDeepZ: 'Potong throughput z kedalaman',
      cutDeepH: 'Melalui metode pengeboran lubang',
      onceThrough: 'Meninju sekaligus',
      rollThrough: 'Balikkan wajah dua kali',
      cutDeepS: 'Cara menembus melalui alur',
      plankCutDirection: 'Arah pemotongan pelat kecil',
      sDirection: 'Searah jarum jam',
      nDirection: 'Berlawanan arah jarum jam',
      curveReplenish: 'Perintah interpolasi busur untuk memotong pelat busur',
      curveReplenishTip:
        'Fungsi ini dapat digunakan saat gerigi muncul dalam pemotongan pelat melengkung',
      noGen: 'Tidak ada perintah wajah G17/G18/G19',
      arcInstruction: 'Awalan instruksi interpolasi busur',
      example: 'Contoh',
      arcExample: 'G17G03 X100 Y100',
      curveCutTip: 'Pemotongan busur diwakili oleh i dan j',
      coverRate:
        'Laju tumpang tindih pemotongan selama alur penggilingan adalah:',
      roughTip:
        'Gunakan fungsi ini saat ada gerombolan residual di dalam alur saat menggiling alur pahat',
      clearCurve:
        'Secara otomatis membersihkan sudut bundar dari slot yang tidak ditarik selama penggilingan:',
      curveUseTip:
        'Fungsi ini diperlukan untuk membersihkan sudut bundar saat menggiling slot. Harap dicatat bahwa fungsi ini tidak berlaku pada konektor.',
      slotCutWay: 'Metode perkakas slot penggilingan',
      outToIn: 'Slot penggilingan dari luar ke dalam',
      inToOut: 'Slot penggilingan dari dalam ke luar',
      zShapProcess:
        'Slot penggilingan berbentuk Z atas dan ke bawah untuk slot melalui',
      zShapProcessTip:
        'Hanya efektif untuk alur alur tarik-tarik depan dan belakang alur, laju tumpang tindih jalur pahat default ke jari-jari pahat',
      zSlotCuttingOverlapRate:
        'Tingkat tumpang tindih dari pemotongan alur penggilingan berbentuk Z adalah',
      slotWorkLimit:
        '(Hanya efektif ketika jumlah lapisan pemotongan alur penggilingan adalah> 1)',
      workSpeedOp: 'Kecepatan pemrosesan yang dioptimalkan',
      obliqueCut: 'Memotong ujung tombak',
      backOblique: 'Membalikkan pemangkasan secara diagonal diturunkan',
      obliqueSpeed: 'Kecepatan down-down',
      arcSpeed: 'Kecepatan pemesinan kurva',
      cuttingPreDistance: 'Keluar dari pisau sebelumnya',
      backKnife: 'Memotong pisau untuk mengurangi kecepatan',
      openKnifeSpeed: 'Kecepatan pengumpulan pisau',
      cutCornerSlow: 'Pengurangan sudut pemotongan',
      addRow: 'Baru',
      bettwenDistance: 'Jarak sebelum dan sesudah sudut',
      cuttingSpeed: 'Kecepatan pemotongan',
      stepCutting: 'Pemotongan berlapis',
      splitSlot: 'Potong alur dua kali',
      splitPlank: 'Potong piring kecil dua kali',
      maxWH: 'Panjang atau lebar lebih kecil dari',
      areaLimit: 'Dan area ini lebih kecil dari',
      secondDeep: 'Kedalaman pemotongan kedua',
      splitPlankThick:
        'Pemotongan berlapis dengan ketebalan pelat yang berbeda',
      saveLayeredCuttingTip:
        'Jumlah dari tiga lapisan pemotongan pemotongan harus sama dengan ketebalan pelat',
      saveLayeredRepeatTip:
        'Tidak disarankan untuk mengatur data berlapis yang berbeda untuk memotong dan melapisi bahan yang sama dengan ketebalan atribut highlight. Harap hapus kelebihan data!',
      movePlankSetting: 'Pengaturan Papan Anti-Run',
      newCutSequence: 'Versi baru dari algoritma pemotongan',
      newCutSequenceText:
        'Gaya hisap vakum di tengah pelat besar adalah yang terkuat, dan pelat kecil di daerah ini akhirnya dipotong',
      positionTip: 'Klik pada area yang sesuai untuk membuat pilihan',
      suctionPosition: 'Posisi hisap vakum',
      strongArea: 'Zona pengisapan yang kuat',
      minSuctionPlank: 'Pelat hisap minimum',
      safeWidth: 'lebar',
      safeHeight: 'panjang',
      safeTip:
        'Pelat akan dipotong terlebih dahulu jika Anda tidak dapat menyerapnya',
      scattered_layout: 'Tata Letak Papan Kecil Tersebar',
      dispersePaibanTipOne:
        '1. Setelah aktivasi, setidaknya satu pelat pada setiap papan besar akan memenuhi ukuran minimum yang dapat diserap.',
      dispersePaibanTipTwo:
        '2. Perhatikan kemungkinan meningkatkan volume pelat besar setelah digunakan',
      totalThickLimit:
        'Jumlah dari tiga lapisan pemotongan harus sama dengan ketebalan pelat',
      offsetFollowStep: 'Pemangkasan tepi juga diikuti oleh pemotongan lapisan',
      longPlankTip1:
        '1. Setelah mengaktifkan fungsi ini, Anda harus mengatur kode suspensi pompa vakum di "Butt Butt - jeda pompa vakum", jika tidak masalah pemotongan dan lentur pelat panjang tidak dapat diselesaikan.',
      longPlankTip2:
        '2. Setelah menyalakan atau mematikan fungsi ini, simpan catatan tata letak dalam sejarah. Saat mengunduh NC, Anda perlu mengetikkan ulang.',
      secondCutting: 'Objek pemotongan sekunder',
      normalPlank: 'Dewan reguler',
      normalPlankTip:
        'Papan konvensional: Partisi horizontal dan vertikal di badan kabinet dan pintu dan papan non-kabinet lainnya ditambahkan langsung ke daftar material',
      plankLongLimit: 'Ketika sisi panjang papan melebihi',
      plankShortLimit: 'Sisi pendek melebihi',
      cuttingSplit: 'Potong dua kali',
      longExpand: 'Volume Potong Pertama: Volume Sisi Panjang',
      firstExpand: 'Volume pemotongan pertama',
      longExpandTip: 'Volume Sisi Panjang',
      longLimit: 'Sisi panjang melebihi',
      cuttingSplitTip: 'Piring panjang dipotong dalam dua kali',
      shortExpand: 'Volume sisi pendek',
      expandTip:
        '(Ukuran sisi panjang/pendek meningkat x mm, dan panjang dan sisi pendek dari pelat panjang berbentuk khusus semuanya meningkat di sisi panjang)',
      pumpOffWay: 'Metode shutdown vakum',
      afterOnePlank: 'Setelah memotong satu pelat panjang',
      afterAllPlank: 'Setelah memotong seluruh pelat',
      numberFixed: 'Jumlah tempat desimal dicadangkan',
      bufferCode: 'Menyiapkan perintah buffer',
      layoutGap: 'Pengaturan kesenjangan yang mengeset',
      layoutGapTip:
        'CATATAN: Kesenjangan yang setel diatur ke 0 dapat menyebabkan pengesahan huruf tumpang tindih',
      wasteCutting: 'Pemotongan limbah otomatis',
      zkPointCustomize:
        'Titik referensi rotasi yang disesuaikan untuk pemesinan lubang z-buckle miring',
      sawSetting: 'Melihat pengaturan pemotongan',
      cuttingLimit: 'Ukuran cutoff lebih besar dari',
      waste: 'Buang -buang',
      wasteSizeLimit: 'Ukuran minimum tidak boleh kurang dari 100mm',
      topLeft: 'Kiri, atas',
      topLeftD: 'Sudut kiri atas',
      bottomLeft: 'Kiri, bawah',
      bottomLeftD: 'Sudut kiri bawah',
      topRight: 'Benar, atasnya',
      topRightD: 'Sudut kanan atas',
      bottomRight: 'Kanan, bawah',
      bottomRightD: 'Sudut kanan bawah',
      firstTime: 'Pertama',
      secondTime: 'Kedua kalinya',
      thirdTime: 'Ketiga kalinya',
      startCutting: 'Pemotongan dimulai',
      thickRange: 'Ketebalan ≥',
      longCut: 'Pemrosesan Longboard',
      edgeSetting: 'Pengaturan Pemangkasan',
      cuttingOrder: 'Pesanan pemotongan:',
      cuttingOrderTip:
        'Metode ini memiliki risiko menjalankan papan, silakan gunakan dengan hati -hati',
      plankFirst: 'Potong papan terlebih dahulu',
      wasteFirst: 'Potong limbah terlebih dahulu',
      offTip:
        'Saat ditutup, pengaturan pemangkasan akan diikuti dalam parameter pemotongan.',
      edgeCutDirection: 'Arah pemangkasan',
      lGapTip:
        'Posisi pelat besar berlekuk dari bahan residu berbentuk L secara otomatis disesuaikan dalam arah diagonal silinder penentuan posisi, kecuali untuk pelat besar bahan residu gloss tinggi, yang tidak kompatibel dengan fungsi pertukaran sumbu XY.',
      onlyLong: 'Hanya sisi ramping',
      backCutOrder: 'Reverse Tripming Order',
      backFirst: 'Bagian depan sebaliknya adalah yang pertama',
      backSecond: 'Reverse Face Groove Processing dan Tripming',
      frontReCut: 'Retouch bagian depan',
      frontReCutTip:
        '(Buat tepi silinder posisi lebih halus untuk mencegah jejak)',
      useNewCut: 'Aktifkan versi baru aturan pemangkasan tepi',
      newCutTip:
        'Gunakan fungsi ini ketika bagian depan pelat besar dua sisi akan diperbaiki dengan satu ukuran pemangkasan lagi.',
      fixedTip:
        'CATATAN: Mungkin ada teseettings yang tumpang tindih ketika jumlah digit yang ditahan adalah 0 atau 1.',
      cuttingSlow: 'Buffer permukaan pelat pemotongan',
      holeSlow: 'Meninju bantal permukaan pelat',
      slotSlow:
        'Menarik alur/lubang penggilingan/buffer permukaan pelat alur milling',
      slowCode: 'Penggunaan instruksi buffering',
      code: 'petunjuk',
      zkRotatePoint: 'Putar titik referensi',
      zkRotateDir: 'Arah kenaikan sudut rotasi',
      restSizeLimit:
        'Ukuran yang tersisa dari bahan yang tersisa lebih kecil dari',
      useSaw: 'Menggunakan bilah gergaji',
      unGenSawPath:
        'Tidak ada jalur pemotongan bilah gergaji longitudinal yang dihasilkan',
      suitMachine: 'Cocok untuk memproses semua pelat yang dilas aluminium',
      plankBack: 'Sisi belakang piring besar',
      plankLeft: 'Sisi kiri papan',
      plankRight: 'Sisi kanan papan',
      plankFront: 'Sisi depan piring besar',
      frontSide: 'Sisi depan',
      backSide: 'Sisi belakang',
      rightSide: 'Sisi kanan',
      leftSide: 'Sisi kiri',
      singleMachineLimit:
        'Bagian depan papan pemesinan satu sisi menghasilkan jalur pemangkasan dan memangkasnya terlebih dahulu',
      singleMachineLimitTip:
        'Mesin all-in-one dan pemotongan bilah tidak berlaku.',
      setBatchPositionWaring:
        'Kiat: Jika Anda perlu membatalkan modifikasi cepat dari mode titik alat, silakan hapus centang modifikasi kanan dari tombol titik alat',
      setBatchPosition: 'Titik modifikasi batch',
      setBatchPositionAs: 'Ubah arah alat berikutnya ke:',
      activeBatchPosition: '{ActivePartCount} Board telah dipilih',
    },
    labelingSettings: {
      title: 'Pengaturan Pelabelan',
      output: 'File pelabelan otomatis output',
      specification: 'Format File Label',
      codeFrontGenerate:
        'Kode pemuatan otomatis dihasilkan sebelum kode pelabelan',
      templateOffline: 'Template yang digunakan telah offline!',
      rollLabelling: 'Pengaturan pelabelan flip',
      flipSetting: {
        title: 'Pengaturan flip',
        engravingFlipProcess:
          'Aktifkan mode pelabelan pemrosesan flip mesin ukiran',
        flipTip: 'Aktifkan fungsi ini ketika mesin ukiran perlu dibalik.',
      },
      rotateSetting: {
        title: 'Pengaturan Rotasi Tag',
        rotateTip:
          'Nyalakan kondisi rotasi sesuai dengan persyaratan. Jika dihidupkan pada saat yang sama, pilih nilai yang ditetapkan untuk pilihan terbaik',
        horizontal: 'Tepi horizontal pelat ≤',
        vertical: 'Tepi vertikal pelat ≤',
        either: 'Horizontal | Tepi vertikal ≤',
      },
      countertopSetting: {
        title: 'Pengaturan Desktop',
        startPoint: 'Titik awal stasiun',
        upLeft: 'Sudut kiri atas',
        upRight: 'Sudut kanan atas',
        downLeft: 'Sudut kiri bawah',
        downRight: 'Sudut kanan bawah',
        baseExchange: 'Koordinat Penyelarasan Referensi',
        exchangeX: 'Penyesuaian sumbu x',
        exchangeY: 'Sakelar sumbu y',
      },
      offset: 'Label Offset Koordinat',
      offsetTip:
        'Secara umum, koordinat label berada di tengah papan kecil, dan beberapa peralatan perlu disesuaikan.',
      label_rotate_offset: 'Koordinat offset setelah rotasi label',
      label_rotate_offset_tip:
        'Ketika perbedaan posisi offset antara label sebelum dan sesudah rotasi besar, itu perlu disesuaikan.',
      label_rotate_offset_err_tip:
        'Setelah offset koordinat dihidupkan setelah label diputar, offset koordinat sumbu X dan Y diperlukan.',
      imgSetting: {
        title: 'Pengaturan gambar pelabelan',
        suffix: 'Sufiks gambar label:',
        code: 'Metode pengkodean gambar:',
        codeTip:
          'Catatan: PNG dan JPEG akan secara bersamaan memodifikasi format pengkodean diagram tata letak, dan tata letak awan yang tertanam belum mendukung fungsi ini.',
        imgRatio: 'Labeling Resolusi Gambar:',
        slowDown: 'Akan mengurangi efisiensi mengekspor gambar tag',
        color: 'Sangat berwarna',
        bit: 'Hitam dan putih',
        resolution: 'Label resolusi gambar',
        resolutionPlaceholder: 'Resolusi',
        labelPool: 'Kumpulan tag data',
        dragTip: 'Tag drag-drop di tag pool',
        labelImgName: 'Nama gambar berlabel',
        labelImgNameErrTip: 'Nama gambar label tidak bisa kosong',
        labelTip1:
          'Catatan: 1. Saat menyesuaikan, Anda harus menggunakan salah satu dari "papan barcode papan" atau "nomor seri label", jika tidak akan ada masalah cakupan gambar;',
        labelTip2: '2. Template sistem mesin pelabelan tidak berlaku.',
        delimiter: 'Pembatas',
        inputTip: 'Input /:? <> | . ” * Dan karakter lain tidak didukung',
        numStart: 'Nilai mulai dari nomor seri berlabel',
        example: 'Nama Gambar Pelabelan Kustom (Contoh)',
        divideFolders:
          'Gambar tag dibagi menjadi folder yang berbeda sesuai dengan papan besar',
        foldersTip1:
          'Catatan: 1. Fungsi ini tidak mendukung penggunaan dengan fungsi "Spinder File Gabungan";',
        foldersTip2:
          '2. Fungsi ini belum efektif untuk pengaturan huruf awan tertanam',
        stickIntoFolders:
          'Masukkan file pelabelan ke dalam folder gambar pelabelan dari papan besar yang sesuai',
        stickTip1:
          'Catatan: 1. Fungsi ini perlu digunakan bersama dengan fungsi "Unduh NC dan Ekspor";',
        stickTip2:
          '2. Aturan pembuatan file dalam templat pelabelan yang digunakan perlu memilih "menghasilkan satu file" untuk berlaku;',
        stickTip3:
          '3. Fungsi ini belum efektif untuk pengaturan huruf awan tertanam',
      },
      paibanImgSetting: {
        title: 'Pengaturan Diagram Pengesahan',
        reparation: 'Nomor tata letak akan dikompensasi secara otomatis',
        figure: 'Sedikit',
        inputTip: 'Harap masukkan nomor dari 1-99',
        paibanImg: {
          export: 'Tata letak ekspor',
          prefix: 'Awalan yang mengeset',
          size: 'Ukuran pengukur huruf',
          format: 'Format pengukur huruf',
          directional: 'Arah diagram yang memetik',
          vertical: 'Vertikal',
          horizontal: 'Horisontal',
        },
      },
      autolabel: 'Pengaturan Pesanan Pelabelan Otomatis',
      customTemp: 'Efektif untuk templat khusus saja',
      cuttingLabelOder: 'Label sesuai dengan urutan pemotongan',
      labelToUp: 'Label dari bawah ke atas sesuai dengan hasil tata letak',
      labelToDown: 'Label dari atas ke bawah sesuai dengan hasil tata letak',
      labelDodge: 'Penghindaran pelabelan otomatis',
      settingValue: 'Pilih Nilai Set',
      enabelTip:
        'Setelah dibuka, saat papan kecil memenuhi nilai pengaturan berikut, label berputar',
      fixedHori:
        'Pilih tepi horizontal label agar sejajar dengan tepi tetap dari pelat kecil',
      fixedHoriTip:
        'Hanya efektif untuk papan kecil tanpa tekstur. Setelah membuka, saat memberi label, label akan berputar ke paralel ke tepi horizontal label dan tepi tetap dari papan kecil.',
      tagHori: 'Tag Border Horizontal',
    },
    drillSettings: {
      title: 'Pengaturan pengeboran',
      enable: 'Aktifkan paket pengeboran',
      toolTable: {
        use: 'Memungkinkan',
        number2: 'Workstation 2 Nomor Bor Baris',
        number: 'Nomor pengeboran',
        diameter: 'Bor sedikit diameter',
        xOffset: 'X Offset',
        yOffset: 'Y offset',
        moveZF: 'Z-Axis Fast Moving Speed',
        feedRate: 'Kecepatan pemesinan sumbu Z',
        moveF: 'Kecepatan Gerakan Cepat Sumbu XY',
        benchmarkDrill: 'BATURAN BENCHMARK',
        addKnife: 'Tambahkan alat',
        deleteKnife: 'Hapus alat',
      },
      prefix: 'Awalan pengeboran',
      suffix: 'Suffix Pengeboran',
      startCode: 'Kode Mulai Pengeboran',
      endCode: 'Kode akhir pengeboran',
      combineKnife: 'Pemotong kombinasi pengeboran',
      combineTable: {
        prefix: 'Awalan nomor bor',
        suffix: 'Suffix nomor bor',
        mainHole: 'Lubang utama',
        extraHole: 'Dari lubang',
        notes: 'Komentar Kombinasi',
        firstUseNotes: 'Aktifkan komentar untuk pertama kalinya',
        addExtraHole: 'Tambahkan dari lubang',
        deleteExtraHole: 'Hapus dari lubang',
        example: 'Mencicipi',
      },
      processSetting: {
        title: 'Pengaturan pemrosesan',
        mode: 'Mode Pemrosesan Pengeboran',
        processTip:
          'Setelah memilih metode ini, fungsi pemotongan kombinasi pengeboran dan pengeboran tidak akan berlaku.',
        recentHole: 'Lubang pukulan di dekatnya (perubahan alat cepat)',
        groupHole:
          'Lubang pukulan berdasarkan kategori (lengkapi jenis lubang pertama dan kemudian ubah alat)',
      },
      changeBenchDrillTip:
        'Harap masukkan nomor pengeboran sebelum memilih ini',
    },
    NCExportSettings: {
      title: 'Pengaturan Ekspor NC',
      tab1: 'Pengaturan Nama File Mesin Pemotongan',
      tab2: 'Gabungkan nama file pemotong',
      tab3: 'Catatan dalam file mesin pemotong',
      tab4: 'Jenis dalam file mesin pemotong',
      tab5: 'Folder mesin pemotong',
      tab6: 'Memproses Pengaturan Perintah',
      tab7: 'Pengaturan Format File',
      fileNameSettings: {
        twoStationAutoSort: 'Pemilahan Stasiun Ganda Otomatis',
        reparation: 'Jumlah nomor papan besar dikompensasi',
        figure: 'Sedikit',
        labelerBegin: 'Mulai mesin label',
        machineCenter: 'Mulai Pusat Pemesinan',
        front: 'depan:',
        back: 'Balik:',
        customizeName: 'Nama File Kustom (Format):',
        example: 'Nama File Kustom (Contoh):',
        emptyTip:
          'Pengidentifikasi file depan dan belakang tidak dapat kosong pada saat yang sama',
        labelPool: 'Kumpulan tag data',
        dragTip:
          'Anda dapat menyeret atau mengklik untuk menyalin tag di kumpulan tag ke kotak input',
      },
      fileMerge: {
        engravingMerge: 'Kombinasi file mesin pemotong',
        zfMerge: 'Gabungkan depan dan belakang',
        singleMerge: 'Penggabungan stasiun tunggal',
        doubleMerge: 'Penggabungan Stasiun Ganda',
        fEndCode: 'Kode akhir terbalik',
        zBeginCode: 'Kode Mulai Depan',
        displayfbInfo: 'Nama file menampilkan informasi depan dan belakang',
        fBeginCode: 'Kode Mulai Terbalik',
        zEndCode: 'Kode ujung depan',
      },
      marginalNotes: {
        addPlankInfo:
          'Tambahkan informasi pelat besar saat ini di awal file pemotongan:',
        plankInfo: 'Informasi Papan Besar:',
        addStationCodeFirst:
          'Tambahkan kode stasiun kerja di awal file pemotongan:',
        addStationCodeSecond:
          'Tambahkan kode stasiun kerja sebelum mengubah alat saat memotong file materi:',
      },
      fileType: {
        zAddtion: 'Z Koordinat Suplemen:',
        addSemicolon: 'Tambahkan titik koma di akhir setiap baris NC:',
        semicolonTip:
          'File pemrosesan pegangan tanduk juga akan berlaku setelah memeriksa',
        fileName: 'Nama file',
        defaultName: 'Nama file default',
        dedicatedName: 'Nama file khusus untuk peralatan starlight',
        xhNotes: 'Starlight E2-9 Komentar',
        format: 'Format file mesin ukiran ukiran',
        example: 'Contoh File: File NC',
        stillGenerate:
          'Ketika sisi terbalik tidak diproses, itu masih menghasilkan file NC terbalik dengan hanya kode ujung terbalik.',
        generateTip:
          'Fitur ini tidak mendukung penggunaan dengan fungsi penggabungan file',
        fileNameErrTip:
          'Nama file tidak dapat berisi karakter berikut: /: *? "',
      },
      categorize:
        'Menurut warna, ketebalan dan bahan papan besar, itu diklasifikasikan ke dalam folder yang berbeda (label file bersama):',
      simplifyG: 'Memproses singkatan instruksi G',
      ZToW: 'Kode diubah dari sumbu z ke sumbu W',
      knives: 'Alat pengeboran',
      lineBreakSetting: 'Pengaturan Format Line Break',
      codeFormatSetting: 'Pengaturan format pengkodean file',
      utf8bom: 'UTF-8 dengan bom',
      oneCodeSetting: 'Pengaturan Barcode Papan',
      simplifyOneCode: 'Hasilkan Barcode Tag Format Sederhana',
      codeLocation: 'Pengaturan Lokasi Kode',
      generateLocation:
        'Kode akhir stasiun kerja dihasilkan sebelum kode akhir terbalik',
      emergencySetting: 'Pengaturan darurat',
      show: 'Tampilkan pengaturan darurat',
      emergencyCode: 'Kode darurat',
      params: 'parameter',
      emergencyTip:
        'Beberapa fungsi niche dapat untuk sementara waktu diimplementasikan dengan kerja sama personel setelah penjualan',
      holeSetting: 'Pengaturan file meninju',
      holeFileCategorize:
        'File meninju ditempatkan secara terpisah sesuai dengan pesanan yang berbeda',
      holeTip: 'Tata letak awan klien kabinet tertanam tidak berlaku',
      specialCodeErr: 'Input karakter khusus tidak didukung!',
      copySuccess: 'Salin dengan sukses!',
      copyErr: 'Menyalin gagal, coba seret dan menjatuhkan tag!',
      lengthLimit: 'Hanya 15 bit yang dimasukkan!',
      plankInfo: 'Informasi papan',
      otherInfo: 'Informasi tambahan',
      uniqueBarCode: 'Barcode yang unik',
      uniqueBarCodeForPack: 'Barcode papan unik (untuk kemasan)',
      machineOrder:
        'Memotong nama file untuk menghasilkan nomor urutan pemrosesan',
      folderSetting:
        'Tempatkan folder yang sama dengan piring besar dengan ukuran yang sama',
      labelImageINCFolder:
        'Gambar pelabelan ditempatkan di folder NC dengan file pelabelan',
      custom: 'dibuat khusus',
      folderSettingTip:
        'Catatan: Fitur ini belum didukung dalam pengaturan huruf tertanam',
      settingMoveTip:
        'Fungsi "{func}" asli telah dipindahkan ke halaman "{Modul}"!',
      fileManagerFunction:
        'File meninju ditempatkan secara terpisah sesuai dengan file pesanan yang berbeda',
      gotoSetting: 'Pergi ke Pengaturan',
    },
    aioMachineSetting: {
      openFunction:
        'Docking membutuhkan fungsi -fungsi berikut untuk diaktifkan',
      openCarving: 'Nyalakan mesin ukiran',
      xyReverseFunction: 'Fungsi pertukaran sumbu xy',
      carvingStartSet: 'Titik awal stasiun mesin ukiran diatur ke',
      rightTop: 'Sudut kanan atas',
      carvingEdgeSet: 'Bagian depan mesin ukiran disesuaikan',
      finalEdgeSame: 'Sama seperti nilai total selesai',
      beginCode: 'Kode Mulai Program',
      endCode: 'Kode Akhir Program',
      drillHole: 'Lubang meninju',
      pullTrough: 'Tarik slotnya',
      showDataTemp: 'Tampilkan sampel data',
      dataTemp: 'Sampel data',
      title: 'Pengaturan all-in-one',
      outputFile: 'Output file permesinan all-in-one',
      outputFileTips:
        'Beberapa merek mesin all-in-one tidak sesuai dengan XY Exchange, penggabungan file, dan tidak ada lubang berbentuk khusus yang tertusuk. Harap gunakan dengan hati -hati.',
      fileType: 'Format file',
      select: 'Pilih',
      diameter: 'Diameter alat pemangkasan',
      jidongTip:
        'Gunakan mesin jidong all-in-one, silakan atur titik awal stasiun mesin ukiran ke sudut kanan atas dan aktifkan fungsi A',
      skill: 'Fungsi',
      clampHandLength: 'Panjang klem tangan (l)',
      clampHandWidth: 'Lebar tangan penjepit (w)',
      clampHandMargin1: 'Klip Tangan 1 Tepi Jarak (H1)',
      clampHandMargin2: 'Klip Tangan 2 Tepi Jarak (H2)',
      duchClampHand: 'Slot lubang menghindari posisi penjepit tangan',
      clampHandDirection: 'Arah penjepit tangan',
      left: 'Sisi kiri',
      right: 'Sisi kanan',
      top: 'Sisi atas',
      bottom: 'Sisi bawah',
    },
    oneDragTwo: {
      title: 'Mesin ukiran satu-ke-dua',
      outputMachineFile: 'Output file pemrosesan mesin dua-drag-drag-drag',
      outputMachineFileTips:
        'Setelah mengaktifkan mesin ukiran satu-ke-dua, tidak kompatibel dengan fungsi penggabungan file pemotong. Harap gunakan dengan hati -hati.',
      fileType: 'Format file',
      select: 'Pilih',
      machineStart: 'Titik awal stasiun',
      positionClose:
        'Saat ditutup, pengaturan titik awal stasiun dalam parameter pemotongan akan diikuti.',
      engravingPlus: 'Mesin ukiran cepat',
      a: '',
    },
    sawEngraving: {
      title: 'Melihat mesin ukiran pemotongan',
      preLayNav: 'Pengaturan mesin ukiran gergaji',
      preLayKnifeInput: 'Diameter pemotong silang blade gergaji',
      enableSaw: 'Aktifkan mesin penggergajian dan ukiran',
      cutSet: 'Pengaturan pemotongan',
      sawUsing: 'Penggunaan bilah gergaji',
      trim: 'Pemangkasan Perbatasan',
      cutBlank: 'Memotong',
      sawUseCond: 'Kondisi untuk penggunaan bilah gergaji',
      hasUsing: 'Gunakan jika tersedia',
      onlyUse: 'Gunakan hanya kapan',
      cutMethod: 'Metode pemotongan',
      oneKnifePull: 'Tarik melalui papan besar dengan satu pisau',
      onlyOneKnifePull: 'Tarik saja dengan satu pisau',
      enableSawThick: 'Aktifkan tata letak ketebalan blade gergaji',
      edgedDir: 'Tepi pelat besar yang telah disegel dan tidak lagi dipotong',
      top: 'Di atas',
      bottom: 'di bawah',
      left: 'kiri',
      right: 'Kanan',
      dockTip:
        'Pemrosesan pelat pengelasan pantat membutuhkan penyegelan empat sisi pelat persegi panjang dan pemangkasan tepi keseluruhan, dan mengoperasikannya secara manual ke tata letak baris tunggal.',
      tempPaiabanTip:
        'Setelah mengaktifkan pemotongan bilah gergaji, tidak didukung untuk menambahkan pelat berbentuk khusus secara langsung di tata letak. Jika Anda perlu menambahkan, silakan buka daftar pembuka untuk ditambahkan dan ketik!',
      generateCutSurplusPath:
        'Hasilkan jalur potong melintang yang terlebih dahulu memotong sisa bahan',
    },
    firstSave:
      'Terdeteksi bahwa ini adalah pertama kalinya pengguna menggunakan tata letak cloud. Setelah menyimpan pengaturan, harap perhatikan apakah arah pemangkasan dalam file tersebut masuk akal untuk menghindari kerugian yang tidak perlu.',
    knives: {
      xOffset1: 'X Offset Koordinat',
      yOffset1: 'Y Offset Koordinat',
      zOffset1: 'Z Offset Koordinat',
      insert: 'Masukkan baris',
      delete: 'Hapus baris',
      tip: '(Catatan: Setelah menyelesaikan modifikasi atau penyesuaian informasi majalah alat, pengguna yang berada dalam produksi tata letak kabinet perlu memulai kembali klien kabinet untuk menerapkan perubahan terbaru!)',
      number: 'nomor seri',
      name: 'nama',
      diameter: 'Diameter pisau φ',
      cutting: 'Pembukaan',
      use: 'menggunakan',
      moveZF: 'Z-Axis Fast Moving Speed',
      cutZF: 'Kecepatan pemesinan sumbu Z',
      moveF: 'Kecepatan Gerakan Cepat Sumbu XY',
      cutF: 'Kecepatan permesinan sumbu xy',
      startCode: 'Kode Mulai Perubahan Alat',
      stopCode: 'Kode Akhir Perubahan Alat',
      startCode2: 'Kode Mulai Perubahan Alat Workstation 2',
      stopCode2: 'Workstation 2 Alat Ubah Kode Akhir',
      preStartCode: 'Kode pra-awal',
      punchHoles: 'Lubang meninju',
      pullSlots: 'Tarik slotnya',
      millHoles: 'Lubang penggilingan',
      millSlots: 'Slot penggilingan',
      sawBlade: 'Saw blade',
      doubleSaw:
        'Aktifkan kode start perubahan alat yang berbeda untuk stasiun ganda',
      singleStation: 'Ruang kerja tunggal',
      doubleStation: 'Rangkap',
      sawBladeTip:
        'Fungsi ini tidak berlaku pada mesin ukiran satu-ke-dua dan mesin all-in-one',
      serialNum: 'nomor seri',
      sawWidth: 'Lebar pisau',
      sawType: 'Jenis pisau gergaji',
      cutDirection: 'Arah pemotongan',
      moveSpeed: 'Kecepatan kosong',
      cutSpeed: 'Kecepatan pemrosesan',
      endCode: 'Kode Akhir Perubahan Alat',
      sawDownCode: 'Kode Potongan',
      sawUpCode: 'Kode pisau',
      abscissa: 'Awalan absis',
      ordinate: 'Awalan ordinat',
      sawHor: 'Pisau gergaji silang',
      sawVer: 'Pisau gergaji longitudinal',
      towardsZero: 'Potong ke 0 koordinat titik',
      awayFromZero: 'Jauh dari pemotongan koordinat 0-poin',
      diameterDesc: 'Diameter pisau φ',
      xOffset: 'Offset koordinat horizontal',
      yOffset: 'Offset koordinat vertikal',
      zOffset: 'Mengangkat Offset Koordinat',
      offsetX: 'Offset x',
      offsetY: 'Mengimbangi y',
      offsetZ: 'Offset Z.',
      offsetNo: 'Nomor offset',
      knifeWidth: 'Lebar pisau',
      drill: 'Melepaskan pisau',
      cut: 'Pengolahan',
      speed: 'Kecepatan',
      type: 'jenis',
      millHoleSlot: 'Lubang/slot penggilingan',
      rapidFeedSpeed: 'Kecepatan umpan cepat',
    },
  },
  sideHoleMachine: {
    coordSwitch: 'Koordinat Penyelarasan Referensi',
    updownCoordSwitch: 'Mengangkat penyesuaian referensi koordinat',
    knifes: {
      number: 'nomor seri',
      diameter: 'Lebar pisau',
      type: 'jenis',
      moveSpeed: 'Kecepatan umpan cepat',
      doSpeed: 'Kecepatan pemrosesan',
      startCode: 'Kode Mulai Perubahan Alat',
      endCode: 'Kode Akhir Perubahan Alat',
    },
    ymSetting: 'Pengaturan Koneksi Easy-Mu',
    flanking: 'Pemrosesan samping',
    frontProcess: 'Pemrosesan frontal',
    oldSetting: 'Pengaturan versi lama',
    equipParamsSetting: 'Pengaturan Parameter Perangkat',
    holeSetting: 'Pengaturan meninju',
    raminoSetting: 'Pengaturan Koneksi Lamino',
    mortiseTenonSetting: 'Pengaturan Koneksi Monolitik dan Tenon',
    straight: 'Langsung',
    eccentric: 'Model ekstrasentrik',
    pbSize: 'Ukuran pelat pemecah atas dan bawah',
    coordinateValue: 'Nilai koordinat',
    codeFormat: 'Format Pengkodean File',
    suffixSetting: 'Pengaturan Suffix File',
    insertRow: 'Masukkan baris',
    deleteRow: 'Hapus baris',
    equipmentParams: {
      equipPauseCode: 'Kode jeda perangkat',
      wiggleCode: 'Kode ayunan',
      equipStartCode: 'Kode startup perangkat',
      flipPauseCode: 'Balikkan kode',
      equipEndCode: 'Kode Akhir Perangkat',
      equipStartCodePlaceholder: 'Masukkan kode startup perangkat',
      flipPauseCodePlaceholder: 'Masukkan kode jeda flip',
      equipEndCodeTip: 'Masukkan Kode Akhir Perangkat',
      sideChangeSetting: 'Pengaturan facelift samping',
      workTip:
        'Secara default, itu dimulai dari kiri ① di arah berlawanan arah jarum jam.',
      frontTurnCode: 'Mulai kode di atas',
      frontTurnEndCode: 'Kode akhir di atas',
      frontTurnCodePlaceholder: 'Masukkan kode mulai di atas',
      frontTurnEndCodePlaceholder: 'Masukkan kode akhir di atas',
      leftTurnCode: 'Mulai kode di sebelah kiri',
      leftTurnEndCode: 'Kode akhir di sebelah kiri',
      leftTurnCodePlaceholder: 'Masukkan kode start di sebelah kiri',
      leftTurnEndCodePlaceholder: 'Masukkan kode akhir di sebelah kiri',
      downTurnCode: 'Mulai kode di bawah ini',
      downTurnEndCode: 'Kode akhir berikut',
      downTurnCodePlaceholder: 'Masukkan kode mulai berikut',
      downTurnEndCodePlaceholder: 'Masukkan kode akhir berikut',
      rightTurnCode: 'Mulai kode di sebelah kanan',
      rightTurnEndCode: 'Kode akhir di sebelah kanan',
      rightTurnCodePlaceholder: 'Masukkan kode start di sebelah kanan',
      rightTurnEndCodePlaceholder: 'Masukkan kode akhir di sebelah kanan',
      topFlipCode: 'Kode Flip - Top',
      downFlipCode: 'Kode Flip - Berikutnya',
      leftFlipCode: 'Kode flip - kiri',
      rightFlipCode: 'Kode Flip - Benar',
      cylinderOutCode: 'Kode peluncuran silinder',
      cylinderbackCode: 'Kode Pengambilan Silinder',
      cylinderbackDirective: 'Instruksi retraksi silinder',
      moveDirective: 'Pindahkan perintah',
      moveAxisCode: 'Pindahkan sumbu',
      pushAxisCode: 'Poros dorong silinder',
      swingAxisCode: 'Poros ayunan',
      moveCode: 'Kode seluler',
      punchCode: 'Kode pemrosesan',
      free: 'Jalan kosong',
      punchPart: 'Bagian pemrosesan',
      cutFaceCode: 'Memproses kode pesawat',
      horVerCutFace: 'Bidang horizontal',
      horCutFace: 'Pesawat pengangkat silang',
      verCutFace: 'Pesawat pengangkat longitudinal',
    },
    tagPool: 'Kumpulan tag data',
    dragTip: 'Tag drag-drop di tag pool',
    knives: 'Alat pemrosesan:',
    directives: 'Instruksi Pemrosesan:',
    slot: 'Pemrosesan alur lurus',
    cirWork: 'Permesinan busur',
    cirInter: 'Interpolasi busur',
    cirOption1: 'Searah jarum jam: g03 berlawanan arah jarum jam: g02',
    cirOption2: 'Searah jarum jam: G02 berlawanan arah jarum jam: G03',
    coordinate: {
      xPointSymbol: 'Koordinat horizontal:',
      yPointSymbol: 'Koordinat vertikal:',
      zPointSymbol: 'Koordinat pengangkat:',
      upright: 'Nilai positif',
      negative: 'Nilai negatif',
    },
    encodeFormat: {
      format: 'Format pengkodean file:',
      wrap: 'Pengaturan Format Line Break:',
      formatOption1: 'Format UTF-8',
      formatOption2: 'Format ANSI',
    },
    suffix: 'Pengaturan Suffix File:',
    enableFP: 'Aktifkan pemrosesan frontal',
    slotSetting: 'Tarik pengaturan slot',
    takeUpDirective: 'Perintah Knife-Collection',
    workSeparately: 'Alur maju/terbalik diproses dalam dua bagian',
    secondSlotLong: 'Panjang slot kedua',
    enableOld: 'Aktifkan Pengaturan Mesin Lubang Sisi Lama',
    default: 'Pengaturan default',
    customizable1: 'Sesuaikan satu',
    customizable2: 'Kustom Dua',
    customizable3: 'Tiga Kustom',
    customizable4: 'Kustom empat',
    knifeSetting: 'Pengaturan Alat',
    soltTip: '(Termasuk lubang/slot penggilingan)',
    specialSetting: 'Pengaturan Khusus',
    ramino: 'RAMINO',
    sun: 'Raja dan mortise',
    please: 'Pantau terus',
    designatedKnives: 'Pisau yang ditunjuk',
    designated: 'Menentukan',
    workOrder: 'Perintah pemrosesan',
    other: 'lainnya',
    nameSetting: 'Pengaturan Nama Suffix',
    repeatErr: 'Ulangi nomor, harap setel ulang',
  },
  electronicSaw: {
    importMaterial: 'Impor Daftar Bahan Baku',
    formSetting: 'Pengaturan bentuk gergaji elektronik',
    fileFormat: 'Bill output Format file daftar material:',
    isSmallFile: 'Ekspor File Gambar Papan Kecil:',
    fileGroupRule:
      'File gergaji elektronik dibagi menjadi tabel yang berbeda sesuai dengan warna, material, dan ketebalan papan:',
    roomsByCategory:
      'File gergaji elektronik dibagi menjadi berbagai tabel sesuai dengan ruangan:',
    delimiter: 'Pembatas:',
    fileName: 'Nama file Bill of Material:',
    fineNameTip: 'Nama file Bill of Material tidak bisa kosong!',
    fileNamePlaceholder: 'Kustomisasi Bill of Material',
    outputFileEncoding: 'Format Pengkodean Daftar Output:',
    utf8: 'UTF-8 dengan bom',
    cellTab: 'Hasilkan Tab Sel:',
    texture: 'Representasi Tekstur:',
    special: 'Representasi ekstra berbentuk:',
    notSpecial: 'Bukan alien',
    genSimpleLabelBarcode: 'Hasilkan Barcode Tag Format Sederhana',
    uniqueBarCode: 'Barcode yang unik',
    uniqueBarCodeTip:
      'Fungsi menghasilkan barcode tag format sederhana setelah pembukaan tidak berlaku',
    customeForm: 'Formulir Kustom',
    tagPool: 'Kumpulan tag data',
    tagPoolTips: 'Anda dapat menyeret atau mengklik untuk menyalin tag di pool',
    displayHead: 'Jangan menampilkan header',
    addTableHead: 'Tambahkan header',
    deleteTableHead: 'Hapus tajuk meja',
    generateExample: 'Menghasilkan sampel formulir output',
    importExample: 'Sampel bentuk impor',
    importExampleTip:
      'Contoh bentuk yang diimpor, hanya untuk referensi gergaji elektronik khusus',
    importExampleErrTip:
      'Daftar bahan baku belum diimpor, harap impor daftar bahan baku',
    outputExample: 'Sampel formulir output',
    outputExampleTip: 'Contoh output formulir khusus',
    outputExampleErrTip:
      'Sampel formulir output belum dihasilkan, harap hasilkan sampel formulir output',
    defaultTableHead: 'Header default',
    deleteHeadErrTip: 'Tidak ada header untuk dihapus!',
    genNonString: 'Menghasilkan tipe data format non-teks',
    genNonStringTip:
      'Anda dapat mencoba mengaktifkan data tabel yang dihasilkan jika gergaji elektronik tidak dikenali.',
  },
  lockModal: {
    passwordInput: 'Harap masukkan kata sandi Anda',
    lockPasswordInput: 'Harap masukkan kata sandi buka kunci',
    passwordInputPlaceholder: 'Harap masukkan kata sandi empat digit',
    editPassword: 'Ubah Kata Sandi',
    confirmPassword: 'Konfirmasi Kata Sandi',
    setLockPwd: 'Setel Kata Sandi Buka Kunci',
    pwdFormatErr: 'Kesalahan format kata sandi',
    sendMessageTo: 'Kode verifikasi SMS akan dikirim ke',
    captchaPlaceholder: 'Harap masukkan kode verifikasi',
    sendCaptcha: 'Kirim kode verifikasi',
    sendSeccess: 'SMS berhasil dikirim',
    sendFail: 'SMS gagal, coba lagi nanti',
    updateSeccess: 'Pengaturan kata sandi berhasil',
    editSuccess: 'Modifikasi kata sandi berhasil',
    lockSeccess: 'Tidak terkunci dengan sukses',
    lock: 'Membuka kunci',
    lockSeccessTip: 'Terkunci dengan sukses',
    inputErrLimit:
      'Jumlah kesalahan saat ini telah mencapai batas, silakan atur ulang kata sandi buka kunci!',
  },
  dock56Drill: {
    equipType: 'Jenis peralatan',
    equipment: 'peralatan',
    fiveDock: 'Berlian lima sisi',
    sixDock: 'Berlian enam sisi',
    fileType: 'Menghasilkan jenis file',
    templateOffline: 'Template yang digunakan telah offline!',
    showExample: 'Tampilkan sampel data',
    conNoticeTip: 'Tindakan Pencegahan Docking:',
    conNoticeTip1:
      '1. Mesin ukiran dengan "XY Axis Swap" yang diaktifkan perlu menggunakan templat khusus bor lima sisi dengan pertukaran depan dan belakang',
    conNoticeTip2:
      '2. Untuk menampilkan atau memproses bagian berbentuk pelat pada latihan lima dan enam sisi, data bagian yang dibentuk perlu dikonfigurasi dalam templat.',
    conNoticeTip3:
      '3. Setelah menggunakan bor lima dan enam untuk pertama kalinya atau mengganti templat, silakan <span style = "Color:#f00"> Untuk produksi uji coba kabinet </span>, dan kemudian melakukan produksi reguler setelah mengkonfirmasi bahwa itu benar.',
    conNoticeTip4:
      '4. Selama produksi percobaan, silakan periksa apakah ukuran, kedalaman, permukaan, dan lokasi slot wajah depan dan belakang benar, dan hindari masalah mirroring kabinet.',
    example: 'Sampel data',
    commonSetting: 'Pengaturan Parameter Umum',
    throughHoleSetting: 'Lubang melalui dibuka di kedua sisi',
    throughSlotSetting: 'Buka slot di kedua sisi',
    nonHolesNotFiles:
      'Pelat persegi panjang tidak memiliki lubang dan tidak ada file output:',
    frontFileIdentifier: 'Identifikasi file depan:',
    oppositeFileIdentifier: 'Pengidentifikasi File Terbalik:',
    oppositeFileIdentifierTips:
      'Metode pembuatan adalah: Kode papan 13-bit asli + karakter identifikasi',
    arcDescription: 'Metode deskripsi busur',
    linePoints: 'Deskripsi cetakan segmen garis',
    arcCommand: 'Deskripsi instruksi busur',
    slotWidthSplit:
      'Alur lebar dibongkar menjadi beberapa alur sempit untuk diproses:',
    slotSplitWidth: 'Lebar slot tunggal setelah pembongkaran:',
    mm: 'CATATAN: Objek yang dibongkar adalah slot persegi panjang normal',
    isNotMergeSlot:
      'Proses penarik lebaran dan kedalaman yang sama berdekatan:',
    slotPunchTip:
      'Fitur ini hanya berfungsi untuk slot panel belakang dan slot panel bawah laci menggunakan templat khusus',
    sizeRange: 'Ukuran yang berdekatan kurang dari atau sama dengan',
    enableWorningTip:
      'Fungsi ini tidak dapat dihidupkan pada saat yang sama dengan arah penempatan tepi depan',
    longDirection: 'Arah penempatan sisi yang panjang',
    horizontal: 'Horisontal',
    vertical: 'Vertikal',
    slotDirection:
      'Lima/enam sisi pengeboran atau arah penempatan berbentuk khusus',
    default: 'Default Sistem',
    frontLeft: 'Depan dan kiri',
    frontRight: 'Depan dan kanan',
    backLeft: 'Kembali dan kiri',
    backRight: 'Kembali dan kanan',
    frontDirection: 'Arah penempatan tepi depan',
    enableTip: 'Catatan: Hanya efektif untuk pengaturan huruf cloud',
    fixedOn: 'Diperbaiki di:',
    front: 'Sebelum',
    back: 'di belakang',
    left: 'kiri',
    right: 'Kanan',
    coordinateValue: 'Jumlah digit yang ditahan dari nilai koordinat:',
    decimalPlaces: 'Sedikit',
    specialParameter: 'Pengaturan Parameter Khusus',
    output5FDrillSlotKnifeCode: 'XML2 Slot Milling Alat Nomor',
    output5FDrillShapeKnifeCode: 'Nomor alat penggilingan xml2',
    plankTexture: 'Tekstur papan XML2',
    offset: 'Kompensasi Alat Penggilingan XML2',
    mprConHoleDir:
      'Lima/Enam Sisi Pengeboran Sisi File MPR Wajah Z Koordinat Permukaan Referensi',
    plankTop: 'Permukaan atas pelat',
    plankBottom: 'Permukaan pelat bawah',
    codeInfo: 'File format larangan berisi informasi kode:',
    bomFile: 'File format BOM output:',
    plankHeight: 'Deskripsi Tinggi Piring:',
    plankNum: 'Nomor Dewan Deskripsi:',
    backZpoint: 'Reverse Groove Z Koordinat:',
    plankThick: 'Ketebalan',
    slotDeep: 'Kedalaman alur',
    outputMprFile: 'Jenis file',
    oldMPR: 'Versi lama MPR',
    newMPR: 'Versi baru MPR',
    mprFileZside:
      'Lima/Enam Sisi Pengeboran Sisi File MPR Wajah Z Koordinat Permukaan Referensi',
    fileTypeOffline:
      'Jenis file bor lima dan enam sisi yang saat ini dipilih telah offline, harap konfirmasi!',
    permissionErr:
      'Informasi identitas telah hilang, buka halaman ini lagi dari kabinet!',
    banFileSetting: 'Pengaturan file format larangan',
    banFileCodeInfo: 'File format larangan berisi informasi kode:',
    huahuaLine: 'Birch Automatic Line',
  },
  PTP: {
    title: 'PTP',
    outputFileFormat: 'Format file output',
    outputDefaultFileFormat: 'File format default output',
    outputMPRFormat: 'File format MPR output',
    fileFormatSettings: 'Pengaturan Format File',
    wrapSettings: 'Pengaturan Format Line Break:',
    enCodeSettings: 'Pengaturan Format Pengkodean File:',
    utf8bom: 'UTF-8 dengan bom',
    partCoordinate: 'Koordinat papan kecil',
    xPointSymbol: 'Koordinat horizontal:',
    yPointSymbol: 'Koordinat vertikal:',
    zPointSymbol: 'Koordinat pengangkat:',
    upRight: 'Nilai positif',
    negative: 'Nilai negatif',
    plankMirroring: 'Cermin papan',
    plankMirrorSetting: 'Pengaturan Mirroring Papan:',
    xAxis: 'Mirroring X-Axis',
    yAxis: 'Cermin sumbu y',
    mirrorTip: '(Fungsi ini digunakan saat mencerminkan papan)',
    knifeTable: {
      type: 'jenis',
      diameter: 'Diameter φ',
      offsetX: 'Offset x',
      offsetY: 'Mengimbangi y',
      offsetZ: 'Offset Z.',
      offsetNo: 'Nomor offset',
      drillVelocity: 'Memotong [f] mm/mnt',
      cutVelocity: 'Memproses [f] mm/mnt',
      rotationSeep: 'Kecepatan R/Min',
      insertRow: 'Masukkan baris',
      deleteRow: 'Hapus baris',
    },
    labelPool: 'Kumpulan tag data',
    dragTip:
      'Anda dapat menyeret atau mengklik untuk menyalin tag di kumpulan tag ke kotak input',
    procedure: 'program',
    beginCode: 'Kode Mulai Program:',
    endCode: 'Kode Akhir Program:',
    zzdKnifeBeginCode: 'SPINDLE TOOD Ubah Kode Mulai:',
    spindleCutter: 'Alat spindel',
    zzdKnifeEndCode: 'Alat spindle mengubah kode akhir:',
    verticalDrill: 'Bor vertikal',
    czzBeginCode: 'Kode startup bor vertikal:',
    czzEndCode: 'Kode ujung bor vertikal:',
    czzKnifeBeginCode: 'Alat bor vertikal Ubah Kode Mulai:',
    czzKnifeEndCode: 'Alat bor vertikal Ubah kode akhir:',
    sidetrack: 'Bor samping',
    czBeginCode: 'Kode startup bor samping:',
    czEndCode: 'Kode akhir pengeboran samping:',
    czKnifeBeginCode: 'Perubahan Alat Bor Samping Kode Mulai:',
    czKnifeEndCode: 'Alat bor samping Ubah Kode Akhir:',
    pauseCode: 'Kode jeda:',
    zSafeHeight: 'Jarak yang aman',
    spindleKnife: 'Alat spindle dan bor vertikal ketinggian keamanan sumbu z',
    sideDrillZ: 'Pengeboran samping ketinggian keamanan sumbu z',
    sideDrillXY: 'Pengeboran samping X/Y Jarak Keselamatan Sumbu',
    auxiliaryFunction: 'Fungsi tambahan',
    xhDedicatedInfo: 'Hasilkan Informasi Khusus untuk Peralatan Starlight',
    xhCheckBox: 'data:',
    outputSettings: 'Pengaturan Output:',
    partExegesis: 'Menghasilkan komentar ukuran papan kecil dalam file',
    knifeNo: 'Nomor Alat',
    specailKnifeNo: 'Memproses Nomor Alat Berbentuk Khusus:',
    slotKnifeNo: 'Nomor Alat Grooving:',
    fileNamePlaceholder: 'Kustomisasi Bill of Material',
    slotDrill: 'Pisau pelurus',
  },
  glassEquip: {
    title: 'Mesin pemotong kaca',
    fileType: 'Memproses Jenis File:',
    sizeReparation: 'Kompensasi Ukuran Plat:',
    cutPreSpace: 'Jarak yang dipesan untuk cutting piring:',
    divideIntoFolders:
      'Mengklasifikasikannya menjadi folder yang berbeda sesuai dengan warna, bahan, dan ketebalan kaca:',
  },
  translate: {
    search: 'Harap masukkan terjemahan Cina atau resmi untuk menemukan entri',
    en: 'Bahasa inggris',
    kz: 'Kazakh',
    vi: 'Vietnam',
    th: 'Bahasa Thailand',
    id: 'Indonesian',
    ug: 'Bahasa Uyghur',
    customTranslate: 'Klik untuk menambahkan terjemahan khusus',
    customText: 'Terjemahan khusus',
    text: 'Cina',
    targetLang: 'Terjemahan resmi',
  },
  preLayoutSetting: {
    title: 'Pengaturan pra-tipe',
    titleTip:
      'Anda dapat mengatur parameter tata letak terlebih dahulu, ketik terlebih dahulu, dan kemudian pilih jalur produksi.',
    settingTip:
      'Parameter yang diatur dalam jalur produksi harus konsisten dengan parameter pra-tipe berikut sebelum mengeluarkan file produksi, dan hanya akan efektif untuk mesin pemotong',
    emptyKnife: 'Alat tidak diisi',
    noModify: 'Pengaturan tidak dimodifikasi dan tidak perlu menyimpannya',
    reSet: 'pemulihan',
    gayPosition: 'Kotak abu -abu dapat mengganti penempatan bahan residu',
    surplusPosition: 'Penempatan bahan residu saat ini',
    click: 'Klik',
    grayStart: 'Kotak abu -abu dapat mengganti titik awal stasiun',
    currentStart: 'Ini adalah titik awal dari stasiun saat ini',
    holeSlotSetting: 'Pengaturan pemrosesan slot pelurus dan lubang',
    knifeDiameter: 'Diameter alat pemotongan',
    currentVersion: 'Versi saat ini',
    on: 'Membuka',
    off: 'penutup',
    firstShort: 'Volume tepi tiang pangkas pertama',
    firstLong: 'Beban samping cut-length pertama',
    cuttingSplitLong:
      'Piring panjang dipotong dalam dua kali - sisi panjang melebihi',
    cuttingSplitShort:
      'Piring panjang dipotong dalam dua kali - sisi pendek melebihi',
    plankThick: 'Ketebalan piring biasa ≥',
    normalPlank: 'Dewan reguler',
    confilictTip: 'Ada konflik dalam pengaturan: {confilicttotal}',
    selectLine: 'Pilih Jalur Produksi',
    selectLinePlaceholder: 'Pilih jalur produksi',
    lineConfilictTip: 'Ada konflik antara memilih jalur produksi dan {line}',
    preWay: 'Solusi pra-typesetting',
    paibanErrTip:
      'Ketika ada perubahan dalam pemrosesan papan panjang [tepi panjang dan pendek meningkatkan volume], itu dapat menyebabkan kegagalan tata letak.',
    paibanTip:
      'Tata letak ini memiliki pemotong yang ditunjuk untuk bahan pelat besar, sehingga perlu diketik ulang',
    reLayout: 'Diatur ulang dengan jalur produksi saat ini',
    fetchErr: 'Gagal mendapatkan pengaturan pra-tipe',
    resetSuccess: 'Kembalikan dengan sukses',
    resetConfirm: 'Konfirmasi untuk memulihkan ke versi historis?',
    prolineName: 'Nama Jalur Produksi',
    layoutGap: 'Kesenjangan pengaturan huruf',
    generalThickness: 'Ketebalan pelat objek pemotongan sekunder',
    cutGeneralPlank: 'Papan Pengaturan Objek Pemotongan Sekunder',
    cutSingleDoor: 'Panel pintu objek pemotongan sekunder',
    changeProline: 'Beralih Jalur Produksi',
    backTip: 'Harap kembali ke jalur produksi seleksi tata letak global',
    preLayout: 'Ketik sesuai dengan skema tata letak pra-tipe',
    preWayEmpty: '(Kosong) Skema Pra-Jenis',
    specialConfilictTip:
      'Proses penggilingan bawah busur mempengaruhi hasil tata letak dan perlu diketik ulang',
  },
  lunbo: {
    tips: 'Tip Operasi',
    introduction: 'Pendahuluan Fungsional',
    qtDownloadText:
      'Warm Reminder: Jika ada file unduhan yang tidak lengkap, silakan buka halaman web Cloud Layout untuk diunduh!',
  },
}
