import { getCustomTranslation } from '@/apis/translateApis'
import en from '@/data/i18n/json/en'
import id from '@/data/i18n/json/id'
import kz from '@/data/i18n/json/kz'
import th from '@/data/i18n/json/th'
import ug from '@/data/i18n/json/ug'
import vi from '@/data/i18n/json/vi'
import zh from '@/data/i18n/json/zh.ts'
import store from '@/store'

const langMap = {
  vi,
  kz,
  en,
  zh,
  th,
  id,
  ug,
}

export type ResponseLangType = {
  lang_key: string
  map_lang: string
  zh: string
}
export type LangType = keyof typeof langMap
export type LangTypeList = { name: string; value: LangType }[]
export type CustomLangType = {
  key: string
  text: string
  targetLang: string
  customText: string
}

export function flatObject(
  obj: any,
  result: { [key: string]: string } = {},
  resultKey = ''
) {
  for (const key in obj) {
    const reKey = (resultKey ? `${resultKey}.` : resultKey) + key
    if (typeof obj[key] === 'object') {
      flatObject(obj[key], result, reKey)
    } else {
      result[reKey] = obj[key]
    }
  }
  return result
}

export async function dealLangJSONToTableData(lang: LangType = 'en') {
  const result = []
  const zhObj = flatObject(zh)
  const targetLangObj = flatObject(langMap[lang])
  const { originalData } = await getDealCustomTranslation(lang)

  // const obj = {
  //   'common.select':'选择',
  //   'main.select':'选择'
  // }
  // a[obj['common.select']]
  // a[obj['main.select']]
  // const a = {
  //   '选择':'sss',
  // }
  for (const key in zhObj) {
    const obj: CustomLangType = {
      key: key,
      text: zhObj[key],
      targetLang: targetLangObj[key] ?? '',
      customText: originalData.find((i) => i.lang_key == key)?.map_lang ?? '',
    }
    result.push(obj)
  }
  return result
}

export async function getDealCustomTranslation(lang: LangType) {
  const { data: res } = await getCustomTranslation(lang)
  /** 存在于数据库的翻译数据 */
  const originalData = (res?.language_data || []) as ResponseLangType[]
  /** 处理用于展示的翻译数据 */
  const customerTranslateData: { [key: string]: string } = {}
  originalData.forEach((item) => {
    Object.assign(customerTranslateData, {
      [item.zh]: item.map_lang,
    })
  })

  store.commit('setCustomerTranslateData', customerTranslateData)

  return { customerTranslateData, originalData }
}
