// index.ts
import enJson from './json/en'
import idJson from './json/id'
import kzJson from './json/kz'
import thJson from './json/th'
import uhJson from './json/ug'
import viJson from './json/vi'
import zhJson from './json/zh'

export const messages = {
  zh: zhJson,
  en: enJson,
  vi: viJson,
  kz: kzJson,
  th: thJson,
  id: idJson,
  ug: uhJson,
}
