import { nanoid } from 'nanoid'

import store from '../store'
import libCurve from './Curve'
import { uniquePlankNum } from './commonFuncs'

export function dealThinkerxMaterialData(special_data) {
  let arr = []
  let extraInfoKeys = []
  for (let i = 0; i < special_data.length; i++) {
    if (i == 0) {
      for (let key in special_data[i].remark_names) {
        extraInfoKeys.push({
          label: special_data[i].remark_names[key],
          value: key,
          text: special_data[i].remark_names[key],
        })
      }
      store.commit('setThinkerxMaterialKeys', extraInfoKeys)
    }
    let plank = special_data[i]

    plank.thick = Number(plank.thick)
    plank.customer_name = plank.customer_name
    plank.customer = plank.customer
    plank.edgeInfo = `←${plank.leftEdge ?? 0}↓${plank.frontEdge ?? 0}→${
      plank.rightEdge ?? 0
    }↑${plank.backEdge ?? 0}`
    // 处理板件数量
    plank.amount = Number(Number(plank.quantity).toFixed(0))
    // 处理板件id,  暂无
    plank.plankID = i + 1
    // 处理板件名称
    plank.partName = plank.wardrobeName
      ? plank.wardrobeName + '_' + plank.name
      : plank.name
    // plankNum
    if (plank.code) {
      plank.plankNum = plank.code
      plank.oriPlankNum = plank.code
    } else {
      const plankNum = uniquePlankNum.createUniquePlankNum()
      plank.plankNum = plankNum
      plank.oriPlankNum = plankNum
    }
    /** 左侧偏移量，默认为左封边 */
    let leftOffset = plank.leftEdge || 0
    /** 下侧偏移量，默认为下封边 */
    let bottomOffset = plank.frontEdge || 0
    // 没有 path，但是有 shape的异形板
    // shape 为没带封边的值
    if (plank.shape && plank.shape.length) {
      if (plank.path && plank.path.length) {
        plank.path = [plank.path]
        plank.oPath = [plank.oPath]
      } else {
        const path = dealPathCurve(plank.shape)
        plank.path = [path]
        plank.oPath = [path]
      }
    } else if (plank.curveHoles) {
      plank.oPath = [
        [
          { x: 0, y: 0 },
          { x: plank.width, y: 0 },
          { x: plank.width, y: plank.height },
          { x: 0, y: plank.height },
        ],
      ]
      plank.path = [
        [
          { x: 0, y: 0 },
          { x: plank.width - plank.leftEdge - plank.rightEdge, y: 0 },
          {
            x: plank.width - plank.leftEdge - plank.rightEdge,
            y: plank.height - plank.frontEdge - plank.backEdge,
          },
          { x: 0, y: plank.height - plank.frontEdge - plank.backEdge },
        ],
      ]
    }

    // 根据 path 处理异形相关数据
    if (plank.path) {
      const { min: oMin, max: oMax } = getPathMinMax(plank.oPath[0])
      plank.width = oMax.x - oMin.x
      plank.height = oMax.y - oMin.y

      const { min, max } = getPathMinMax(plank.path[0])
      const lOffset = Math.abs(min.x - oMin.x)
      const bOffset = Math.abs(min.y - oMin.y)
      leftOffset = lOffset
      bottomOffset = bOffset

      plank.path.forEach((path) => {
        path.forEach((point) => {
          point.x -= lOffset
          point.y -= bOffset
        })
      })
      // 处理 rect 尺寸
      plank.rect = {
        x: 0,
        y: 0,
        width: max.x - min.x,
        height: max.y - min.y,
      }
      plank.realRect = {
        x: 0,
        y: 0,
        width: plank.rect.width,
        height: plank.rect.height,
      }

      // 处理异形孔及内部异形偏移
      plank.curveHoles = plank.curveHoles ?? []
      /** 带 b 值 的原始异形孔数据（成品尺寸） */
      const oriCurveHoles = JSON.parse(JSON.stringify(plank.curveHoles))
      /** 不带b值，成品尺寸下的curveHoles */
      plank.oriCurveHoles = oriCurveHoles.map((curveHole) => {
        curveHole.path = dealPathCurve(curveHole.path)
        const through = curveHole.deep >= plank.thick - 0.01
        return {
          ...curveHole,
          through,
          disType: 'curve',
          deep: hole.depth,
          side: hole.face == '0' ? 1 : -1,
        }
      })
      /** 不带b值，不带封边尺寸下的curveHoles */
      plank.curveHoles = plank.oriCurveHoles.map((curveHole) => {
        // 处理封边偏移
        const path = curveHole.path.map((point) => {
          return {
            x: point.x - lOffset,
            y: point.y - bOffset,
          }
        })
        return { ...curveHole, path }
      })
      /** 带b 值的异形外轮廓 （带封边）*/
      plank.realCurve = plank.shape.map((point) => {
        return {
          ...point,
          b: -point.b,
        }
      })
      /** 带b 值的内部异形孔 path 集合 （带封边）*/
      plank.realCurveEx = oriCurveHoles.map((curveHole) =>
        curveHole.path.map((point) => ({ ...point, b: -point.b }))
      )
    }

    // 处理板件尺寸相关数据
    plank.rect = plank.rect ?? {
      x: 0,
      y: 0,
      width: plank.width - plank.leftEdge - plank.rightEdge,
      height: plank.height - plank.frontEdge - plank.backEdge,
    }
    plank.oRect = {
      x: 0,
      y: 0,
      width: Number(plank.width),
      height: Number(plank.height),
    }
    plank.oldSize = {
      width: plank.width,
      height: plank.height,
    }
    // -----------

    // 临时处理 柜门板件纹理为横纹时，将板件的宽高对调
    // if (plank.grain == 1) {
    //   ;[plank.width, plank.height] = [plank.height, plank.width]
    // }

    // 板件成品尺寸
    plank.fullSize = {
      width: plank.width,
      height: plank.height,
    }
    plank.realRect = {
      x: 0,
      y: 0,
      width: plank.rect.width,
      height: plank.rect.height,
    }

    plank.sholes = dealPlankSHoleData(plank)
    plank.sslots = dealPlankSSlotData(plank)

    // 处理孔槽信息
    if (plank.holes && plank.holes.length > 0) {
      plank.holes = plank.holes.filter((v) => v.face == 0 || v.face == 5)
      for (let k = 0; k < plank.holes.length; k++) {
        let hole = plank.holes[k]
        hole.ocenter = {
          x: hole.x,
          y: hole.y,
        }
        hole.center = {
          x: hole.x - leftOffset,
          y: hole.y - bottomOffset,
        }
        // y 值进行上下颠倒，左下角切到左上角圆点
        hole.center.y = plank.rect.height - hole.center.y
        hole.ocenter.y = plank.oRect.height - hole.ocenter.y
        // 深度处理
        hole.deep = hole.depth
        hole.side = hole.face == 0 ? 1 : -1
        // plank.holes[i].
        hole.holeType = 'hole'
        hole.uniqueId = nanoid()
      }
    }
    // 处理拉槽数据
    if (plank.slots && plank.slots.length > 0) {
      plank.slots.filter((v) => v.face == 0 || v.face == 5)
      for (let k = 0; k < plank.slots.length; k++) {
        let slot = plank.slots[k]
        const w = Math.abs(slot.x1 - slot.x2)
        const h = Math.abs(slot.y1 - slot.y2)
        slot.d = Math.min(w, h)
        slot.width = slot.d
        const [minX, maxX] = [slot.x1, slot.x2].sort((a, b) => a - b)
        const [minY, maxY] = [slot.y1, slot.y2].sort((a, b) => a - b)
        // 竖拉槽
        if (w < h) {
          slot.opt1 = {
            x: minX + slot.d / 2,
            y: minY,
          }
          slot.opt2 = {
            x: maxX - slot.d / 2,
            y: maxY,
          }
          slot.pt1 = {
            x: minX + slot.d / 2 - leftOffset,
            y: minY - bottomOffset,
          }
          slot.pt2 = {
            x: maxX - slot.d / 2 - leftOffset,
            y: maxY - bottomOffset,
          }
        } else {
          // 横拉槽
          slot.opt1 = {
            x: minX,
            y: minY + slot.d / 2,
          }
          slot.opt2 = {
            x: maxX,
            y: maxY - slot.d / 2,
          }
          slot.pt1 = {
            x: minX - leftOffset,
            y: minY + slot.d / 2 - bottomOffset,
          }
          slot.pt2 = {
            x: maxX - leftOffset,
            y: maxY - slot.d / 2 - bottomOffset,
          }
        }

        // 柜门跳转拉槽位置需要进行上下颠倒
        slot.pt1.y = plank.rect.height - slot.pt1.y
        slot.pt2.y = plank.rect.height - slot.pt2.y
        slot.opt1.y = plank.oRect.height - slot.opt1.y
        slot.opt2.y = plank.oRect.height - slot.opt2.y
        slot.deep = slot.depth
        slot.side = slot.face == 0 ? 1 : -1
        slot.knifeName = slot.knifeName
        slot.through = Number(slot.depth ?? slot.deep) >= plank.thick - 0.01
        if (!slot.knifeName) delete slot['knifeName']
        slot.uniqueId = nanoid()
      }
    }
    // 统一旋转为false
    plank.matRotatable = false
    switch (plank.grain) {
      case 0:
        plank.texDir = 'normal'
        break
      case 1:
        plank.texDir = 'reverse'
        break
      case 2:
        plank.texDir = 'notcare'
        plank.matRotatable = true
        break
      default:
        plank.texDir = 'normal'
    }
    // 记录板件的成品宽度
    plank.specWidth = plank.width
    // 记录板件的成品长度
    plank.specHeight = plank.height
    // 处理板件孔槽信息
    plank.hsInfo = `${plank.holes.length}孔${plank.slots.length}槽`
    // 处理长圆孔数据(目前只有牛角槽)
    if (plank.curveHoles) {
      plank.curveHoles.forEach((hole) => {
        hole.path = hole.path.map((point) => ({
          ...point,
          x: point.x,
          y: plank.rect.height - point.y,
          b: point.b,
        }))
        const curve = libCurve.newCurve()
        curve.points = hole.path
        const newPath = curve.getPolygon(true) // 处理圆弧数据
        hole.path = newPath
        hole.deep = hole.depth
        hole.side = hole.face == '0' ? 1 : -1
        hole.through = Number(hole.depth ?? hole.deep) >= plank.thick - 0.01
      })
    }
    plank.oriCurveHoles = plank.curveHoles?.map((hole) => {
      return {
        ...hole,
        path: hole.path.map((point) => {
          return {
            ...point,
            x: point.x + plank.leftEdge,
            y: point.y + plank.backEdge,
          }
        }),
      }
    })

    // 处理板件是否有异形
    plank.specialShape = Boolean(plank.shape?.length)

    // 判断板件是否异形，处理异形路径的上下颠倒
    if (plank.path) {
      plank.path.forEach((path) => {
        dealYTopBottomPath(path, plank.rect.height)
      })
      plank.oPath.forEach((path) => {
        dealYTopBottomPath(path, plank.oRect.height)
      })
      plank.curveHoles.forEach((hole) => {
        hole.path.forEach((path) => {
          dealYTopBottomPath(path, plank.rect.height)
        })
      })
      plank.oriCurveHoles.forEach((hole) => {
        hole.path.forEach((path) => {
          dealYTopBottomPath(path, plank.oRect.height)
        })
      })
      plank.realCurve.forEach((point) => {
        point.y = plank.oRect.height - point.y
      })
      plank.realCurveEx.forEach((path) => {
        dealYTopBottomPath(path, plank.oRect.height)
      })
    }

    plank.type = 'SingleDoor'
    // 处理封边信息
    // plank.frontEdge = plank.frontEdge
    // plank.backEdge = plank.backEdge
    // plank.leftEdge = plank.leftEdge
    // plank.rightEdge = plank.rightEdge
    arr.push(plank)
  }
  uniquePlankNum.plankNumCollect.clear()
  return arr
}

export function dealPathCurve(path) {
  const curve = libCurve.newCurve()
  curve.points = path
  const newPath = curve.getPolygon(true)
  return newPath
}

export function getPathMinMax(path) {
  let min = { x: Infinity, y: Infinity }
  let max = { x: 0, y: 0 }
  for (let point of path) {
    min.x = Math.min(min.x, point.x)
    min.y = Math.min(min.y, point.y)
    max.x = Math.max(max.x, point.x)
    max.y = Math.max(max.y, point.y)
  }
  return { min, max }
}

export function dealYTopBottomPath(path, height) {
  path.forEach((point) => {
    point.y = height - point.y
  })
}

/**
 *  华夏数据中的侧孔和侧槽数据都是以孔槽所在侧面的左下角为原点的。side：1,2,3,4 分别代表 左，下，右，上
 *  --- 需要将其转换为以板件右下角为原点的坐标。
 *  --- 特殊点： 侧槽的原始坐标中是左下和右上对角的坐标点， 转换后的坐标是两侧中心的坐标。
 */

// 处理华夏跳转数据的侧孔数据
const dealPlankSHoleData = (plank) => {
  const { width, height, sHoles } = plank

  if (!sHoles) return []

  const result = sHoles.map((hole) => {
    const { x, y, side, diameter, depth, symbol, type } = hole
    // 为每个孔创建新对象，避免引用同一个对象
    const holeItem = {
      center: { y },
      deep: depth,
      diameter,
      holeType: 'sideHole',
      ocenter: {},
      others: null,
      side,
      uniqueId: nanoid(),
      symbol: symbol || '',
      type: type || 'shole',
    }
    // 根据不同的侧面计算坐标
    switch (side) {
      case 1:
        holeItem.center.x = height - x
        holeItem.center.z = height
        holeItem.ocenter.x = 0
        holeItem.ocenter.y = x
        break
      case 2:
        holeItem.center.x = width - x
        holeItem.center.z = width
        holeItem.ocenter.x = x
        holeItem.ocenter.y = height
        break
      case 3:
        holeItem.center.x = height - x
        holeItem.center.z = height
        holeItem.ocenter.x = width
        holeItem.ocenter.y = height - x
        break
      case 4:
        holeItem.center.x = width - x
        holeItem.center.z = width
        holeItem.ocenter.x = width - x
        holeItem.ocenter.y = 0
        break
    }
    return holeItem
  })
  Reflect.deleteProperty(plank, 'sHoles')
  return result
}

// 处理华夏跳转数据的侧拉槽数据
const dealPlankSSlotData = (plank) => {
  const { width, height, sSlots } = plank
  if (!sSlots) return []
  const result = sSlots.map((slot) => {
    const { x1, y1, x2, y2, side, depth, symbol = '' } = slot
    const differenceWidth = Math.abs(y1 - y2)
    const centerY = (y1 + y2) / 2
    // 为每个槽创建新的对象
    const slotItem = {
      deep: depth,
      opt1: {},
      opt2: {},
      others: null,
      pt1: {},
      pt2: {},
      side,
      symbol,
      type: 'sslot',
      uniqueId: nanoid(),
      width: differenceWidth,
    }

    // 根据不同的侧面计算坐标
    switch (side) {
      case 1:
        slotItem.pt1 = { x: height - x1, y: centerY, z: height }
        slotItem.pt2 = { x: height - x2, y: centerY, z: height }
        slotItem.opt1 = { x: 0, y: x1 }
        slotItem.opt2 = { x: 0, y: x2 }
        break
      case 2:
        slotItem.pt1 = { x: width - x1, y: centerY, z: width }
        slotItem.pt2 = { x: width - x2, y: centerY, z: width }
        slotItem.opt1 = { x: x1, y: height }
        slotItem.opt2 = { x: x2, y: height }
        break
      case 3:
        slotItem.pt1 = { x: height - x1, y: centerY, z: height }
        slotItem.pt2 = { x: height - x2, y: centerY, z: height }
        slotItem.opt1 = { x: width, y: height - x1 }
        slotItem.opt2 = { x: width, y: height - x2 }
        break
      case 4:
        slotItem.pt1 = { x: width - x1, y: centerY, z: width }
        slotItem.pt2 = { x: width - x2, y: centerY, z: width }
        slotItem.opt1 = { x: x1, y: 0 }
        slotItem.opt2 = { x: x2, y: 0 }
        break
    }

    return slotItem
  })

  Reflect.deleteProperty(plank, 'sSlots')
  return result
}
