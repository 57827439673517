import { DrawPlankColor } from '@/data/plank'
import { CurveHolesType, HoleType, PartType, Rect, SlotType } from '@/partTypes'
import {
  DrawCurveHoles,
  DrawCutDot,
  DrawCutDotOption,
  DrawCutOrder,
  DrawHoles,
  DrawLittlePlankSize,
  DrawLittlePlankSizeOptions,
  DrawMillInfo,
  DrawSlots,
} from '@/util/canvas/drawPlank'
import { pxTomm } from '@yige/utils'
import PDF from 'jspdf'

import { toDecimal } from './commonFuncs'
import { dealCurveHoles, getThroughPathObj } from './drawPlankFuncs'
import { getSlotBasicInfo } from './plankCommonFuncs'

type PDFType = InstanceType<typeof PDF>
type ConfigType = ConstructorParameters<typeof PDF>
type DrawBackCallType = (pdf: PDFType) => void
type SideHoleObjType = {
  left: PartType['sholes']
  right: PartType['sholes']
  bottom: PartType['sholes']
  top: PartType['sholes']
}
type TableInfoType = {
  date: string
  version: string
  modifiedCount: string
  designer: string
  size: string
  pic: string
  matCode: string
  check: string
  color: string
  count: string
  percent: string
  approval: string
  index: number
  total: number
  orderNo: string
  partName: string
  proCount: string
}
type SlotInfoType = ReturnType<typeof getSlotBasicInfo>
type RectType = { x1: number; x2: number; y1: number; y2: number }

export class DrawPartView {
  /** 纸张大小 */
  pageWidth: number
  pageHeight: number
  // 板件数据
  part?: PartType
  // 孔的分类编号
  holeOrder = 1
  // 板件尺寸
  partWidth = 0
  partHeight = 0
  // 板件绘制缩放量
  partScale = 0
  // 绘制中心的偏移量
  offsetX = 0
  offsetY = 0
  // PDF对象
  pdf: PDFType
  // 最左边的点X
  leftPointX = 0
  // 最下边的点Y
  bottomPointY = 0
  // 已生成文本框 注意，以左下角为起点
  textRectArr: { x: number; y: number; width: number; height: number }[] = []
  // 构造函数，初始化数据
  constructor(config?: ConfigType) {
    const conf: ConfigType = config ?? ['landscape']
    this.pdf = new PDF(...conf)
    // 字体配置
    this.pdf.setFont('msyh')
    this.pdf.setFontSize(8)
    this.pdf.setTextColor(0)
    // 设置纸张大小
    const { width: pageWidth, height: pageHeight } = this.pdf.internal.pageSize
    this.pageWidth = pageWidth
    this.pageHeight = pageHeight
  }
  // 初始化绘制
  init(part: PartType) {
    this.part = part
    this.holeOrder = 1
    this.textRectArr = []
    if ((this, part)) {
      const { width: partW, height: partH } = part.oRect
      const partHeightScale = ((this.pageHeight - 50) * 0.45) / pxTomm(partH)
      const partWidthScale = (this.pageWidth * 0.45) / pxTomm(partW)
      this.partScale = Math.min(partHeightScale, partWidthScale)
      this.partHeight = pxTomm(partH) * this.partScale
      this.partWidth = pxTomm(partW) * this.partScale

      this.offsetX = (this.pageWidth - this.partWidth) / 1.7
      this.offsetY = (this.pageHeight - this.partHeight) / 3
      this.leftPointX = this.offsetX
      this.bottomPointY = this.offsetY + this.partHeight
      this.pdf.setTextColor('#000000')
      this.pdf.rect(5, 5, this.pageWidth - 10, this.pageHeight - 10)
    }
  }

  /** 绘制正面视图
   *
   * @param drawBackCall 额外回调
   */
  private drawFrontView(part: PartType, drawBackCall?: DrawBackCallType) {
    this.leftPointX = this.offsetX
    this.bottomPointY = this.offsetY + this.partHeight
    this.drawSlots(this.offsetX, this.offsetY, part.slots)
    this.drawHoles(this.offsetX, this.offsetY, part.holes)
    if (part.oPath && part.curveHoles) {
      part.curveHoles.forEach((hole) => {
        this.drawPath(hole.path)
        // TODO 长圆孔标记
      })
    }
    if (part.sholes) {
      part.sholes.forEach((shole) => {
        const startX = pxTomm(shole.ocenter.x) * this.partScale + this.offsetX
        const startY = pxTomm(shole.ocenter.y) * this.partScale + this.offsetY
        const deep = pxTomm(shole.deep) * this.partScale
        if ([1, 3].includes(Number(shole.side))) {
          if (startX >= this.partWidth + this.offsetX) {
            this.drawDashLine(startX - deep, startY, deep, 'h')
          } else {
            this.drawDashLine(startX, startY, deep, 'h')
          }
        } else {
          if (startY >= this.partHeight + this.offsetY) {
            this.drawDashLine(startX, startY - deep, deep, 'v')
          } else {
            this.drawDashLine(startX, startY, deep, 'v')
          }
        }
      })
    }
    // 板子轮廓绘制
    if (part.oPath && part.oPath[0].length > 4) {
      const path = part.oPath[0].map((point) => ({
        x: point.x,
        y: point.y,
      }))
      path.push({
        x: part.oPath[0][0].x,
        y: part.oPath[0][0].y,
      })

      this.drawPath(path)
    } else {
      this.pdf.rect(this.offsetX, this.offsetY, this.partWidth, this.partHeight)
    }

    this.dealMarkHoleSlot(
      { holes: part.holes, slots: part.slots },
      { x: this.offsetX, y: this.offsetY + this.partHeight },
      'bottom'
    )
    this.dealMarkHoleSlot(
      { holes: part.holes, slots: part.slots },
      { x: this.offsetX, y: this.offsetY },
      'left'
    )

    // 板件宽度
    this.drawSizeInfo(
      { x: this.offsetX, y: this.offsetY },
      { x: this.offsetX + this.partWidth, y: this.offsetY },
      'top',
      Number(this.part?.oRect.width).toFixed(1)
    )
    // 板件长度
    this.drawSizeInfo(
      { x: this.offsetX + this.partWidth, y: this.offsetY },
      { x: this.offsetX + this.partWidth, y: this.offsetY + this.partHeight },
      'right',
      Number(this.part?.oRect.height).toFixed(1)
    )
    // this.pdf.moveTo(this.offsetX, this.offsetY - 5)
    // this.pdf.lineTo(this.offsetX, this.offsetY - 1)
    // this.pdf.moveTo(this.offsetX + this.partWidth, this.offsetY - 5)
    // this.pdf.lineTo(this.offsetX + this.partWidth, this.offsetY - 1)
    // this.pdf.moveTo(this.offsetX, this.offsetY - 3)
    // this.pdf.lineTo(this.offsetX + this.partWidth, this.offsetY - 3)
    drawBackCall && drawBackCall(this.pdf)
  }

  // 绘制反面面视图
  private drawBackView(part: PartType, drawBackCall?: DrawBackCallType) {
    drawBackCall && drawBackCall(this.pdf)
  }

  // 绘制侧面视图
  private drawSideView(part: PartType, drawBackCall?: DrawBackCallType) {
    const sideMap = new Map([
      [1, 'left'],
      [2, 'bottom'],
      [3, 'right'],
      [4, 'top'],
    ])
    // 按侧孔所在面聚类
    const sideHoleObj: SideHoleObjType = part.sholes.reduce(
      (res: SideHoleObjType, cur) => {
        const dir = sideMap.get(Number(cur.side)) as keyof typeof sideHoleObj
        res[dir].push(cur)
        return res
      },
      {
        left: [],
        top: [],
        right: [],
        bottom: [],
      }
    )
    /** 板件厚度 */
    const thick = pxTomm(Number(part.thick)) * this.partScale

    // 绘制上侧
    if (sideHoleObj.top && sideHoleObj.top.length) {
      this.pdf.rect(this.offsetX, this.offsetY - 20, this.partWidth, thick)
      const tHoles = sideHoleObj.top.map((hole) => ({
        ...hole,
        center: {
          x: hole.center.y,
          y: hole.center.x,
        },
      }))
      const holeObj = tHoles.reduce((res: { [key: string]: HoleType }, cur) => {
        const key = String(cur.center.x)
        if (!res[key]) {
          res[key] = cur
        }
        return res
      }, {})
      this.drawSizeInfo(
        { x: this.offsetX, y: this.offsetY - 20 },
        { x: this.offsetX, y: this.offsetY - 20 + thick },
        'left',
        `${Number(part.thick).toFixed(1)}`
      )

      let idx = 0
      for (const key in holeObj) {
        const baseX = this.offsetX + this.partWidth + 6 * (idx + 1)
        const baseY = this.offsetY - 20
        const hole = holeObj[key]
        this.drawSizeInfo(
          { x: baseX, y: baseY },
          { x: baseX, y: baseY + pxTomm(hole.center.x) * this.partScale },
          'left',
          String(hole.center.x)
        )
        idx++
      }
      this.drawHoles(this.offsetX, this.offsetY - 20, sideHoleObj.top, 'top')
      this.dealMarkHoleSlot(
        {
          holes: sideHoleObj.top.map((hole) => ({
            ...hole,
            center: {
              x: hole.center.y,
              y: hole.center.x,
            },
          })),
        },
        { x: this.offsetX, y: this.offsetY - 30 },
        'bottom'
      )
    }

    // 绘制下侧
    if (sideHoleObj.bottom && sideHoleObj.bottom.length) {
      this.pdf.rect(this.offsetX, this.bottomPointY + 10, this.partWidth, thick)

      const bHoles = sideHoleObj.bottom.map((hole) => ({
        ...hole,
        center: {
          x: part.oRect.width - hole.center.x,
          y: hole.center.y,
        },
      }))
      const holeObj = bHoles.reduce((res: { [key: string]: HoleType }, cur) => {
        const key = String(cur.center.y)
        if (!res[key]) {
          res[key] = cur
        }
        return res
      }, {})

      let idx = 0
      for (const key in holeObj) {
        const baseX = this.offsetX + this.partWidth + 6 * (idx + 1)
        const baseY = this.bottomPointY + 10
        const hole = holeObj[key]
        this.drawSizeInfo(
          { x: baseX, y: baseY },
          { x: baseX, y: baseY + pxTomm(hole.center.y) * this.partScale },
          'left',
          String(hole.center.y)
        )
        idx++
      }
      const bottomHoles = sideHoleObj.bottom.map((hole) => ({
        ...hole,
        center: {
          x: part.oRect.width - hole.center.x,
          y: hole.center.y,
        },
      }))
      this.drawHoles(
        this.offsetX,
        this.bottomPointY + 10,
        bottomHoles,
        'bottom'
      )

      this.dealMarkHoleSlot(
        {
          holes: bHoles,
        },
        { x: this.offsetX, y: this.bottomPointY + 20 },
        'bottom'
      )
    }

    // 绘制左侧
    if (sideHoleObj.left && sideHoleObj.left.length) {
      this.pdf.rect(this.leftPointX - 20, this.offsetY, thick, this.partHeight)
      this.drawSizeInfo(
        { x: this.leftPointX - 20, y: this.offsetY },
        { x: this.leftPointX - 20 + thick, y: this.offsetY },
        'top',
        `${Number(part.thick).toFixed(1)}`
      )
      // 左侧孔需要Y轴颠倒绘制
      const leftHoles = sideHoleObj.left.map((hole) => ({
        ...hole,
        center: {
          x: part.oRect.height - hole.center.x,
          y: hole.center.y,
        },
      }))
      this.drawHoles(this.leftPointX - 20, this.offsetY, leftHoles, 'left')
      this.dealMarkHoleSlot(
        {
          holes: leftHoles.map((hole) => ({
            ...hole,
            center: {
              x: hole.center.y,
              y: hole.center.x,
            },
          })),
        },
        { x: this.leftPointX - 20, y: this.offsetY },
        'left',
        'left'
      )
      this.dealMarkHoleSlot(
        {
          holes: leftHoles.map((hole) => ({
            ...hole,
            center: {
              x: hole.center.y,
              y: hole.center.x,
            },
          })),
        },
        { x: this.leftPointX - 20, y: this.offsetY + this.partHeight },
        'bottom',
        'left'
      )
    }

    //绘制右侧
    if (sideHoleObj.right && sideHoleObj.right.length) {
      this.pdf.rect(
        this.offsetX + this.partWidth + 20,
        this.offsetY,
        thick,
        this.partHeight
      )
      this.drawHoles(
        this.offsetX + this.partWidth + 20,
        this.offsetY,
        sideHoleObj.right,
        'right'
      )
      const sHoles = sideHoleObj.right.map((hole) => ({
        ...hole,
        center: {
          x: hole.center.y,
          y: hole.center.x,
        },
      }))
      this.dealMarkHoleSlot(
        {
          holes: sHoles,
        },
        { x: this.offsetX + this.partWidth + 20, y: this.offsetY },
        'left',
        'right'
      )
      this.dealMarkHoleSlot(
        {
          holes: sHoles,
        },
        {
          x: this.offsetX + this.partWidth + 20,
          y: this.offsetY + this.partHeight,
        },
        'bottom',
        'right'
      )
      this.drawSizeInfo(
        { x: this.offsetX + this.partWidth + 20, y: this.offsetY },
        { x: this.offsetX + this.partWidth + 20 + thick, y: this.offsetY },
        'top',
        `${Number(part.thick).toFixed(1)}`
      )
    }
    drawBackCall && drawBackCall(this.pdf)
  }

  // 绘制三视图导出
  public drawThreeView(part: PartType, drawBackCall?: DrawBackCallType) {
    this.init(part)
    this.drawFrontView(part)

    this.drawSideView(part)
    drawBackCall && drawBackCall(this.pdf)
  }

  /** 绘制板件孔
   * @param startX 板件的起始X
   * @param startY 板件的起始Y
   * @param holes 孔数据
   * @param side 孔槽所在面
   */
  private drawHoles(
    startX: number,
    startY: number,
    holes: HoleType[],
    side?: 'left' | 'right' | 'top' | 'bottom'
  ) {
    const holeObj = holes.reduce((res: { [key: string]: HoleType[] }, cur) => {
      const key = String(cur.deep) + String(cur.diameter) + String(cur.side)
      if (res[key]) {
        res[key].push(cur)
      } else {
        res[key] = [cur]
      }
      return res
    }, {})

    for (const key in holeObj) {
      const minX = Math.min(
        ...holeObj[key].map((hole) => {
          if (side) {
            return ['left', 'right'].includes(side)
              ? hole.center.y
              : hole.center.x
          } else {
            return hole.ocenter.x
          }
        })
      )
      const maxY = Math.max(
        ...holeObj[key]
          .filter((hole) => {
            if (side) {
              return ['left', 'right'].includes(side)
                ? hole.center.y <= minX
                : hole.center.x <= minX
            } else {
              return hole.ocenter.x <= minX
            }
          })
          .map((h) => {
            if (side) {
              return ['left', 'right'].includes(side) ? h.center.x : h.center.y
            } else {
              return h.ocenter.y
            }
          })
      )

      holeObj[key].forEach((hole) => {
        let { x, y } = hole.ocenter
        if (side && ['left', 'right'].includes(side)) {
          x = hole.center.y
          y = hole.center.x
        } else if (side && ['top', 'bottom'].includes(side)) {
          x = hole.center.x
          y = hole.center.y
        }
        this.pdf.setDrawColor(0, 0, 0, 0.5)
        this.pdf.setDrawColor(0, 0.2, 0.2, 0.1)
        this.pdf.circle(
          pxTomm(x) * this.partScale + startX,
          pxTomm(y) * this.partScale + startY,
          (pxTomm(hole.diameter) / 2) * this.partScale,
          'S'
        )

        this.pdf.setDrawColor(0, 0, 0)

        // TODO 孔序号标记大小   王超
        // this.pdf.setFontSize(pxTomm(hole.diameter * this.partScale) * 2)

        const textWidth = this.pdf.getTextWidth(String(this.holeOrder))
        const textHeight = this.pdf.getLineHeightFactor()
        // const textHeight = this.pdf.getLineHeightFactor() * this.partScale

        this.pdf.text(
          String(this.holeOrder),
          pxTomm(x) * this.partScale + startX - textWidth / 2,
          pxTomm(y) * this.partScale + startY + textHeight
        )
        this.pdf.setFontSize(8)
        if (x <= minX && y >= maxY) {
          const text = `数量${holeObj[key].length} 孔径${Number(
            hole.diameter
          ).toFixed(1)} 孔深${Number(hole.deep).toFixed(1)} ${
            !side ? (hole.side > 0 ? '(正)' : '(反)') : ''
          }`
          const width = pxTomm(hole.diameter) * this.partScale

          const offsetPointX = pxTomm(x) * this.partScale + startX
          const offsetPointY = pxTomm(y) * this.partScale + startY - width / 2
          const isOverText = offsetPointY - 4 < this.offsetY

          this.drawHoleSlotInfo(
            text,
            {
              x: offsetPointX,
              y:
                isOverText && !side
                  ? pxTomm(y) * this.partScale + startY + width / 2
                  : offsetPointY,
              height: width,
            },
            side == 'left',
            isOverText && !side ? 'bottom' : 'top',
            !side
          )
        }
      })

      this.holeOrder++
    }
  }

  /** 孔的线段标签
   * @param holes 孔
   * @param startPoint 开始点
   * @param dir 方向
   * @param isSide 是否侧面
   */
  private dealMarkHoleSlot(
    data: {
      holes?: HoleType[]
      slots?: SlotType[]
    },
    startPoint: { x: number; y: number },
    dir: 'left' | 'bottom' | 'right' | 'top',
    sideView?: 'left' | 'bottom' | 'right' | 'top'
  ) {
    /** 是否左右开向 */
    const isLR = ['left', 'right'].includes(dir)
    /** 是否左右视图 */
    const isLRSideView = sideView && ['left', 'right'].includes(sideView)
    // 侧孔绘制使用center中的坐标值
    const holeCenter = (hole: HoleType) =>
      isLRSideView ? hole.center : hole.ocenter
    const xyBaseHoleObj = data.holes?.reduce(
      (res: { [key: string]: HoleType[] }, cur) => {
        const yBase = isLR ? holeCenter(cur).x : holeCenter(cur).y
        if (res[yBase]) {
          res[yBase].push(cur)
        } else {
          res[yBase] = [cur]
        }
        return res
      },
      {}
    )

    let slots: SlotInfoType[] | undefined = data.slots?.map((slot) =>
      getSlotBasicInfo(slot, 'opt')
    )

    const newObj: { [key: string]: string } = {}
    for (const key in xyBaseHoleObj) {
      newObj[key] = xyBaseHoleObj[key]
        .map((hole) =>
          Number((isLR ? holeCenter(hole).y : holeCenter(hole).x).toFixed(2))
        )
        .sort((a, b) => a - b)
        .join(',')
    }
    const tempArr: string[] = []
    for (const key in newObj) {
      if (
        tempArr.includes(newObj[key]) ||
        tempArr.some((str) => str.includes(newObj[key]))
      ) {
        if (xyBaseHoleObj) delete xyBaseHoleObj[key]
      } else {
        tempArr.push(newObj[key])
      }
    }
    const offsetWidth = 6
    let index = 0
    const xyBaseHoleArr: HoleType[][] = []
    for (const key in xyBaseHoleObj) {
      xyBaseHoleArr.push(xyBaseHoleObj[key])
    }
    xyBaseHoleArr.sort((a, b) => a.length - b.length)
    xyBaseHoleArr.forEach((holes) => {
      const baseY = startPoint.y + index * offsetWidth + 2
      const baseX = startPoint.x - index * offsetWidth - 2
      let lastPoint = isLR
        ? { x: baseX, y: startPoint.y }
        : { x: startPoint.x, y: baseY }

      let lastHole: HoleType | undefined
      // 左右标注时，侧孔的center中x,y是绘制的y,x;
      holes
        .sort((h1, h2) =>
          isLR
            ? holeCenter(h1).y - holeCenter(h2).y
            : holeCenter(h1).x - holeCenter(h2).x
        )
        .forEach((hole) => {
          const info = (
            lastHole
              ? Math.abs(
                  isLR
                    ? holeCenter(hole).y - holeCenter(lastHole).y
                    : holeCenter(hole).x - holeCenter(lastHole).x
                )
              : isLR
              ? holeCenter(hole).y
              : holeCenter(hole).x
          ).toFixed(1)
          this.drawSizeInfo(
            lastPoint,
            {
              x: isLR
                ? baseX
                : startPoint.x + pxTomm(holeCenter(hole).x) * this.partScale,
              y: isLR
                ? startPoint.y + pxTomm(holeCenter(hole).y) * this.partScale
                : baseY,
            },
            dir,
            Number(info)
              ? String(
                  Number(info) == parseInt(info)
                    ? parseInt(info)
                    : Number(info).toFixed(1)
                )
              : ''
          )
          lastHole = hole
          lastPoint = {
            x: isLR
              ? baseX
              : startPoint.x + pxTomm(holeCenter(hole).x) * this.partScale,
            y: isLR
              ? startPoint.y + pxTomm(holeCenter(hole).y) * this.partScale
              : baseY,
          }
          if (!sideView) {
            this.leftPointX =
              lastPoint.x < this.leftPointX ? lastPoint.x : this.leftPointX
            this.bottomPointY =
              lastPoint.y > this.bottomPointY ? lastPoint.y : this.bottomPointY
          }
        })
      lastHole = undefined
      index++
    })

    if (slots) {
      // 槽数据去重
      const slotObj = slots.reduce(
        (res: { [key: string]: SlotInfoType }, cur) => {
          const key = isLR ? `${cur.y}L${cur.height}` : `${cur.x}L${cur.width}`
          if (!res[key]) {
            res[key] = cur
          } else {
            res[key]
          }
          return res
        },
        {}
      )
      const slotKeys = Object.keys(slotObj)
      const newSlotsArr: SlotInfoType[] = []
      slotKeys.forEach((key) => {
        newSlotsArr.push(slotObj[key])
      })
      slots = newSlotsArr
      slots
        .sort((s1, s2) => (isLR ? s1.y - s2.y : s1.x - s2.x))
        .forEach((slot, idx) => {
          const baseY = startPoint.y + index * offsetWidth + 2
          const baseX = startPoint.x - index * offsetWidth - 2
          let lastSlot: typeof slot | undefined
          let lastPoint = isLR
            ? { x: baseX, y: startPoint.y }
            : { x: startPoint.x, y: baseY }
          let info = (
            lastSlot
              ? Math.abs(
                  isLR
                    ? slot.y - (lastSlot.y + lastSlot.height)
                    : slot.x - (lastSlot.x + lastSlot.width)
                )
              : isLR
              ? slot.y
              : slot.x
          ).toFixed(2)
          idx > 0 && lastSlot ? (info = '') : info
          const curPoint = {
            x: isLR ? baseX : startPoint.x + pxTomm(slot.x) * this.partScale,
            y: isLR ? startPoint.y + pxTomm(slot.y) * this.partScale : baseY,
          }

          this.drawSizeInfo(
            lastPoint,
            curPoint,
            dir,

            String(
              Number(info) == parseInt(info)
                ? parseInt(info)
                : Number(info).toFixed(1)
            )
          )
          lastPoint = curPoint
          const info2 = Math.abs(isLR ? slot.height : slot.width).toFixed(1)
          const curPoint2 = {
            x: isLR
              ? baseX
              : startPoint.x + pxTomm(slot.x + slot.width) * this.partScale,
            y: isLR
              ? startPoint.y + pxTomm(slot.y + slot.height) * this.partScale
              : baseY,
          }
          this.drawSizeInfo(
            lastPoint,
            curPoint2,
            dir,

            String(
              Number(info2) == parseInt(info2)
                ? parseInt(info2)
                : Number(info2).toFixed(1)
            )
          )
          lastSlot = slot
          lastPoint = curPoint2
          if (!sideView) {
            this.leftPointX =
              lastPoint.x < this.leftPointX ? lastPoint.x : this.leftPointX
            this.bottomPointY =
              lastPoint.y > this.bottomPointY ? lastPoint.y : this.bottomPointY
          }
          lastSlot = undefined
          index++
        })
    }
  }

  /** 绘制槽
   * @param startX 起始点X
   * @param startY 起始点Y
   * @param slots 槽数组数据
   * @param side 所在面 非侧面不传
   */
  private drawSlots(
    startX: number,
    startY: number,
    slots: SlotType[],
    side?: 'left' | 'right' | 'top' | 'bottom'
  ) {
    const newSlotsArr = slots.map((slot) => getSlotBasicInfo(slot, 'opt'))

    const slotObj = newSlotsArr.reduce(
      (res: { [key: string]: typeof newSlotsArr }, cur) => {
        const key =
          Number(cur.slotData.deep).toFixed(1) +
          Number(cur.height).toFixed(1) +
          Number(cur.width).toFixed(1)
        if (res[key]) {
          res[key].push(cur)
        } else {
          res[key] = [cur]
        }
        return res
      },
      {}
    )

    const dealSlotData = (
      slot: SlotInfoType,
      index: number,
      isOverLength: boolean
    ) => {
      this.pdf.rect(
        pxTomm(slot.x) * this.partScale + this.offsetX,
        pxTomm(slot.y) * this.partScale + this.offsetY,
        pxTomm(slot.width) * this.partScale,
        pxTomm(slot.height) * this.partScale
      )

      const centerPoint = {
        x: pxTomm(slot.x + slot.width / 2) * this.partScale + startX,
        y: pxTomm(slot.y + slot.height / 2) * this.partScale + startY,
      }
      this.pdf.setFontSize(8)

      const w = slot.width > slot.height ? slot.height : slot.width
      const deep = slot.slotData.deep
      const text = `槽宽${Number(w).toFixed(1)} 槽深${Number(deep).toFixed(
        1
      )} (${slot.slotData.side > 0 ? '正' : '反'})`
      const height = pxTomm(slot.height) * this.partScale
      const isOverText = centerPoint.y - height / 2 - 4 < this.offsetY

      if (!isOverLength || (isOverLength && index == 0)) {
        this.drawHoleSlotInfo(
          text,
          {
            x: centerPoint.x,
            y:
              isOverText && !side
                ? centerPoint.y + height / 2
                : centerPoint.y - height / 2,
            height,
          },
          side == 'left',
          isOverText && !side ? 'bottom' : 'top',
          !side
        )
      }
    }

    // 暂时没有槽的类别编号
    for (const key in slotObj) {
      slotObj[key].forEach((slot, index) => {
        dealSlotData(slot, index, slotObj[key].length > 9)
        // hasDrawInfo = true
      })

      // order++
    }
  }

  /** 异形path的绘制
   * @param path 路径点位数组
   * @param hasOffset 是否有偏移值
   */
  private drawPath(path: { x: number; y: number }[], hasOffset = true) {
    path.forEach((point, idx) => {
      const scaleX =
        pxTomm(point.x) * this.partScale + (hasOffset ? this.offsetX : 0)
      const scaleY =
        pxTomm(point.y) * this.partScale + (hasOffset ? this.offsetY : 0)
      if (idx) {
        this.pdf.lineTo(scaleX, scaleY)
      } else {
        this.pdf.moveTo(scaleX, scaleY)
      }
    })
  }

  /** 绘制孔槽信息
   * @param text 文本信息
   * @param startPoint 起始点
   * @param isLeftView 是否左视图
   * @param dir 标记方向 向上|向下
   * @param [isMainView=false] 是否主视图
   */
  private drawHoleSlotInfo(
    text: string,
    startPoint: { x: number; y: number; height: number },
    isLeftView = false,
    dir: 'top' | 'bottom' = 'top',
    isMainView = false
  ) {
    const textWidth = this.pdf.getTextWidth(text)
    const textHeight = this.pdf.getLineHeightFactor()
    // 计算矩形框是否交叉
    const areRectanglesIntersecting = (rect1: RectType, rect2: RectType) => {
      return (
        rect1.x1 < rect2.x2 &&
        rect1.x2 > rect2.x1 &&
        rect1.y1 < rect2.y2 &&
        rect1.y2 > rect2.y1
      )
    }

    // 4是拐点偏移量
    if (
      isLeftView ||
      (isMainView &&
        startPoint.x + 4 + textWidth > this.offsetX + this.partWidth)
    ) {
      let offsetY = startPoint.y
      const offsetX = startPoint.x - textWidth
      let isInTextRect = true
      let hasChangeDirStatus = false
      if (this.textRectArr.length) {
        while (isInTextRect) {
          offsetY = dir == 'top' ? offsetY - 4 : offsetY + 4
          isInTextRect = this.textRectArr.some((rect) => {
            return areRectanglesIntersecting(
              {
                x1: offsetX,
                y1: offsetY - textHeight,
                x2: offsetX + textWidth,
                y2: offsetY,
              },
              {
                x1: rect.x,
                y1: rect.y - rect.height,
                x2: rect.x + rect.width,
                y2: rect.y,
              }
            )
          })
          if (
            isMainView &&
            (offsetY < this.offsetY ||
              offsetY > this.offsetY + this.partHeight) &&
            !hasChangeDirStatus
          ) {
            if (dir == 'bottom') {
              dir = 'top'
              offsetY = startPoint.y - startPoint.height
            } else {
              dir = 'bottom'
              offsetY = startPoint.y + startPoint.height
            }
            hasChangeDirStatus = true
            isInTextRect = true
          } else if (hasChangeDirStatus) {
            offsetY = dir == 'top' ? startPoint.y - 4 : startPoint.y + 4
            break
          }
        }
      } else {
        offsetY = dir == 'top' ? offsetY - 4 : offsetY + 4
      }
      this.pdf.line(startPoint.x, startPoint.y, startPoint.x - 4, offsetY)

      this.pdf.line(
        startPoint.x - 4,
        offsetY,
        startPoint.x - 4 - textWidth,
        offsetY
      )
      this.pdf.text(text, startPoint.x - 4 - textWidth, offsetY)
      this.textRectArr.push({
        x: startPoint.x - 4 - textWidth,
        y: offsetY,
        width: textWidth,
        height: textHeight * 1.3, // 1.3保证文字框存在间隙。不会太紧凑
      })
    } else if (!isLeftView) {
      let offsetY = startPoint.y
      const offsetX = startPoint.x + 4
      let isInTextRect = true
      let hasChangeDirStatus = false
      if (this.textRectArr.length) {
        while (isInTextRect) {
          offsetY = dir == 'top' ? offsetY - 4 : offsetY + 4
          isInTextRect = this.textRectArr.some((rect) => {
            return areRectanglesIntersecting(
              {
                x1: offsetX,
                y1: offsetY - textHeight,
                x2: offsetX + textWidth,
                y2: offsetY,
              },
              {
                x1: rect.x,
                y1: rect.y - rect.height,
                x2: rect.x + rect.width,
                y2: rect.y,
              }
            )
          })
          if (
            isMainView &&
            (offsetY < this.offsetY ||
              offsetY > this.offsetY + this.partHeight) &&
            !hasChangeDirStatus
          ) {
            if (dir == 'bottom') {
              dir = 'top'
              offsetY = startPoint.y - startPoint.height
            } else {
              dir = 'bottom'
              offsetY = startPoint.y + startPoint.height
            }
            hasChangeDirStatus = true
            isInTextRect = true
          } else if (hasChangeDirStatus) {
            offsetY = dir == 'top' ? startPoint.y - 4 : startPoint.y + 4
            break
          }
        }
      } else {
        offsetY = dir == 'top' ? offsetY - 4 : offsetY + 4
      }
      if (offsetY > this.offsetY) this.pdf.moveTo(startPoint.x, startPoint.y)
      this.pdf.line(startPoint.x, startPoint.y, offsetX, offsetY)
      this.pdf.line(offsetX, offsetY, offsetX + textWidth, offsetY)
      this.pdf.text(text, offsetX, offsetY)

      this.textRectArr.push({
        x: offsetX,
        y: offsetY,
        width: textWidth,
        height: textHeight * 1.3,
      })
    }
  }

  /** 绘制长线距离描述
   * @param startPoint 线段起始点
   * @param endPoint 线段结束点
   * @param dir 标记方向
   * @param info 标记信息
   */
  private drawSizeInfo(
    startPoint: { x: number; y: number },
    endPoint: { x: number; y: number },
    dir: 'left' | 'right' | 'top' | 'bottom',
    info: string
  ) {
    const { x: startX, y: startY } = startPoint

    const { x: endX, y: endY } = endPoint
    /** 起始线段点1 */
    const sp1 = { x: 0, y: 0 }
    /** 起始线段点2 */
    const sp2 = { x: 0, y: 0 }
    /** 结束线段点1 */
    const ep1 = { x: 0, y: 0 }
    /** 结束线段点2 */
    const ep2 = { x: 0, y: 0 }
    /** 字符串长度 */
    const textWidth = this.pdf.getTextWidth(info)
    const textHeight = this.pdf.getLineHeightFactor()
    /** 标记线段宽度 */
    const markWidth = 3
    switch (dir) {
      case 'left':
        sp1.x = startX - 2
        sp2.x = startX - 2 - markWidth
        sp1.y = sp2.y = startY
        ep1.x = endX - 2
        ep2.x = endX - 2 - markWidth
        ep1.y = ep2.y = endY

        if (Math.abs(startY - endY) - textWidth <= 0) {
          this.pdf.line(
            sp1.x - markWidth / 2,
            sp1.y,
            ep1.x - markWidth / 2,
            ep1.y
          )
          this.pdf.text(info, sp2.x, sp2.y, { angle: 90 })
        } else {
          this.pdf.line(
            sp1.x - markWidth / 2,
            sp1.y,
            ep1.x - markWidth / 2,
            ep1.y
          )
          this.pdf.text(
            info,
            sp2.x,
            (Math.abs(startY - endY) + textWidth) / 2 + startY,
            { angle: 90 }
          )
        }
        break
      case 'right':
        sp1.x = startX + 2
        sp2.x = startX + 2 + markWidth
        sp1.y = sp2.y = startY
        ep1.x = endX + 2
        ep2.x = endX + 2 + markWidth
        ep1.y = ep2.y = endY
        if (Math.abs(startY - endY) - textWidth <= 0) {
          this.pdf.line(
            sp1.x + markWidth / 2,
            sp1.y,
            sp1.x + markWidth / 2,
            sp1.y - textWidth
          )
          this.pdf.line(
            ep1.x + markWidth / 2,
            ep1.y,
            ep1.x + markWidth / 2,
            ep1.y + 2
          )

          this.pdf.text(
            info,
            sp1.x + markWidth / 2,
            (Math.abs(startY - endY) - textWidth) / 2 + startY, // 10为字体大小fontSize,即字体高度
            { angle: 90 }
          )
        } else {
          this.pdf.line(
            sp1.x + markWidth / 2,
            sp1.y,
            ep1.x + markWidth / 2,
            ep1.y
          )
          this.pdf.text(
            info,
            sp2.x + markWidth / 2,
            (Math.abs(startY - endY) + textWidth) / 2 + startY, // 10为字体大小fontSize,即字体高度
            { angle: 90 }
          )
        }
        break
      case 'top':
        sp1.y = startY - 1
        sp2.y = startY - 1 - markWidth
        sp1.x = sp2.x = startX
        ep1.y = endY - 1
        ep2.y = endY - 1 - markWidth
        ep1.x = ep2.x = endX
        if (Math.abs(sp1.x - ep1.x) - textWidth <= 0) {
          this.pdf.moveTo(sp1.x, sp1.y - markWidth / 2)
          this.pdf.lineTo(ep1.x, ep1.y - markWidth / 2)
          this.pdf.moveTo(sp1.x, sp1.y - markWidth / 2)
          this.pdf.lineTo(ep1.x, ep1.y - markWidth / 2)
          this.pdf.text(
            info,
            sp1.x,
            (Math.abs(startY - endY) - textWidth) / 2 + startY - textHeight
          )
        } else {
          this.pdf.moveTo(sp1.x, sp1.y - markWidth / 2)
          this.pdf.lineTo(ep1.x, ep1.y - markWidth / 2)
          this.pdf.text(
            info,
            (Math.abs(sp1.x - ep1.x) - textWidth) / 2 + startX,
            sp1.y - markWidth / 2
          )
        }
        break
      case 'bottom':
        sp1.y = startY + 1
        sp2.y = startY + 1 + markWidth
        sp1.x = sp2.x = startX
        ep1.y = endY + 1
        ep2.y = endY + 1 + markWidth
        ep1.x = ep2.x = endX

        if (Math.abs(sp1.x - ep1.x) - textWidth <= 0) {
          this.pdf.moveTo(sp1.x, sp1.y + markWidth / 2)
          this.pdf.lineTo(ep1.x, ep1.y + markWidth / 2)
          this.pdf.text(
            info,
            sp1.x + markWidth / 2,
            sp1.y // 10为字体大小fontSize,即字体高度
          )
        } else {
          this.pdf.moveTo(sp1.x, sp1.y + markWidth / 2)
          this.pdf.lineTo(ep1.x, ep1.y + markWidth / 2)
          this.pdf.text(
            info,
            (Math.abs(sp1.x - ep1.x) - textWidth) / 2 + startX,
            sp1.y
          )
        }
        break
    }

    this.pdf.line(sp1.x, sp1.y, sp2.x, sp2.y)
    this.pdf.line(ep1.x, ep1.y, ep2.x, ep2.y)
  }

  /** 虚线绘制
   * @param startX 起始点
   * @param startY 结束点
   * @param long 长度
   * @param dir 方向 横向|纵向
   * @param splitL 分段长度
   */
  private drawDashLine(
    startX: number,
    startY: number,
    long: number,
    dir: 'h' | 'v',
    splitL = 1
  ) {
    let index = 0

    while ((index + 1) * splitL < long) {
      if (dir == 'h') {
        const newStartX = startX + index * (splitL + 0.3)
        this.pdf.moveTo(newStartX, startY)
        if (newStartX + splitL > startX + long) {
          this.pdf.lineTo(startX + long, startY)
        } else {
          this.pdf.lineTo(newStartX + splitL, startY)
        }
      } else {
        const newStartY = startY + index * (splitL + 0.3)
        this.pdf.moveTo(startX, newStartY)
        if (newStartY + splitL > startY + long) {
          this.pdf.lineTo(startX, startY + long)
        } else {
          this.pdf.lineTo(startX, newStartY + splitL)
        }
      }
      index++
    }
  }

  // 绘制表格
  drawInfoTable(info: TableInfoType) {
    const tableW = 146
    const tableH = 45
    const startX = this.pageWidth - tableW - 5
    const startY = this.pageHeight - tableH - 5
    this.pdf.setFontSize(10)
    const textHeight = this.pdf.getLineHeightFactor()
    this.pdf.rect(startX, startY, tableW, tableH)
    this.pdf.line(
      this.pageWidth - 40,
      startY,
      this.pageWidth - 40,
      this.pageHeight - 5
    )

    for (let index = 0; index < 4; index++) {
      if (!index) {
        for (let i = 1; i < 4; i++) {
          let text = ''
          switch (i) {
            case 1:
              text = `生效日期: ${info.date}`
              break
            case 2:
              text = `版本号: ${info.version}`
              break
            case 3:
              text = `第 ${info.modifiedCount} 次修改`
              break
            default:
              break
          }
          this.pdf.text(
            text,
            startX + 37 * (i - 1) + 5,
            startY + (9 + textHeight) / 2
          )
          this.pdf.line(startX + 37 * i, startY, startX + 37 * i, startY + 9)
        }
      }
      this.pdf.line(
        startX,
        startY + 9 * (index + 1),
        this.pageWidth - 40,
        startY + 9 * (index + 1)
      )
    }
    this.pdf.text(
      `设计: ${info.designer}`,
      startX + 5,
      startY + 9 + (9 + textHeight) / 2
    )
    this.pdf.text(
      `规格: ${info.size}`,
      startX + 50,
      startY + 9 + (9 + textHeight) / 2
    )
    this.pdf.text(
      `制图: ${info.pic}`,
      startX + 5,
      startY + 18 + (9 + textHeight) / 2
    )
    this.pdf.text(
      `材料: ${info.matCode}`,
      startX + 50,
      startY + 18 + (9 + textHeight) / 2
    )
    this.pdf.text(
      `校对: ${info.check}`,
      startX + 5,
      startY + 27 + (9 + textHeight) / 2
    )
    this.pdf.text(
      `颜色: ${info.color}`,
      startX + 50,
      startY + 27 + (9 + textHeight) / 2
    )
    this.pdf.text(
      `数量: ${info.count}`,
      startX + 85,
      startY + 27 + (9 + textHeight) / 2
    )
    this.pdf.text(
      `审批: ${info.approval}`,
      startX + 5,
      startY + 36 + (9 + textHeight) / 2
    )
    this.pdf.text(
      `比例: ${info.percent}`,
      startX + 50,
      startY + 36 + (9 + textHeight) / 2
    )
    this.pdf.text(
      `共${info.total}张 第${info.index}张`,
      startX + 85,
      startY + 36 + (9 + textHeight) / 2
    )
    this.pdf.line(startX + 80, startY + 27, startX + 80, this.pageHeight - 5)
    this.pdf.line(startX + 45, startY + 9, startX + 45, this.pageHeight - 5)

    for (let idx = 1; idx <= 2; idx++) {
      this.pdf.line(
        this.pageWidth - 40,
        startY + 15 * idx,
        this.pageWidth - 5,
        startY + 15 * idx
      )
    }
    this.pdf.setTextColor('#ff0000')
    const textWidth = this.pdf.getTextWidth(info.orderNo ?? '')
    this.pdf.text(
      info.orderNo ?? '',
      this.pageWidth - (45 + textWidth) / 2,
      startY + (15 - textHeight) / 2
    )

    const textArr = dealPartName(info.partName)
    textArr.forEach((str, i) => {
      const text2Width = this.pdf.getTextWidth(str)
      this.pdf.text(
        str,
        this.pageWidth - (45 + text2Width) / 2,
        startY +
          15 +
          (15 + textHeight) / 2 -
          (textArr.length - 1) * textHeight +
          i * 3 * textHeight
      )
    })

    const text3Width = this.pdf.getTextWidth(String(info.count))
    this.pdf.text(
      String(info.count),
      this.pageWidth - (45 + text3Width) / 2,
      startY + 30 + (15 - textHeight) / 2
    )
  }
  // 保存
  save(fileName: string) {
    this.pdf.save(`${fileName}.pdf`)
  }
}

function dealPartName(str: string) {
  if (str.length <= 10) return [str]
  else {
    const rows = Math.ceil(str.length / 10)

    const strArr: string[] = []
    for (let i = 0; i < rows; i++) {
      strArr.push(str.slice(i * 10, (i + 1) * 10))
    }
    return strArr
  }
}
/** 返回一个板件所需要的绘制类 */
type OtherDraw =
  | 'hole'
  | 'slot'
  | 'curDot'
  | 'cutOrder'
  | 'curveHole'
  | 'millInfo'
  | 'plankSize'
export function plankDrawFactory(
  ctx: CanvasRenderingContext2D,
  part: PartType,
  bigPankStartX: number,
  bigPankStartY: number,
  defaultScale: number,
  scalePercent: number,
  options?: DrawCutDotOption & DrawLittlePlankSizeOptions,
  notDrawInstance: OtherDraw[] = []
) {
  const instances: Partial<Record<OtherDraw, any>> = {}
  if (!notDrawInstance.includes('hole')) {
    instances.hole = new DrawHoles(
      ctx,
      part,
      bigPankStartX,
      bigPankStartY,
      defaultScale,
      scalePercent
    )
  }
  if (!notDrawInstance.includes('slot')) {
    instances.slot = new DrawSlots(
      ctx,
      part,
      bigPankStartX,
      bigPankStartY,
      defaultScale,
      scalePercent
    )
  }

  if (!notDrawInstance.includes('curDot')) {
    instances.curDot = new DrawCutDot(
      ctx,
      part,
      bigPankStartX,
      bigPankStartY,
      defaultScale,
      scalePercent,
      options
    )
  }

  if (!notDrawInstance.includes('cutOrder')) {
    instances.cutOrder = new DrawCutOrder(
      ctx,
      part,
      bigPankStartX,
      bigPankStartY,
      defaultScale,
      scalePercent
    )
  }
  if (!notDrawInstance.includes('curveHole')) {
    instances.curveHole = new DrawCurveHoles(
      ctx,
      part,
      bigPankStartX,
      bigPankStartY,
      defaultScale,
      scalePercent
    )
  }

  if (!notDrawInstance.includes('millInfo')) {
    instances.millInfo = new DrawMillInfo(
      ctx,
      part,
      bigPankStartX,
      bigPankStartY,
      defaultScale,
      scalePercent
    )
  }

  if (!notDrawInstance.includes('plankSize')) {
    instances.plankSize = new DrawLittlePlankSize(
      ctx,
      part,
      bigPankStartX,
      bigPankStartY,
      defaultScale,
      scalePercent,
      options as DrawLittlePlankSizeOptions
    )
  }

  return instances
}
