/** 处理板件数据的文件 */
import { dealHoleSlotShunt } from '@/apis/paiban'
import type { PointType, PointUpperCase } from '@/partTypes'

import { compShapeData, dealAllParts } from './dealPaibanData'

const SCALE = 10000
/**
 * 扩大板件路径
 * @param offset 偏移量 正数路径扩张 负数路径内缩
 * @param path 路径
 * @returns
 */
export function expandPath(offset: number, path: PointUpperCase[]) {
  window.ClipperLib.JS.ScaleUpPath(path, SCALE)
  const co = new window.ClipperLib.ClipperOffset()
  const offsetPaths = new window.ClipperLib.Paths()
  co.Clear()
  co.AddPath(
    path,
    window.ClipperLib.JoinType.jtMiter,
    window.ClipperLib.EndType.etClosedPolygon
  )
  //   执行扩张
  co.Execute(offsetPaths, offset * SCALE)
  const resPath = JSON.parse(JSON.stringify(offsetPaths))
  const result = resPath.map((path: any[]) =>
    path.map((point) => ({
      x: Number((point.X / SCALE).toFixed(3)),
      y: Number((point.Y / SCALE).toFixed(3)),
    }))
  )
  //   返回处理好的路径
  return result
}

/** 根据孔槽集中面设置处理板件孔槽信息 */
export async function dealPlankHoleSlot(partList: any[], ncSetting: any) {
  const newPartList = dealAllParts(partList)
  const shuntPreData = await dealHoleSlotShunt({
    process_line_id: ncSetting.process_line_id ?? ncSetting.process_setting_id,
    planks: newPartList,
  })
  if (shuntPreData.status) {
    const newParts = compShapeData(shuntPreData.data.planks, partList)
    partList.forEach((part) => {
      const newPart = newParts.find(
        (p: any) => p.partUniqueId == part.partUniqueId
      )
      if (newPart) {
        Object.assign(part, newPart)
      }
    })
  }
}
