import store from '@/store'
import { getDeviceXDPI, mmToPx } from '@/util/exportFuncs'

import { dealElementWH } from './pdf-draw'

let CONFIG = {}
let NC_SETTING = {}
const DPI = getDeviceXDPI()
// 映射封边信息合位置关系
const dirDataMap = {
  '←': '左',
  '↓': '下',
  '→': '右',
  '↑': '上',
}
// 真正要展示的位置和数据中的开门方向正好相反
const openDirMap = {
  left: 'right',
  right: 'left',
  top: 'bottom',
  bottom: 'top',
}
const OPENMAPTEXT = {
  left: '左',
  right: '右',
  top: '上',
  bottom: '下',
}
const TRIANGLE_WIDTH = 10
const TRIANGLE_HEIGHT = 7
// 初始化一些数据
export function initDrawData(config, nc) {
  CONFIG = config
  NC_SETTING = nc
}

// 绘制单个板件的孔槽图
export function drawPartHoleSlot(
  canvas,
  part,
  rectL,
  rectT,
  info,
  other,
  jx_checked
) {
  const { left, top, holeF, holeZ, defaultScale, uniqueID } = info
  const { oRect: rect } = part
  // 获取子级
  const auxiliary = other.tempData.filter(
    (item) =>
      item.data &&
      item.data.uniqueID == uniqueID &&
      item.type == 'holeSlotPicture-size'
  )
  // 获取板件是否需要逆时针旋转
  let isRotatePlank
  const typographyKlass = other.tempData.find((it) => it.type === 'Typography')
  if (typographyKlass) {
    isRotatePlank = typographyKlass.data.plankRotate
  }
  // 默认的缩放大小(我一时竟不知道该怎么给这玩意赋值了，就是一个默认值了)
  let pw = mmToPx(info.mWidth, DPI) / rect.width
  let ph = mmToPx(info.mHeight, DPI) / rect.height
  const slotHoleDefaultScale = Math.min(pw, ph) * defaultScale
  // 起始点
  const cutLeft = (left - rectL) * CONFIG.scale
  const cutTop = (top - rectT) * CONFIG.scale
  // 需要显示的孔槽
  let allHole = [],
    allSlot = [],
    allCurveSlot = []
  if (holeZ) {
    allHole = [...allHole, ...part.holes.filter((item) => item.side == 1)]
    allSlot = [...allSlot, ...part.slots.filter((item) => item.side == 1)]
    allCurveSlot = [
      ...allCurveSlot,
      ...(part.curveHoles
        ? part.curveHoles.filter(
            (item) => item.side == 1 && item.disType == 'CCCurveSlot'
          )
        : []),
    ]
  }
  if (holeF) {
    allHole = [...allHole, ...part.holes.filter((item) => item.side == -1)]
    allSlot = [...allSlot, ...part.slots.filter((item) => item.side == -1)]
    allCurveSlot = [
      ...allCurveSlot,
      ...(part.curveHoles
        ? part.curveHoles.filter(
            (item) => item.side == -1 && item.disType == 'CCCurveSlot'
          )
        : []),
    ]
  }
  let mainShape
  let shapes = []
  if (part.path) {
    let curveHoles = []
    if (part.curveHoles) {
      if (jx_checked) {
        part.curveHoles.forEach((item) => {
          item.path.forEach((point) => {
            if (NC_SETTING.xyReverse) {
              point.y = rect.height - point.y
            } else {
              point.x = rect.width - point.x
            }
          })
        })
      }
      curveHoles = part.curveHoles
        .filter((it) => it.deep >= part.thick)
        .map((it) => it.path)
    }
    let millInfos = []
    if (part.millInfo) {
      if (jx_checked) {
        part.millInfo.forEach((item) => {
          item.shape.forEach((point) => {
            if (NC_SETTING.xyReverse) {
              point.y = rect.height - point.y
            } else {
              point.x = rect.width - point.x
            }
          })
        })
      }
      millInfos = part.millInfo
        .filter((it) => it.depth >= part.thick)
        .map((it) => it.shape)
    }
    const resPath = [...part.path, ...curveHoles, ...millInfos]
    resPath.forEach((it, idx) => {
      const pointArr = it.map((p) => {
        return {
          x: p.x * slotHoleDefaultScale * CONFIG.scale,
          y: p.y * slotHoleDefaultScale * CONFIG.scale,
        }
      })
      const shape = new fabric.Polygon(pointArr, {
        stroke: '#000',
        fill: '#fff',
        strokeWidth: 1,
        selectable: false,
      })
      shapes.push(shape)
      if (idx === 0) mainShape = shape
    })
  } else {
    mainShape = new fabric.Rect({
      selectable: false,
      stroke: '#000',
      strokeWidth: 1.5,
      fill: '#fff',
      width: rect.width * slotHoleDefaultScale * CONFIG.scale,
      height: rect.height * slotHoleDefaultScale * CONFIG.scale,
    })
  }
  // 绘制文本
  let textArr = []
  if (auxiliary[0].data.isShow) {
    auxiliary.forEach((klass) => {
      const { positionName, fontSize } = klass.data
      const text = parseFloat(Number(rect[positionName]).toFixed(2)) + ''
      const { offsetWidth, offsetHeight } = dealElementWH(text, fontSize)
      let left, top
      if (positionName == 'width') {
        top =
          (jx_checked && NC_SETTING.xyReverse) || !NC_SETTING.xyReverse
            ? mainShape.height
            : -32
        left = mainShape.width / 2
      } else {
        top = mainShape.height / 2
        left = !(jx_checked && !NC_SETTING.xyReverse) ? mainShape.width : -32
      }
      // 做旋转的位置调整
      if (NC_SETTING.xyReverse && !isRotatePlank) {
        top += mmToPx(offsetWidth / 2, DPI)
        left -= mmToPx(offsetWidth, DPI) - mmToPx(offsetHeight, DPI) - 3
      }
      if (!NC_SETTING.xyReverse && isRotatePlank) {
        top += mmToPx(offsetHeight / 2, DPI)
        left -= mmToPx(offsetWidth / 3, DPI)
      }
      const res = new fabric.Text(text, {
        fontSize: klass.data.fontSize * CONFIG.scale,
        top,
        left,
        selectable: false,
        fontWeight: klass.data.fontWeight,
        lineHeight: klass.data.lineHeight,
        splitByGrapheme: klass.data.splitByGrapheme
          ? klass.data.splitByGrapheme
          : false, // 自动换行
        textAlign: klass.data.textAlign ? klass.data.textAlign : 'left',
        fontFamily: klass.data.fontFamily ?? '微软雅黑',
      })
      if (NC_SETTING.xyReverse || isRotatePlank) res.rotate(90)
      if (NC_SETTING.xyReverse && isRotatePlank) res.rotate(180)
      textArr.push(res)
    })
  }
  // 绘制孔
  let drawHoles = []
  allHole.forEach((item) => {
    const { diameter, ocenter: center } = item
    const x = !(jx_checked && !NC_SETTING.xyReverse)
      ? center.x * slotHoleDefaultScale
      : (rect.width - center.x) * slotHoleDefaultScale
    const y = !(jx_checked && NC_SETTING.xyReverse)
      ? center.y * slotHoleDefaultScale
      : (rect.height - center.y) * slotHoleDefaultScale
    const left = x * CONFIG.scale
    const top = y * CONFIG.scale
    const radius = (diameter / 2) * slotHoleDefaultScale * CONFIG.scale
    const circle = new fabric.Circle({
      radius, //圆形半径
      fill: '#fff', //填充的颜色
      stroke: '#000', // 边框颜色
      strokeWidth: 1, // 边框大小
      originX: 'cneter',
      originY: 'cneter',
      left,
      top,
    })
    drawHoles.push(circle)
  })
  // 绘制槽
  let drawSlots = []
  allSlot.forEach((item) => {
    const { opt1: pt1, opt2: pt2 } = item
    let slot = {}
    // 竖拉槽
    if (pt1.x == pt2.x) {
      slot.width = item.width
      slot.x = Math.min(pt1.x, pt2.x) - item.width / 2
      slot.long = Math.abs(pt1.y - pt2.y)
      slot.y = pt1.y > pt2.y ? pt2.y : pt1.y
    }
    // 横拉槽
    if (pt1.y == pt2.y) {
      slot.width = Math.abs(pt1.x - pt2.x)
      slot.y = Math.min(pt1.y, pt2.y) - item.width / 2
      slot.long = item.width
      slot.x = pt1.x > pt2.x ? pt2.x : pt1.x
    }
    const left =
      !(jx_checked && !NC_SETTING.xyReverse) || pt1.y == pt2.y
        ? slot.x * slotHoleDefaultScale * CONFIG.scale
        : (rect.width - slot.x) * slotHoleDefaultScale * CONFIG.scale
    const top =
      !(jx_checked && NC_SETTING.xyReverse) || pt1.y !== pt2.y
        ? slot.y * slotHoleDefaultScale * CONFIG.scale
        : (rect.height - slot.y) * slotHoleDefaultScale * CONFIG.scale
    const width = slot.width * slotHoleDefaultScale * CONFIG.scale
    const height = slot.long * slotHoleDefaultScale * CONFIG.scale
    const slotRect = new fabric.Rect({
      selectable: false,
      stroke: '#000',
      strokeWidth: 1,
      fill: '#fff',
      width,
      height,
      left,
      top,
    })
    drawSlots.push(slotRect)
  })
  // 绘制异形槽
  const drawCurveSlots = allCurveSlot.map((curve) => {
    const path = curve.path.map((point) => {
      return {
        x: !(jx_checked && !NC_SETTING.xyReverse)
          ? point.x * slotHoleDefaultScale * CONFIG.scale
          : (rect.width - point.x) * slotHoleDefaultScale * CONFIG.scale,
        y: !(jx_checked && NC_SETTING.xyReverse)
          ? point.y * slotHoleDefaultScale * CONFIG.scale
          : (rect.height - point.y) * slotHoleDefaultScale * CONFIG.scale,
      }
    })
    const poly = new fabric.Polygon(path, {
      fill: '#fff', //填充的颜色
      stroke: '#000', // 边框颜色
      strokeWidth: 1, // 边框大小
    })
    return poly
  })
  let groupTop = cutTop
  let groupLeft = cutLeft
  const groupWidth = rect.width * slotHoleDefaultScale * CONFIG.scale
  const groupHeight = rect.height * slotHoleDefaultScale * CONFIG.scale
  if (NC_SETTING.xyReverse) {
    groupTop = cutTop + (groupWidth - groupHeight) / 2
    groupLeft = cutLeft - (groupWidth - groupHeight) / 2
  }
  mainShape = part.path ? shapes : [mainShape]
  const group = new fabric.Group(
    [...mainShape, ...drawHoles, ...drawSlots, ...textArr, ...drawCurveSlots],
    {
      selectable: false,
      left: groupLeft,
      top: groupTop,
      width: groupWidth,
      height: groupHeight,
    }
  )
  // 当xy互换的时候孔槽图逆时针旋转90°
  if (NC_SETTING.xyReverse || isRotatePlank) group.rotate(-90)
  if (NC_SETTING.xyReverse && isRotatePlank) group.rotate(-180)
  canvas.add(group)
}
export function drawTagText(canvas, text, info, other, isDraw = true) {
  let Text = new fabric.Textbox(text, {
    fontSize: info.fontSize * (CONFIG.scale ?? 2),
    selectable: false,
    fontWeight: info.fontWeight,
    lineHeight: info.lineHeight,
    left: info.left,
    top: info.top,
    splitByGrapheme: info.splitByGrapheme ? info.splitByGrapheme : false, // 自动换行
    textAlign: info.textAlign ? info.textAlign : 'left',
    scaleX: info.scaleX ?? 1,
    scaleY: info.scaleY ?? 1,
    fontFamily: info.fontFamily ?? '微软雅黑',
  })
  if (info.width && info.height)
    Text.set({
      width: info.width * (CONFIG.scale ?? 1),
      height: info.height * (CONFIG.scale ?? 1),
    })
  if (isDraw) {
    canvas.add(Text)
  } else {
    return Text
  }
}

export function getCutDirEdge(dir, textArr, xyReverse) {
  let position = ''
  switch (dir) {
    case '左':
      position = xyReverse ? 'BE' : 'LE'
      break
    case '下':
      position = xyReverse ? 'LE' : 'BE'
      break
    case '右':
      position = xyReverse ? 'TE' : 'RE'
      break
    case '上':
      position = xyReverse ? 'RE' : 'TE'
      break
  }
  return textArr.find((it) => it.position == position)
}

// 绘制开门方向
export function drawOpenDoor(textArr, part, info, nc_setting, type) {
  // 控制开门方向在左边的时候 板件图的偏移
  let distance = 0
  let findText = null
  const { xyReverse } = nc_setting
  if (part.doorOpenSide && info.showOpenDoor && type !== 'plank') {
    const doorOpenSide = part.doorOpenSide
    const position = OPENMAPTEXT[doorOpenSide]
    findText = getCutDirEdge(position, textArr)
    let { left, top, width, height } = findText
    // width = width < 20 ? 20 : width
    left = left + width + 10
    top = top + height / 2
    const circle = drawCircle(10, 10, {
      left,
      top,
      canRotate: true,
      openDoor: true,
    })
    const text = drawText('开', top, left, 10, 14, {
      canRotate: true,
      openDoor: true,
    })
    return {
      arr: [circle, text],
      distance,
      findText,
    }
  }
  return {
    arr: [],
    distance,
    findText,
  }
}
// 绘制见光边
export function drawLightSign(textArr, part, nc_setting, type) {
  const { lightEdgeInfo } = part
  if (!lightEdgeInfo) return []
  const { xyReverse } = nc_setting
  let result = []
  if (type !== 'plank') {
    const dir = ['左', '下', '右', '上']
    const disposeDirArr = lightEdgeInfo
      .split(/←|↓|→|↑/)
      .filter((it) => it)
      .map((it, idx) => {
        return { value: it, dir: dir[idx] }
      })
    disposeDirArr.forEach((it) => {
      const { value, dir } = it
      if (+value) {
        const findText = getCutDirEdge(dir, textArr)
        let { left, top, width, height, position } = findText
        width = width < 20 ? 20 : width

        const isSide = position == 'LE' || position == 'RE'
        if (isSide) {
          // 绘制在文字下方
          top += height
          if (position == 'RE') {
            left += width / 2
          } else {
            left -= TRIANGLE_WIDTH + 3
          }
        } else {
          left -= TRIANGLE_WIDTH
          top += TRIANGLE_HEIGHT
        }
        const triangle = new fabric.Triangle({
          top,
          left,
          width: 10,
          height: 7,
          fill: '#000',
        })
        // 在各个方向上箭头都对外
        switch (dir) {
          case '左':
            triangle.rotate(-90)
            break
          case '下':
            triangle.rotate(-180)
            break
          case '右':
            triangle.rotate(90)
            break
          case '上':
            triangle.rotate(0)
        }
        result.push(triangle)
      }
    })
  }
  return result
}
//绘制前封边
export function drawFormerEdge(
  textArr,
  part,
  nc_setting,
  type,
  // textMark,
  configScale,
  jxChecked,
  trimSide
) {
  if (part.model_front_edge && type !== 'plank') {
    const former = part.model_front_edge
    const { xyReverse, startPosition } = nc_setting
    const dir = dirDataMap[former]
    // textArr是拿到的文字的位置
    const findText = getCutDirEdge(dir, textArr)
    let { left, top, width, height } = findText
    if (former == '↓') {
      // 文字的位置加上文字的高度
      top += height
    } else {
      top -= height
    }
    left += width / 2 - 12 / 2

    // 前字 宽12 高13.59
    const text = new fabric.Text('前', {
      left,
      top,
      // 字体大小跟随设置变化，不固定，默认模板还是12px
      fontSize: store.state.frontLabelFont?.fontSize * configScale ?? 12,
      canRotate: true,
      fontFamily: store.state.frontLabelFont?.fontFamily ?? '微软雅黑',
      fontWeight: store.state.frontLabelFont?.fontWeight ?? '',
    })
    return text
  }
}
export function drawCircle(radius, mRadius, other = {}) {
  return new fabric.Circle({
    radius: radius, //圆形半径
    fill: '#fff', //填充的颜色
    stroke: '#000', // 边框颜色
    strokeWidth: 1, // 边框大小
    mRadius,
    originX: 'cneter',
    originY: 'cneter',
    left: radius,
    top: radius,
    ...other,
  })
}
export function drawText(text, top, left, pt, fontSize, other = {}) {
  return new fabric.IText(text + '', {
    fontSize: pt,
    fontPt: fontSize,
    fontWeight: '',
    originX: 'cneter',
    originY: 'cneter',
    left: left,
    top: top,
    ...other,
  })
}

export function handleOpenDoorDistance(klass, info, findText) {
  const openKlass = klass.getObjects().filter((it) => it.openDoor)
  if (!openKlass.length) return
  if (findText && findText.position == 'LE') {
    openKlass.forEach((it) => {
      if (info.plankRotate) {
        it.left = it.left - findText.height - 20
      } else {
        it.left = it.left - findText.width - 20
      }
    })
  }
}

// fabric绘制表格
export function fabricTable(
  canvas,
  isEdit = false,
  tableData,
  fontSize = 16,
  type = 'extraParts',
  top = 10,
  left = 10,
  fontFamily = '微软雅黑'
) {
  const tableTypeObj = {
    extraParts: [['名称', '规格', '数量']],
  }
  const defaultTable = {
    extraParts: [
      ['名称', '规格', '数量'],
      ['款式1', '巨型', '1'],
      ['款式2', '小型', '1'],
    ],
  }
  const d = defaultTable[type]
  let data = tableTypeObj[type]
  if (tableData && tableData.length) {
    data = data.concat(tableData)
  }
  if (isEdit && !tableData) {
    data = d
  }
  let columsText = []
  for (let index = 0; index < data[0].length; index++) {
    columsText.push(data.map((e) => e[index]))
  }
  const columsWidth = columsText.map((e) => {
    const widthArr = e.map((text) => calcTextSize(text, fontSize).width + 15)
    return Math.max(...widthArr)
  })
  const rowHeight = calcTextSize(data[0][0], fontSize).height
  // 表格宽度
  let totalWidth = 0
  columsWidth.forEach((width) => {
    totalWidth += width
  })
  // 表格高度
  let totalHeight = data.length * rowHeight

  let myCanvas = new fabric.StaticCanvas(`myCanvas`, {
    width: totalWidth + 5,
    height: totalHeight + 5,
  })

  for (let i = 0; i < data.length; i++) {
    let leftWidth = isEdit ? 0 : left
    let topHeight = isEdit ? 0 : top
    topHeight += rowHeight * i
    for (let j = 0; j < data[0].length; j++) {
      if (j) {
        leftWidth += columsWidth[j - 1]
      }
      // 创建表格元素
      let cell = new fabric.Rect({
        left: leftWidth,
        top: topHeight,
        fill: '#fff',
        width: columsWidth[j],
        height: rowHeight,
        stroke: '#000',
        strokeWidth: 1,
      })
      let text = new fabric.Text(data[i][j], {
        left:
          leftWidth +
          (columsWidth[j] - calcTextSize(data[i][j], fontSize).width) / 2,
        top: topHeight + 3,
        fontSize: fontSize,
        fontFamily: fontFamily,
      })

      isEdit ? myCanvas.add(cell) : canvas.add(cell)
      isEdit ? myCanvas.add(text) : canvas.add(text)
    }
  }

  let startX = isEdit ? 0 : left
  let startY = isEdit ? 0 : top
  totalWidth = isEdit ? totalWidth : totalWidth + left
  totalHeight = isEdit ? totalHeight : totalHeight + top

  // 表格border
  const border1 = new fabric.Line([startX, startY, totalWidth, startY], {
    stroke: '#000',
    strokeWidth: 1,
  })
  const border2 = new fabric.Line(
    [totalWidth, startY, totalWidth, totalHeight],
    {
      stroke: '#000',
      strokeWidth: 1,
    }
  )
  const border3 = new fabric.Line(
    [startX, totalHeight, totalWidth, totalHeight],
    {
      stroke: '#000',
      strokeWidth: 1,
    }
  )
  const border4 = new fabric.Line([startX, startY, startX, totalHeight], {
    stroke: '#000',
    strokeWidth: 1,
  })

  // 画布上画出border

  if (isEdit) {
    myCanvas.add(border1, border2, border3, border4)
    const imgBase64 = myCanvas.toDataURL('image/png', {
      quality: 1,
      format: 'jpeg',
    })
    return imgBase64
  } else {
    canvas.add(border1, border2, border3, border4)
  }
}

// 计算文字宽度
export function calcTextSize(text, fontSize = 14) {
  let span = document.createElement('span')
  let result = {}
  result.width = span.offsetWidth
  result.height = span.offsetHeight
  span.style.visibility = 'hidden'
  span.style.fontSize = `${fontSize}px`
  span.style.fontFamily = 'PingFangSC-Regular, PingFang SC'
  span.style.display = 'inline-block'
  document.body.appendChild(span)
  if (typeof span.textContent != 'undefined') {
    span.textContent = text
  } else {
    span.innerText = text
  }
  result.width = parseFloat(window.getComputedStyle(span).width) - result.width
  result.height =
    parseFloat(window.getComputedStyle(span).height) - result.height
  document.body.removeChild(span)
  return {
    width: result.width,
    height: result.height,
  }
}

// 标签特殊标记形状
export const signShape = {
  triangle_shape: [
    { x: 0, y: 50 },
    { x: 25, y: 0 },
    { x: 50, y: 50 },
  ],
  square_shape: [
    { x: 0, y: 0 },
    { x: 50, y: 0 },
    { x: 50, y: 50 },
    { x: 0, y: 50 },
  ],
  // hexagon_shape: new Array(6).fill(null).map((_, index) => {
  //   const radius = 25
  //   const angle = (index * Math.PI) / 3
  //   return index
  //     ? { x: radius * Math.cos(angle), y: radius * Math.sin(angle) }
  //     : { x: radius, y: 0 }
  // }),
  hexagon_shape: [
    { x: 0, y: 18.75 },
    { x: 12.5, y: 0 },
    { x: 37.5, y: 0 },
    { x: 50, y: 18.75 },
    { x: 37.5, y: 37.5 },
    { x: 12.5, y: 37.5 },
  ],
}
export function drawSpecialSign(
  ctx,
  sign,
  template,
  opacity = 0.24,
  sizeAuto = true,
  size = 1,
  op
) {
  if (!sign || sign === 'default' || template.type === 'plank') return

  const cusSpecialFlag = op && Reflect.ownKeys(op).length > 0
  const { left, top, custSpeciialTagratioX, custSpeciialTagratioY } = op ?? {}
  if (sizeAuto) {
    size = 1
  }
  const widthBase = ctx.getWidth()
  const heightBase = ctx.getHeight()
  const center = cusSpecialFlag
    ? { x: left, y: top }
    : { x: widthBase / 2, y: heightBase / 2 }
  const diameter = 75
  const ratio = Math.min(widthBase / diameter, heightBase / diameter) * size //返回低值数字
  const scale = 0.92
  switch (sign) {
    case 'triangle_shape':
    case 'square_shape':
    case 'hexagon_shape':
      drawPathShape(sign)
      break
    case 'circle_shape':
      drawCircleShape()
      break
    case 'half_circle_shape':
      drawHalfCircle()
      break
  }
  // 路径可以绘制的形状 三角形/矩形/六边形
  function drawPathShape() {
    const path = signShape[sign].map((it) => ({
      x: it.x * (custSpeciialTagratioX ?? ratio),
      y: it.y * (custSpeciialTagratioY ?? ratio),
    }))
    const polygon = new fabric.Polygon(path, {
      fill: 'transparent',
      stroke: 'black',
      strokeWidth: 2,
      opacity,
    })
    const littlePolygon = new fabric.Polygon(
      path.map((it) => ({
        x: it.x * scale,
        y: it.y * scale,
      })),
      {
        fill: '#000',
        opacity,
      }
    )
    dealCenter(polygon, littlePolygon)
    if (sign === 'triangle_shape') {
      littlePolygon.set({ top: littlePolygon.top + littlePolygon.height / 50 }) //魔法数字(麻了)
    }
    ctx.add(polygon, littlePolygon)
  }
  // 圆形特殊标记(2024-08-02 周驰修改 修改原因：圆也是椭圆)
  function drawCircleShape() {
    const option = {
      // radius: 25 * ratio,
      rx: 25 * (custSpeciialTagratioX ?? ratio),
      ry: 25 * (custSpeciialTagratioY ?? ratio),
      opacity,
      top: center.y,
      left: center.x,
      originX: 'center',
      originY: 'center',
    }
    const circle = new fabric.Ellipse({
      ...option,
      fill: 'transparent',
      stroke: 'black',
      strokeWidth: 2,
    })
    const littleCircle = new fabric.Ellipse({
      ...option,
      // radius: 25 * ratio * scale,
      rx: 25 * (custSpeciialTagratioX ?? ratio) * scale,
      ry: 25 * (custSpeciialTagratioY ?? ratio) * scale,
    })
    ctx.add(circle, littleCircle)
  }
  // 半圆特殊标记
  function drawHalfCircle() {
    // 自定义特殊标签的半径和缩放都用值小的
    const othRadio = cusSpecialFlag
      ? Math.min(custSpeciialTagratioX, custSpeciialTagratioY)
      : ratio
    const radius = 25 * othRadio
    const littleRadius = radius * scale
    const halfCircle = new fabric.Path(
      `M 0,${radius} A ${radius},${radius} 0 0,1 ${
        radius * 2
      },${radius} L ${radius},${radius} L 0,${radius} Z`,
      {
        fill: 'transparent', // 填充颜色为白色
        stroke: 'black', // 描边颜色为黑色
        strokeWidth: 2, // 描边宽度为2
        opacity,
      }
    )
    const littleHalfCircle = new fabric.Path(
      `M 0,${littleRadius} A ${littleRadius},${littleRadius} 0 0,1 ${
        littleRadius * 2
      },${littleRadius} L ${littleRadius},${littleRadius} L 0,${littleRadius} Z`,
      { opacity }
    )
    dealCenter(halfCircle, littleHalfCircle)
    ctx.add(halfCircle, littleHalfCircle)
  }
  // 居中
  function dealCenter(...klass) {
    klass.forEach((it) => {
      it.set({
        left: center.x - it.width / 2,
        top: center.y - it.height / 2,
      })
    })
  }
}

// 处理文字换行
let reFontSize = ''
export function dealTextStr(
  str,
  fontSize,
  maxWidth,
  maxHeight,
  isAutoFontSize = false
) {
  const newStr = str.trim()
  reFontSize = fontSize
  const { height: h } = new fabric.Text('A', { fontSize })
  const strArr = newStr.split('\n')
  let reStr = ''
  reStr = strArr
    .map((str) => {
      let lineWidth = 0 //行宽度 满了就置0
      let charArr = [] //每行文字 满了就置空
      let res = ''
      str.split('').forEach((char) => {
        const { width: w } = new fabric.Text(String(char), { fontSize })
        if (lineWidth < maxWidth) {
          lineWidth += w
          charArr.push(char)
        } else {
          res = res + charArr.join('') + '\n' + String(char)
          lineWidth = 0
          charArr = []
        }
      })
      res = res + charArr.join('')
      return res
    })
    .join('\n')

  const TextH = reStr.split('\n').length * h
  if (TextH > maxHeight && isAutoFontSize) {
    return dealTextStr(str, fontSize - 1, maxWidth, maxHeight, isAutoFontSize)
  } else {
    return {
      text: reStr,
      fontSize: reFontSize,
      isLineBreak: reStr.includes('\n'),
    }
  }
}
