/**
 * 泰语
 */
export default {
  common: {
    checkPlankIsConflict: 'กำลังตรวจสอบสถานะของแผ่น',
    preferenceSaveErr: 'การตั้งค่าที่ต้องการบันทึกผิดพลาด',
    guimen: 'ประตูตู้',
    preferenceLoadTip: 'กำลังโหลดการตั้งค่าของคุณ',
    preferenceLoadErrTip:
      'การตั้งค่าไม่สามารถโหลดได้โปรดรีเฟรชและลองอีกครั้งเพื่อหลีกเลี่ยงการทำให้เกิดข้อผิดพลาดในการตั้งค่าของคุณ!',
    slotKnifeErr:
      'สำหรับเครื่องมือประมวลผลที่ระบุสล็อตที่ขาดหายไปโปรดตรวจสอบการตั้งค่าสายการผลิต! 【 {msg} 】',
    localFileIsBeingWrite: 'ไฟล์ท้องถิ่นกำลังเขียน',
    taskMonitor: 'การตรวจสอบงาน',
    downloadByType: 'ดาวน์โหลด {type}',
    anewChooseCatalog: 'Reselect Directory',
    downloadSetting: 'ดาวน์โหลดการตั้งค่า',
    attention: 'สังเกต',
    errpb: 'เกิดข้อผิดพลาดโปรดลองอีกครั้ง',
    unit: 'ชิ้นส่วน',
    defaultUse: 'ใช้โดยค่าเริ่มต้น',
    defaultChooseUse: 'เลือกโดยค่าเริ่มต้น',
    requestFail: 'คำขอล้มเหลว',
    downloadDoneByType: '{type} ดาวน์โหลดเสร็จสมบูรณ์',
    sysTip: 'ระบบแจ้งเตือน',
    pleaseChoose: 'โปรดเลือก',
    confirmDelete: 'ยืนยันการลบ',
    notDataTipByType: 'ยังไม่มีข้อมูลโปรด {msg} ก่อน',
    paginationTotalTip: '{current}-{page}/total {total}',
    chooseAmountData: '{หมายเลข} เลือกข้อมูล',
    moreOperate: 'มากกว่า',
    transferByType: 'ถ่ายโอน {type}',
    clickCopy: 'คลิกเพื่อคัดลอก {type}',
    currentLine: 'แถวปัจจุบัน',
    remark: 'คำพูด',
    customFunction: 'คุณสมบัติที่กำหนดเอง',
    limitedTimeTrial: 'จำกัด ที่จะได้รับการยกเว้น',
    startDate: 'วันที่เริ่มต้น',
    endDate: 'วันที่สิ้นสุด',
    startTime: 'เวลาเริ่มต้น',
    endTime: 'เวลาสิ้นสุด',
    lastMonth: 'เดือนที่แล้ว',
    lastSixMonth: 'หกเดือนที่ผ่านมา',
    lastYear: 'ปีที่แล้ว',
    searchByType: 'โปรดป้อน {type} เพื่อสอบถาม',
    addTime: 'เพิ่มเวลา',
    startArrange: 'เริ่มการพิมพ์',
    select: 'เลือก',
    name: 'ชื่อ',
    plankNo: 'หมายเลขบอร์ด',
    plankName: 'ชื่อบอร์ด',
    orderNo: 'หมายเลขสั่งซื้อ',
    order: 'คำสั่ง',
    plankRemark: 'โน้ตแผ่น',
    room: 'ห้อง',
    orderAddress: 'แกลเลอรี่โครงการ',
    matCode: 'วัสดุ',
    matCode2: 'วัสดุ',
    plankMatCode: 'วัสดุจาน',
    count: 'ปริมาณ',
    customer: 'ลูกค้า',
    customerInfo: 'ข้อมูลลูกค้า',
    factoryOrderNo: 'หมายเลขคำสั่งซื้อจากโรงงาน',
    roomName: 'ห้อง',
    color: 'สี',
    plankColor: 'สีจาน',
    plankThick: 'ความหนาของแผ่น',
    plankHighGloss: 'ไฮไลต์คุณลักษณะ',
    thick: 'ความหนา',
    front: 'ด้านหน้า',
    back: 'ตรงกันข้าม',
    slot: 'ร่อง',
    hole: 'รู',
    hsInfo: '{hole} หลุม {slot} slot',
    diameter: 'เส้นผ่าศูนย์กลาง',
    finishedWidth: 'ความกว้างของผลิตภัณฑ์สำเร็จรูป',
    finishedHeight: 'ความยาวของผลิตภัณฑ์สำเร็จรูป',
    texture: 'พื้นผิว',
    normal: 'เส้นแนวตั้ง',
    reverse: 'เส้นแนวนอน',
    notCare: 'ไม่มีพื้นผิว',
    holeSlotsStastic: 'จำนวนช่องหลุม',
    edge: {
      title: 'ปิดผนึกขอบ',
      info: 'ข้อมูลการบล็อกขอบ',
      front: 'ซีลขอบด้านหน้า',
      back: 'ปิดผนึกขอบด้านหลัง',
      left: 'บล็อกด้านซ้าย',
      right: 'บล็อกด้านขวา',
      up: 'ปิดผนึกขอบด้านบน',
      down: 'ซีลขอบถัดไป',
    },
    isSpecial: 'คนต่างด้าว',
    notSpecial: 'ไม่มีรูป',
    isDoorPlank: 'แผงประตู',
    notDoorPlank: 'แผงที่ไม่ใช่ประตู',
    width: 'ความกว้าง',
    long: 'ความยาว',
    plankWidth: 'ความกว้างของแผ่น',
    plankHeight: 'ความยาวแผ่น',
    shortLong: 'ความยาวด้านสั้น',
    shortWidth: 'ความกว้างด้านสั้น',
    plankShape: 'รูปแผ่น',
    detail: 'รายละเอียด',
    cancel: 'ยกเลิก',
    confirm: 'ยืนยัน',
    deep: 'ความลึก',
    other: 'อื่น',
    heighGloss: 'เน้น',
    heighGlossPlank: 'ไฮไลต์บอร์ด',
    notHeighGloss: 'ไม่สูง',
    notHeighGlossPlank: 'บอร์ดที่ไม่ได้มีแสงสว่าง',
    saveSuccess: 'บันทึกให้สำเร็จ',
    deleteSuccess: 'ลบออกได้สำเร็จ',
    addSuccess: 'เพิ่มสำเร็จ',
    editSuccess: 'การดัดแปลงประสบความสำเร็จ',
    engraving: 'เครื่องแกะสลัก',
    electronicSaw: 'เลื่อยอิเล็กทรอนิกส์',
    glassEquip: 'เครื่องตัดแก้ว',
    labelingMachine: 'เครื่องติดฉลาก',
    copy: 'สำเนา',
    paste: 'แปะ',
    undo: 'ถอน',
    move: 'เคลื่อนไหว',
    PTP: 'PTP',
    dock56FDrill: 'เพชรห้าหกหก',
    sideHoleDrill: 'เครื่องหลุมด้านข้าง',
    combination: 'อุปกรณ์ผสมผสาน',
    integration: 'เครื่อง all-in-one',
    standardPlank: 'บอร์ดขนาดใหญ่มาตรฐาน',
    standardMaterial: 'แผ่นมาตรฐาน',
    standardMaterialTip:
      'หมายเหตุ: หลังจากเสร็จสิ้นการปรับข้อมูลบอร์ดขนาดใหญ่ผู้ใช้ที่อยู่ในการผลิตเค้าโครงของคณะรัฐมนตรีจำเป็นต้องรีสตาร์ทไคลเอนต์ตู้เพื่อใช้การเปลี่ยนแปลงล่าสุด!',
    specialMaterial: 'จานพิเศษ',
    specialPlank: 'กระดานใหญ่พิเศษ',
    singlePlank: 'แผงเดี่ยว',
    previous: 'ก่อนหน้า',
    plankInfo: 'รายละเอียดบอร์ดใหญ่',
    moreResult: 'โซลูชันการเรียงพิมพ์เพิ่มเติม',
    moreResultTip:
      'มีร่องสำหรับการบดในจานขนาดใหญ่และแผ่นขนาดใหญ่จะไม่เข้าร่วมในรูปแบบประเภทใหม่',
    ok: 'แน่นอน',
    dispersePaibanTip:
      'ตรวจพบว่าบอร์ดขนาดใหญ่บางตัวไม่สามารถแยกย้ายกันไปได้ในรูปแบบ คุณสามารถปรับหรือเปลี่ยนแผนเลย์เอาต์ด้วยตนเองเพื่อหลีกเลี่ยงการเรียกใช้บอร์ด!',
    next: 'ต่อไป',
    rate: 'อัตราการใช้ประโยชน์',
    paibanBatch: 'ชุดเรียงพิมพ์',
    return: 'กลับ',
    FGNo: 'หมายเลขสัญญา',
    refresh: 'รีเฟรช',
    inde: 'หมายเลขซีเรียล',
    productionLine: 'สายการผลิต',
    operation: 'ดำเนินงาน',
    colorPlaceHolder: 'กด Enter เพื่อสร้างการ์ดสีโดยอัตโนมัติ',
    saveAndContinue: 'บันทึกและดำเนินการต่อ',
    reset: 'รีเซ็ต',
    check: 'สอบถาม',
    inputPlaceholder: 'โปรดป้อน',
    filter: 'กรอง',
    all: 'ทั้งหมด',
    exportPDF: 'PDF ส่งออก',
    exportPic: 'รูปภาพส่งออก',
    add: 'เพิ่ม',
    edit: 'แก้ไข',
    delete: 'ลบ',
    save: 'บันทึก',
    saveSetting: 'บันทึกการตั้งค่า',
    isHeightGloss: 'โปรดเลือกว่าเป็นบอร์ดไฮไลต์หรือไม่',
    noSync: 'ไม่มา',
    sync: 'ซิงโครนัส',
    formatSuccess: '{type} ความสำเร็จ',
    dataLoading: 'ข้อมูลกำลังโหลด ...',
    warmTip: 'เคล็ดลับชนิด',
    wellcome: 'ยินดีต้อนรับสู่ Cloud Layout',
    timeOut: 'หมดหมดการหมดเวลาไม่ว่าจะพิมพ์ซ้ำ',
    warning: 'เตือน',
    export: 'ส่งออก',
    mark: 'เครื่องหมาย',
    noMark: 'ไม่ได้ทำเครื่องหมาย',
    sawArrange: 'เลื่อยประเภทตัด',
    directArrange: 'ประเภทตัดตรง',
    area: 'พื้นที่',
    plankNum: 'บาร์โค้ดบอร์ด',
    loc: 'ตู้',
    cutHeight: 'ความยาวการตัด',
    cutWidth: 'ความกว้างของการตัด',
    defaultProduction: 'สายการผลิตเริ่มต้น',
    tip: 'คำใบ้',
    history: 'ประวัติศาสตร์',
    hinge: 'รูบานพับ',
    hingeScrew: 'คู่มือแผงบานพับ',
    stretch: 'เครื่องยืดสาย',
    stretcher: 'สล็อต',
    clothole: 'เสื้อผ้าผ่านรู',
    backPlank: 'สล็อตแผ่นหลัง',
    ALS: 'สล็อตด้ามจับมืด',
    doorSlot: 'แผงประตูร่องโลหะ',
    drawerSlot: 'ลิ้นชักโลหะ',
    KC: 'กางเกงดึงหลุม',
    stealth: 'ชิ้นส่วนที่มองไม่เห็น',
    lkSlot: 'สล็อตล็อค',
    lightSlot: 'รางเบา',
    allHole: 'ทุกหลุมบนกระดานรูปพิเศษ',
    allSlot: 'สล็อตทั้งหมดบนกระดานรูปพิเศษ',
    haiTang: 'ฮอร์น Begonia',
    millInfo: 'มือจับแตร',
    FSDrill: 'เพชรห้า/หกด้าน',
    engraving2: 'เครื่องตัด',
    xyReverse: 'การแลกเปลี่ยนแกน xy',
    xyReverseTip:
      'ฟังก์ชั่นการแลกเปลี่ยนแกน XY ไม่สามารถใช้งานได้กับวัสดุที่เหลืออยู่ของ L-type อย่างถูกต้องโปรดใช้ด้วยความระมัดระวัง',
    rect: 'สี่เหลี่ยมผืนผ้า',
    Ltype: 'รูปตัว L',
    archive: 'คลังเก็บเอกสารสำคัญ',
    tellPlaceholder: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
    knivesStore: 'ห้องสมุดเครื่องมือ',
    roll: 'จานพลิก',
    pause: 'หยุดชั่วคราว',
    editTrans: 'แก้ไขและแปล',
    translateManager: 'การจัดการการแปล',
    gratingSlot: 'สล็อตกระจิต',
    addressEditErr:
      'การปรับเปลี่ยนที่อยู่โครงการไม่ได้รับการสนับสนุนสำหรับคำสั่งซื้อหลายคำสั่ง',
    customerEditErr: 'คำสั่งซื้อหลายคำสั่งไม่สนับสนุนการแก้ไขข้อมูลลูกค้า',
    factoryEditErr:
      'การปรับเปลี่ยนหมายเลขคำสั่งซื้อจากโรงงานไม่ได้รับการสนับสนุนสำหรับคำสั่งซื้อหลายรายการ',
    roomNameEditErr: 'การแก้ไขชื่อห้องไม่รองรับสำหรับคำสั่งซื้อหลายคำสั่ง',
    remarkEditErr: 'คำสั่งซื้อหลายคำสั่งยังไม่สนับสนุนการปรับเปลี่ยนคำสั่งซื้อ',
    toMaterialList: 'ไปที่บิลวัสดุ',
    deleteFail: 'การลบล้มเหลวโปรดลองอีกครั้งในภายหลัง!',
    batchDelete: 'การลบแบทช์',
    workShop: 'การผลิตเวิร์กช็อป',
    FGAdrress: 'ที่อยู่โครงการ/หมายเลขสัญญา',
    cuttingSize: 'ขนาดตัด',
    finishedSize: 'ขนาดผลิตภัณฑ์สำเร็จรูป',
    labelImg: 'การติดฉลากรูปภาพ',
    layoutImg: 'การเรียงพิมพ์',
    file: 'เอกสาร',
    folder: 'โฟลเดอร์',
    reName: 'การเปลี่ยนชื่อ',
    to: 'ถึง',
    plankUnit: 'เปิด',
    tagTemp: 'แท็กเทมเพลต',
    roomRemark: 'หมายเหตุห้องพัก',
    noModificationPermission: 'ไม่มีสิทธิ์ในการปรับเปลี่ยน',
    modificationRecord: 'แก้ไขระเบียน',
    page: 'หน้าหนังสือ',
    strip: 'เปลื้องผ้า',
    jump: 'กระโดด',
    freeAgreement: 'ข้อจำกัดความรับผิดชอบซอฟต์แวร์',
    preStep: 'ก่อนหน้า',
    nextStep: 'ขั้นตอนต่อไป',
    upload: 'อัพโหลด',
    uploading: 'การอัพโหลด',
    iAgree: 'ฉันเห็นด้วย',
    pleaseSelectPlank: 'โปรดเลือกบอร์ดอย่างน้อยหนึ่งบอร์ด',
    saveToStoreTip: 'โปรดเลือกชิ้นส่วนที่ไม่ตัดอย่างน้อยหนึ่งชิ้น',
    fileIsNotExist: 'ไฟล์ไม่มีอยู่จริง',
    selectAll: 'เลือกทั้งหมด',
    pdfTag: 'แท็ก PDF',
    tagImage: 'แท็กรูปภาพ',
    projectName: 'การพิมพ์ดีดคลาวด์',
    guigui: 'ตู้',
    deleteTip:
      'ไม่สามารถกู้คืนได้หลังจากลบข้อมูล คุณแน่ใจหรือไม่ว่าข้อมูลถูกลบ?',
    deleteError: 'เกิดข้อผิดพลาดที่ไม่รู้จักการลบล้มเหลว',
    cancelDelete: 'ลบล้าง',
    confirmDeleteTip:
      'หลังจากลบบอร์ดบอร์ดจะเข้าสู่ "จานที่จะพิมพ์ไลบรารี-ไม่ป้อน" ที่มุมล่างขวาของหน้า คุณแน่ใจว่าจะลบข้อมูลได้หรือไม่?',
    deleteEmptyTip: 'ไม่ว่าจะลบบอร์ดนี้',
    notCanDelete: 'วัสดุที่เหลืออยู่ไม่รองรับการจัดเก็บ',
    new: 'ใหม่',
    innerBluneFailure: 'เค้าโครงเมฆแบบฝังไม่มีผล',
    deleteBigPlankTip:
      'แผ่นขนาดใหญ่จะต้องเก็บไว้ภายใต้ความหนาของสีวัสดุปัจจุบันและไม่สามารถลบได้!',
    continueExport: 'ละเว้นดำเนินการต่อเพื่อส่งออกไฟล์',
    toCheck: 'ตรวจสอบ',
    pleaseCheck:
      'ผลการจัดวางในปัจจุบันมีความขัดแย้งในช่องโหว่/บินออกจากแผ่นเล็ก ๆ/ความผิดปกติลึกโปรดตรวจสอบ!',
    colorNotUndefined: 'สีไม่สามารถว่างเปล่า',
    materialNotUndefined: 'วัสดุไม่สามารถว่างเปล่า',
    newSetting: 'คุณสมบัติใหม่',
    downImageErrTip:
      'ภาพการส่งออกล้มเหลวเนื่องจากภาพการติดฉลากถูกเปิดและแบ่งออกเป็นโฟลเดอร์ที่แตกต่างกันตามบอร์ดขนาดใหญ่ คุณต้องตรวจสอบฉลากบอร์ดขนาดเล็กเพื่อส่งออกด้วยกัน',
    downNcErrTip:
      'ดาวน์โหลด NC ล้มเหลวเนื่องจากภาพการติดฉลากแบ่งออกเป็นโฟลเดอร์ที่แตกต่างกันตามบอร์ดขนาดใหญ่ คุณต้องตรวจสอบฉลากบอร์ดขนาดเล็กเพื่อส่งออกด้วยกัน',
    yes: 'ใช่',
    no: 'เลขที่',
    checkColorPrint: 'เปิดฉลากสีในขณะนี้โปรดใช้เครื่องพิมพ์สีเพื่อพิมพ์ ~',
    colorRules:
      'หลังจากเปิดแท็กสีที่สร้างขึ้นคำสั่ง/ห้อง/ตู้/แผงประตูจะแตกต่างกันตามสี ปัจจุบันขีด จำกัด สูงสุดของแท็กสีสำหรับข้อมูลประเภทเดียวกันคือ 12 กฎเฉพาะมีดังนี้:',
    greyPart: 'ส่วนสีเทาในฉลากทางด้านขวาคือ',
    region: 'พื้นที่',
    notSame: 'ภูมิภาคต่าง ๆ ที่มีสี {regionName} ต่างกันแตกต่างกัน',
    doorTip: 'สีเทาคือแผงประตูสีขาวเป็นแผงที่ไม่ใช่ประตู',
    exist: 'มีอยู่',
    limitNotEffect: 'มากกว่า 12 ข้อมูลฉลากสีไม่มีผล',
    hoverTip:
      'ภาพปัจจุบันมีคำต่อท้าย BMP รูปแบบการเข้ารหัสเป็นสีดำและสีขาวและรูปภาพฉลากสีไม่สามารถส่งออกได้',
    lock: 'การล็อค',
    unlock: 'ปลดล็อค',
    batchLock: 'แบทช์ล็อค/ปลดล็อค',
    surplusLock: 'ล็อควัสดุที่เหลืออยู่',
    surplusUnlock: 'ปลดล็อควัสดุที่เหลืออยู่',
    is: 'ใช่',
    not: 'เลขที่',
    savingHistory: 'การบันทึกไฟล์ประวัติ',
    saveHistorySuccess: 'การบันทึกไฟล์ประวัติศาสตร์สำเร็จ',
    saveHistoryFail: 'ไม่สามารถบันทึกไฟล์ประวัติศาสตร์',
    bw: 'ไม่ว่าจะส่งออกฉลากขาวดำ',
    modular: 'ตู้',
    autoAdjust: 'การปรับอัตโนมัติ',
    cutOrderTip:
      'ตรวจพบข้อผิดพลาดในลำดับการตัดของแผ่นงาน [{errorcutordersstring} ของคุณ]! โปรดทำการปรับเปลี่ยนก่อนดาวน์โหลดไฟล์ NC',
    lockedCutOrderTip:
      '【 {errorcutordersstring} บอร์ดขนาดใหญ่】ล็อคโปรดไปที่การแก้ไขด้วยตนเอง',
    autoAdjustWarning: 'ผลลัพธ์เค้าโครงปัจจุบันไม่รองรับการปรับอัตโนมัติ',
    checkNetwork: 'โปรดตรวจสอบเครือข่าย',
    moreInfo: 'ดูข้อมูลเพิ่มเติม',
    continueLayout: 'พิมพ์ต่อไป',
    adjustPosition: 'ปรับการตั้งค่า',
    specialAndFiveSix:
      'เมื่อขุดเจาะการขุดเจาะห้า/หกด้านเพื่อประมวลผลแผ่นที่มีรูปร่างพิเศษการเปิดการทำรังรูปร่างพิเศษอาจทำให้การตัดบนแผ่น โปรดยืนยันว่าจะพิมพ์ต่อไปหรือไม่?',
    plankPathErr:
      'ข้อมูลรูปร่างพิเศษภายในของบอร์ดไม่ถูกต้อง โปรดตรวจสอบว่าตรงตามข้อกำหนดของเครื่องมือหรือไม่! หมายเลขบอร์ด [{plankidlist}]',
  },
  home: {
    head: {
      production: 'การแนะนำผลิตภัณฑ์',
      resolve: 'สารละลาย',
      about: 'เกี่ยวกับเรา',
      equipment: 'เครื่องจักรและอุปกรณ์',
      tell: 'หมายเลขโทรศัพท์ร้องเรียน',
      enter: 'ป้อนเค้าโครงเมฆ',
    },
    swiper: {
      case1: {
        tag: 'เวอร์ชันอัพเกรดใหม่',
        title: 'การพิมพ์ดีดคลาวด์',
        subTitle: 'สมองอัจฉริยะของเครื่องจักร',
        description:
          'การปฏิรูปซอฟต์แวร์การเพิ่มประสิทธิภาพเค้าโครงออนไลน์คลาวด์ของอุตสาหกรรม',
      },
      case2: {
        title: 'การผลิตคณะรัฐมนตรี',
        subTitle: 'นวัตกรรมเทคโนโลยี',
        description:
          'รองรับเครื่องฉลากหลายเครื่องเครื่องตัดเครื่องรูด้านข้าง PTP ห้าด้านการเชื่อมต่อการขุดเจาะห้าด้าน',
      },
      case3: {
        title: 'สมองอุตสาหกรรม',
        subTitle: 'สารละลาย',
        description:
          'อัลกอริทึมการเรียงพิมพ์อัจฉริยะเพื่อให้ได้อัตราการใช้งานที่สูงขึ้นของบอร์ด',
      },
    },
    statistics: {
      today: 'ปริมาณการเปลี่ยนแปลงของวันนี้',
      toMounth: 'ปริมาณเค้าโครงรายเดือน',
      outturn: 'ผลผลิตวัสดุ',
      cumulative: 'ผู้ใช้ที่สะสม',
    },
    intro: {
      title: 'ซอฟต์แวร์เค้าโครงออนไลน์ขนาดใหญ่ระดับองค์กร',
      subTitle:
        'ข้อมูลเชิงลึกเกี่ยวกับแนวโน้มของอุตสาหกรรมมุ่งเน้นไปที่ความต้องการของลูกค้าช่วยให้ผู้ประกอบการเปลี่ยนและอัพเกรดแบบดิจิทัลและส่งเสริมการเปลี่ยนแปลงของอุตสาหกรรมการผลิตเฟอร์นิเจอร์อินเทอร์เน็ต ในการตอบสนองต่อสถานการณ์การผลิตของอุตสาหกรรมเฟอร์นิเจอร์แผงที่มีอยู่อัลกอริทึม AI ช่วยให้เปิดตัวเพื่อดำเนินการต่อเพื่อการเติบโตใหม่ให้กับองค์กร',
      menue: {
        case1: {
          title: 'ตัวอักษรออนไลน์',
          subTitle: 'พิมพ์ในเวลาใดก็ได้',
          description:
            'ไม่จำเป็นต้องดาวน์โหลดซอฟต์แวร์สามารถพิมพ์ได้ตลอดเวลารีโมทควบคุมไม่จำเป็นต้องพึ่งพาคอมพิวเตอร์',
        },
        case2: {
          title: 'การผลิตหลายบรรทัด',
          subTitle: 'สวิตช์ด่วนของสายการผลิต',
          description:
            'รองรับสายการผลิตหลายสายเพื่อดำเนินการอย่างรวดเร็วในเวลาเดียวกันและสามารถสลับระหว่างสายการผลิตได้อย่างรวดเร็ว',
        },
        case3: {
          title: 'การผลิตที่ดี',
          subTitle: 'เค้าโครงที่ดีการตั้งค่าพารามิเตอร์ที่ดี',
          description:
            'เค้าโครงที่ดีและการตั้งค่าพารามิเตอร์ที่ดีเพื่อให้การผลิตบอร์ดมีความแม่นยำมากขึ้น',
        },
        case4: {
          title: 'การผลิตแบบกำหนดเอง',
          subTitle:
            'ฉลากที่กำหนดเองการฝึกซ้อมห้าและหกด้านรูปทรงวัสดุที่เหลืออยู่',
          description:
            'ฉลากที่กำหนดเอง, การฝึกซ้อมห้าและหกด้านที่กำหนดเอง, รูปร่างวัสดุตกค้างที่กำหนดเอง, การประมวลผลอุปกรณ์รวม',
        },
      },
    },
    fangan: {
      title: 'โซลูชั่นมืออาชีพในอุตสาหกรรม',
      subTitle:
        'ค่าใช้จ่ายต่ำค่าเอาท์พุทสูงอัลกอริทึมอัจฉริยะปรับปรุงการใช้งานบอร์ดเข้ากันได้กับซอฟต์แวร์การออกแบบที่หลากหลายทำให้ผู้ใช้มีโซลูชันการผลิตที่ดีที่สุด',
      menue: {
        case1: {
          title: 'การรวมการผลิตแบบสั่งซื้อออนไลน์',
          description:
            'การดำเนินการออนไลน์ระยะไกลอุปสรรคข้ามอวกาศคำสั่งเอาท์พุทได้ตลอดเวลาและการออกแบบการออกแบบและการซิงโครไนซ์การผลิตที่สมบูรณ์',
        },
        case2: {
          title: 'สายการผลิตสลับคลิกเดียว',
          description:
            'สายการผลิตที่กำหนดเองรองรับสายการผลิตหลายสายและการเชื่อมต่ออุปกรณ์หลายประเภท',
        },
        case3: {
          title: 'อัลกอริทึมอัจฉริยะปรับปรุงการใช้งานบอร์ด',
          description:
            'การใช้ประโยชน์จากบอร์ดอัลกอริทึมแบบดั้งเดิมอยู่ในระดับต่ำอัลกอริทึมอัลกอริทึมการตั้งค่าพารามิเตอร์ที่ดีแผนการผลิตที่กำหนดเอง',
        },
        case4: {
          title: 'แผนการตัดมืออาชีพ',
          description:
            'เพิ่มประสิทธิภาพลำดับการตัดและจุดตัดเพื่อให้เส้นทางสั้นลงและมีเสถียรภาพมากขึ้น',
        },
      },
    },
    aboutUs: {
      title1: 'เทคโนโลยีที่อยู่อาศัยของมนุษย์ Xingeer',
      subTitle: 'ผู้นำในการเปลี่ยนแปลงของอุตสาหกรรมการผลิตเฟอร์นิเจอร์',
      description:
        'Xingeer Enterprise มุ่งเน้นไปที่การตระหนักถึงการเปลี่ยนแปลงทางอินเทอร์เน็ตของอุตสาหกรรมการผลิตเฟอร์นิเจอร์แบบดั้งเดิม',
      culture: {
        case1: {
          title: 'วัตถุประสงค์ขององค์กร',
          subTitle: 'ช่วยให้โรงงานเติบโตสาม',
        },
        case2: {
          title: 'ค่าวัฒนธรรม',
          subTitle:
            'ลูกค้าเป็นศูนย์กลางมุ่งมั่นเพื่อคนทำงานหนักและเป็นประโยชน์ต่อผู้คน',
        },
        case3: {
          title: 'แนวทางอุดมการณ์',
          subTitle:
            'Mass Line สร้างทีมชั้นยอดด้วยยอดขายผลิตภัณฑ์และความแข็งแกร่งขององค์กร',
        },
      },
      feature: {
        case1: 'การบำรุงรักษาทีมงานระดับมืออาชีพ',
        case2: 'การอัปเดตและการทำซ้ำอย่างต่อเนื่อง',
        case3: 'บริการหลังการขายแบบหนึ่งต่อหนึ่ง',
        case4: 'บริการปรับแต่งฟังก์ชั่น',
      },
    },
    cooperation: {
      title: 'ร่วมมืออย่างจริงใจเพื่อสร้างอนาคตร่วมกัน',
      subTitle:
        'แบรนด์อุปกรณ์เชื่อมต่อครอบคลุม 90% ของอุปกรณ์ทั่วไปที่ใช้ในประเทศจีนและพ่อค้า 107,554 คนเดินทางไปกับเรา',
    },
    footer: {
      contact: {
        title: 'ติดต่อเรา',
        adrres:
          'ที่อยู่: หมายเลข 50 Xiuxi, Binhu District, Wuxi City, Jiangsu Province',
        subAdrres: 'ชั้น 16 อาคาร 1, ศูนย์บริการ K-Park',
        tell: 'โทรศัพท์',
      },
      business: {
        title: 'ธุรกิจหลักของ Xingele',
        department1: 'แผนกธุรกิจซอฟต์แวร์ Door and Windows',
        department2: 'แผนกธุรกิจซอฟต์แวร์เฟอร์นิเจอร์',
        department3: 'กรมสมัชชาและก่อสร้าง',
        department4: 'เว็บไซต์ทางการของสำนักงานใหญ่ของ Xingele',
      },
      brand: {
        title: 'แบรนด์ภายใต้ Xingele',
        brand1: 'เทพเจ้าแห่งวัสดุ',
        brand2: 'เครือข่ายแผนที่ประตู',
        brand3: 'การลงทุน dingxin',
        brand4: 'สามรูปภาพของเทคโนโลยี',
        brand5: 'เทคโนโลยีสีชมพูเซี่ยงไฮ้',
      },
      organization: {
        title: 'สาขา Xingere Global',
        organization1: 'สำนัก Xingele-South China (สาขา Foshan)',
        organization2: 'สำนัก Xingele-Northern (สาขา Linyi)',
        organization3: 'สำนัก Xingele-Southwest (สาขาเฉิงตู)',
        organization4: 'ศูนย์บริการ Xingele-Coffee Cabinet Western',
        organization5: 'ศูนย์บริการ Eastern Xingele-Coffer',
        organization6: 'คลัสเตอร์ Xingele-Agent',
        organization7: 'แผนกการตลาดของ Xingele-International',
      },
      follow: {
        title: 'สแกน "ติดตามคณะรัฐมนตรี"',
      },
      friendLink: {
        link1: 'ซอฟต์แวร์ Xingele',
        link2: 'K Show Shopping Software Software',
        link3: 'เครือข่ายแผนที่ประตู',
        link4: 'ซอฟต์แวร์ตู้อลูมิเนียมทั้งหมด',
        link5: 'ดาวน์โหลดซอฟต์แวร์ ZOL',
        link6: 'สวนซอฟต์แวร์ Huajun',
        link7: 'ดาวน์โหลดบ้าน',
        link8: 'ดาวน์โหลดซอฟต์แวร์ ZOL',
        link9: 'เครือข่ายทรัพยากรสีเขียว',
        link10: 'แฟรนไชส์ตู้เสื้อผ้า',
        link11: 'แฟรนไชส์บ้าน',
        link12: 'แฟรนไชส์ห้องน้ำมาตรฐานอเมริกัน',
      },
    },
  },
  login: {
    title: 'เข้าสู่ระบบ',
    tell: 'หมายเลขโทรศัพท์',
    password: 'รหัสผ่าน',
    passwordPlaceholder: 'กรุณากรอกรหัสผ่านของคุณ',
    forget: 'ลืมรหัสผ่าน',
    register: 'ลงทะเบียน',
    backLog: 'กลับสู่การเข้าสู่ระบบ',
    verifyCode: 'รหัสการตรวจสอบ',
    getVerifyCode: 'รับรหัสการยืนยัน',
    confimPassword: 'ยืนยันรหัสผ่าน',
    confimPasswordPlaceholder: 'โปรดยืนยันรหัสผ่านของคุณ',
    editPassword: 'แก้ไขรหัสผ่าน',
    reSend: 'หลังจากนั้นอีกครั้งหลังจากนั้น',
    loginLoadText: 'เข้าสู่ระบบโปรดรอ ...',
    loadingPageTextL: 'เข้าสู่ระบบสำเร็จโครงสร้างหน้ากำลังโหลด ...',
    loginSuccess: 'เข้าสู่ระบบสำเร็จ',
    tellErr: 'รูปแบบหมายเลขโทรศัพท์มือถือที่ไม่ถูกต้อง',
    passwordErr: 'กรุณากรอกรหัสผ่านของคุณ',
  },
  main: {
    head: {
      title: 'Bluen',
      subTitle: 'Save time, materials, and effort',
      task: 'รับงาน',
      arrange: 'การเรียงพิมพ์',
      surplus: 'วัสดุที่เหลืออยู่',
      setting: 'ตั้งค่า',
      find: 'ค้นพบ',
      upgrade: 'อัพเกรดตอนนี้',
      editNickName: 'แก้ไขชื่อเล่น',
      editPassword: 'แก้ไขรหัสผ่าน',
      logout: 'ออกจากระบบ',
      notice: 'ประกาศ',
      enterOfficial: 'ไปที่เว็บไซต์อย่างเป็นทางการ',
      equipment: 'เครื่องจักรและอุปกรณ์',
      help: 'ช่วย',
      enterThinkerOfficial: 'ป้อนเว็บไซต์ทางการของ Xingele',
      history: 'บันทึกประวัติศาสตร์',
      plankManager: 'การจัดการภาพยนตร์ดั้งเดิม',
      tagManager: 'การจัดการแท็ก',
      suplumentManager: 'ห้องสมุดการเติมเต็มและเค้าโครง',
      fileManager: 'การจัดการไฟล์',
      productionLine: 'อุปกรณ์เชื่อมต่อ',
      workeShop: 'อุปกรณ์ออนไลน์',
      useLesson: 'การสอนการใช้งาน',
      videoLesson: 'การสอนวิดีโอ',
      prolineManager: 'การจัดการสายการผลิต',
      materialManager: 'ผู้บริหารคณะกรรมการ',
    },
    sideBar: {
      title: 'การเพิ่มประสิทธิภาพการผลิต',
      materialList: 'รายการวัสดุ',
      paibanResult: 'แผนการเรียงลำดับ',
      printTag: 'พิมพ์ฉลาก',
      history: 'บันทึกประวัติศาสตร์',
      limitDate: 'จำกัด ที่จะได้รับการยกเว้น',
      surplusManager: 'การจัดการวัสดุที่เหลืออยู่',
      translateManager: 'การจัดการการแปล',
      moreSettings: 'การตั้งค่าเพิ่มเติม',
    },
    allRead: 'อ่านทั้งหมด',
    learnMore: 'เรียนรู้เพิ่มเติม',
    noticeEmpty: 'ยังไม่มีการประกาศ ...',
    noticeErr: 'ข้อผิดพลาดรับเนื้อหาประกาศโดยละเอียด',
    feedBack: {
      tab1: 'ส่งข้อเสนอแนะ',
      tab2: 'บันทึกข้อเสนอแนะ',
      type: 'ประเภทของความคิดเห็น',
      typePlaceholder: 'โปรดเลือกฟังก์ชั่น',
      description: 'อธิบาย',
      descriptionPlaceholder:
        'สวัสดี ~ โปรดกรอกคำอธิบายสถานการณ์การใช้โดยละเอียดไม่น้อยกว่า 15 คำเพื่อให้เราสามารถช่วยคุณได้ดีขึ้น ขอบคุณสำหรับความร่วมมือ!',
      uploadPic:
        'เพิ่มรูปภาพได้มากถึง 6 รูปภาพและขนาดของภาพเดียวจะต้องไม่เกิน 10m)',
      tell: 'หมายเลขติดต่อ',
      submit: 'ส่ง',
      admin: 'ผู้ดูแลระบบประเภทคลาวด์:',
      picTip: 'รูปภาพ: (คลิกเพื่อขยายเพื่อดูรายละเอียดรูปภาพ)',
      empty: 'ยังไม่มีข้อมูลข้อเสนอแนะ',
      picErr: 'ขนาดของภาพเดียวไม่ควรเกิน 10m',
      contentErr: 'จำนวนคำควรอยู่ระหว่าง 15 ถึง 300',
      necessary: 'ตัวเลือกนี้เป็นสิ่งจำเป็น',
    },
  },
  taskPage: {
    tab1: 'งานผลิต',
    tab2: 'คิวการผลิตจำนวนมาก',
    filter: {
      title: 'กรอง',
      unArrange: 'ไม่ได้พิมพ์',
      arranged: 'ที่พิมพ์ออกมา',
      allArranged: 'ทั้งหมดได้รับการพิมพ์',
      preLayout: 'ก่อนประเภท',
    },
    search: {
      placeholder: 'กรุณากรอกเนื้อหาการค้นหา',
      orderName: 'ชื่อสั่งซื้อ',
      sender: 'ผู้ส่ง',
    },
    importTask: 'งานนำเข้า',
    refuseTask: 'ปฏิเสธงาน',
    refreshList: 'รีเฟรชรายการ',
    refuseRecord: 'ปฏิเสธบันทึก',
    taskCard: {
      sender: 'รายการวัสดุที่นำเข้า',
      delivery: 'วันที่จัดส่ง',
      delete: 'ลบ',
      addToBatch: 'เพิ่มลงในแบทช์',
      save: 'คลังเก็บเอกสารสำคัญ',
      arrange: 'เค้าโครงโดยตรง',
      deleteTitle: 'ลบงาน',
      checkDetail: 'คลิกเพื่อดูรายละเอียด',
      refuse: 'ปฏิเสธ',
      refuseTime: 'ปฏิเสธเวลา',
      refuseReason: 'เหตุผลในการปฏิเสธ',
      refuseTask: 'ปฏิเสธงาน',
      refuseTaskPlaceholder: 'โปรดป้อนเหตุผลในการปฏิเสธ',
      addErr: 'การเพิ่มล้มเหลวโปรดลองอีกครั้งในภายหลัง!',
    },
    batchNo: 'หมายเลขแบทช์',
    batchArrange: 'เค้าโครงแบทช์',
    clearBatch: 'ล้างคิว',
    taskCount: 'จำนวนงานปัจจุบัน:',
    checkAllTask: 'ดูรายการวัสดุทั้งหมด',
    clearSuccess: 'เคลียร์สำเร็จ',
    record: {
      batchNo: 'ชุด',
      saveDate: 'ประหยัดเวลา',
      materialList: 'รายการวัสดุ',
      arrangedRecord: 'แผนการเรียงลำดับ',
      searchPlaceholder: 'กรุณากรอกหมายเลขแบทช์',
      tipText: 'ฉันควรลบที่เก็บถาวรหรือไม่?',
    },
    errTip: 'ไม่มีข้อมูลงานที่ต้องดำเนินการโปรดไปที่รายการงานเพื่อเพิ่ม!',
    emptyErr: 'ใบสั่งงานนี้ไม่มีข้อมูลบอร์ดและไม่สามารถพิมพ์และผลิตได้!',
    operationSuccess: 'การดำเนินการสำเร็จ',
    operationFail: 'การดำเนินการล้มเหลวโปรดลองอีกครั้งในภายหลัง!',
    saveSuccess: 'เก็บถาวรให้สำเร็จ',
    plankMaxLimit: 'จำนวนบอร์ดขนาดเล็กเกินขีด จำกัด เค้าโครงสูงสุด',
    batchWorning: 'กรุณาทำการผลิตเป็นแบทช์',
    batchEmptyErr:
      'รายการคำสั่งซื้อไม่มีข้อมูลบอร์ดและไม่สามารถผลิตได้จำนวนมาก!',
    noRoomTip: 'ไม่มีที่ว่างและไม่สามารถผลิตได้มากมาย!',
    refuseHistory: 'ปฏิเสธประวัติ',
  },
  materialPage: {
    edgeEmptyTip: 'ค่าขอบเขตไม่สามารถว่างเปล่า',
    edgeNumberLimit: 'สามารถป้อนตัวเลขได้เฉพาะในค่าการตกแต่ง',
    chooseSurplusQuantity:
      'มีวัสดุตกค้าง {ทั้งหมด} ประเภทและประเภท {ปริมาณ} ได้รับการเลือก',
    noCanSaveInfo: 'ไม่มีข้อมูลที่จะบันทึก',
    chooseBujianStoreQuantity:
      'มีบล็อก {Total} สำหรับการเติมเต็มและสามารถเติมเต็มบอร์ดได้ในบล็อกทั้งหมด {ปริมาณ} และบล็อก {เลือก Quantity} ได้รับการเลือก',
    isConfirmPushToAwaitStore:
      'คุณแน่ใจว่าจะเพิ่มบอร์ดที่เลือกลงในไลบรารีที่จะพิมพ์หรือไม่?',
    enterAutoCreateMatCode: 'กด Enter เพื่อสร้างวัสดุโดยอัตโนมัติ',
    chooseAwaitStoreQuantity:
      'มีบล็อกทั้งหมด {ทั้งหมด} บอร์ดที่จะพิมพ์คือบล็อก {ปริมาณ} เลือกบล็อก {เลือก Quantity} แล้ว',
    defaultFold: 'ข้อมูลบอร์ดถูกปิดโดยค่าเริ่มต้น',
    startArrange: 'เริ่มการพิมพ์',
    importMaterial: 'นำเข้าบิลวัสดุ',
    saveToHistory: 'บันทึกประวัติศาสตร์',
    addPlank: 'เพิ่มบอร์ด',
    deletePlank: 'ลบบอร์ด',
    batchEdit: 'การดัดแปลงแบทช์',
    moreSetting: 'การตั้งค่าเพิ่มเติม',
    plankSize: 'ขนาดแผ่น',
    plankSize2: 'ขนาด (ความยาว*ความกว้าง*ความหนา)',
    searchPlaceholder: 'ชื่อคณะกรรมการค้นหา/หมายเลขบอร์ด',
    checkArranged: 'ดูเลย์เอาต์',
    checkErr: 'ไม่พิมพ์ไม่สามารถดูได้!',
    downloadSheet: 'ดาวน์โหลดตารางตัวอย่าง',
    downloadSheetShowScientific:
      'ดาวน์โหลดตารางตัวอย่าง (การแสดงวิธีสัญลักษณ์ทางวิทยาศาสตร์)',
    exportHoleSlotPDF: 'ไดอะแกรมช่องส่งออกแผ่น',
    style1: 'สไตล์หนึ่ง',
    style2: 'สไตล์ 2',
    style3: 'สไตล์สาม',
    importNoCover: 'ไม่มีการนำเข้าเขียนทับ',
    openBujianPlankRecommend: 'แนะนำให้เปิดบอร์ดเสริม',
    openAwaitStorePlankRecommend:
      'เปิดบอร์ดขนาดเล็กสำหรับคำแนะนำห้องสมุดเค้าโครง',
    openSurplusPlankRecommend: 'แนะนำให้เปิดแผ่นวัสดุที่เหลืออยู่',
    pushToAwaitStore: 'บันทึกไว้ในห้องสมุดประเภท TO-TYPE',
    setTableHead: 'การตั้งค่าส่วนหัวของบิลวัสดุ',
    syncConfirm:
      'ข้อมูลของตู้เก็บของและฟิล์มดั้งเดิมของเมฆเค้าโครงนั้นไม่สอดคล้องกัน! ข้อมูลต้นฉบับของบล็อกตู้หรือไม่?',
    handleSync:
      'หมายเหตุ: การซิงโครไนซ์แบบแมนนวลสามารถทำได้ในการจัดการภาพยนตร์ต้นฉบับ',
    noMoreTip: 'ไม่มีพรอมต์อีกต่อไป',
    checkMore: 'ดูเพิ่มเติม',
    noHistory: 'ไม่มีประวัติที่จะรักษา ...',
    sameOrder:
      'มีคำสั่งซื้อที่ส่งออกไฟล์การผลิตโปรดให้ความสนใจกับปัญหาการผลิตซ้ำ!',
    markOrder: 'การผลิตมีการทำเครื่องหมายไว้ในหน้าคำสั่งซื้อหรือไม่?',
    editErrTip: 'คำสั่งซื้อหลายคำสั่งไม่สนับสนุนข้อมูลการแก้ไข',
    autoSaveSuccess: 'บันทึกไว้ในประวัติศาสตร์โดยอัตโนมัติ!',
    goAdd: 'ไปเพิ่ม',
    noProductionLine:
      'สายการผลิตของบันทึกนี้ถูกลบไปแล้ว โปรดไปที่รายการวัสดุเพื่อพิมพ์ใหม่',
    fileErr: 'การแยกวิเคราะห์เนื้อหาไฟล์ล้มเหลวโปรดยืนยันเนื้อหาไฟล์!',
    noCheckData: 'ไม่พบข้อมูล!',
    noArrangeTip:
      'หากคุณไม่สามารถดูแผนเลย์เอาต์ได้โดยตรงโปรดคลิกเพื่อเริ่มเค้าโครง!',
    tooOld: 'เพชรห้าและหกด้านเก่าเกินไป',
    contactUs: 'กรุณาติดต่อเจ้าหน้าที่บริการหลังการขายเพื่อเชื่อมต่อกับ',
    pathErr:
      'มีข้อมูลที่ผิดปกติภายในบอร์ด (หมายเลขบอร์ด: {ErrorPathPlankId}) โปรดตรวจสอบ!',
    sizeErr: 'แผ่นขนาดใหญ่มากเกินไป! ขนาดบอร์ดขนาดเล็ก: {width}-{ความสูง}',
    noKnife:
      'เส้นผ่านศูนย์กลางของเครื่องมือตัดไม่ได้ตั้งค่าโปรดไปที่เครื่องแกะสลักเพื่อตั้งค่าเครื่องมือ!',
    sendFactorySuccess: 'ส่งไปยังเวิร์กช็อปสำเร็จ',
    sendFactoryErr:
      'การประชุมเชิงปฏิบัติการการจัดส่งล้มเหลวโปรดติดต่อเจ้าหน้าที่หลังการขายเพื่อขอความช่วยเหลือ',
    createTaskCard: 'การ์ดงานที่สอดคล้องกันถูกสร้างขึ้นหรือไม่',
    surplusStatistics: 'สามารถใช้วัสดุที่เหลือทั้งหมดได้',
    surplusStatistics2: 'ที่เลือก',
    suplementStatistic: 'การเติมเต็มบล็อกสามารถใช้งานได้โดยรวม',
    expand: 'ขยาย',
    packUp: 'ปิด',
    importErr: 'โปรดเลือกไฟล์ที่ถูกต้อง!',
    sendLoadingText: 'การประมวลผลและส่งไฟล์ NC ...',
    arrangeLoading: 'ทั้งหมด',
    arrangeLoading2: 'Zhang Xiaoban กำลังเพิ่มประสิทธิภาพเค้าโครง ...',
    dealing: 'กำลังประมวลผล...',
    surplusHead: {
      shape: 'รูปร่าง',
      type: 'พิมพ์',
      remark: 'คำพูด',
      area: 'พื้นที่ (m²)',
    },
    surplusTip: 'ไม่มีวัสดุที่เหลืออยู่คุณสามารถไปก่อน',
    creatSurplus: 'วัสดุที่เหลืออยู่ใหม่',
    orderInfo: 'สั่งซื้อข้อมูล',
    suplement: {
      statistic:
        'มีการใช้บล็อกทั้งหมด {Total} และใช้บล็อกทั้งหมด {useable}} {เลือกบล็อกที่เลือก}',
      exportSuplement: 'ส่งออกรายการเสริม',
      suplementLoading: 'ข้อมูลเสริมกำลังโหลด',
      exportSuplementIng: 'รายการอาหารเสริมกำลังถูกส่งออก',
      noMatchSup: 'ไม่สามารถจับคู่ข้อมูลเสริมที่มีอยู่ได้',
      exportTip: 'โปรดเลือกการเติมเต็มอย่างน้อยหนึ่งครั้งก่อนส่งออก',
    },
    empty: 'ยังไม่มีข้อมูลบอร์ด',
    paibanSetting: {
      title: 'การเรียงพิมพ์',
      selectProduction: 'การเลือกสายการผลิต',
      arrangeWay: 'การเลือกโซลูชันการเรียงพิมพ์',
      suitText: 'ใช้ได้กับ',
      suitEquipmentNormal: 'เครื่องแกะสลักทั่วไป',
      suitEquipmentSpecial: 'เครื่องตัดแก้วเครื่องตัดใบมีด',
      lessRoll: 'พิมพ์ซ้ำน้อยลง',
      computedSpecial: 'คำนวณรูปร่าง',
      specialTip: 'มีอิทธิพลต่อความเร็วของเค้าโครง',
      irregularNesting: 'การทำรังพิเศษ',
      primaryNesting: 'การทำรังปกติ',
      specialShapedPanel: 'บอร์ดธรรมดาที่มีรูปทรงพิเศษ',
      limitedTimeTrial: 'จำกัด ที่จะได้รับการยกเว้น',
      seniorShape: 'การทำรังขั้นสูง',
      heteromorphicInlay: 'รูปร่างพิเศษฝัง',
      autoCutSurplus: 'การตัดวัสดุที่เหลือโดยอัตโนมัติ',
      cutWay: 'วิธีการปลูกพืช',
      maxRect: 'ถูกครอบตัดเป็นสี่เหลี่ยมที่ใหญ่ที่สุด',
      fixedRect: 'ตัดเป็นขนาดคงที่',
      minRectSetting: 'ขนาดขั้นต่ำสำหรับการปลูกพืช',
      firstCutL: 'ตัดวัสดุตกค้างรูปตัว L',
      firstCutLTip:
        'หลังจากเปิดการรวมกันของแผ่นรูปสี่เหลี่ยมผืนผ้าที่ตรงกับเงื่อนไขจะถูกตัดเป็นวัสดุที่ตกค้างรูปตัว L',
      shortLine: 'ด้านสั้นมากกว่า',
      longLine: 'ด้านยาวมีขนาดใหญ่กว่า',
      surplusTemplate: 'เทมเพลตทั่วไปสำหรับวัสดุที่เหลืออยู่',
      add: 'เพิ่ม',
      templateErr:
        'เลือก Crop เป็นขนาดทั่วไปและจำเป็นต้องเลือกเทมเพลตข้อมูลอย่างน้อยหนึ่งแบบ',
      cutLong: 'ความยาวของพืช',
      cutWidth: 'ความกว้างของพืช',
      baseInfo: 'ข้อมูลพื้นฐาน',
      continue: 'ยืนยันและดำเนินการต่อ',
      standardSelect: 'การเลือกบอร์ดขนาดใหญ่มาตรฐาน',
      customPlankOrder: 'ปรับแต่งคำสั่งเลย์เอาต์ของบอร์ดขนาดใหญ่',
      customPlankOrderDetail: 'ไม่ได้อยู่ในบอร์ดขนาดเล็ก',
      customPlankOrderTip: 'เค้าโครงเมฆแบบฝังไม่มีผล',
      surplusPlank: 'รายชื่อบอร์ดขนาดเล็กที่เหลืออยู่',
      overSizePlank: 'รายชื่อชิ้นส่วนบอร์ดขนาดใหญ่',
      plankOrder: 'คำสั่งเรียงลำดับ',
      plankType: 'ประเภทจาน',
      plankSize: 'ขนาดแผ่น',
      plankEdgeOff: 'มูลค่าชายแดน',
      amount: 'การตัดปริมาณ',
      amountMax: 'ปริมาณสูงสุดของการปลดปล่อย',
      numAndOverSizeTip:
        'มีบอร์ดขนาดเล็กที่เกินไม้บรรทัดและบอร์ดขนาดใหญ่ไม่เพียงพอที่จะพิมพ์',
      numErrorTip: 'ไม่สามารถใช้บอร์ดขนาดใหญ่ได้ไม่เพียงพอ',
      overSizeTip: 'มีบอร์ดขนาดเล็กที่ไม่สามารถพิมพ์ได้',
      restPartList: 'ไม่ได้อยู่ในบอร์ดขนาดเล็ก',
      layoutOrder: 'คำสั่งเรียงลำดับ',
      arrangeAmount: 'การตัดปริมาณ',
    },
    filter: {
      title: 'ตัวกรองแผ่น',
      search: 'ค้นหา',
      all: 'เลือกทั้งหมด',
      resetFilter: 'ยกเลิกตัวกรอง',
    },
    edit: {
      title: 'การดัดแปลงแบทช์',
      target: 'ปรับเปลี่ยนวัตถุ',
      onlineSelectRow: 'เลือกแถวเท่านั้น',
      allRow: 'ทั้งหมด',
      editKey: 'จำเป็นต้องแก้ไขเนื้อหา',
      editValue: 'ดัดแปลงเป็น',
      warningTip:
        'หมายเหตุ: หลังจากการดัดแปลงสล็อตรูและรูปร่างพิเศษจะถูกล้างโดยอัตโนมัติ',
      allEdge: 'ตราประทับชายแดนทั้งหมด',
    },
    add: {
      title: 'เพิ่มบอร์ด',
      continue: 'ยืนยันและดำเนินการต่อ',
      matCodeErr: 'วัสดุไม่สามารถว่างเปล่า',
      thickErr: 'ความหนาไม่สามารถว่างเปล่า',
      colorErr: 'สีไม่สามารถว่างเปล่า',
      longErr: 'ความยาวของผลิตภัณฑ์สำเร็จรูปไม่สามารถว่างเปล่าได้',
      widthErr: 'ความกว้างของผลิตภัณฑ์สำเร็จรูปไม่สามารถว่างเปล่าได้',
      countErr: 'จำนวนอย่างน้อย 1',
    },
    delete: {
      tip: 'ไม่สามารถกู้คืนได้หลังจากลบข้อมูล คุณแน่ใจหรือไม่ว่าข้อมูลถูกลบ?',
      errTip: 'โปรดเลือกบอร์ดอย่างน้อยหนึ่งบอร์ดก่อนดำเนินการนี้!',
      errTip1: 'โปรดเลือกอย่างน้อยหนึ่งบอร์ดและพิมพ์',
      confirm: 'ยืนยันการลบ',
    },
    save: {
      errTip: 'ไม่มีข้อมูลที่จะบันทึก!',
      title: 'บันทึกแผน',
      subTitle: '(หมายเหตุ: ประวัติจะถูกบันทึกไว้เป็นเวลาหนึ่งปีเท่านั้น)',
      name: 'ชื่อโปรแกรม',
      historyName: 'แผนประวัติศาสตร์',
      successTip: 'ประหยัดได้สำเร็จ!',
      fieldTip: 'การออมล้มเหลว!',
    },
    exportErr: 'ยังไม่มีบอร์ดไม่สามารถส่งออกได้',
    exportPicErr:
      'พบข้อผิดพลาดที่ไม่รู้จักเมื่อส่งออกไดอะแกรมสล็อตรูของแผ่นโปรดติดต่อเราเพื่อแก้ไข',
    plankNotFound: 'ไม่พบบอร์ดที่เกี่ยวข้อง! โปรดยืนยันว่าจะวาดบอร์ด!',
    noPlankName: 'ไม่พบชื่อบอร์ด',
    plankDataErr:
      'ข้อผิดพลาดข้อมูลบอร์ดโปรดตรวจสอบอย่างรอบคอบเพื่อหลีกเลี่ยงการสูญเสียการผลิต',
    exportThreeViewErr:
      'สามมุมมองของสล็อตหลุมล้มเหลวโปรดติดต่อบริการหลังการขาย',
    heighGlossWarning:
      'หมายเหตุ: เมื่อวัสดุเป็นปากกาเน้นข้อความบอร์ดจะไม่สามารถกู้คืนไปยังปากกาเน้นข้อความได้หลังจากแก้ไขวัสดุ!',
    reset: 'กู้คืนค่าเริ่มต้น',
    settingHead: {
      title: 'การตั้งค่าส่วนหัวของบิลวัสดุ',
      head: 'ส่วนหัว',
      example: 'ตัวอย่าง',
      tip: 'เคล็ดลับการดำเนินงาน: คุณสามารถลากตำแหน่งป้ายข้อมูลตารางซ้ายและขวา',
    },
    copyLine: 'คลิกเพื่อคัดลอกบรรทัดปัจจุบันด้านล่าง',
    copySuccess: 'การคัดลอกข้อมูล {name} สำเร็จ',
    arranging:
      '{count} Zhang Xiaoban ทั้งหมดได้รับการปรับให้เหมาะสมและพิมพ์ ...',
    overSize: {
      title: 'ผู้ปกครองซุปเปอร์',
      subTitle: 'รายชื่อชิ้นส่วนบอร์ดขนาดใหญ่',
      overSizeTip: 'เคล็ดลับสำหรับเพลตที่มีไม้บรรทัดพิเศษ',
      nonWorkInfo: 'รายละเอียดของแผ่นที่ยังไม่ผ่านกระบวนการ',
      conflictWorkInfo: 'รายละเอียดคณะกรรมการความขัดแย้ง',
      warning:
        'ตรวจพบว่าบอร์ดขนาดเล็กนั้นยาวเกินไปซึ่งทำให้มันเป็นไปไม่ได้ที่จะพิมพ์! โปรดปรับขนาดของบอร์ดขนาดเล็กหรือเพิ่มแผ่นต้นฉบับขนาดที่สอดคล้องกัน',
      checkInfo: 'ดูรายละเอียดของบอร์ด',
      tipContent:
        'ระบบตรวจสอบว่ามีบอร์ดขนาดใหญ่ในบอร์ดเลย์เอาต์ ด้านล่างนี้เป็นคำแนะนำสำหรับการเพิ่มบอร์ดพิเศษ คุณสามารถปรับเปลี่ยนความยาวและความกว้างของบอร์ดด้วยตนเองและคลิกปุ่ม <span class = "BOLD COLOR-0"> "เพิ่มการเพิ่มหนึ่งคลิก" ที่ด้านล่างของตาราง หลังจากเพิ่มอย่างรวดเร็วคุณสามารถพิมพ์ต่อไป นอกจากนี้คุณยังสามารถเพิ่มบอร์ดด้วยตนเองใน <span class = "bold color-0"> กระดานการตั้งค่าชีตดั้งเดิม-พิเศษ </span>',
      add: 'เพิ่มด้วยคลิกเดียว',
      addPlank: 'เพิ่มภาพยนตร์ต้นฉบับ',
      error: 'บอร์ดคือ Super Ruler, เค้าโครงล้มเหลว',
      successTip: 'คณะกรรมการได้รับการเพิ่มสำเร็จ! พิมพ์ใหม่',
      warningTip: 'กรุณาเติมความยาวและความกว้างของบอร์ด!',
    },
    arrangeErr: 'มีความผิดปกติในข้อมูลภายในของบอร์ดและไม่สามารถพิมพ์และผลิตได้',
    nonDelete: 'ไม่ลบ',
    addOverSizeTip: 'มีไม้บรรทัดพิเศษโปรดเพิ่มอีกครั้ง',
    addPlankSuccess: 'การเพิ่มบอร์ดพิเศษให้สำเร็จ',
    addPlankFail: 'ไม่สามารถเพิ่มบอร์ดพิเศษ',
    openDir: 'ทิศทางการเปิดประตู',
  },
  arrangedPage: {
    slotErrTip:
      'บอร์ดปัจจุบันมีร่องสำหรับการประมวลผลที่ยึดและไม่รองรับเค้าโครงประเภทใหม่!',
    batchBujianErrTip:
      'บอร์ดปัจจุบันไม่มีอยู่ในข้อมูลต้นฉบับของแผ่นวัสดุ คุณสามารถเลือกประเภทใหม่ในไลบรารีเสริม/ประเภท TO-TYPE',
    useFolderIsNot:
      'ตำแหน่งของการดาวน์โหลดและบันทึกไฟล์ของคุณถูกตรวจพบโดย "{type}" ไม่มีอยู่! โปรดตรวจสอบว่าโฟลเดอร์ถูกลบหรือไม่ หากคุณใช้ไดรฟ์ USB มือถือโปรดตรวจสอบว่าไดรฟ์ USB เชื่อมต่ออย่างถูกต้องหรือไม่',
    useFolderIsNot1:
      'ตรวจพบว่าคุณยังไม่ได้ตั้งค่าตำแหน่งดาวน์โหลดไฟล์โปรดเลือกไดเรกทอรีก่อน',
    downloadNC: 'ดาวน์โหลด NC',
    downloadNC1: 'ดาวน์โหลดไฟล์ NC',
    reArrange: 'ประเภทใหม่',
    saveToHistory: 'บันทึกประวัติศาสตร์',
    moreSetting: 'มากกว่า',
    followExportTag: 'ดาวน์โหลดไฟล์แท็ก NC และส่งออก',
    tagTemplateSelect: 'การเลือกแท็กเทมเพลต',
    showCuttingSequence: 'แสดงคำสั่งตัด',
    showPlankSize: 'แสดงขนาดบอร์ดขนาดเล็ก',
    sendToFactory: {
      title: 'ส่งไปยังเวิร์กช็อป',
      fileType: 'ประเภทไฟล์แท็ก',
    },
    checkData: 'การตรวจสอบความสมบูรณ์ของข้อมูล ...',
    dataLoading: 'ทำงานเพื่อโหลด ...',
    addToBujianStore: 'เพิ่มเข้าไปในคลังสินค้าที่เติมเต็ม ...',
    updateTaskStatus: 'อัปเดตสถานะงาน ...',
    sending: 'ส่งไปยังเวิร์กช็อป',
    waitLoadding: 'รอ',
    loadding: 'การดำเนินการ',
    finish: 'เสร็จ',
    loadSetting: 'กำลังโหลดการตั้งค่า',
    loadPreference: 'การตั้งค่าการโหลด',
    dealTemplate: 'เทมเพลตการประมวลผล',
    initTagData: 'เริ่มต้นข้อมูลแท็ก',
    prepareDrawData: 'เตรียมข้อมูลการวาดฉลาก',
    drawDataText: 'แท็กข้อมูลการวาด',
    genPdfTagFile: 'สร้างไฟล์แท็ก PDF',
    genPicTagFile: 'สร้างไฟล์รูปภาพแท็ก',
    uploadFile: 'อัปโหลดไฟล์',
    dealNCFile: 'ประมวลผลไฟล์ NC',
    zipFile: 'สร้างไฟล์บีบอัดในเครื่อง',
    plankCount: 'จำนวนบอร์ดขนาดใหญ่',
    rollCount: 'จำนวนการพิมพ์ซ้ำ',
    totalRate: 'อัตราการเพิ่มประสิทธิภาพโดยรวม',
    rate: 'อัตราการเพิ่มประสิทธิภาพ',
    useArea: 'พื้นที่ที่ได้รับการปรับปรุง',
    materialList: 'รายการวัสดุ',
    saveSurplus: 'วัสดุที่เหลืออยู่ในคลังสินค้า',
    workeShop: 'ใบสั่งซื้อ',
    holeSlotErr: 'ตรวจพบความลึกของรูผิดปกติของแผ่น!',
    knivesErr: 'ตรวจพบเครื่องมือประมวลผลว่าไม่พบแผ่น!',
    checkDetail: 'ตรวจสอบรายละเอียด',
    deepErr: {
      title: 'ความลึกที่ผิดปกติของช่องเสียบหลุม',
      errStatistic: 'มีความผิดปกติในบอร์ดที่สอง',
      errText: 'ส่วนยกเว้น',
      markErrPart: 'กล่องออกส่วนข้อยกเว้น',
    },
    warningTip:
      'แผ่นมันวาวสูงสองด้าน: กระบวนการแรก <span class = "รูปแบบฟอนต์"> พื้นผิวมันวาวสูง </span> จากนั้นประมวลผล <span class = "รูปแบบฟอนต์"> พื้นผิวด้าน </span>; แผ่นมันวาวสูงด้านเดียว: ประมวลผลโดยตรง <span class = "รูปแบบฟอนต์"> พื้นผิวด้าน </span>',
    showDetail: {
      tip1: 'คลิกเพื่อเลือกบอร์ดเดียว',
      tip2: 'ดูฉลากบอร์ด',
    },
    arrangeTip: '(การจัดเรียงการเรียงลำดับรังรูปกว้างที่รองรับ)',
    plankOpration: 'การทำงานของแผ่น',
    rotate: 'หมุน 90 องศา',
    notCare: 'ไม่มีพื้นผิวบนกระดาน',
    editRemark: 'แก้ไขบันทึกย่อ',
    clearRemark: 'ล้างบันทึกย่อ',
    recalcIndex: 'คำนวณจุดตัดและคำสั่งตัดใหม่',
    recalcIndexSuccess: 'คำนวณจุดตัดและคำสั่งตัดได้สำเร็จ',
    storageArea: {
      title: 'แผ่นพื้นที่จัดเก็บชั่วคราว',
      empty: 'ชิ้นส่วนที่ไม่มีบอร์ดจัดเก็บชั่วคราว',
      storageTip: 'คุณสามารถลากเพลตได้ที่นี่เพื่อจัดเก็บจานชั่วคราว',
    },
    subtleArrange: 'เค้าโครงที่ดี',
    addPlank: 'เพิ่มบอร์ดขนาดใหญ่',
    deletePlank: 'ลบบอร์ดขนาดใหญ่',
    deletePlankErr: 'ปัจจุบันมีบอร์ดขนาดเล็กและไม่สามารถลบบอร์ดขนาดใหญ่ได้!',
    useSurplus: {
      title: 'ใช้วัสดุที่เหลืออยู่',
    },
    dragWarning: 'กรุณาลากจานไปที่จานขนาดใหญ่!',
    areaWarning: 'ไม่สามารถวางบอร์ดได้ให้ความสนใจกับขนาดของพื้นที่ว่าง',
    subtlePage: {
      cut: 'การตัดวัสดุที่เหลืออยู่',
      cancelCut: 'จบการตัด',
      cutPoint: 'จุดดูดซับวัสดุที่เหลืออยู่',
      storageArea: 'ชิ้นส่วนจานที่จะจัดเรียง',
      cutErr: 'คุณสามารถตัดบนแผ่นขนาดใหญ่และไม่สามารถสัมผัสแผ่นเล็ก ๆ ได้!',
      saveErr: 'กรุณาเลือกวัสดุตัด',
      cutErr2: 'ตัดสามารถทำได้บนแผ่นขนาดใหญ่และไม่เกินแผ่นขนาดใหญ่!',
      selectTip: 'กรุณาเลือกชิ้นส่วนสำรอง',
      noCutPath: 'รูปร่างไม่สามารถตัดเป็นสี่เหลี่ยมได้',
      cutOperate: 'การดำเนินการตัดวัสดุที่เหลืออยู่',
      line: 'จังหวะด้วยตนเอง',
    },
    shortCut: 'คีย์ทางลัด',
    updownRoll: 'ปัดขึ้นและลง',
    leftRightRoll: 'เลื่อนไปทางซ้ายและขวา',
    zoomScale: 'ซูมเข้าและออก',
    dragCanvas: 'ลากหน้า',
    mouseRoll: 'ล้อเลื่อนเมาส์',
    mouseRight: 'ปุ่มเมาส์ขวา',
    doublePlank: 'ดับเบิลคลิกที่กระดานใหญ่',
    keyboard: 'คีย์บอร์ด',
    picSymble: 'ตำนาน',
    funished: 'สินค้าสำเร็จรูป',
    holeSlotConflict1: 'ความขัดแย้งของหลุม',
    plankErr: 'ความขัดแย้งของแผ่น',
    frontSlot: 'ช่องดึงด้านหน้า',
    backSlot: 'สล็อตดึงย้อนกลับ',
    frontHole: 'ตำแหน่งของหน้าด้านหน้า',
    backHole: 'ตำแหน่งใบหน้าย้อนกลับ',
    resetScale: 'รีเซ็ตซูม',
    maxLong: 'ยาวนานที่สุด',
    tempErr:
      'ไม่สามารถเพิ่มตำแหน่งเทมเพลตที่ไม่ถูกต้องหรือเทมเพลตขนาดใหญ่เกินไป!',
    sendErr:
      'การประชุมเชิงปฏิบัติการการส่งล้มเหลว โปรดตรวจสอบว่าเทมเพลตฉลากและข้อมูลบอร์ดนั้นถูกต้องก่อนที่จะส่งอีกครั้งหรือไม่',
    someTips:
      'คุณกำลังลองใช้ฟังก์ชั่นเค้าโครงรูปทรงเมฆในระยะเวลาที่ จำกัด โปรดระวังเพื่อหลีกเลี่ยงปัญหาการผลิต!',
    cutOrderErr: 'คำสั่งตัดไม่ถูกต้องโปรดคลิกเพื่อคำนวณจุดตัดและคำสั่งตัด!',
    holeSlotConflict:
      'ตรวจพบว่าความขัดแย้งของหลุมและสล็อตของจานนั้นบินออกจากแผ่นเล็ก ๆ',
    exportSetting: 'การตั้งค่าการส่งออก',
    specialPaibanErr:
      'ชิ้นส่วนที่ชุบมีกระบวนการพิเศษหรือร่องประมวลผลขอบและยังไม่ได้รับการสนับสนุนอีกครั้ง!',
    moreResult: 'ผลลัพธ์การเรียงพิมพ์เพิ่มเติม',
    moreResultTip:
      'มีร่องสำหรับการบดในจานขนาดใหญ่และแผ่นขนาดใหญ่จะไม่เข้าร่วมในรูปแบบประเภทใหม่',
    batchBujian: 'การเติมเต็มชุด',
    disPlaySetting: 'แสดงการตั้งค่า',
    stored: 'ได้ถูกนำไปใส่ในคลังสินค้า',
    onlySurplus: 'เพียงแค่ดูวัสดุที่เหลืออยู่',
    UnuseSurplus:
      'วัสดุที่เหลืออยู่ไม่ได้มีส่วนร่วมในการคำนวณอัตราการใช้ประโยชน์',
    usePlankAdsorption: 'ฟังก์ชั่นการดูดซับแผ่น',
    dataDefaultCollapsed: 'ข้อมูลถูกปิดโดยค่าเริ่มต้น',
    unit: 'ชิ้นส่วน',
    cutSurplus: 'ตัดวัสดุที่เหลือออก',
    frontWarningTip:
      'แผ่นเงาสูงสองด้าน: ประมวลผลพื้นผิวด้านแรกจากนั้นประมวลผลเพลตมันวาวสูง แผ่นเงาวาวสูงด้านเดียว: ประมวลผลพื้นผิวมันวาวสูงโดยตรง',
    selectTip: 'โปรดเลือกบอร์ดขนาดเล็กด้านล่างเพื่อต้องการอาหารเสริม!',
    plankKeyWordPlaceholder: 'กรุณากรอกชื่อบอร์ดหรือหมายเลขบอร์ด',
    surplusStoreSuccess: 'เข้าสู่คลังสินค้าได้สำเร็จ',
    editCutOrder: 'ปรับเปลี่ยนลำดับการตัด',
    setCutPoint: 'ตั้งจุดตัดลง',
    specialPaibanChangeErr:
      'ชิ้นส่วนที่ชุบมีกระบวนการพิเศษหรือร่องการประมวลผล Welt และรูปแบบเค้าโครงสลับยังไม่รองรับ!',
    storedSuccess:
      'การเติมเต็มที่ประสบความสำเร็จ! บอร์ดที่เลือกได้ถูกวางไว้ในการจัดการการเติมเต็ม!',
    noSurplus: 'ไม่พบวัสดุที่เหลืออยู่',
    selectErrTip:
      'แผ่นที่เหลือไม่ได้รับอนุญาตให้เติมโปรดเลือกแผ่นเล็ก ๆ ปกติก่อน',
    noRoomIDTip:
      'บอร์ดนี้ไม่มีรหัสห้องและไม่สามารถเติมเต็มได้ กรุณาเลือกบอร์ดอื่นก่อน',
    noPlank: 'ไม่พบบอร์ด',
    noPaibanData: 'ไม่มีข้อมูลการเรียงพิมพ์ไม่สามารถทำได้',
    historyNameErr: 'กรุณากรอกชื่อแผน',
    tempErrTip:
      'ตรวจพบว่าคุณไม่ได้เพิ่มเทมเพลตที่ใช้สำหรับการฝึกซ้อมห้า/หกด้านเพื่อไม่สามารถส่งออกไฟล์สว่านห้า/หกด้านได้ หมายเหตุ: คุณต้องพิมพ์ใหม่อีกครั้งหลังจากการเพิ่มเสร็จสิ้น',
    changeDataTip:
      'หมายเหตุ: คุณสร้างไฟล์ประวัติศาสตร์ใหม่เพื่อบันทึกเนื้อหาของการเปลี่ยนแปลงนี้เมื่อคุณตรวจพบว่าข้อมูลของคุณมีการเปลี่ยนแปลงหรือไม่?',
    upgrade: 'อัพเกรด',
    upgradeTip: 'คุณสามารถคลิกปุ่มด้านล่างเพื่ออัพเกรดเวอร์ชัน',
    plankOverEdge:
      'NC ดาวน์โหลดล้มเหลว! โปรดตรวจสอบว่าแผ่นซ้อนทับกันหรือแผ่นอยู่ด้านนอกแผ่นขนาดใหญ่',
    useSurplusTip: 'ไม่ว่าจะลดวัสดุที่เหลืออยู่',
    noDataExport: 'ไม่มีข้อมูลการพิมพ์ส่งออก',
    noPlankExport: 'ไม่มีข้อมูลบอร์ดขนาดเล็กที่สามารถส่งออกได้',
    lockTip1: 'คลิกเพื่อปลดล็อกหลังจากปลดล็อค',
    lockTip2: 'แก้ไขตำแหน่งและคุณสมบัติของคณะกรรมการ',
    unlockTip1: 'คลิกเพื่อล็อคไม่สามารถล็อคได้หลังจากล็อค',
    unlockTip2: 'การแก้ไขตำแหน่งบอร์ดและคุณลักษณะ',
    plankLockErr: 'แผ่นขนาดใหญ่ถูกล็อคและไม่สามารถวางได้',
    lockPartEditTip: 'แผ่นถูกล็อคและไม่สามารถแก้ไขได้',
    glassEquipmentTip:
      'เมื่อสายการผลิตเป็นเครื่องตัดแก้วแผ่นไม่สามารถเคลื่อนย้ายได้',
    dragErrTip: 'ไม่สามารถวางได้โปรดตรวจสอบวัสดุสีและความหนาของจาน!',
    subtleSaveTip: 'คณะกรรมการดำเนินการแล้ว แต่ยังไม่ได้รับการบันทึก ออกจาก?',
    rollTip: 'มีผ่านรู/รางบนจานขนาดใหญ่และไม่แนะนำให้พิมพ์ซ้ำ!',
    glossRollErr: 'ห้ามใช้บอร์ดมันวาวสูง',
    lockDeleteErr: 'แผ่นถูกล็อคและไม่สามารถลบได้',
    deleteConfirmTip: 'การลบการดำเนินการจะไม่ถูกกู้คืน',
    deleteContinueTip: 'คุณต้องการดำเนินการต่อหรือไม่?',
    deleteConfirm: 'โปรดยืนยันว่าจะลบหรือไม่',
    selectPlankTip: 'โปรดเลือกบอร์ดก่อนก่อนดำเนินการนี้',
    storeFailTip:
      'วัสดุที่เหลือไม่สามารถเข้าคลังสินค้าได้! โปรดตรวจสอบว่าแผ่นซ้อนทับกันหรือแผ่นอยู่ด้านนอกแผ่นขนาดใหญ่',
    repeatStoreErr: 'แผ่นวัสดุที่เหลืออยู่ในปัจจุบันได้ถูกนำไปใส่ในคลังสินค้า',
    surplusStoreFail:
      'หากการจัดเก็บล้มเหลวโปรดตรวจสอบว่าจานเป็นแผ่นรูปสี่เหลี่ยมผืนผ้าหรือรูปตัว L!',
    plankConfilictErr:
      'ไม่ได้รับการสนับสนุนให้วางจุดที่นี่ซึ่งจะขัดแย้งกับแผ่นอื่น ๆ',
    surplusErrTip:
      'ไม่ได้รับการสนับสนุนให้วางจุดที่นี่จะมีการสร้างวัสดุที่ตกค้างผิดพลาด',
    changeArrageSuccess: 'เปลี่ยนรูปแบบเค้าโครงสำเร็จ',
    trialProduction: {
      trialProductionSample: 'ตู้เก็บตัวอย่างทดลองใช้',
      trialProduced: 'ฉันได้ลองผลิต',
      trialWarning:
        'คุณจะแบกรับความสูญเสียและผลกระทบที่ไม่พึงประสงค์ทั้งหมดต่อตัวคุณเองอื่น ๆ และซอฟต์แวร์เลย์เอาต์คลาวด์เนื่องจากความล้มเหลวในการตรวจสอบการผลิตของตู้ตัวอย่าง',
      trialSlotTip:
        'ในระหว่างการผลิตทดลองโปรดตรวจสอบอย่างระมัดระวังว่าขนาดความลึกพื้นผิวและที่ตั้งของช่องด้านหน้าและด้านหลังนั้นถูกต้องหรือไม่',
      trialSlotTip1: 'หลีกเลี่ยงการมิเรอร์ของตู้',
      trialSlotTip2: 'ปัญหา',
      pleaseUploadTrial:
        'กรุณาอัปโหลดรูปภาพของคณะรัฐมนตรีตัวอย่างการผลิตทดลองประกอบ',
      uploadTrial: 'อัพโหลดตัวอย่างตู้',
    },
    awaitPlankStore: {
      store: 'ชิ้นส่วนบอร์ดที่จะจัดในห้องสมุดบอร์ด',
      notTip: 'ไม่มีการแจ้งเตือนอีกต่อไปว่ายังไม่ได้ป้อน',
      justLookCurrent: 'ดูเฉพาะบอร์ดคำสั่งซื้อปัจจุบัน',
      notInPushStorage: 'ไม่รวมอยู่ในคลังสินค้า',
      beInStorage: 'ได้ถูกนำไปใส่ในคลังสินค้า',
      searchPlaceholder:
        'ป้อนคำสั่งซื้อเพื่อค้นหาบอร์ดขนาดเล็กของห้องสมุด TO-type',
      plankUploading: 'กระดานจะถูกบันทึกไว้',
      oneClickStorage: 'ที่เก็บข้อมูลเดียวที่จะจัดเรียง',
      notUsePlank: 'ไม่มีบอร์ดขนาดเล็ก',
      notPlank: 'ชิ้นส่วนที่ไม่มีบอร์ดจัดเก็บชั่วคราว',
      dragPlankInHere: 'คุณสามารถลากเพลตได้ที่นี่เพื่อจัดเก็บจานชั่วคราว',
      surplusCannotBeStorage: 'ไม่สามารถเก็บวัสดุที่เหลืออยู่ได้',
      plankHasBeenDeduction:
        'บอร์ดได้ถูกหักออกหรือไม่มีข้อมูลต้นฉบับและไม่สามารถเก็บไว้ในฐานข้อมูล',
      plankLoadErr: 'ข้อมูลบอร์ดไม่สามารถโหลดได้',
      taskTitle: 'อัปโหลดบอร์ดในห้องสมุดที่จะพิมพ์',
      dataGenErr: 'ข้อผิดพลาดในการสร้างข้อมูล',
      pushSuccess: 'เข้าสู่คลังสินค้าได้สำเร็จ',
      pushErr: 'เกิดข้อผิดพลาดที่ไม่รู้จักและไม่สามารถป้อนฐานข้อมูลได้',
      awaitBoxExistPlank:
        'ห้องสมุดเลย์เอาต์ไม่รวมอยู่ในบอร์ดขนาดเล็กและขอแนะนำให้ทำงานหลังจากเข้าสู่ห้องสมุด',
      checkPlankStatus: 'ตรวจสอบสถานะของคณะกรรมการ ...',
    },
    batchSetCut: 'ตั้งจุดตัดลงในแบทช์',
    applyTo: 'ใช้ได้กับ',
    ncDownloadLifeText: {
      checkData: 'ตรวจพบข้อมูล',
      checkDone: 'การตรวจจับข้อมูลเสร็จสมบูรณ์',
      checkDataText: 'การตรวจจับข้อมูล',
      loadSetting: 'กำลังโหลดการตั้งค่า',
      settingLoadDone: 'การตั้งค่าการโหลดเสร็จสมบูรณ์',
      loadSettingText: 'การตั้งค่าการโหลด',
      downloadNc: 'ดาวน์โหลด NC',
      ncDownloadDone: 'NC ดาวน์โหลดเสร็จสมบูรณ์',
      downloadText: 'ดาวน์โหลด NC',
      loadPreferenceSetting: 'การตั้งค่าการโหลด',
      PreferenceSettingLoadDone: 'การตั้งค่าการโหลดเสร็จสมบูรณ์',
      loadPreferenceText: 'การตั้งค่าการโหลด',
      dealTemp: 'ทำงานกับแม่แบบ',
      TempDealDone: 'การประมวลผลแม่แบบเสร็จสมบูรณ์',
      dealTempText: 'เทมเพลตการประมวลผล',
      beBeingGenTagFile: 'การสร้างไฟล์ {fileType}',
      genTagFileDone: 'สร้างไฟล์ {fileType} ให้เสร็จสมบูรณ์',
      genTagFileText: 'สร้างไฟล์ {fileType}',
      zip: 'สร้างไฟล์บีบอัดในเครื่อง',
      zipDone: 'สร้างไฟล์บีบอัดในเครื่องเพื่อให้เสร็จสมบูรณ์',
      genZipText: 'สร้างไฟล์ที่ถูกบีบอัด',
      fileTypeText: 'รูปภาพ',
    },
    surplusCommonTemp: 'เทมเพลตทั่วไปสำหรับวัสดุที่เหลืออยู่',
    plankErrTip:
      'มีบอร์ดขนาดเล็กและไม่สามารถใช้บอร์ดขนาดใหญ่สำหรับเค้าโครงได้!',
    overSizeTip: 'บอร์ดขนาดใหญ่จำนวนไม่เพียงพอหรือสูงกว่าไม้บรรทัด',
    uselessPart: 'ขณะนี้ไม่มีบอร์ดขนาดเล็กบนกระดานขนาดใหญ่',
    splitArranged: 'มีบอร์ดขนาดเล็กมากเกินไปดังนั้นจึงขอแนะนำให้พิมพ์เป็นแบทช์',
    arrangedErrTip:
      'การเรียงพิมพ์ล้มเหลวมีบอร์ดขนาดใหญ่หรือบอร์ดขนาดเล็กจำนวนไม่เพียงพอ',
    knivesModalTitle: 'ไม่พบเครื่องมือในช่องหลุม',
    cannotDownloadByKnivesErr:
      'ไม่สามารถดาวน์โหลด NC ได้ระบบตรวจพบว่ามีรูต่อไปนี้เครื่องมือไม่สามารถหาได้',
    viewPart: 'ตรวจสอบกระดานคำถาม',
    deductionModalTitle: 'โปรดเลือกวัตถุหัก',
    bujianDeduction: 'ลดบอร์ดเสริม',
    paibanDeduction: 'หักพื้นที่จัดเก็บชั่วคราวบอร์ดขนาดเล็ก',
    surplusDeduction: 'หักวัสดุที่เหลือแผ่นขนาดใหญ่',
    unlockPaiban: 'ปลดล็อกวัสดุที่เหลืออยู่ที่เกี่ยวข้องในเลย์เอาต์',
    maintain: 'รักษาการล็อควัสดุตกค้างที่เกี่ยวข้องกับการเรียงพิมพ์',
    lockModalText:
      'มีวัสดุตกค้างขนาดใหญ่ที่เคยครอบครองโดยคำสั่งซื้ออื่น ๆ มันพิมพ์ใหม่อีกครั้งหรือไม่?',
    lockNewSModalText:
      'ขณะนี้ใช้วัสดุที่เหลืออยู่จำนวนมากถูกครอบครองโดยคำสั่งซื้ออื่น ๆ ฉันควรดาวน์โหลด NC ต่อไปหรือไม่?',
    rePaibanWarn:
      'แผ่นวัสดุที่เหลือขนาดใหญ่จะถูกครอบครองโดยคำสั่งซื้ออื่น ๆ และแผ่นขนาดใหญ่ไม่เพียงพอ โปรดไปที่รายการวัสดุเพื่อจัดเรียงใหม่',
    sawRePaiban:
      'หมายเหตุ: คุณกำลังใช้การตัดใบเลื่อยและมีการเพิ่มเค้าโครงแผ่นรูปทรงพิเศษ คุณต้องพิมพ์ไฟล์ NC อีกครั้งเพื่อส่งออก',
  },
  historyPage: {
    download: 'ดาวน์โหลดในพื้นที่',
    allType: 'บันทึกทั้งหมด',
    handleSave: 'บันทึกด้วยตนเอง',
    autoSave: 'บันทึกโดยอัตโนมัติ',
    allArrange: 'การพิมพ์ทั้งหมด',
    searchPlaceholder: 'ชื่อใบสั่งค้นหา / batch การเรียงลำดับ',
    trailProduction: {
      title: 'ตู้เก็บตัวอย่างทดลองใช้',
      lastTime: 'วันที่ผลิตทดลองใช้ครั้งล่าสุด',
      recordList: 'บันทึกการผลิตทดลองใช้',
      start: 'เริ่มการผลิตทดลองใช้',
      date: 'เวลา',
      outputFrom: 'ตำแหน่งเอาต์พุต',
      empty: 'ยังไม่มีข้อมูล',
      record: 'บันทึกรูปภาพที่อัปโหลดของคณะรัฐมนตรีตัวอย่างการผลิตทดลองใช้',
      tip1: 'หมายเหตุ: ใบสั่งการผลิตทดลองใช้ปัจจุบันใช้ข้อมูลการผลิตเริ่มต้น',
      tip2: 'หมายเหตุ: ใบสั่งการผลิตทดลองใช้ปัจจุบันไม่ได้ใช้พารามิเตอร์การตั้งค่ากระบวนการล่าสุดดังนั้นคุณสามารถไปที่ห้องผลิตทดลองเพื่อบันทึกอีกครั้ง!',
      download: 'ดาวน์โหลดไฟล์ประมวลผล',
    },
    deleteTip: 'คุณแน่ใจว่าจะลบประวัติต่อไปนี้หรือไม่?',
    startDate: 'วันที่เริ่มต้น',
    endDate: 'วันที่สิ้นสุด',
    lastWeek: 'สัปดาห์ที่แล้ว',
    lastMonth: 'เดือนที่แล้ว',
    lastSeason: 'เดือนมีนาคมที่ผ่านมา',
    toArrangePage: 'ไปที่หน้าเค้าโครง',
    notArrangedTip: 'บันทึกนี้ยังไม่ได้รับการพิมพ์โปรดไปที่รายการวัสดุก่อน!',
    toMaterialPage: 'ไปที่หน้า Bill of Material',
    lessSelectTip: 'โปรดเลือกอย่างน้อยหนึ่งระเบียนก่อนดำเนินการนี้',
    downloadNc: 'ดาวน์โหลดไฟล์ประมวลผล',
  },
  workShopPage: {
    warningTip:
      'ฟังก์ชั่นนี้เป็นการทดลองใช้เวลา จำกัด โปรดจัดเรียงคำสั่งซื้อของคุณอย่างสมเหตุสมผล!',
    fileManager: 'การจัดการไฟล์การผลิต',
    selected: 'ที่เลือก',
    suplement: 'การเติมเต็ม',
    size: 'ขนาด',
    saveToStore: 'เพิ่มไปยังคลังสินค้าเติมเต็ม',
    enterArrange: 'เข้าสู่การพิมพ์ ...',
    deleteTip:
      'ไม่สามารถกู้คืนได้หลังจากลบข้อมูล คุณแน่ใจหรือไม่ว่าข้อมูลถูกลบ?',
    arrangeTip: 'ความละเอียดของข้อมูลเสริมโปรดรอ ...',
    store: 'คลังสินค้าที่เติมเต็ม',
    downloadFile: 'ดาวน์โหลดไฟล์การผลิต',
    notBujianFile: 'ไม่พบไฟล์เสริม',
    productLineDelete: 'สายการผลิตถูกลบและไม่สามารถดาวน์โหลดไฟล์ NC ได้!',
  },
  basePlankManager: {
    saveTip: 'หมายเหตุ: กด Enter เพื่อบันทึก',
    offsetSize: 'ขนาดตัดแต่ง',
    overSizeTip:
      'เมื่อขนาดมีขนาดใหญ่เกินไปบอร์ดขนาดและขนาดที่ใหญ่กว่าจะถูกเลือกโดยอัตโนมัติสำหรับเค้าโครง',
    addTip:
      'หากบอร์ดสิ้นสุดลงในระหว่างการจัดวางหน้าต่างป๊อปอัพบอร์ดขนาดใหญ่พิเศษจะถูกเพิ่มเข้ามาในป๊อปอัพโดยอัตโนมัติ',
    onlyLayoutTooBig: 'แผ่นขนาดใหญ่ขนาดพิเศษจะเรียงรายไปด้วยแผ่นเล็กขนาดพิเศษ',
    settingRemark:
      'หมายเหตุ: ฟังก์ชั่นดั้งเดิมที่นี่ถูกปรับให้เข้ากับการตั้งค่าที่ถูกต้อง',
    searchPlaceholder: 'ค้นหาวัสดุบอร์ด/สี',
    syncData: 'ซิงโครไนซ์ข้อมูลตู้',
    syncDataM: 'ซิงโครไนซ์ข้อมูลประตูตู้',
    deletePlank: 'ลบบอร์ดพิเศษ',
    addPlank: 'บอร์ดพิเศษใหม่',
    matCodePlaceholder: 'กรุณาเลือกจาน',
    thickPlaceHolder: 'โปรดเลือกความหนา',
    widthErr: 'ความกว้างของแผ่นไม่สามารถว่างเปล่า',
    heightErr: 'ความยาวของแผ่นไม่สามารถว่างเปล่า',
    matCodeErr: 'วัสดุของบอร์ดไม่สามารถว่างเปล่าได้',
    colorErr: 'สีของแผ่นไม่สามารถว่างเปล่า',
    editPlank: 'แก้ไขบอร์ด',
    deleteTip:
      'ไม่สามารถกู้คืนได้หลังจากลบข้อมูล คุณแน่ใจหรือไม่ว่าข้อมูลถูกลบ?',
    plankKnifeSelect: 'การเลือกมีดตัดแผ่นเดิม',
    knifeSelectTip:
      'หมายเหตุ: คุณสมบัตินี้ยังไม่รองรับในการเรียงพิมพ์แบบฝังตัว',
    knife: 'เครื่องมือตัด',
    matcodeInput: 'โปรดป้อนเนื้อหาของบอร์ด',
    thickInput: 'โปรดป้อนความหนาของบอร์ด',
    default: 'ค่าเริ่มต้นของระบบ',
    unessential: 'คอลัมน์นี้คือ: ไม่ต้องการ',
    emptyTip:
      'หมายเหตุ: หากคุณไม่กรอกรายการนี้คุณจะไม่แยกความแตกต่างของรายการนี้เมื่อจับคู่ชิ้นส่วนต้นฉบับ!',
    defaultUse: 'เลือกโดยค่าเริ่มต้น',
    editMatcode: 'แก้ไขวัสดุ',
    syncTip:
      'ข้อมูลใน {frommc} จะได้รับและเขียนทับข้อมูลการจัดการชิ้นเมฆเมฆ zhongyuan ที่มีอยู่ มันซิงโครไนซ์หรือไม่?',
    sameErr: 'มีข้อมูลเดียวกันอยู่แล้ว',
    cancelDelete: 'ลบล้าง',
    syncErr: 'การซิงโครไนซ์ล้มเหลว',
    syncSuccess: 'การซิงโครไนซ์ประสบความสำเร็จ',
    syncDataTip: 'ซิงโครไนซ์ {frommc} ข้อมูล',
    editErrTip: 'มีข้อมูลที่ไม่สำเร็จโปรดตรวจสอบ!',
    numberCheck: '{type} สามารถป้อนหมายเลขเท่านั้น!',
    deleteStandardPlate: 'ลบบอร์ดมาตรฐาน',
    createStandardPlate: 'บอร์ดมาตรฐานใหม่',
    editStandardPlate: 'แก้ไขบอร์ดมาตรฐาน',
    trimmingDimension: 'ขนาดตัดแต่ง',
  },
  bujianAndStore: {
    bujianStore: 'คลังสินค้าที่เติมเต็ม',
    awaitStore: 'บอร์ดที่จะพิมพ์ไลบรารี',
    depositAwaitStore: 'บันทึกไว้ในห้องสมุดประเภท TO-TYPE',
    pushToAwaitStore: 'ใส่ไว้ในห้องสมุดที่จะพิมพ์',
    useTwoTableArrange: 'ใช้สองรูปแบบในการพิมพ์',
    confirmUseTwoTableTip:
      'บอร์ดขนาดเล็กที่เลือกในปัจจุบันมีห้องสมุดเสริมและห้องสมุด TO-type บอร์ดขนาดเล็กจะยังคงพิมพ์ต่อไปหรือไม่?',
    confirmAddPlankToAwaitStore:
      'คุณแน่ใจว่าจะเพิ่มบอร์ดที่เลือกลงในไลบรารีที่จะพิมพ์หรือไม่?',
    pushStoreSuccess: 'เข้าสู่คลังสินค้าได้สำเร็จ',
    dataGenErr: 'ข้อผิดพลาดในการสร้างข้อมูล',
    unknownErr: 'เกิดข้อผิดพลาดที่ไม่รู้จักและไม่สามารถป้อนฐานข้อมูลได้',
  },
  surplusPage: {
    surplusMaterial: 'รายการวัสดุที่เหลืออยู่',
    surplusStore: 'คลังสินค้าวัสดุที่เหลืออยู่',
    surplusShape: 'รูปร่างวัสดุที่เหลืออยู่',
    batchTransfer: 'การถ่ายโอนแบทช์',
    createNewSurplusStore: 'คลังสินค้าวัสดุส่วนเกินใหม่',
    pushStoreSetting: 'การตั้งค่าการจัดเก็บวัสดุที่เหลืออยู่',
    surplusStoreName: 'ชื่อของไซโล',
    surplusStoreName2: 'ชื่อคลังสินค้าวัสดุส่วนเกิน',
    addSurplusStore: 'เพิ่มคลังสินค้าวัสดุที่เหลืออยู่',
    inventoryCeiling: 'หมวกสต็อก',
    autoTransferTip:
      'หลังจากการลบวัสดุที่เหลือในคลังสินค้าวัสดุที่เหลือจะถูกถ่ายโอนไปยังรายการวัสดุที่เหลือโดยอัตโนมัติ',
    asIndexPushStore: 'เข้าสู่คลังสินค้าตามลำดับการจัดเก็บ',
    pushToSurplusMaterial: 'บันทึกรายการวัสดุที่เหลืออยู่',
    surplusStoreTable: {
      number: 'หมายเลขซีเรียล',
      name: 'ชื่อคลังสินค้าวัสดุส่วนเกิน',
      quantity: 'ปริมาณวัสดุที่เหลืออยู่ (ชิ้น)',
      capacity: 'ขีด จำกัด สูงสุดของสินค้าคงคลังวัสดุที่เหลืออยู่ (ชิ้น)',
      remark: 'คำพูด',
      operation: 'ดำเนินงาน',
    },
    editSurplusStore: 'แก้ไขห้องสมุดส่วนเกิน',
    addSurplus: 'เพิ่มวัสดุที่เหลืออยู่',
    deletSurplus: 'ลบวัสดุที่เหลืออยู่',
    editSurplus: 'ปรับเปลี่ยนวัสดุที่เหลืออยู่',
    surplusNoRoll: 'เปิดใช้งานวัสดุที่เหลือโดยไม่ต้องพิมพ์ซ้ำ',
    type: {
      all: 'รูปร่างทั้งหมด',
    },
    allMatcode: 'วัสดุทั้งหมด',
    allThick: 'ความหนาทั้งหมด',
    surplusName: 'ชื่อของวัสดุที่เหลืออยู่',
    rectSurplus: 'วัสดุที่เหลือเป็นรูปสี่เหลี่ยมผืนผ้า',
    LTypeSurplus: 'วัสดุตกค้างรูปตัว L',
    noMatchSup: 'ไม่พบข้อมูลที่เกี่ยวข้อง',
    jumpTo: 'ข้ามไป',
    page: 'หน้าหนังสือ',
    newMatcode: 'วัสดุก่อสร้างใหม่',
    newColor: 'สร้างสีใหม่',
    autoCreateColor: 'กด Enter เพื่อสร้างการ์ดสีโดยอัตโนมัติ',
    nameLimit: 'ป้อนมากถึง 10 คำในชื่อวัสดุที่เหลืออยู่',
    nameLimitNameErr: 'ชื่อวัสดุที่เหลือไม่สามารถว่างเปล่า',
    tempLongTip: 'สามารถป้อนตัวเลขได้ตามความยาวของบอร์ดเท่านั้น',
    minLongLimit: 'สามารถป้อนตัวเลขได้ในระยะสั้น ๆ',
    minLongEmptyTip: 'ความยาวด้านสั้นไม่สามารถว่างเปล่าได้',
    minLongLimitMax: 'ความยาวของด้านสั้นไม่เกินความยาวของบอร์ด',
    widthLimit: 'สามารถป้อนตัวเลขได้เท่านั้นในความกว้างของบอร์ด',
    minWidthLimit: 'สามารถป้อนตัวเลขได้เฉพาะในความกว้างด้านสั้น ๆ',
    minWidthEmptyLimit: 'ความกว้างด้านสั้นไม่สามารถว่างเปล่าได้',
    minWidthLimitMax: 'ความกว้างของด้านสั้นไม่เกินความกว้างของบอร์ด',
    thickLimit: 'สามารถป้อนตัวเลขได้เท่านั้นในความหนาของบอร์ด',
    thickEmptyLimit: 'ความหนาของแผ่นไม่สามารถว่างเปล่า',
    countInputLimit: 'จำนวนสามารถเป็นจำนวนเต็มบวกเท่านั้น',
    countEmptyLimit: 'ปริมาณไม่สามารถว่างเปล่า',
    remarkMaxlimit: 'ป้อนคำศัพท์ได้มากถึง 50 คำในบันทึกย่อ',
    orderAddressMaxlimit: 'ป้อนคำศัพท์ได้มากถึง 100 คำจากที่อยู่ลำดับต้นทาง',
    orderIdMaxlimit: 'ป้อนคำศัพท์ได้มากถึง 100 คำ',
    createTime: 'เพิ่มเวลา',
    branchName: 'คลังสินค้าวัสดุที่เหลืออยู่',
    orderAddress: 'ที่อยู่ลำดับต้นทาง',
    orderId: 'หมายเลขลำดับต้นทาง',
    lockStatus: 'สถานะล็อค',
    lockOrder: 'คำสั่งล็อค',
    enableSurplusLock: 'เปิดใช้งานฟังก์ชั่นการล็อควัสดุที่เหลืออยู่',
    isCloseSurplusLock:
      'หลังจากปิดฟังก์ชั่นวัสดุที่เหลือทั้งหมดจะถูกปลดล็อค ไม่ว่าจะดำเนินการต่อ',
    confirm: 'ยืนยันที่จะปิด',
    surplusTransfer: 'การถ่ายโอนวัสดุที่เหลืออยู่',
    targetSurplusStore: 'เป้าหมายคลังสินค้าวัสดุตกค้าง',
    enableSurLockTip:
      'หลังจากเปิดทุกเลย์เอาต์จะล็อควัสดุที่เหลือที่ใช้เพื่อป้องกันไม่ให้คำสั่งซื้ออื่น ๆ จากการครอบครอง',
  },
  printPage: {
    deleteWarning: 'เคล็ดลับชนิด',
    print: 'พิมพ์',
    defaultTemplate: 'เทมเพลตเริ่มต้น (60 x 40)',
    editTemplate: 'แก้ไขเทมเพลต',
    deleteTip:
      'ไม่สามารถกู้คืนได้หลังจากลบเทมเพลต คุณแน่ใจว่าจะลบเทมเพลตได้หรือไม่?',
    tagSetting: 'การตั้งค่าแท็ก',
    rememberSetting: 'หลังจากการแก้ไขการตั้งค่าจะถูกจดจำ',
    genCutingTag: 'สร้างฉลากตัด',
    genSurplusTag: 'สร้างฉลากวัสดุที่เหลืออยู่',
    genPlankTag: 'สร้างฉลากบอร์ดขนาดใหญ่',
    genPartTag: 'สร้างแท็กบอร์ดขนาดเล็ก',
    tagPosition: 'ตำแหน่งการติดฉลาก',
    tagPositionTip: '(เครื่องติดฉลากต้องเติมในพิกัด X/Y ด้านล่าง)',
    Xposition: 'พิกัด x',
    Yposition: 'Y Coordinates',
    genPlankSuffix: 'ฉลากบอร์ดขนาดใหญ่สร้างชื่อไฟล์ต่อท้าย',
    arrangeMirror: 'Tag Image Mirror',
    drillPlankBase: 'การขุดเจาะห้าหกด้านและการอ้างอิงแผ่น',
    suitAuto: 'การจัดเรียงแท็กปรับให้เข้ากับการโหลดและขนถ่ายอัตโนมัติ:',
    colorLabel: 'สร้างแท็กสี',
    up: 'ขึ้น',
    down: 'ลง',
    default: 'ค่าเริ่มต้น',
    printSetting: 'การตั้งค่าการพิมพ์',
    oneToMore: 'หลายหน้าต่อภาพ',
    perPage: 'แต่ละ',
    coloumn: 'รายการ',
    tagMargin: 'ช่องว่างฉลาก',
    coloumnMargin: 'ระยะห่างคอลัมน์',
    specialMark: 'เครื่องหมายพิเศษ',
    opacity: 'ความโปร่งใส',
    suite: 'ปรับตัวได้',
    svgListTip: 'ไม่เปิดใช้งานแท็กบอร์ดรุ่นโปรดตรวจสอบทางด้านซ้าย',
    size: 'ขนาด',
    lightSide: 'ขอบของแสง',
    locFrontSide: 'ด้านหน้าของตู้',
    openSide: 'ทิศทางการเปิดประตู',
    extraTip:
      'เคล็ดลับ: เมื่อคุณใช้การพิมพ์โดยตรงเป็นครั้งแรกโปรดเพิ่มแผ่นกระดาษที่มีขนาดและความยาวและความกว้างเท่ากันกับที่ใช้ในการตั้งค่าเครื่องพิมพ์จากนั้นใช้กระดาษใหม่เพื่อพิมพ์ (ตัวอย่าง: ขนาดกระดาษดั้งเดิม 60 × 40 ขนาดกระดาษใหม่ 40 × 60)',
    noMore: 'ไม่มีอีกต่อไป',
    prepareData: 'เตรียมข้อมูล ...',
    genTagFile:
      'เมื่อสร้างการสร้างแท็กโปรดอย่าทำการสลับเพจลดเบราว์เซอร์ย่อเลื่อนแท็กขึ้นและลง ฯลฯ !',
    waitGen: 'โปรดรอให้แท็กโหลดก่อนดาวน์โหลด',
    tempLoading: 'เทมเพลตแท็กกำลังโหลดโปรดรอ',
    front: 'ซึ่งไปข้างหน้า',
    open: 'เปิด',
    toEdit: 'ไปแก้ไข',
    simplifyOneCodeTip:
      'หมายเหตุ: แอตทริบิวต์บาร์โค้ดที่ตั้งค่าโดยเทมเพลตแท็กนี้ไม่รองรับการใช้ฟังก์ชัน "บาร์โค้ดแบบง่าย" โปรดแก้ไขแอตทริบิวต์บาร์โค้ด (แอตทริบิวต์บาร์โค้ดที่แนะนำ: Code128)',
    plankTemp: 'เทมเพลตฉลากบอร์ดขนาดเล็ก',
    surplusTemp: 'เทมเพลตฉลากวัสดุที่เหลืออยู่',
    tempNamePlaceHolder: 'กรุณากรอกชื่อเทมเพลต',
    editTip:
      'คุณสามารถแก้ไขเทมเพลตฉลากได้โดยการลากปุ่มซ้ายไปยังกระดาษฉลากด้านขวาตามความต้องการของคุณ',
    oneCode: 'บาร์โค้ด',
    image: 'ภาพ',
    editPage: {
      title: 'การแก้ไขเทมเพลตแท็ก',
      reset: 'กู้คืนค่าเริ่มต้น',
      exit: 'ออกจากการแก้ไข',
      tagInfo: 'แท็กข้อมูล',
      tagName: 'ชื่อแท็ก',
      tagSize: 'ขนาดฉลาก',
      tagData: 'แท็กข้อมูล',
      dragTip: 'กดปุ่มเมาส์ซ้ายค้างไว้เพื่อลากฉลากข้อมูล',
      dataTip: 'โปรดเลือกข้อมูลข้อมูลในแถบคุณสมบัติทางด้านขวา',
      text: 'ข้อความ',
      dataSource: 'ข้อมูลข้อมูล',
      graph: 'กราฟิก',
      typograpyPic: 'การเรียงพิมพ์',
      barCode: 'บาร์โค้ด',
      QRCode: 'รหัส QR',
      plankMark: 'การทำเครื่องหมายจาน',
      holeSlotPic: 'ไดอะแกรมช่องเสียบ',
      holeSlotPicZoom: 'การซูมไดอะแกรมช่องเสียบ',
      rotateDeg: 'มุมหมุน',
      colorDeep: 'ความลึกสี',
      surplusMark: 'เครื่องหมายวัสดุที่เหลืออยู่',
      surplusSizePic: 'ไดอะแกรมขนาดวัสดุที่เหลืออยู่',
      dataSize: 'ขนาดข้อมูล',
      extraList: 'รายการเพิ่มเติม',
      QRCodeFormat: 'รูปแบบรหัส QR',
      formatQR: 'รูปแบบ QR',
      formatDM: 'รูปแบบ DM',
      locIndex: 'หมายเลขซีเรียล',
      plankFrontSign: 'เครื่องหมายแผง',
      genNCSuffix: 'สร้างคำต่อท้าย NC',
      table: 'แผ่น',
      inputPlaceholder: 'โปรดคลิกสองครั้งเพื่อป้อนข้อความ',
      attribute: 'คุณสมบัติ',
      fontFamily: 'ประเภทตัวอักษร',
      fontSize: 'ขนาดตัวอักษร',
      fontWeight: 'ตัวอักษรหนา',
      autoRow: 'การห่อเส้นอัตโนมัติ',
      rowMargin: 'ระยะห่างของเส้น',
      autoFontSize: 'ปรับขนาดฟอนต์ล้นโดยอัตโนมัติ',
      statisticsDimension: 'มิติทางสถิติ',
      noLimit: 'ไม่ จำกัด',
      sameOrderNo: 'คำสั่งเดียวกัน',
      sameRoom: 'ห้องเดียวกัน',
      sameWardrobe: 'ตู้เดียวกัน',
      alignWay: 'การจัดตำแหน่ง',
      JustifyLeft: 'จัดแนวซ้าย',
      JustifyRight: 'จัดตำแหน่งขวา',
      JustifyCenter: 'จัดตำแหน่งศูนย์',
      otherOpration: 'การดำเนินการอื่น ๆ',
      attributeReset: 'รีเซ็ตพารามิเตอร์',
      barCodeSize: 'ขนาดบาร์โค้ด',
      installPic: 'แผนภาพการติดตั้ง',
      plankCode2: 'บอร์ดบาร์โค้ด (Nomark)',
      frontCode: 'รหัสก๋วยเตี๋ยวบวก',
      backCode: 'รหัสก๋วยเตี๋ยวย้อนกลับ',
      orderRemark: 'บันทึกการสั่งซื้อ',
      NCFileName: 'ตัดชื่อไฟล์',
      NCFileNameTip: 'อย่ารวมภาษาจีนในชื่อไฟล์และความยาวต้องไม่เกิน 10 หลัก',
      showOpenSide: 'แสดงทิศทางการเปิดประตู',
      limitFixed: 'จำกัด จำนวนจุดทศนิยม',
      showFrontHS: 'แสดงช่องด้านหน้า',
      showBackHS: 'แสดงสล็อตใบหน้าย้อนกลับ',
      showPlankSize: 'ขนาดแผงแสดง',
      tempNamePlaceholder: 'กรุณากรอกชื่อแท็ก',
      deleteAreaTip: 'โปรดเลือกบล็อกเพื่อลบ',
      noHoleSlot: 'ไม่มีช่องรูแสดงบาร์โค้ด',
      rotate: 'บาร์โค้ดหมุน',
      barcodeAtrribute: 'แอตทริบิวต์บาร์โค้ด',
      imgSize: 'ขนาดภาพ',
      noEdgeDisplay: 'ขอบคือ 0 และจะไม่แสดง',
      allEdgeNoDisplay: 'การปิดกั้นขอบไม่แสดง',
      imgRotate: 'การหมุนภาพ',
      circleD: 'เส้นผ่านศูนย์กลางเฟรมวงกลม',
      roomNo: 'หมายเลขห้องพัก',
      roomOrder: 'หมายเลขตู้ (จัดเรียงตามห้อง)',
      alphabetically: 'จัดเรียงด้วยตัวอักษร',
      numberOrder: 'เรียงลำดับตามหมายเลข',
      specialMarkup: 'สัญลักษณ์เครื่องหมายพิเศษ',
    },
    autoSaveToHistory: 'บันทึกไว้ในประวัติศาสตร์โดยอัตโนมัติ!',
    firstPrintTip:
      'เมื่อคุณใช้การพิมพ์โดยตรงเป็นครั้งแรกให้เพิ่มแผ่นกระดาษที่มีขนาดและความยาวและความกว้างเท่ากันกับที่ใช้ในการตั้งค่าเครื่องพิมพ์แล้วพิมพ์ด้วยกระดาษใหม่',
    tagDownloading: 'มีการดาวน์โหลดแท็ก ...',
  },
  fileManagerPage: {
    notOpeFolderPermission: 'การดำเนินงานที่ไม่ได้รับอนุญาตของไดเรกทอรีนี้',
    notFindFolder: 'ไดเรกทอรีนี้ไม่สามารถตรวจพบได้ในไคลเอนต์ปัจจุบัน',
    productLineUniversal: 'สายการผลิตทั้งหมดเป็นสากล',
    effectCondition:
      'การตั้งค่านี้จะต้องมีการพิมพ์ซ้ำอีกครั้งก่อนที่จะมีผลหลังจากเปลี่ยน',
    addPath: 'เพิ่มเส้นทาง',
    downloadPositionChangeByType:
      'ตำแหน่งดาวน์โหลดของไฟล์ที่สร้างขึ้นถูกเปลี่ยนเป็น {type}',
    isDeleteFolder: 'คุณแน่ใจว่าจะลบตำแหน่งที่เก็บไฟล์ที่ใช้แล้วหรือไม่?',
    netWorkIsNotSafe:
      'สภาพแวดล้อมเครือข่ายของคุณดูเหมือนไม่ปลอดภัยและเราไม่สามารถเปิดใช้งานคุณสมบัตินี้ได้คุณสามารถตอบกลับเราได้',
    NotSupportBrowse:
      'เบราว์เซอร์ของคุณยังไม่รองรับคุณสมบัตินี้ โปรดดาวน์โหลดเวอร์ชันล่าสุดของ <a href = "https://www.microsoft.com/zh-cn/edge/download" target = "_ blank"> edge </a> เพื่อใช้เบราว์เซอร์',
    notSupportDisk:
      'ไม่สามารถเลือกไดเรกทอรีระดับระบบเช่น [เดสก์ท็อป] [C ไดรฟ์]',
    useFolderDownload: 'ไฟล์การผลิตจะถูกดาวน์โหลดในรูปแบบของการบีบอัด',
    downloadPosition: 'ดาวน์โหลดตำแหน่งไฟล์',
    changeUseFolder: 'เปลี่ยน',
    folderNameSetting: 'การตั้งค่าการตั้งชื่อโฟลเดอร์',
    mainFolder: 'โฟลเดอร์ระดับ 1',
    exportNameSetting: 'ส่งออกไฟล์ประมวลผลเพื่อตั้งชื่อตามหมายเลขคำสั่งซื้อ',
    fileMax: 'ชื่อไฟล์สูงสุดคือ',
    char: 'อักขระ',
    overTip:
      'ส่วนที่มากเกินไปจะไม่ปรากฏขึ้น (แพ็คเกจการบีบอัดภาพแท็กและไฟล์ JSON จะเป็นไปตามชื่อโฟลเดอร์ระดับแรก)',
    example: 'ตัวอย่าง',
    engravingFolder: 'โฟลเดอร์เครื่องแกะสลัก',
    electronicSawFolder: 'โฟลเดอร์เลื่อยอิเล็กทรอนิกส์',
    labellingFolder: 'โฟลเดอร์เครื่องฉลาก',
    sideHoleDrillFolder: 'โฟลเดอร์เครื่อง Hole Side',
    fiveDrillFolder: 'โฟลเดอร์สว่านห้าด้าน',
    sixDrillFolder: 'โฟลเดอร์สว่านหกด้าน',
    PTPFolder: 'โฟลเดอร์ PTP',
    labellingTagFolder: 'โฟลเดอร์รูปภาพฉลาก',
    folderMergeSetting: 'การตั้งค่าการผสานโฟลเดอร์',
    folderMerge: 'โฟลเดอร์ผสาน',
    mergeName: 'ชื่อผสาน',
    subMergeFolder: 'รวมเข้ากับโฟลเดอร์ย่อยด้วยความหนาของสีวัสดุ',
    subFolderName: 'ชื่อโฟลเดอร์ย่อย',
    splitChar: 'การแยกอักขระ',
    splitTip: '(ไม่รองรับอักขระอินพุตเช่น /:? <> |.” * ไม่รองรับ)',
    folderAutoAdd: 'จำนวนโฟลเดอร์เพิ่มขึ้นตามค่าเริ่มต้น',
    resetTip: '(รีเซ็ตเป็นค่าเริ่มต้นทุกวัน)',
    emptyErrTip: 'ข้อมูลไม่สามารถว่างเปล่า',
    repeatErrTip: 'ชื่อไฟล์ที่ซ้ำกัน',
    mergeDataTip:
      'สีความหนาความยาวและความกว้างของวัสดุบอร์ดหรือจำนวนไฟล์จะต้องเพิ่มขึ้นโดยอัตโนมัติ',
  },
  moreSettings: {
    generalSetting: 'การตั้งค่าทั่วไป',
    outSyncTip:
      'สร้างหน้าต่างป๊อปอัพเมื่อข้อมูลฟิล์มดั้งเดิมของคลาวด์ไม่ได้ซิงโครไนซ์กับตู้เก็บของ',
    secondarySwitchShow:
      'เนื้อหาที่สองของการควบคุมสวิตช์ในการตั้งค่าเครื่องแกะสลักจะยังคงปรากฏขึ้นหลังจากปิดสวิตช์',
    exPaibanTip: 'สร้างป๊อปอัพพร้อมท์เมื่อใช้การเรียงลำดับการทำรังตรงข้าม',
  },
  lineManager: {
    add: 'เพิ่มสายการผลิต',
    engraving: 'อุปกรณ์ตัด',
    holeSlotEquip: 'อุปกรณ์แปรรูปช่องเสียบหลุม',
    workSetting: 'การตั้งค่าการประมวลผล',
    noUse: 'ไม่เปิดใช้งาน',
    warningTip:
      '* หมายเหตุ: หลังจากตั้งค่าฟังก์ชั่นต่อไปนี้โปรดอย่าบันทึกบนหน้าอุปกรณ์เชื่อมต่ออุปกรณ์ไคลเอนต์ตู้ในอนาคตเพื่อหลีกเลี่ยงการรีเซ็ตพารามิเตอร์!',
    engravingSetting: 'การตั้งค่าเครื่องตัด',
    workWay: 'วิธีการประมวลผล',
    cutedSpecial: 'ช่องโหว่พิเศษสำหรับการประมวลผล:',
    noWork: 'ตัวเลือกนี้ไม่มีผลกับเทมเพลตเริ่มต้นของการฝึกซ้อมห้า/หกด้าน',
    onlyCut: 'ตัดออกเท่านั้น',
    onlyFrontHoleSlot: 'เฉพาะร่องหน้าด้านหน้าของจาน',
    onlyHoleSlot: 'เฉพาะด้านหน้าและด้านหลังร่องของชิ้นส่วนแผ่น',
    sideHoleSetting: 'การตั้งค่าเครื่อง Hole ด้านข้าง',
    cutHoleSlot: 'ร่องหลุมแปรรูป',
    minSize: 'ขนาดสั้น',
    maxSize: 'ขนาดด้านยาว',
    minSize2: 'ช่องหลุมทั้งหมดและชิ้นส่วนที่มีรูปร่างพิเศษบนแผ่น MM',
    singleHole: 'หลุมเดียว',
    singleSlot: 'ช่องเดี่ยว',
    diameterRange: 'ช่วงเส้นผ่านศูนย์กลางของรู',
    hDeepRange: 'ช่วงความลึกของรู',
    widthRange: 'ช่วงความกว้างของสล็อต',
    sDeepRange: 'ช่วงความลึกของร่อง',
    to: 'มาถึง',
    specialHS: 'ช่องโหว่พิเศษและรูปร่างพิเศษ',
    allowRoll: 'อนุญาตให้เครื่องตัดพิมพ์ซ้ำและประมวลผลร่องรูพิเศษ',
    engravingPath: 'ประมวลผลชิ้นส่วนแผ่นบนเครื่องตัด',
    drillSetting: 'การตั้งค่าสว่านห้าหกหก',
    engravingCuted: 'สล็อตรูที่ประมวลผลบนเครื่องตัดจะถูกเจาะในด้านที่ห้า/หก',
    noGen: 'ไม่ได้สร้าง',
    genCut: 'สร้างและดำเนินการ',
    genNoCut: 'สร้าง แต่ไม่ได้ดำเนินการ',
    cutTip:
      'หมายเหตุ: ตัวเลือกนี้มีผลเฉพาะสำหรับไฟล์สว่านห้าและหกด้านที่กำหนดเอง',
    maxShortSize: 'ขนาดสั้นที่สุด',
    maxLongSize: 'ขนาดด้านความยาวสูงสุด',
    normalSlot: 'ช่องปกติ',
    specialSlot: 'ช่องพิเศษ',
    combinSetting: 'การตั้งค่าอุปกรณ์รวมกัน',
    allowShortSize: 'ให้ขนาดสั้น',
    equipment1: 'อุปกรณ์หนึ่ง',
    equipment2: 'อุปกรณ์ 2',
    noSameTip: 'หมายเหตุ: ไม่สามารถเลือกได้เหมือนกันสำหรับอุปกรณ์ทั้งสอง',
    workRange: 'ช่วงสล็อตรูอุปกรณ์อุปกรณ์',
    rangeWarning:
      'หมายเหตุ: เฉพาะช่วงการประมวลผลของอุปกรณ์ที่สามารถตั้งค่าได้และช่วงการประมวลผลทั้งหมดของอุปกรณ์หนึ่งจะถูกประมวลผลบนอุปกรณ์ที่สอง กรุณาใช้งานด้วยความระมัดระวัง!',
    deleteWarning:
      'หลังจากลบสายการผลิตจะไม่สามารถกู้คืนได้ (การตั้งค่าทั้งหมดภายใต้สายการผลิตจะถูกลบ) คุณแน่ใจว่าจะลบสายการผลิตได้หรือไม่?',
    top: 'สูงสุด',
    inputCheck: '{msg} ค่าต่ำสุดไม่สามารถมากกว่าค่าสูงสุด',
    lockTip: 'สายการผลิตถูกล็อคการดำเนินการเป็นสิ่งต้องห้าม!',
    reNameTip: 'ไม่สามารถแก้ไขชื่อของสายการผลิตเริ่มต้นได้',
    permissonTip: 'ข้อมูลตัวตนหมดอายุโปรดเปิดหน้านี้จากตู้อีกครั้ง!',
    permissonErr: 'ยังไม่มีการอนุญาตดังกล่าวโปรดอัปเกรดเวอร์ชันบัญชี!',
    permissionOneMacErr:
      'จำนวนสายการผลิต all-in-one ที่คุณสามารถใช้ได้คือ {idx} หากคุณต้องการเพิ่มโปรดติดต่อพนักงานขาย!',
    newLine: 'สร้างสายการผลิตใหม่ {idx}',
    deleteCheck: 'ไม่สามารถลบทั้งหมดได้',
    deving: 'อยู่ระหว่างการพัฒนา',
    combinTip: 'โปรดคลิกที่สิ่งต่อไปนี้เพื่อรวมการตั้งค่าอุปกรณ์เพื่อตั้งค่า',
    noUseEquip: 'ไม่เปิดใช้งานอุปกรณ์ประมวลผล',
    equipmentSelectTip:
      'เมื่อเลือกเครื่องตัดสำหรับอุปกรณ์รวมเครื่องตัดไม่สามารถเลือกเลื่อยอิเล็กทรอนิกส์ได้',
    lineLoading: 'ข้อมูลกำลังโหลด',
    strenchType: 'ประเภทสล็อตเส้นตรง',
    aioProcessTip:
      'คุณได้เปิดใช้งานอุปกรณ์ "all-in-one" และวิธีการประมวลผลคือการประมวลผลร่องด้านหน้าและด้านหลังของชิ้นส่วนแผ่นตามค่าเริ่มต้น',
    aioProcessTip2:
      'ร่องรูพิเศษที่ประมวลผลในเครื่องแกะสลักหลังจากเปิดใช้งานเครื่อง all-in-one',
    aioProcessTip3:
      'หมายเหตุ: ฟังก์ชั่นนี้เข้ากันได้กับเครื่องแกะสลักที่เปิดใช้งานการแลกเปลี่ยนแกน XY โปรดใช้ด้วยความระมัดระวัง',
    specialHoleTip:
      'หมายเหตุ: เมื่อใช้ฟังก์ชั่นด้านบนของ "ช่องโหว่พิเศษที่ประมวลผล" โปรดตั้งค่า "ผ่านวิธีการเจาะรู" และ "ผ่านวิธีการเจาะช่องสล็อต" ที่จะเจาะในครั้งเดียว',
    specialHoleTip2:
      'หมายเหตุ: เมื่อใช้ฟังก์ชั่น "ช่องโหว่พิเศษที่ประมวลผลข้างต้นข้างต้นโปรดตั้งค่าเครื่องแกะสลัก" ด้วยวิธีการขุดเจาะรู "และ" ผ่านวิธีการขุดเจาะร่อง "ที่จะเจาะในครั้งเดียว',
    fiveSixNotTake:
      'ตัวเลือกนี้ไม่มีผลกับเทมเพลตเริ่มต้นของการฝึกซ้อมห้า/หกด้าน',
    onlyFrontSide: 'ดำเนินการด้านหน้าเท่านั้น',
    onlyBackSide: 'ประมวลผลด้านย้อนกลับเท่านั้น',
    less: 'น้อยกว่า',
    equal: 'เท่ากัน',
    greater: 'สูงกว่า',
    or: 'หรือ',
    and: 'และ',
    bothFrontBack: 'ประมวลผลด้านหน้าและด้านหลัง',
    shareDataErrTip: 'ข้อมูลชิ้นนี้ใช้โดย {operatorphone}, ip: {actionip}',
    restoreParams: 'กู้คืนพารามิเตอร์',
    loadMore: 'โหลดมากขึ้น',
  },
  cuttingDock: {
    title: 'ตัดการเชื่อมต่อ',
    other: {
      title: 'อื่น',
      tip: '(หมายเหตุ: อุปกรณ์สถานีเดียวต้องใช้รหัสโหลดอัตโนมัติเดียวกันเพื่อเติมทั้งสถานี 1 และสถานี 2)',
    },
    autoStockCode: 'รหัสการโหลดและขนถ่ายอัตโนมัติ',
    stationStart: 'เวิร์กสเตชันเริ่มต้นขึ้น',
    stationStartCode1: 'เวิร์กสเตชัน 1 รหัสเริ่มต้น',
    stationStartCode1Placeholder: 'ป้อนรหัสเริ่มต้นของเวิร์กสเตชัน 1',
    stationStartCode2: 'เวิร์กสเตชัน 2 รหัสเริ่มต้น',
    stationStartCode2Placeholder: 'ป้อนรหัสเริ่มต้นของเวิร์กสเตชัน 2',
    stationEnd: 'เวิร์กสเตชันสิ้นสุดลง',
    stationEndCode1: 'เวิร์กสเตชัน 1 รหัสปลาย',
    stationEndCode1Placeholder: 'ป้อนรหัสสิ้นสุดของเวิร์กสเตชัน 1',
    stationEndCode2: 'เวิร์กสเตชัน 2 รหัสจบ',
    stationEndCode2Placeholder: 'ป้อนรหัสสิ้นสุดของเวิร์กสเตชัน 2',
    frontBackStart: 'เริ่มต้นที่ด้านหน้าและด้านหลัง',
    frontBackEnd: 'ด้านหน้าและด้านหลัง',
    frontStartCode: 'รหัสเริ่มต้น',
    frontStartCodePlaceholder: 'ป้อนรหัสเริ่มต้น',
    frontEndCode: 'รหัสส่วนหน้า',
    frontEndCodePlaceholder: 'ป้อนรหัสปลายด้านหน้า',
    backStartCode: 'รหัสเริ่มย้อนกลับ',
    backStartCodePlaceholder: 'ป้อนรหัสเริ่มย้อนกลับ',
    backEndCode: 'รหัสปลายย้อนกลับ',
    backEndCodePlaceholder: 'ป้อนรหัสปลายย้อนกลับ',
    plankStart: 'จานเปิดอยู่',
    plankEnd: 'จานถูกพลิกคว่ำ',
    plankRollCode1: 'เวิร์กสเตชัน 1 รหัสพลิก',
    plankRollCode1Placeholder: 'ป้อนรหัสการทำงาน 1',
    plankRollCode2: 'เวิร์กสเตชัน 2 รหัสพลิก',
    plankRollCode2Placeholder: 'ป้อนรหัส FLIP WORK STATION 2',
    autoBaitingCode: 'รหัสคายประจุอัตโนมัติ',
    autoBaitingCode1: 'เวิร์กสเตชัน 1 รหัสตัด',
    autoBaitingCode1Placeholder: 'ป้อนรหัสการตัดที่ทำงาน 1',
    autoBaitingCode2: 'เวิร์กสเตชัน 2 รหัสตัด',
    autoBaitingCode2Placeholder: 'ป้อนรหัสการปลดปล่อย 2',
    preStartEquipment: 'อุปกรณ์ก่อนเริ่ม',
    firstKnifePreCode: 'รหัสก่อนเริ่มต้นมีดล่วงหน้าล่วงหน้า',
    secondKnifePreCode: 'มีดที่สองและมีดเริ่มต้นก่อนหน้านี้ล่วงหน้า',
    rowInsert: 'การแทรกเส้น',
    pumpPause: 'ปั๊มสูญญากาศหยุดชั่วคราว',
    pumpPauseTip:
      'หมายเหตุ: อุปกรณ์สถานีเดียวต้องการ "รหัสการโหลดอัตโนมัติ" และ "รหัสความคิดเห็นด้านหน้าแบบสองด้าน" เพื่อเติมทั้งสถานี 1 และสถานี 2',
    pumpPause2Tip:
      'หมายเหตุ: อุปกรณ์สถานีเดียวจำเป็นต้องเติมทั้งสถานี 1 และสถานี 2 ด้วย "รหัสช่วงล่างปั๊มสูญญากาศเดียวกัน" เดียวกัน',
    pumpPauseCode1: 'เวิร์กสเตชัน 1 รหัสช่วงล่างปั๊มสูญญากาศ',
    pumpPauseCode1Placeholder: 'กรุณากรอกรหัสช่วงล่างปั๊มสุญญากาศของสถานี 1',
    pumpPauseCode2: 'เวิร์กสเตชัน 2 รหัสช่วงล่างปั๊มสูญญากาศ',
    pumpPauseCode2Placeholder: 'กรุณากรอกรหัสช่วงล่างปั๊มสุญญากาศของสถานี 2',
    annotationCode1: 'เวิร์กสเตชัน 1 รหัสคำอธิบายประกอบหน้าผากสองด้าน',
    annotationCode1Tip:
      'เหมาะสำหรับอุปกรณ์ที่มีคำแนะนำที่แตกต่างกันเกี่ยวกับด้านหน้าการให้อาหารอัตโนมัติ',
    annotationCode1Placeholder:
      'ป้อนรหัสคำอธิบายประกอบด้านหน้าของการประมวลผลสองด้านสำหรับเวิร์กสเตชัน 1',
    annotationCode2: 'เวิร์กสเตชัน 2 รหัสคำอธิบายประกอบหน้าผากสองด้าน',
    annotationCode2Placeholder:
      'ป้อนรหัสคำอธิบายประกอบด้านหน้าสำหรับการประมวลผลสองด้านของเวิร์กสเตชัน 2',
    upSpeedTip: 'กรุณาเติมความเร็วการเร่งความเร็วของขอบเดียวกัน',
    cutThickTip: 'กรุณาเติมความหนาของการตัดขอบทั่วไป',
    sizeErrTip: 'โปรดตั้งค่าขนาดที่ถูกต้องของเค้าโครงส่งออกในเครื่องฉลาก!',
    offEdgeKnifeDiameter: 'โปรดป้อนเส้นผ่านศูนย์กลางเครื่องมือตัดแต่ง',
    slowDownSpeedTip: 'พารามิเตอร์อินพุตการชะลอการลดมุมไม่ถูกต้อง',
    permissionErr: 'ปัจจุบันเวอร์ชันฟรีไม่สามารถบันทึกได้',
    labellingTempErr: 'โปรดเลือกเทมเพลตเครื่องฉลาก',
    autoRollInputTip:
      'เปิดแท็กเพื่อหมุนโดยอัตโนมัติและคุณต้องป้อนค่าที่ถูกต้อง',
    offlineTempFormat:
      'รูปแบบไฟล์เครื่องฉลากปัจจุบันได้รับการออฟไลน์โปรดยืนยัน',
    autoStockCode1: 'สถานี 1 รหัสโหลดอัตโนมัติ',
    autoStockCode1PlaceHolder: 'ป้อนรหัสการโหลดอัตโนมัติของเวิร์กสเตชัน 1',
    autoStockCode2PlaceHolder: 'ป้อนรหัสการโหลดอัตโนมัติของเวิร์กสเตชัน 2',
    autoStockCode2: 'รหัสการโหลดอัตโนมัติสถานี 2',
    pauseCode: 'รหัสหยุดชั่วคราว',
    pauseCodePlaceHolder: 'ป้อนรหัสหยุดชั่วคราว',
    cuttingParams: {
      title: 'การตัดพารามิเตอร์',
      holeSlotMainSide: 'พื้นผิวที่เข้มข้น',
      holeMainSide: 'พื้นผิวความเข้มข้นของรู',
      slotMainSide: 'พื้นผิวเข้ม',
      workPriority:
        '(ลำดับความสำคัญของฟังก์ชั่น: พื้นผิวที่มีช่องเสียบที่มีรูพรุนตามช่องสล็อตที่ไม่มีรูพรุนบนแผ่นเล็ก ๆ > เครื่องหนีบผมและรูของรูบานพับจะถูกวางไว้ที่ด้านหลังของแผ่นขนาดใหญ่เพื่อประมวลผล> พื้นผิวความเข้มข้นของร่อง> พื้นผิวความเข้มข้นของรู)',
      hingeRollWork:
        'มีการวางรูถ้วยและบานพับไว้ที่ด้านหลังของแผ่นขนาดใหญ่เพื่อประมวลผล',
      followWork:
        'พื้นผิวของสล็อตที่มีรูพรุนจะตามสล็อตที่ไม่มีรูพรุนบนแผ่นเล็ก ๆ',
      autoMatchSide: 'แจกจ่ายร่องใบหน้าที่เป็นบวกและลบตามสัดส่วน',
      backHSRate: 'สัดส่วนสล็อตใบหน้าย้อนกลับ',
      matchTip:
        '(การตั้งค่านี้จะไม่มีผลเมื่อบอร์ดไฮไลต์หรือรูปแบบเค้าโครงที่ช่วยให้การคำนวณรูปร่าง)',
      highGlossSetting: 'ไฮไลต์การตั้งค่าบอร์ด',
      heighGlossFront:
        'เมื่อพิมพ์บอร์ดขนาดเล็กมีพื้นผิวมันวาวสูงและจัดเรียงบนกระดานขนาดใหญ่',
      sideSlotPut: 'วางร่องจานขอบ',
      optimize: 'การเพิ่มประสิทธิภาพการทำงาน',
      sysDefault: 'ค่าเริ่มต้นของระบบ',
      plankInSide: 'ภายในจานขนาดใหญ่',
      plankOutSide: 'นอกจานขนาดใหญ่',
      specialNotWork: 'ฟังก์ชั่นนี้ไม่มีผลกับแผ่นรูปทรงพิเศษ',
      commonSide: 'การตัดขอบทั่วไป',
      plankThickRange: 'ความหนาของจานน้อยกว่าหรือเท่ากับ',
      useCommonSide: 'ใช้การตัดขอบทั่วไป',
      zeroTip:
        '(ช่องว่างเค้าโครงจะต้องเปลี่ยนด้วยตนเองเป็น 0 เมื่อประมวลผลจนกว่าขอบจะถูกตัดให้ยกมีดเพื่อข้ามขอบ)',
      useTip:
        'หลังจากเปิดใช้งานฟังก์ชั่นนี้การตัดลงจะไม่ได้รับการสนับสนุนโปรดให้ความสนใจ!',
      commonSpeed: 'การเร่งความเร็วของขอบทั่วไป',
      highSpeed: 'ความเร็วการโม่ความเร็วสูง',
      min: 'นาที',
      notWorkTip:
        '(เมื่อการประมวลผลแผ่นตรงกับการตัดขอบทั่วไปการเร่งความเร็วขอบทั่วไปจะไม่เกิดขึ้น)',
      changeStartPoint: 'การสลับจุดเริ่มต้นของสถานีคู่',
      changeStartPointTip:
        'หลังจากเปิดใช้งานสวิตช์ของจุดเริ่มต้นสองสถานีแล้วมันก็เข้ากันไม่ได้กับฟังก์ชั่นการแลกเปลี่ยนแกน XY โปรดใช้ด้วยความระมัดระวัง',
      flatOffset: 'เครื่องชดเชยระนาบเครื่องจักร',
      xRowChange: 'การจัดตำแหน่งอ้างอิงพิกัดแกน x',
      bigplankPointChange: 'การปรับการอ้างอิงพิกัดแบบย้อนกลับของแผ่นขนาดใหญ่',
      reverseXRowChange: 'x ประสานความคมชัด',
      changebigplankPointTips:
        'หลังจากเปิดใช้งานฟังก์ชั่นนี้มันจะเข้ากันไม่ได้กับฟังก์ชั่นการสลับจุดเริ่มต้นของจุดเริ่มต้น "XY โปรดใช้ด้วยความระมัดระวัง',
      reverseYRowChange: 'Y Coordinates',
      plankMirrorTip: 'ใช้ฟังก์ชั่นนี้เมื่อทำมิเรอร์เพลตที่ประมวลผลบนตัวป้อน',
      notSameSize: 'เคาน์เตอร์สองสถานีขนาดต่างกัน',
      maxSize1: 'เวิร์กสเตชันขนาดสูงสุดหนึ่งขนาด',
      notSameSizeTip:
        '(หลังจากเปิดใช้งานเคาน์เตอร์คู่ที่แตกต่างกันแล้วมันไม่เข้ากันกับฟังก์ชั่นการรวมไฟล์ Dual-Station โปรดใช้ด้วยความระมัดระวัง)',
      maxSize2: 'เวิร์กสเตชัน 2 ขนาดสูงสุด',
      autoSelectTip:
        '(หากโต๊ะสองโต๊ะของอุปกรณ์สองสถานีมีขนาดเท่ากันฟังก์ชั่นนี้จะถูกเลือกโดยอัตโนมัติเพื่อประมวลผลแผ่นขนาดใหญ่ในสถานีที่สอดคล้องกัน)',
      bigFlatLimit:
        'สถานีขนาดใหญ่ประมวลผลเฉพาะแผ่นขนาดใหญ่ที่ไม่สามารถประมวลผลได้โดยสถานีเล็ก ๆ :',
      surplusSetting: 'การตั้งค่าวัสดุที่เหลืออยู่',
      surplusPosition: 'การจัดวางวัสดุที่เหลืออยู่',
      cutHoleWay: 'การตัดและการเจาะ',
      knifeFlat: 'หน้าตา',
      flat: 'เมซา',
      plankFlat: 'พื้นผิวแผ่นขนาดใหญ่',
      freeHeightZ: 'ความสูง z ในการไม่ได้ใช้งาน',
      relativeHeight: 'สัมพันธ์กับความสูงของพื้นผิวด้านบนของแผ่นขนาดใหญ่',
      cutDeepZ: 'ตัดปริมาณความลึก Z',
      cutDeepH: 'ผ่านวิธีการขุดเจาะรู',
      onceThrough: 'เจาะผ่านทันที',
      rollThrough: 'พลิกหน้าสองครั้ง',
      cutDeepS: 'วิธีเจาะผ่านร่อง',
      plankCutDirection: 'ทิศทางการตัดแผ่นเล็ก ๆ',
      sDirection: 'ตามเข็มนาฬิกา',
      nDirection: 'ทวนเข็มนาฬิกา',
      curveReplenish: 'คำสั่งการแก้ไขอาร์คเพื่อตัดแผ่นอาร์ค',
      curveReplenishTip:
        'ฟังก์ชั่นนี้สามารถใช้ได้เมื่อ serrations ปรากฏในการตัดของแผ่นโค้ง',
      noGen: 'ไม่มีการสร้างคำสั่ง G17/G18/G19',
      arcInstruction: 'คำแนะนำคำแนะนำการแก้ไขอาร์ค',
      example: 'ตัวอย่าง',
      arcExample: 'G17G03 X100 Y100',
      curveCutTip: 'การตัดส่วนโค้งแสดงโดย I และ J',
      coverRate: 'อัตราการตัดซ้อนกันระหว่างร่องสีคือ:',
      roughTip:
        'ใช้ฟังก์ชั่นนี้เมื่อมีเสี้ยนที่เหลืออยู่ภายในร่องเมื่อกัดร่องเครื่องมือ',
      clearCurve: 'ทำความสะอาดมุมโค้งมนโดยอัตโนมัติที่ไม่ได้ดึงระหว่างการกัด:',
      curveUseTip:
        'ฟังก์ชั่นนี้จำเป็นสำหรับการทำความสะอาดมุมโค้งมนเมื่อโม่สล็อต โปรดทราบว่าฟังก์ชั่นนี้ไม่มีผลกับตัวเชื่อมต่อ',
      slotCutWay: 'วิธีการโม่สล็อตเครื่องมือ',
      outToIn: 'โม่สล็อตจากภายนอกไปด้านใน',
      inToOut: 'โม่สล็อตจากด้านในไปด้านนอก',
      zShapProcess: 'ขึ้นและลงช่องโหว่รูปตัว Z สำหรับการลอบ',
      zShapProcessTip:
        'มีประสิทธิภาพเฉพาะสำหรับร่องการกัดร่องด้านหน้าและด้านหลัง',
      zSlotCuttingOverlapRate: 'อัตราการทับซ้อนของการตัดร่องรูปตัว Z คือ',
      slotWorkLimit:
        '(มีผลบังคับใช้เฉพาะเมื่อจำนวนเลเยอร์ของการตัดร่องสีมิลลิ่งคือ> 1)',
      workSpeedOp: 'ความเร็วในการประมวลผลที่ดีที่สุด',
      obliqueCut: 'ตัดขอบตัดลง',
      backOblique: 'ลดการตัดทอนลงในแนวทแยงมุม',
      obliqueSpeed: 'ความเร็วลง',
      arcSpeed: 'ความเร็วการตัดเฉือนโค้ง',
      cuttingPreDistance: 'ออกจากมีดล่วงหน้า',
      backKnife: 'ตัดมีดเพื่อลดความเร็ว',
      openKnifeSpeed: 'ความเร็วในการรวบรวมมีด',
      cutCornerSlow: 'การลดมุมตัด',
      addRow: 'ใหม่',
      bettwenDistance: 'ระยะทางก่อนและหลังมุม',
      cuttingSpeed: 'ความเร็วในการตัด',
      stepCutting: 'การตัดชั้น',
      splitSlot: 'ตัดร่องสองครั้ง',
      splitPlank: 'ตัดจานเล็กสองครั้ง',
      maxWH: 'ความยาวหรือความกว้างมีขนาดเล็กกว่า',
      areaLimit: 'และพื้นที่เล็กกว่า',
      secondDeep: 'ความลึกการตัดครั้งที่สอง',
      splitPlankThick: 'การตัดความหนาของแผ่นที่แตกต่างกัน',
      saveLayeredCuttingTip:
        'ผลรวมของการตัดการตัดทั้งสามชั้นจะต้องเท่ากับความหนาของแผ่น',
      saveLayeredRepeatTip:
        'ไม่แนะนำให้ตั้งค่าข้อมูลเลเยอร์ที่แตกต่างกันสำหรับการตัดและเลเยอร์ของวัสดุเดียวกันด้วยความหนาของคุณลักษณะไฮไลต์ โปรดลบข้อมูลส่วนเกิน!',
      movePlankSetting: 'การตั้งค่าคณะกรรมการต่อต้านการวิ่ง',
      newCutSequence: 'อัลกอริทึมการตัดเวอร์ชันใหม่',
      newCutSequenceText:
        'แรงดูดสูญญากาศที่อยู่ตรงกลางของแผ่นขนาดใหญ่นั้นแข็งแกร่งที่สุดและในที่สุดก็มีจานเล็ก ๆ ในพื้นที่นี้',
      positionTip: 'คลิกที่พื้นที่ที่สอดคล้องกันเพื่อทำการเลือก',
      suctionPosition: 'ตำแหน่งดูดสูญญากาศ',
      strongArea: 'เขตดูดที่แข็งแกร่ง',
      minSuctionPlank: 'แผ่นดูดขั้นต่ำ',
      safeWidth: 'ความกว้าง',
      safeHeight: 'ความยาว',
      safeTip: 'จานจะถูกตัดก่อนหากคุณไม่สามารถดูดซับได้',
      scattered_layout: 'บอร์ดขนาดเล็กเลย์เอาต์กระจัดกระจาย',
      dispersePaibanTipOne:
        '1. หลังจากการเปิดใช้งานอย่างน้อยหนึ่งแผ่นในแต่ละบอร์ดขนาดใหญ่จะเป็นไปตามขนาดที่ดูดซับได้ขั้นต่ำ',
      dispersePaibanTipTwo:
        '2. ให้ความสนใจกับความเป็นไปได้ในการเพิ่มปริมาณแผ่นขนาดใหญ่หลังการใช้งาน',
      totalThickLimit: 'ผลรวมของการตัดทั้งสามชั้นจะต้องเท่ากับความหนาของแผ่น',
      offsetFollowStep: 'การตัดขอบตามด้วยการตัดเลเยอร์',
      longPlankTip1:
        '1. หลังจากเปิดใช้งานฟังก์ชั่นนี้คุณต้องตั้งรหัสช่วงล่างปั๊มสูญญากาศใน "การปล่อยก้น - ปั๊มสูญญากาศหยุดชั่วคราว" มิฉะนั้นปัญหาของการตัดแผ่นยาวและการดัดไม่สามารถแก้ไขได้',
      longPlankTip2:
        '2. หลังจากเปิดหรือปิดฟังก์ชั่นนี้ให้บันทึกบันทึกเลย์เอาต์ในประวัติศาสตร์ เมื่อดาวน์โหลด NC คุณต้องพิมพ์ใหม่อีกครั้ง',
      secondCutting: 'วัตถุตัดทุติยภูมิ',
      normalPlank: 'บอร์ดทั่วไป',
      normalPlankTip:
        'บอร์ดทั่วไป: พาร์ทิชันแนวนอนและแนวตั้งในตัวตู้และประตูและบอร์ดที่ไม่ใช่รถตู้อื่น ๆ เพิ่มโดยตรงในรายการวัสดุ',
      plankLongLimit: 'เมื่อด้านยาวของบอร์ดเกินกว่า',
      plankShortLimit: 'ด้านสั้นเกินกว่า',
      cuttingSplit: 'ตัดสองครั้ง',
      longExpand: 'ปริมาตรการตัดครั้งแรก: ปริมาตรด้านข้างยาว',
      firstExpand: 'ปริมาณการตัดครั้งแรก',
      longExpandTip: 'ระดับเสียงยาว',
      longLimit: 'ด้านยาวเกินกว่า',
      cuttingSplitTip: 'แผ่นยาวจะถูกตัดเป็นสองครั้ง',
      shortExpand: 'ระดับเสียงสั้น ๆ',
      expandTip:
        '(ขนาดของความยาว/สั้นเพิ่มขึ้น x มม. และความยาวและด้านสั้นของแผ่นยาวรูปทรงพิเศษจะเพิ่มขึ้นโดยด้านยาว)',
      pumpOffWay: 'วิธีการปิดเครื่องดูดฝุ่น',
      afterOnePlank: 'หลังจากตัดแผ่นยาวเดียว',
      afterAllPlank: 'หลังจากตัดทั้งจาน',
      numberFixed: 'จำนวนทศนิยมที่สงวนไว้',
      bufferCode: 'การตั้งค่าคำสั่งบัฟเฟอร์',
      layoutGap: 'การตั้งค่าช่องว่างการเรียงพิมพ์',
      layoutGapTip:
        'หมายเหตุ: การตั้งค่าช่องว่างการตั้งครรภ์เป็น 0 อาจทำให้เกิดการเรียงลำดับการทับซ้อนกัน',
      wasteCutting: 'การตัดขยะอัตโนมัติ',
      zkPointCustomize:
        'จุดอ้างอิงการหมุนที่กำหนดเองสำหรับการตัดเฉือนหลุม z-buckle',
      sawSetting: 'เห็นการตั้งค่าการตัด',
      cuttingLimit: 'ขนาดตัดมากกว่า',
      waste: 'เสียจาก',
      wasteSizeLimit: 'ขนาดขั้นต่ำจะต้องไม่น้อยกว่า 100 มม.',
      topLeft: 'ซ้าย, ด้านบน',
      topLeftD: 'มุมซ้ายบน',
      bottomLeft: 'ซ้ายล่าง',
      bottomLeftD: 'มุมซ้ายล่าง',
      topRight: 'ขวาบนสุด',
      topRightD: 'มุมขวาบน',
      bottomRight: 'ขวาล่าง',
      bottomRightD: 'มุมขวาล่าง',
      firstTime: 'อันดับแรก',
      secondTime: 'ครั้งที่สอง',
      thirdTime: 'ครั้งที่สาม',
      startCutting: 'การตัดเริ่มต้น',
      thickRange: 'ความหนา≥',
      longCut: 'การประมวลผล longboard',
      edgeSetting: 'การตั้งค่าการตัดแต่ง',
      cuttingOrder: 'คำสั่งตัด:',
      cuttingOrderTip:
        'วิธีนี้มีความเสี่ยงในการเรียกใช้บอร์ดโปรดใช้ด้วยความระมัดระวัง',
      plankFirst: 'ตัดบอร์ดก่อน',
      wasteFirst: 'ตัดของเสียก่อน',
      offTip: 'เมื่อปิดการตั้งค่าการตัดแต่งจะถูกติดตามในพารามิเตอร์การตัด',
      edgeCutDirection: 'การตัดทิศทาง',
      lGapTip:
        'ตำแหน่งของแผ่นวัสดุที่ตกค้างรูปตัว L ขนาดใหญ่จะถูกปรับโดยอัตโนมัติในทิศทางเส้นทแยงมุมของกระบอกสูบตำแหน่งยกเว้นแผ่นขนาดใหญ่ที่มีวัสดุเหลือเฟือสูงซึ่งเข้ากันไม่ได้กับฟังก์ชั่นการแลกเปลี่ยน XY-Axis',
      onlyLong: 'ด้านเรียวเท่านั้น',
      backCutOrder: 'ลำดับการตัดแต่งย้อนกลับ',
      backFirst: 'ด้านหน้าของด้านหลังเป็นอันดับแรก',
      backSecond: 'ย้อนกลับการประมวลผลและตัดแต่งร่องหน้า',
      frontReCut: 'ปรับด้านหน้า',
      frontReCutTip: '(ทำให้ขอบทรงกระบอกที่ราบรื่นขึ้นเพื่อป้องกันร่องรอย)',
      useNewCut: 'เปิดใช้งานกฎการตัดแต่งขอบรุ่นใหม่',
      newCutTip:
        'ใช้ฟังก์ชั่นนี้เมื่อด้านหน้าของแผ่นขนาดใหญ่สองด้านจะได้รับการซ่อมแซมด้วยขนาดการตัดแต่งอีกหนึ่งขนาด',
      fixedTip:
        'หมายเหตุ: อาจมีการเรียงพิมพ์ที่ทับซ้อนกันเมื่อจำนวนตัวเลขที่เก็บไว้คือ 0 หรือ 1',
      cuttingSlow: 'ตัดบัฟเฟอร์พื้นผิวแผ่น',
      holeSlow: 'การเจาะพื้นผิวพื้นผิว',
      slotSlow: 'ดึงร่อง/รูบัฟเฟอร์พื้นผิวร่อง/การกัด',
      slowCode: 'การใช้คำแนะนำบัฟเฟอร์',
      code: 'คำแนะนำ',
      zkRotatePoint: 'หมุนจุดอ้างอิง',
      zkRotateDir: 'ทิศทางการเพิ่มมุมการหมุน',
      restSizeLimit: 'ขนาดที่เหลือของวัสดุที่เหลือมีขนาดเล็กกว่า',
      useSaw: 'ใช้ใบเลื่อย',
      unGenSawPath: 'ไม่มีเส้นทางตัดใบเลื่อยตามยาว',
      suitMachine: 'เหมาะสำหรับการประมวลผลแผ่นเชื่อมอะลูมิเนียมทั้งหมด',
      plankBack: 'ด้านหลังของแผ่นขนาดใหญ่',
      plankLeft: 'ด้านซ้ายของบอร์ด',
      plankRight: 'ด้านขวาของบอร์ด',
      plankFront: 'ด้านหน้าของแผ่นขนาดใหญ่',
      frontSide: 'ด้านหน้า',
      backSide: 'ด้านหลัง',
      rightSide: 'ด้านขวา',
      leftSide: 'ด้านซ้าย',
      singleMachineLimit:
        'ด้านหน้าของบอร์ดเครื่องจักรกลเดี่ยวสร้างเส้นทางการตัดแต่งและตัดแต่งก่อน',
      singleMachineLimitTip: 'เครื่องทั้งหมดและการตัดใบมีดไม่มีผล',
      setBatchPositionWaring:
        'เคล็ดลับ: หากคุณต้องการยกเลิกการปรับเปลี่ยนอย่างรวดเร็วของโหมดจุดเครื่องมือโปรดยกเลิกการตรวจสอบการปรับเปลี่ยนขวาของปุ่มจุดเครื่องมือ',
      setBatchPosition: 'จุดดัดแปลงแบทช์',
      setBatchPositionAs: 'แก้ไขทิศทางเครื่องมือถัดไปเป็น:',
      activeBatchPosition: '{ActivePartCount} ได้รับการคัดเลือกบอร์ด',
    },
    labelingSettings: {
      title: 'การตั้งค่าการติดฉลาก',
      output: 'ไฟล์การติดฉลากอัตโนมัติเอาต์พุต',
      specification: 'รูปแบบไฟล์ป้ายกำกับ',
      codeFrontGenerate: 'รหัสการโหลดอัตโนมัติถูกสร้างขึ้นก่อนรหัสการติดฉลาก',
      templateOffline: 'เทมเพลตที่ใช้ออฟไลน์!',
      rollLabelling: 'การตั้งค่าการติดฉลากพลิก',
      flipSetting: {
        title: 'การตั้งค่าพลิก',
        engravingFlipProcess:
          'เปิดใช้งานโหมดการติดฉลากการประมวลผลแบบพลิกเครื่องแกะสลัก',
        flipTip: 'เปิดใช้งานฟังก์ชั่นนี้เมื่อต้องเปิดเครื่องแกะสลัก',
      },
      rotateSetting: {
        title: 'การตั้งค่าการหมุนแท็ก',
        rotateTip:
          'เปิดเงื่อนไขการหมุนตามข้อกำหนด หากเปิดใช้งานในเวลาเดียวกันให้เลือกค่าที่ตั้งไว้สำหรับตัวเลือกที่ดีที่สุด',
        horizontal: 'ขอบแนวนอนของแผ่น≤',
        vertical: 'ขอบแนวตั้งของแผ่น≤',
        either: 'แนวนอน ขอบแนวตั้ง≤',
      },
      countertopSetting: {
        title: 'การตั้งค่าเดสก์ท็อป',
        startPoint: 'จุดเริ่มต้นของสถานี',
        upLeft: 'มุมซ้ายบน',
        upRight: 'มุมขวาบน',
        downLeft: 'มุมซ้ายล่าง',
        downRight: 'มุมขวาล่าง',
        baseExchange: 'ประสานงานการจัดตำแหน่งอ้างอิง',
        exchangeX: 'การปรับแกน x',
        exchangeY: 'สวิตช์แกน y',
      },
      offset: 'ออฟเซ็ตพิกัดฉลาก',
      offsetTip:
        'โดยทั่วไปพิกัดของฉลากอยู่ในใจกลางของบอร์ดขนาดเล็กและต้องปรับอุปกรณ์บางอย่าง',
      label_rotate_offset: 'ประสานงานออฟเซ็ตหลังจากการหมุนฉลาก',
      label_rotate_offset_tip:
        'เมื่อความแตกต่างของตำแหน่งออฟเซ็ตระหว่างฉลากก่อนและหลังการหมุนมีขนาดใหญ่จะต้องมีการปรับ',
      label_rotate_offset_err_tip:
        'หลังจากเปิดออฟเซ็ตพิกัดหลังจากหมุนฉลากแล้วจะต้องมีการออฟเซ็ตพิกัดของแกน x และ y',
      imgSetting: {
        title: 'การตั้งค่ารูปภาพการติดฉลาก',
        suffix: 'ภาพต่อท้ายภาพฉลาก:',
        code: 'วิธีการเข้ารหัสภาพ:',
        codeTip:
          'หมายเหตุ: PNG และ JPEG จะปรับเปลี่ยนรูปแบบการเข้ารหัสของแผนภาพเค้าโครงพร้อมกันและรูปแบบเมฆแบบฝังยังไม่รองรับฟังก์ชั่นนี้',
        imgRatio: 'การติดฉลากภาพความละเอียด:',
        slowDown: 'จะลดประสิทธิภาพของการส่งออกภาพแท็ก',
        color: 'มีสีสันจริงๆ',
        bit: 'ขาวดำ',
        resolution: 'การติดฉลากความละเอียดรูปภาพ',
        resolutionPlaceholder: 'ปณิธาน',
        labelPool: 'พูลแท็กข้อมูล',
        dragTip: 'แท็ก Drag-Drop ในพูลแท็ก',
        labelImgName: 'ชื่อรูปภาพที่มีป้ายกำกับ',
        labelImgNameErrTip: 'ชื่อภาพฉลากไม่สามารถว่างเปล่า',
        labelTip1:
          'หมายเหตุ: 1. เมื่อปรับแต่งคุณต้องใช้หนึ่งใน "บาร์โค้ดบอร์ด" หรือ "หมายเลขซีเรียลฉลาก" มิฉะนั้นจะมีปัญหาเรื่องการครอบคลุมภาพ',
        labelTip2: '2. เทมเพลตระบบการติดฉลากไม่มีผล',
        delimiter: 'ตัวคั่น',
        inputTip: 'ป้อนข้อมูล / : ? - - ” * และไม่รองรับอักขระอื่น ๆ',
        numStart: 'ค่าเริ่มต้นของหมายเลขซีเรียลที่มีป้ายกำกับ',
        example: 'ชื่อภาพการติดฉลากที่กำหนดเอง (ตัวอย่าง)',
        divideFolders:
          'แท็กรูปภาพแบ่งออกเป็นโฟลเดอร์ที่แตกต่างกันตามบอร์ดขนาดใหญ่',
        foldersTip1:
          'หมายเหตุ: 1. ฟังก์ชั่นนี้ไม่รองรับการใช้งานกับฟังก์ชั่น "File Merge"',
        foldersTip2:
          '2. ฟังก์ชั่นนี้ยังไม่มีประสิทธิภาพสำหรับการเรียงลำดับเมฆแบบฝังตัว',
        stickIntoFolders:
          'แทรกไฟล์การติดฉลากลงในโฟลเดอร์รูปภาพการติดฉลากของบอร์ดขนาดใหญ่ที่เกี่ยวข้อง',
        stickTip1:
          'หมายเหตุ: 1. ฟังก์ชั่นนี้ต้องใช้ร่วมกับฟังก์ชั่น "ดาวน์โหลด NC และ Tags Export";',
        stickTip2:
          '2. กฎการสร้างไฟล์ในเทมเพลตการติดฉลากที่ใช้จำเป็นต้องเลือก "สร้างไฟล์เดียว" เพื่อให้ได้ผล',
        stickTip3:
          '3. ฟังก์ชั่นนี้ยังไม่มีประสิทธิภาพสำหรับการเรียงลำดับเมฆแบบฝังตัว',
      },
      paibanImgSetting: {
        title: 'การตั้งค่าแผนภาพเรียงลำดับ',
        reparation: 'หมายเลขเลย์เอาต์จะได้รับการชดเชยโดยอัตโนมัติ',
        figure: 'นิดหน่อย',
        inputTip: 'กรุณากรอกหมายเลขตั้งแต่ 1-99',
        paibanImg: {
          export: 'เค้าโครงส่งออก',
          prefix: 'คำนำหน้าการเรียงพิมพ์',
          size: 'ขนาดเรียงพิมพ์',
          format: 'รูปแบบการเรียงพิมพ์',
          directional: 'ทิศทางแผนภาพการเรียงลำดับ',
          vertical: 'แนวตั้ง',
          horizontal: 'ตามแนวนอน',
        },
      },
      autolabel: 'การตั้งค่าลำดับการติดฉลากอัตโนมัติ',
      customTemp: 'มีผลสำหรับเทมเพลตที่กำหนดเองเท่านั้น',
      cuttingLabelOder: 'ฉลากตามคำสั่งของการตัด',
      labelToUp: 'ฉลากจากล่างขึ้นไปด้านบนตามผลลัพธ์ของเค้าโครง',
      labelToDown: 'ฉลากจากบนลงล่างตามผลการจัดวาง',
      labelDodge: 'การหลีกเลี่ยงการติดฉลากอัตโนมัติ',
      settingValue: 'เลือกค่าที่ตั้งไว้',
      enabelTip:
        'หลังจากเปิดเมื่อบอร์ดขนาดเล็กตรงกับค่าการตั้งค่าต่อไปนี้ฉลากจะหมุน',
      fixedHori: 'เลือกขอบแนวนอนของฉลากให้ขนานกับขอบคงที่ของแผ่นเล็ก ๆ',
      fixedHoriTip:
        'มีประสิทธิภาพสำหรับบอร์ดขนาดเล็กที่ไม่มีข้อความเท่านั้น หลังจากเปิดเมื่อมีการติดฉลากฉลากจะหมุนไปเป็นเส้นขนานกับขอบแนวนอนของฉลากและขอบคงที่ของบอร์ดขนาดเล็ก',
      tagHori: 'ติดแท็กเส้นขอบแนวนอน',
    },
    drillSettings: {
      title: 'การขุดเจาะ',
      enable: 'เปิดใช้งานแพ็คเกจการขุดเจาะ',
      toolTable: {
        use: 'เปิดใช้งาน',
        number2: 'Workstation 2 แถวหมายเลขเจาะ',
        number: 'หมายเลขขุดเจาะ',
        diameter: 'เส้นผ่านศูนย์กลางบิตเจาะ',
        xOffset: 'X ออฟเซ็ต',
        yOffset: 'y ชดเชย',
        moveZF: 'Z-Axis ความเร็วในการเคลื่อนที่เร็ว',
        feedRate: 'ความเร็วการตัดเฉือนแกน Z',
        moveF: 'ความเร็วในการเคลื่อนที่เร็วของแกน xy',
        benchmarkDrill: 'สว่านเกณฑ์มาตรฐาน',
        addKnife: 'เพิ่มเครื่องมือ',
        deleteKnife: 'ลบเครื่องมือ',
      },
      prefix: 'คำนำหน้าการขุดเจาะ',
      suffix: 'คำต่อท้ายขุดเจาะ',
      startCode: 'รหัสเริ่มการขุดเจาะ',
      endCode: 'รหัสปลายเจาะ',
      combineKnife: 'คัตเตอร์รวมกัน',
      combineTable: {
        prefix: 'คำนำหน้าหมายเลขเจาะ',
        suffix: 'สว่านหมายเลขต่อท้าย',
        mainHole: 'รูหลัก',
        extraHole: 'จากหลุม',
        notes: 'ความคิดเห็นรวมกัน',
        firstUseNotes: 'เปิดใช้งานความคิดเห็นเป็นครั้งแรก',
        addExtraHole: 'เพิ่มจากหลุม',
        deleteExtraHole: 'ลบออกจากหลุม',
        example: 'ตัวอย่าง',
      },
      processSetting: {
        title: 'การตั้งค่าการประมวลผล',
        mode: 'โหมดการขุดเจาะ',
        processTip:
          'หลังจากเลือกวิธีนี้ฟังก์ชั่นการตัดการขุดเจาะและการขุดเจาะจะไม่มีผล',
        recentHole: 'เจาะรูใกล้ ๆ (เปลี่ยนเครื่องมือด่วน)',
        groupHole:
          'เจาะรูตามหมวดหมู่ (ทำหลุมประเภทแรกให้เสร็จแล้วเปลี่ยนเครื่องมือ)',
      },
      changeBenchDrillTip: 'โปรดป้อนหมายเลขการขุดเจาะก่อนเลือกสิ่งนี้',
    },
    NCExportSettings: {
      title: 'การตั้งค่าการส่งออก NC',
      tab1: 'ตัดการตั้งค่าชื่อไฟล์เครื่อง',
      tab2: 'รวมชื่อไฟล์ของเครื่องตัด',
      tab3: 'หมายเหตุในไฟล์เครื่องตัด',
      tab4: 'ประเภทในไฟล์เครื่องตัด',
      tab5: 'โฟลเดอร์เครื่องตัด',
      tab6: 'การตั้งค่าคำสั่งประมวลผล',
      tab7: 'การตั้งค่ารูปแบบไฟล์',
      fileNameSettings: {
        twoStationAutoSort: 'การเรียงลำดับสองสถานีอัตโนมัติ',
        reparation: 'จำนวนบอร์ดจำนวนมากได้รับการชดเชย',
        figure: 'นิดหน่อย',
        labelerBegin: 'จุดเริ่มต้นของเครื่องฉลาก',
        machineCenter: 'จุดเริ่มต้นของศูนย์เครื่องจักรกล',
        front: 'ด้านหน้า:',
        back: 'ย้อนกลับ:',
        customizeName: 'ชื่อไฟล์ที่กำหนดเอง (รูปแบบ):',
        example: 'ชื่อไฟล์ที่กำหนดเอง (ตัวอย่าง):',
        emptyTip:
          'ตัวระบุไฟล์ด้านหน้าและด้านหลังไม่สามารถว่างเปล่าในเวลาเดียวกัน',
        labelPool: 'พูลแท็กข้อมูล',
        dragTip: 'คุณสามารถลากหรือคลิกเพื่อคัดลอกแท็กในแท็กพูลลงในกล่องอินพุต',
      },
      fileMerge: {
        engravingMerge: 'การรวมกันของไฟล์เครื่องตัด',
        zfMerge: 'ผสานด้านหน้าและด้านหลัง',
        singleMerge: 'การควบรวมกิจการของสถานีเดียว',
        doubleMerge: 'การควบรวมกิจการสองสถานี',
        fEndCode: 'รหัสปลายย้อนกลับ',
        zBeginCode: 'รหัสเริ่มต้น',
        displayfbInfo: 'ชื่อไฟล์แสดงข้อมูลด้านหน้าและด้านหลัง',
        fBeginCode: 'รหัสเริ่มย้อนกลับ',
        zEndCode: 'รหัสส่วนหน้า',
      },
      marginalNotes: {
        addPlankInfo:
          'เพิ่มข้อมูลแผ่นขนาดใหญ่ปัจจุบันที่จุดเริ่มต้นของไฟล์ตัด:',
        plankInfo: 'ข้อมูลบอร์ดขนาดใหญ่:',
        addStationCodeFirst: 'เพิ่มรหัสสถานีทำงานที่จุดเริ่มต้นของไฟล์ตัด:',
        addStationCodeSecond:
          'เพิ่มรหัสสถานีทำงานก่อนเปลี่ยนเครื่องมือเมื่อตัดไฟล์วัสดุ:',
      },
      fileType: {
        zAddtion: 'Z พิกัดส่วนเสริม:',
        addSemicolon: 'เพิ่มเครื่องหมายอัฒภาคที่ส่วนท้ายของแต่ละบรรทัด NC:',
        semicolonTip:
          'ไฟล์การประมวลผลของมือจับฮอร์นจะมีผลเช่นกันหลังจากตรวจสอบ',
        fileName: 'ชื่อไฟล์',
        defaultName: 'ชื่อไฟล์เริ่มต้น',
        dedicatedName: 'ชื่อไฟล์พิเศษสำหรับอุปกรณ์ Starlight',
        xhNotes: 'ความคิดเห็น Starlight E2-9',
        format: 'รูปแบบไฟล์เครื่องแกะสลักเอาต์พุต',
        example: 'ตัวอย่างไฟล์: ไฟล์ NC',
        stillGenerate:
          'เมื่อด้านย้อนกลับไม่ได้ประมวลผลมันยังคงสร้างไฟล์ย้อนกลับ NC ด้วยรหัสปลายย้อนกลับเท่านั้น',
        generateTip: 'คุณสมบัตินี้ไม่รองรับการใช้กับฟังก์ชั่นการรวมไฟล์',
        fileNameErrTip: 'ชื่อไฟล์ไม่สามารถมีอักขระใด ๆ ต่อไปนี้: /: *? -',
      },
      categorize:
        'ตามสีความหนาและวัสดุของบอร์ดขนาดใหญ่มันถูกจัดประเภทเป็นโฟลเดอร์ที่แตกต่างกัน (ไฟล์การติดฉลากเข้าด้วยกัน):',
      simplifyG: 'การประมวลผลตัวย่อคำสั่ง G',
      ZToW: 'รหัสถูกเปลี่ยนจากแกน z เป็นแกน W',
      knives: 'เครื่องมือขุดเจาะ',
      lineBreakSetting: 'การตั้งค่ารูปแบบการแบ่งบรรทัด',
      codeFormatSetting: 'การตั้งค่ารูปแบบการเข้ารหัสไฟล์',
      utf8bom: 'UTF-8 พร้อม BOM',
      oneCodeSetting: 'การตั้งค่าบาร์โค้ดบอร์ด',
      simplifyOneCode: 'สร้างบาร์โค้ดแท็กแบบง่าย ๆ',
      codeLocation: 'การตั้งค่าตำแหน่งรหัส',
      generateLocation: 'รหัสปลายสถานีทำงานถูกสร้างขึ้นก่อนรหัสปลายย้อนกลับ',
      emergencySetting: 'การตั้งค่าฉุกเฉิน',
      show: 'แสดงการตั้งค่าฉุกเฉิน',
      emergencyCode: 'รหัสฉุกเฉิน',
      params: 'พารามิเตอร์',
      emergencyTip:
        'ฟังก์ชั่นเฉพาะบางอย่างสามารถนำไปใช้ชั่วคราวกับความร่วมมือของบุคลากรหลังการขาย',
      holeSetting: 'การตั้งค่าไฟล์',
      holeFileCategorize:
        'การเจาะไฟล์จะถูกวางแยกต่างหากตามคำสั่งซื้อที่แตกต่างกัน',
      holeTip: 'รูปแบบเมฆของไคลเอนต์ตู้ฝังไม่มีผลบังคับใช้',
      specialCodeErr: 'ไม่รองรับอินพุตของอักขระพิเศษ!',
      copySuccess: 'คัดลอกสำเร็จ!',
      copyErr: 'การคัดลอกล้มเหลวโปรดลองลากและวางแท็ก!',
      lengthLimit: 'อินพุตเพียง 15 บิต!',
      plankInfo: 'ข้อมูลบอร์ด',
      otherInfo: 'ข้อมูลเพิ่มเติม',
      uniqueBarCode: 'บาร์โค้ดที่ไม่ซ้ำกัน',
      uniqueBarCodeForPack: 'บาร์โค้ดบอร์ดที่ไม่ซ้ำกัน (สำหรับบรรจุภัณฑ์)',
      machineOrder: 'การตัดชื่อไฟล์เพื่อสร้างหมายเลขลำดับการประมวลผล',
      folderSetting: 'วางโฟลเดอร์เดียวกันกับแผ่นขนาดใหญ่ที่มีขนาดเท่ากัน',
      labelImageINCFolder:
        'รูปภาพการติดฉลากจะถูกวางไว้ในโฟลเดอร์ NC พร้อมไฟล์การติดฉลาก',
      custom: 'ทำเอง',
      folderSettingTip:
        'หมายเหตุ: คุณสมบัตินี้ยังไม่รองรับในการเรียงพิมพ์แบบฝังตัว',
      settingMoveTip: 'ฟังก์ชั่น "{func}" ต้นฉบับถูกย้ายไปยังหน้า "{module}"!',
      fileManagerFunction:
        'การเจาะไฟล์จะถูกวางแยกต่างหากตามไฟล์คำสั่งซื้อที่แตกต่างกัน',
      gotoSetting: 'ไปที่การตั้งค่า',
    },
    aioMachineSetting: {
      openFunction: 'การเชื่อมต่อต้องเปิดใช้งานฟังก์ชั่นต่อไปนี้',
      openCarving: 'เปิดเครื่องแกะสลัก',
      xyReverseFunction: 'ฟังก์ชั่นการแลกเปลี่ยนแกน xy',
      carvingStartSet: 'จุดเริ่มต้นของสถานีแกะสลักถูกตั้งค่าเป็น',
      rightTop: 'มุมขวาบน',
      carvingEdgeSet: 'ด้านหน้าของเครื่องแกะสลักถูกปรับให้เป็น',
      finalEdgeSame: 'เช่นเดียวกับค่าเสร็จสิ้นทั้งหมด',
      beginCode: 'รหัสเริ่มโปรแกรม',
      endCode: 'รหัสจบโปรแกรม',
      drillHole: 'การเจาะรู',
      pullTrough: 'ดึงสล็อต',
      showDataTemp: 'แสดงตัวอย่างข้อมูล',
      dataTemp: 'ตัวอย่างข้อมูล',
      title: 'การตั้งค่า all-in-one',
      outputFile: 'เอาต์พุตไฟล์การตัดเฉือนแบบ all-in-one',
      outputFileTips:
        'เครื่องจักร All-in-One บางยี่ห้อไม่เข้ากันกับ XY Exchange การรวมไฟล์และไม่มีการเจาะรูรูปทรงพิเศษ โปรดใช้พวกเขาด้วยความระมัดระวัง',
      fileType: 'รูปแบบไฟล์',
      select: 'โปรดเลือก',
      diameter: 'เส้นผ่านศูนย์กลางเครื่องมือตัดแต่ง',
      jidongTip:
        'ใช้เครื่อง jidong all-in-one โปรดตั้งจุดเริ่มต้นของสถานีเครื่องแกะสลักไปที่มุมขวาบนและเปิดใช้งานฟังก์ชัน',
      skill: 'การทำงาน',
      clampHandLength: 'ความยาวของแคลมป์มือ (L)',
      clampHandWidth: 'ความกว้างของมือจับ (W)',
      clampHandMargin1: 'คลิปมือ 1 ขอบระยะไกล (H1)',
      clampHandMargin2: 'คลิปมือ 2 ระยะทางไกล (H2)',
      duchClampHand: 'ช่องโหว่หลีกเลี่ยงตำแหน่งที่ยึดด้วยมือ',
      clampHandDirection: 'ทิศทางที่ยึดด้วยมือ',
      left: 'ด้านซ้าย',
      right: 'ด้านขวา',
      top: 'ด้านบน',
      bottom: 'ด้านล่าง',
    },
    oneDragTwo: {
      title: 'เครื่องแกะสลักแบบหนึ่งต่อสอง',
      outputMachineFile: 'เอาต์พุตไฟล์การประมวลผลเครื่องเอื้อสองแผ่นสองแผ่น',
      outputMachineFileTips:
        'หลังจากเปิดใช้งานเครื่องแกะสลักแบบหนึ่งถึงสองแล้วมันก็เข้ากันไม่ได้กับฟังก์ชั่นการรวมไฟล์ของเครื่องตัด โปรดใช้ด้วยความระมัดระวัง',
      fileType: 'รูปแบบไฟล์',
      select: 'โปรดเลือก',
      machineStart: 'จุดเริ่มต้นของสถานี',
      positionClose:
        'เมื่อปิดการตั้งค่าจุดเริ่มต้นของสถานีในพารามิเตอร์การตัดจะถูกติดตาม',
      engravingPlus: 'เครื่องแกะสลักอย่างรวดเร็ว',
      a: '',
    },
    sawEngraving: {
      title: 'เครื่องแกะสลักตัด',
      preLayNav: 'การตั้งค่าเครื่องแกะสลักเลื่อย',
      preLayKnifeInput: 'เส้นผ่านศูนย์กลางของใบมีดเลื่อยข้ามคัตเตอร์',
      enableSaw: 'เปิดใช้งานเครื่องเลื่อยและแกะสลัก',
      cutSet: 'การตั้งค่าการตัด',
      sawUsing: 'การใช้ใบเลื่อย',
      trim: 'การตัดขอบ',
      cutBlank: 'ตัดออก',
      sawUseCond: 'เงื่อนไขสำหรับการใช้ใบมีดเลื่อย',
      hasUsing: 'ใช้ถ้ามี',
      onlyUse: 'ใช้เฉพาะเมื่อ',
      cutMethod: 'วิธีการตัด',
      oneKnifePull: 'ดึงบอร์ดขนาดใหญ่ด้วยมีดหนึ่งใบ',
      onlyOneKnifePull: 'เพียงแค่ดึงด้วยมีดเดียว',
      enableSawThick: 'เปิดใช้งานเค้าโครงความหนาของใบมีด',
      edgedDir: 'ขอบของแผ่นขนาดใหญ่ที่ปิดผนึกและไม่ตัดอีกต่อไป',
      top: 'ข้างบน',
      bottom: 'ด้านล่าง',
      left: 'ซ้าย',
      right: 'ขวา',
      dockTip:
        'การประมวลผลแผ่นเชื่อมก้นต้องใช้การปิดผนึกสี่ด้านของแผ่นสี่เหลี่ยมและการตัดขอบโดยรวมและดำเนินการด้วยตนเองในรูปแบบแถวเดียว',
      tempPaiabanTip:
        'หลังจากเปิดใช้งานการตัดใบมีดไม่ได้รับการสนับสนุนเพื่อเพิ่มแผ่นรูปทรงพิเศษในเค้าโครงโดยตรง หากคุณต้องการเพิ่มโปรดไปที่รายการเปิดเพื่อเพิ่มและพิมพ์!',
      generateCutSurplusPath: 'สร้างเส้นทางตัดขวางโดยตัดเศษวัสดุออกก่อน',
    },
    firstSave:
      'ตรวจพบว่านี่เป็นครั้งแรกที่ผู้ใช้ใช้เลย์เอาต์คลาวด์ หลังจากบันทึกการตั้งค่าโปรดให้ความสนใจว่าทิศทางการตัดแต่งในไฟล์นั้นสมเหตุสมผลเพื่อหลีกเลี่ยงการสูญเสียที่ไม่จำเป็นหรือไม่',
    knives: {
      xOffset1: 'x ประสานงาน',
      yOffset1: 'y ประสานงาน',
      zOffset1: 'Z Offset',
      insert: 'แทรกแถว',
      delete: 'ลบแถว',
      tip: '(หมายเหตุ: หลังจากเสร็จสิ้นการปรับเปลี่ยนหรือปรับข้อมูลนิตยสารเครื่องมือผู้ใช้ที่อยู่ในการผลิตเค้าโครงของคณะรัฐมนตรีจำเป็นต้องรีสตาร์ทไคลเอนต์ตู้เพื่อใช้การเปลี่ยนแปลงล่าสุด!)',
      number: 'หมายเลขซีเรียล',
      name: 'ชื่อ',
      diameter: 'เส้นผ่านศูนย์กลางมีดφ',
      cutting: 'การเปิด',
      use: 'ใช้',
      moveZF: 'Z-Axis ความเร็วในการเคลื่อนที่เร็ว',
      cutZF: 'ความเร็วการตัดเฉือนแกน Z',
      moveF: 'ความเร็วในการเคลื่อนที่เร็วของแกน xy',
      cutF: 'ความเร็วการตัดเฉือนแกน xy',
      startCode: 'เครื่องมือเริ่มต้นเปลี่ยนรหัส',
      stopCode: 'เครื่องมือเปลี่ยนเครื่องมือ',
      startCode2: 'Workstation 2 เครื่องมือเปลี่ยนรหัสเริ่มต้น',
      stopCode2: 'Workstation 2 เครื่องมือเปลี่ยนรหัสสิ้นสุด',
      preStartCode: 'รหัสก่อนเริ่ม',
      punchHoles: 'การเจาะรู',
      pullSlots: 'ดึงสล็อต',
      millHoles: 'โม่',
      millSlots: 'ช่องโหว่',
      sawBlade: 'ใบมีดเห็น',
      doubleSaw:
        'เปิดใช้งานรหัสเริ่มต้นการเปลี่ยนเครื่องมือที่แตกต่างกันสำหรับ Dual-Station',
      singleStation: 'พื้นที่ทำงานเดี่ยว',
      doubleStation: 'เพล็กซ์',
      sawBladeTip:
        'ฟังก์ชั่นนี้ไม่มีผลกับเครื่องแกะสลักแบบหนึ่งต่อสองและเครื่องจักรแบบ all-in-one',
      serialNum: 'หมายเลขซีเรียล',
      sawWidth: 'ความกว้างของมีด',
      sawType: 'เลื่อยใบมีด',
      cutDirection: 'ทิศทางการตัด',
      moveSpeed: 'ความเร็วเปล่า',
      cutSpeed: 'ความเร็วในการประมวลผล',
      endCode: 'เครื่องมือเปลี่ยนเครื่องมือ',
      sawDownCode: 'รหัสของการตัด',
      sawUpCode: 'รหัสมีด',
      abscissa: 'คำนำหน้า abscissor',
      ordinate: 'คำนำหน้า',
      sawHor: 'ใบเลื่อยข้ามตัด',
      sawVer: 'ใบเลื่อยตามยาว',
      towardsZero: 'ตัดเป็น 0 จุดพิกัด',
      awayFromZero: 'ห่างจากการตัดพิกัด 0 จุด',
      diameterDesc: 'เส้นผ่านศูนย์กลางมีดφ',
      xOffset: 'ออฟเซ็ตพิกัดแนวนอน',
      yOffset: 'ออฟเซ็ตพิกัดแนวตั้ง',
      zOffset: 'การยกพิกัดออฟเซ็ต',
      offsetX: 'ชดเชย x',
      offsetY: 'ชดเชย y',
      offsetZ: 'ชดเชย Z',
      offsetNo: 'หมายเลขออฟเซ็ต',
      knifeWidth: 'ความกว้างของมีด',
      drill: 'ออกจากมีด',
      cut: 'กำลังประมวลผล',
      speed: 'ความเร็ว',
      type: 'พิมพ์',
      millHoleSlot: 'โม่/สล็อต',
      rapidFeedSpeed: 'ความเร็วฟีดเร็ว',
    },
  },
  sideHoleMachine: {
    coordSwitch: 'ประสานงานการจัดตำแหน่งอ้างอิง',
    updownCoordSwitch: 'การปรับการอ้างอิงพิกัดการยก',
    knifes: {
      number: 'หมายเลขซีเรียล',
      diameter: 'ความกว้างของมีด',
      type: 'พิมพ์',
      moveSpeed: 'ความเร็วฟีดเร็ว',
      doSpeed: 'ความเร็วในการประมวลผล',
      startCode: 'เครื่องมือเริ่มต้นเปลี่ยนรหัส',
      endCode: 'เครื่องมือเปลี่ยนเครื่องมือ',
    },
    ymSetting: 'การตั้งค่าการเชื่อมต่อ Easy-MU',
    flanking: 'การประมวลผลด้านข้าง',
    frontProcess: 'การประมวลผลด้านหน้า',
    oldSetting: 'การตั้งค่าเวอร์ชันเก่า',
    equipParamsSetting: 'การตั้งค่าพารามิเตอร์อุปกรณ์',
    holeSetting: 'การตั้งค่าการเจาะ',
    raminoSetting: 'การตั้งค่าการเชื่อมต่อ Lamino',
    mortiseTenonSetting: 'การตั้งค่าการเชื่อมต่อเสาหินและเดือย',
    straight: 'โดยตรง',
    eccentric: 'โมเดล Extracentric',
    pbSize: 'ขนาดของแผ่นแตกบนและล่าง',
    coordinateValue: 'ค่าประสานงาน',
    codeFormat: 'รูปแบบการเข้ารหัสไฟล์',
    suffixSetting: 'การตั้งค่าไฟล์ต่อท้าย',
    insertRow: 'แทรกแถว',
    deleteRow: 'ลบแถว',
    equipmentParams: {
      equipPauseCode: 'รหัสหยุดอุปกรณ์ชั่วคราว',
      wiggleCode: 'รหัสสวิง',
      equipStartCode: 'รหัสเริ่มต้นอุปกรณ์',
      flipPauseCode: 'พลิกรหัส',
      equipEndCode: 'รหัสปลายอุปกรณ์',
      equipStartCodePlaceholder: 'ป้อนรหัสเริ่มต้นอุปกรณ์',
      flipPauseCodePlaceholder: 'ป้อนรหัสหยุดชั่วคราว',
      equipEndCodeTip: 'ป้อนรหัสปลายอุปกรณ์',
      sideChangeSetting: 'การตั้งค่าด้านข้าง',
      workTip: 'โดยค่าเริ่มต้นจะเริ่มต้นจากซ้าย①ในทิศทางทวนเข็มนาฬิกา',
      frontTurnCode: 'เริ่มรหัสด้านบน',
      frontTurnEndCode: 'รหัสปลายทางข้างต้น',
      frontTurnCodePlaceholder: 'ป้อนรหัสเริ่มต้นด้านบน',
      frontTurnEndCodePlaceholder: 'ป้อนรหัสท้ายด้านบน',
      leftTurnCode: 'เริ่มรหัสทางด้านซ้าย',
      leftTurnEndCode: 'รหัสสิ้นสุดทางด้านซ้าย',
      leftTurnCodePlaceholder: 'ป้อนรหัสเริ่มด้านซ้าย',
      leftTurnEndCodePlaceholder: 'ป้อนรหัสสิ้นสุดทางด้านซ้าย',
      downTurnCode: 'เริ่มรหัสด้านล่าง',
      downTurnEndCode: 'รหัสสิ้นสุดต่อไปนี้',
      downTurnCodePlaceholder: 'ป้อนรหัสเริ่มต้นต่อไปนี้',
      downTurnEndCodePlaceholder: 'ป้อนรหัสสิ้นสุดต่อไปนี้',
      rightTurnCode: 'เริ่มรหัสทางด้านขวา',
      rightTurnEndCode: 'รหัสสิ้นสุดทางด้านขวา',
      rightTurnCodePlaceholder: 'ป้อนรหัสเริ่มทางด้านขวา',
      rightTurnEndCodePlaceholder: 'ป้อนรหัสสิ้นสุดทางด้านขวา',
      topFlipCode: 'รหัสพลิก - ด้านบน',
      downFlipCode: 'รหัสพลิก - ถัดไป',
      leftFlipCode: 'รหัสพลิก - ซ้าย',
      rightFlipCode: 'รหัสพลิก - ขวา',
      cylinderOutCode: 'รหัสเปิดตัวทรงกระบอก',
      cylinderbackCode: 'รหัสดึงกระบอกสูบ',
      cylinderbackDirective: 'คำแนะนำในการหดตัวของกระบอกสูบ',
      moveDirective: 'ย้ายคำสั่ง',
      moveAxisCode: 'เลื่อนแกน',
      pushAxisCode: 'เพลาดันกระบอกสูบ',
      swingAxisCode: 'เพลาแกว่ง',
      moveCode: 'รหัสมือถือ',
      punchCode: 'รหัสประมวลผล',
      free: 'เดินว่างเปล่า',
      punchPart: 'การประมวลผล',
      cutFaceCode: 'ประมวลผลรหัสเครื่องบิน',
      horVerCutFace: 'ระนาบแนวนอน',
      horCutFace: 'ระนาบข้าม',
      verCutFace: 'ระนาบยกยาว',
    },
    tagPool: 'พูลแท็กข้อมูล',
    dragTip: 'แท็ก Drag-Drop ในพูลแท็ก',
    knives: 'เครื่องมือการประมวลผล:',
    directives: 'คำแนะนำในการประมวลผล:',
    slot: 'การแปรรูปร่องตรง',
    cirWork: 'เครื่องตัดเฉือนโค้ง',
    cirInter: 'การแก้ไขอาร์ค',
    cirOption1: 'ตามเข็มนาฬิกา: G03 ทวนเข็มนาฬิกา: G02',
    cirOption2: 'ตามเข็มนาฬิกา: G02 ทวนเข็มนาฬิกา: G03',
    coordinate: {
      xPointSymbol: 'พิกัดแนวนอน:',
      yPointSymbol: 'พิกัดแนวตั้ง:',
      zPointSymbol: 'การยกพิกัด:',
      upright: 'ค่าบวก',
      negative: 'ค่าลบ',
    },
    encodeFormat: {
      format: 'รูปแบบการเข้ารหัสไฟล์:',
      wrap: 'การตั้งค่ารูปแบบการแบ่งบรรทัด:',
      formatOption1: 'รูปแบบ UTF-8',
      formatOption2: 'รูปแบบ ANSI',
    },
    suffix: 'การตั้งค่าไฟล์ต่อท้าย:',
    enableFP: 'เปิดใช้งานการประมวลผลด้านหน้า',
    slotSetting: 'ดึงการตั้งค่าสล็อต',
    takeUpDirective: 'คำสั่งการรวบรวมมีด',
    workSeparately: 'ร่องไปข้างหน้า/ย้อนกลับจะถูกประมวลผลในสองส่วน',
    secondSlotLong: 'ความยาวสล็อตที่สอง',
    enableOld: 'เปิดใช้งานการตั้งค่าเครื่องหลุมด้านเก่า',
    default: 'การตั้งค่าเริ่มต้น',
    customizable1: 'ปรับแต่งหนึ่ง',
    customizable2: 'กำหนดเองสอง',
    customizable3: 'กำหนดเองสาม',
    customizable4: 'กำหนดเองสี่',
    knifeSetting: 'การตั้งค่าเครื่องมือ',
    soltTip: '(รวมถึงรู/สล็อต)',
    specialSetting: 'การตั้งค่าพิเศษ',
    ramino: 'อะมิโน',
    sun: 'พระมหากษัตริย์',
    please: 'คอยติดตาม',
    designatedKnives: 'มีดกำหนด',
    designated: 'ระบุ',
    workOrder: 'คำสั่งประมวลผล',
    other: 'อื่น',
    nameSetting: 'การตั้งค่าชื่อต่อท้าย',
    repeatErr: 'หมายเลขซ้ำโปรดรีเซ็ต',
  },
  electronicSaw: {
    importMaterial: 'นำเข้ารายการวัตถุดิบ',
    formSetting: 'การตั้งค่าแบบฟอร์มเลื่อยอิเล็กทรอนิกส์',
    fileFormat: 'รูปแบบไฟล์รายการวัสดุรายการวัสดุ:',
    isSmallFile: 'ส่งออกไฟล์รูปภาพบอร์ดขนาดเล็ก:',
    fileGroupRule:
      'ไฟล์เลื่อยอิเล็กทรอนิกส์แบ่งออกเป็นตารางต่าง ๆ ตามสีวัสดุและความหนาของบอร์ด:',
    roomsByCategory: 'ไฟล์เลื่อยอิเล็กทรอนิกส์แบ่งออกเป็นตารางต่าง ๆ ตามห้อง:',
    delimiter: 'ตัวคั่น:',
    fileName: 'ชื่อไฟล์ของ Bill of Material:',
    fineNameTip: 'ชื่อไฟล์ของใบเรียกเก็บเงินไม่สามารถว่างเปล่าได้!',
    fileNamePlaceholder: 'ปรับแต่งบิลวัสดุ',
    outputFileEncoding: 'รูปแบบการเข้ารหัสรายการเอาต์พุต:',
    utf8: 'UTF-8 พร้อม BOM',
    cellTab: 'สร้างแท็บเซลล์:',
    texture: 'การแสดงพื้นผิว:',
    special: 'การแสดงรูปพิเศษ:',
    notSpecial: 'ไม่ใช่คนต่างด้าว',
    genSimpleLabelBarcode: 'สร้างบาร์โค้ดแท็กแบบง่าย ๆ',
    uniqueBarCode: 'บาร์โค้ดที่ไม่ซ้ำกัน',
    uniqueBarCodeTip:
      'ฟังก์ชั่นของการสร้างบาร์โค้ดแท็กรูปแบบง่าย ๆ หลังจากเปิดไม่มีผล',
    customeForm: 'รูปแบบที่กำหนดเอง',
    tagPool: 'พูลแท็กข้อมูล',
    tagPoolTips: 'คุณสามารถลากหรือคลิกเพื่อคัดลอกแท็กในพูล',
    displayHead: 'อย่าแสดงส่วนหัว',
    addTableHead: 'เพิ่มส่วนหัว',
    deleteTableHead: 'ลบส่วนหัวของตาราง',
    generateExample: 'สร้างตัวอย่างแบบฟอร์มเอาต์พุต',
    importExample: 'ตัวอย่างแบบฟอร์มนำเข้า',
    importExampleTip:
      'ตัวอย่างแบบฟอร์มนำเข้าสำหรับการอ้างอิงเลื่อยอิเล็กทรอนิกส์แบบกำหนดเองเท่านั้น',
    importExampleErrTip:
      'รายการวัตถุดิบยังไม่ได้นำเข้าโปรดนำเข้ารายการวัตถุดิบ',
    outputExample: 'ตัวอย่างแบบฟอร์มเอาต์พุต',
    outputExampleTip: 'ตัวอย่างผลลัพธ์ของแบบฟอร์มที่กำหนดเอง',
    outputExampleErrTip:
      'ตัวอย่างแบบฟอร์มเอาต์พุตยังไม่ได้รับการสร้างโปรดสร้างตัวอย่างแบบฟอร์มเอาต์พุต',
    defaultTableHead: 'ส่วนหัวเริ่มต้น',
    deleteHeadErrTip: 'ไม่มีส่วนหัวที่จะลบ!',
    genNonString: 'สร้างประเภทข้อมูลรูปแบบที่ไม่ใช่ข้อความ',
    genNonStringTip:
      'คุณสามารถลองเปิดใช้งานข้อมูลตารางที่สร้างขึ้นหากไม่รู้จักเลื่อยอิเล็กทรอนิกส์',
  },
  lockModal: {
    passwordInput: 'กรุณากรอกรหัสผ่านของคุณ',
    lockPasswordInput: 'กรุณากรอกรหัสผ่านปลดล็อค',
    passwordInputPlaceholder: 'โปรดป้อนรหัสผ่านสี่หลัก',
    editPassword: 'แก้ไขรหัสผ่าน',
    confirmPassword: 'ยืนยันรหัสผ่าน',
    setLockPwd: 'ตั้งค่ารหัสผ่านปลดล็อก',
    pwdFormatErr: 'ข้อผิดพลาดรูปแบบรหัสผ่าน',
    sendMessageTo: 'รหัสการตรวจสอบ SMS จะถูกส่งไปยัง',
    captchaPlaceholder: 'โปรดป้อนรหัสการยืนยัน',
    sendCaptcha: 'ส่งรหัสยืนยัน',
    sendSeccess: 'SMS ส่งสำเร็จ',
    sendFail: 'SMS ล้มเหลวโปรดลองอีกครั้งในภายหลัง',
    updateSeccess: 'การตั้งค่ารหัสผ่านสำเร็จ',
    editSuccess: 'การปรับเปลี่ยนรหัสผ่านสำเร็จ',
    lockSeccess: 'ปลดล็อคสำเร็จ',
    lock: 'ปลดล็อค',
    lockSeccessTip: 'ล็อคสำเร็จ',
    inputErrLimit:
      'จำนวนข้อผิดพลาดปัจจุบันถึงขีด จำกัด โปรดรีเซ็ตรหัสผ่านปลดล็อค!',
  },
  dock56Drill: {
    equipType: 'ประเภทอุปกรณ์',
    equipment: 'อุปกรณ์',
    fiveDock: 'เพชรห้าด้าน',
    sixDock: 'เพชรหกด้าน',
    fileType: 'สร้างประเภทไฟล์',
    templateOffline: 'เทมเพลตที่ใช้ออฟไลน์!',
    showExample: 'แสดงตัวอย่างข้อมูล',
    conNoticeTip: 'ข้อควรระวังในการเชื่อมต่อ:',
    conNoticeTip1:
      '1. เครื่องแกะสลักที่เปิดใช้งาน "XY Axis Swap" จำเป็นต้องใช้เทมเพลตสว่านพิเศษห้าด้านพร้อมการแลกเปลี่ยนด้านหน้าและด้านหลัง',
    conNoticeTip2:
      '2. ในการแสดงหรือประมวลผลส่วนที่มีรูปร่างของแผ่นบนการฝึกซ้อมห้าและหกด้านข้อมูลของชิ้นส่วนรูปทรงจะต้องกำหนดค่าในเทมเพลต',
    conNoticeTip3:
      '3. หลังจากใช้สว่านห้าและหกด้านเป็นครั้งแรกหรือเปลี่ยนเทมเพลตโปรด <span style = "สี:#f00"> สำหรับการผลิตทดลองใช้ตู้ </span> จากนั้นทำการผลิตปกติหลังจากยืนยันว่าถูกต้อง',
    conNoticeTip4:
      '4. ในระหว่างการผลิตทดลองใช้โปรดตรวจสอบอย่างระมัดระวังว่าขนาดความลึกพื้นผิวและที่ตั้งของช่องด้านหน้าและด้านหลังนั้นถูกต้องและหลีกเลี่ยงปัญหาการมิเรอร์ของตู้',
    example: 'ตัวอย่างข้อมูล',
    commonSetting: 'การตั้งค่าพารามิเตอร์ทั่วไป',
    throughHoleSetting: 'ผ่านรูผ่านทั้งสองด้าน',
    throughSlotSetting: 'เปิดช่องทั้งสองด้าน',
    nonHolesNotFiles: 'แผ่นสี่เหลี่ยมไม่มีรูและไม่มีไฟล์เอาต์พุต:',
    frontFileIdentifier: 'การระบุไฟล์ด้านหน้า:',
    oppositeFileIdentifier: 'ตัวระบุไฟล์ย้อนกลับ:',
    oppositeFileIdentifierTips:
      'วิธีการสร้างคือ: รหัสบอร์ด 13 บิตดั้งเดิม + อักขระการระบุตัวตน',
    arcDescription: 'วิธีคำอธิบายส่วนโค้ง',
    linePoints: 'คำอธิบายการปั้นส่วนของเส้น',
    arcCommand: 'คำสั่งคำสั่งอาร์ค',
    slotWidthSplit:
      'ร่องกว้างจะถูกถอดออกเป็นร่องแคบ ๆ หลายตัวสำหรับการประมวลผล:',
    slotSplitWidth: 'ความกว้างของสล็อตเดี่ยวหลังจากถอดชิ้นส่วน:',
    mm: 'หมายเหตุ: วัตถุที่ถอดประกอบเป็นช่องสี่เหลี่ยมปกติ',
    isNotMergeSlot:
      'กระบวนการดึงความกว้างและความลึกที่อยู่ติดกัน: กระบวนการดึงร่อง:',
    slotPunchTip:
      'คุณสมบัตินี้ใช้ได้เฉพาะกับช่องด้านหลังและช่องด้านล่างของลิ้นชักโดยใช้เทมเพลตที่กำหนดเอง',
    sizeRange: 'ขนาดที่อยู่ติดกันน้อยกว่าหรือเท่ากับ',
    enableWorningTip:
      'ฟังก์ชั่นนี้ไม่สามารถเปิดได้ในเวลาเดียวกันกับทิศทางการวางขอบด้านหน้า',
    longDirection: 'ทิศทางการจัดวางด้านข้างยาว',
    horizontal: 'ตามแนวนอน',
    vertical: 'แนวตั้ง',
    slotDirection: 'ร่องเจาะห้า/หกด้านหรือทิศทางการจัดวางรูปทรงพิเศษ',
    default: 'ค่าเริ่มต้นของระบบ',
    frontLeft: 'ด้านหน้าและซ้าย',
    frontRight: 'ด้านหน้าและขวา',
    backLeft: 'ด้านหลังและซ้าย',
    backRight: 'กลับและขวา',
    frontDirection: 'ทิศทางการจัดตำแหน่งขอบด้านหน้า',
    enableTip: 'หมายเหตุ: มีผลเฉพาะสำหรับการเรียงพิมพ์คลาวด์เท่านั้น',
    fixedOn: 'แก้ไขใน:',
    front: 'ก่อน',
    back: 'ด้านหลัง',
    left: 'ซ้าย',
    right: 'ขวา',
    coordinateValue: 'จำนวนตัวเลขที่เก็บไว้ของค่าพิกัด:',
    decimalPlaces: 'นิดหน่อย',
    specialParameter: 'การตั้งค่าพารามิเตอร์พิเศษ',
    output5FDrillSlotKnifeCode: 'หมายเลขเครื่องมือมิลลิ่งสล็อต XML2',
    output5FDrillShapeKnifeCode: 'หมายเลขเครื่องมือการกัด XML2',
    plankTexture: 'พื้นผิวบอร์ด XML2',
    offset: 'การชดเชยเครื่องมือการกัด XML2',
    mprConHoleDir: 'ห้า/หกด้านการขุดเจาะไฟล์ด้านข้างหน้า z พิกัดพื้นผิวอ้างอิง',
    plankTop: 'พื้นผิวด้านบนของแผ่น',
    plankBottom: 'พื้นผิวด้านล่างของแผ่น',
    codeInfo: 'ไฟล์รูปแบบแบนมีข้อมูลรหัส:',
    bomFile: 'ไฟล์รูปแบบ BOM เอาท์พุท:',
    plankHeight: 'คำอธิบายความสูงของแผ่นแผ่น:',
    plankNum: 'คำอธิบายหมายเลขบอร์ด:',
    backZpoint: 'Reverse Groove Z พิกัด:',
    plankThick: 'ความหนาของบอร์ด',
    slotDeep: 'ความลึกของร่อง',
    outputMprFile: 'ประเภทไฟล์',
    oldMPR: 'MPR เวอร์ชันเก่า',
    newMPR: 'MPR เวอร์ชันใหม่',
    mprFileZside: 'ห้า/หกด้านการขุดเจาะไฟล์ด้านข้างหน้า z พิกัดพื้นผิวอ้างอิง',
    fileTypeOffline:
      'ประเภทไฟล์สว่านห้าและหกด้านที่เลือกในปัจจุบันได้รับการออฟไลน์โปรดยืนยัน!',
    permissionErr: 'ข้อมูลประจำตัวหายไปโปรดเปิดหน้านี้อีกครั้งจากคณะรัฐมนตรี!',
    banFileSetting: 'แบนการตั้งค่าไฟล์รูปแบบ',
    banFileCodeInfo: 'ไฟล์รูปแบบแบนมีข้อมูลรหัส:',
    huahuaLine: 'สายเบิร์ชอัตโนมัติ',
  },
  PTP: {
    title: 'PTP',
    outputFileFormat: 'รูปแบบไฟล์เอาต์พุต',
    outputDefaultFileFormat: 'ไฟล์รูปแบบเริ่มต้นเอาต์พุต',
    outputMPRFormat: 'ไฟล์รูปแบบ MPR เอาท์พุท',
    fileFormatSettings: 'การตั้งค่ารูปแบบไฟล์',
    wrapSettings: 'การตั้งค่ารูปแบบการแบ่งบรรทัด:',
    enCodeSettings: 'การตั้งค่ารูปแบบการเข้ารหัสไฟล์:',
    utf8bom: 'UTF-8 พร้อม BOM',
    partCoordinate: 'บอร์ดขนาดเล็กพิกัด',
    xPointSymbol: 'พิกัดแนวนอน:',
    yPointSymbol: 'พิกัดแนวตั้ง:',
    zPointSymbol: 'การยกพิกัด:',
    upRight: 'ค่าบวก',
    negative: 'ค่าลบ',
    plankMirroring: 'กระจกกระดาน',
    plankMirrorSetting: 'การตั้งค่าบอร์ดมิเรอร์:',
    xAxis: 'มิเรอร์แกน x',
    yAxis: 'กระจกแกน y',
    mirrorTip: '(ฟังก์ชั่นนี้ใช้เมื่อทำมิเรอร์บอร์ด)',
    knifeTable: {
      type: 'พิมพ์',
      diameter: 'เส้นผ่านศูนย์กลางφ',
      offsetX: 'ชดเชย x',
      offsetY: 'ชดเชย y',
      offsetZ: 'ชดเชย Z',
      offsetNo: 'หมายเลขออฟเซ็ต',
      drillVelocity: 'ตัด [F] มม./นาที',
      cutVelocity: 'การประมวลผล [F] มม./นาที',
      rotationSeep: 'ความเร็ว [s] r/min',
      insertRow: 'แทรกแถว',
      deleteRow: 'ลบแถว',
    },
    labelPool: 'พูลแท็กข้อมูล',
    dragTip: 'คุณสามารถลากหรือคลิกเพื่อคัดลอกแท็กในแท็กพูลลงในกล่องอินพุต',
    procedure: 'โปรแกรม',
    beginCode: 'รหัสเริ่มโปรแกรม:',
    endCode: 'รหัสสิ้นสุดโปรแกรม:',
    zzdKnifeBeginCode: 'เครื่องมือ Spindle เปลี่ยนรหัสเริ่มต้น:',
    spindleCutter: 'เครื่องมือแกนหมุน',
    zzdKnifeEndCode: 'เครื่องมือ Spindle เปลี่ยนรหัสสิ้นสุด:',
    verticalDrill: 'สว่านแนวตั้ง',
    czzBeginCode: 'รหัสเริ่มต้นการเจาะแนวตั้ง:',
    czzEndCode: 'รหัสปลายเจาะแนวตั้ง:',
    czzKnifeBeginCode: 'เครื่องมือเจาะแนวตั้งเปลี่ยนรหัสเริ่มต้น:',
    czzKnifeEndCode: 'เครื่องมือสว่านแนวตั้งเปลี่ยนรหัสสิ้นสุด:',
    sidetrack: 'การฝึกซ้อมด้านข้าง',
    czBeginCode: 'รหัสเริ่มต้นสว่านด้านข้าง:',
    czEndCode: 'รหัสปลายขุดด้านข้าง:',
    czKnifeBeginCode: 'เครื่องมือสว่านด้านข้างเปลี่ยนรหัสเริ่มต้น:',
    czKnifeEndCode: 'เครื่องมือสว่านด้านข้างเปลี่ยนรหัสสิ้นสุด:',
    pauseCode: 'รหัสหยุดชั่วคราว:',
    zSafeHeight: 'ระยะทางปลอดภัย',
    spindleKnife:
      'เครื่องมือ Spindle และความสูงความปลอดภัยของแนวแนวตั้ง Z-Axis Safety',
    sideDrillZ: 'ความสูงด้านความปลอดภัยของแกน Z-Axis',
    sideDrillXY: 'ระยะทางความปลอดภัยแกน x/y ด้านข้าง',
    auxiliaryFunction: 'ฟังก์ชั่นเสริม',
    xhDedicatedInfo: 'สร้างข้อมูลพิเศษสำหรับอุปกรณ์ Starlight',
    xhCheckBox: 'ข้อมูล:',
    outputSettings: 'การตั้งค่าเอาต์พุต:',
    partExegesis: 'สร้างความคิดเห็นขนาดบอร์ดขนาดเล็กในไฟล์',
    knifeNo: 'หมายเลขเครื่องมือ',
    specailKnifeNo: 'การประมวลผลหมายเลขเครื่องมือรูปทรงพิเศษ:',
    slotKnifeNo: 'หมายเลขเครื่องมือการนั่งร้าน:',
    fileNamePlaceholder: 'ปรับแต่งบิลวัสดุ',
    slotDrill: 'มีดแบบตรง',
  },
  glassEquip: {
    title: 'เครื่องตัดแก้ว',
    fileType: 'ประเภทการประมวลผลไฟล์:',
    sizeReparation: 'การชดเชยขนาดแผ่น:',
    cutPreSpace: 'ระยะห่างที่สงวนไว้สำหรับการตัดแผ่น:',
    divideIntoFolders:
      'แบ่งออกเป็นโฟลเดอร์ที่แตกต่างกันตามสีวัสดุและความหนาของแก้ว:',
  },
  translate: {
    search: 'โปรดป้อนการแปลภาษาจีนหรืออย่างเป็นทางการเพื่อค้นหารายการ',
    en: 'ภาษาอังกฤษ',
    kz: 'คาซัค',
    vi: 'เวียดนาม',
    th: 'ภาษาไทย',
    id: 'ภาษาอินโดนีเซีย',
    ug: 'ภาษาอุยกูร์',
    customTranslate: 'คลิกเพื่อเพิ่มการแปลที่กำหนดเอง',
    customText: 'การแปลที่กำหนดเอง',
    text: 'ชาวจีน',
    targetLang: 'การแปลอย่างเป็นทางการ',
  },
  preLayoutSetting: {
    title: 'การตั้งค่าก่อนประเภท',
    titleTip:
      'คุณสามารถตั้งค่าพารามิเตอร์เลย์เอาต์ก่อนพิมพ์ก่อนจากนั้นเลือกสายการผลิต',
    settingTip:
      'พารามิเตอร์ที่ตั้งไว้ในสายการผลิตจะต้องสอดคล้องกับพารามิเตอร์ pre-type ต่อไปนี้ก่อนที่จะส่งออกไฟล์การผลิตและจะมีประสิทธิภาพสำหรับเครื่องตัดเฉพาะ',
    emptyKnife: 'เครื่องมือที่ไม่ได้กรอก',
    noModify: 'การตั้งค่าไม่ได้รับการแก้ไขและไม่จำเป็นต้องบันทึกไว้',
    reSet: 'การกู้คืน',
    gayPosition: 'สี่เหลี่ยมสีเทาสามารถสลับตำแหน่งของวัสดุตกค้างได้',
    surplusPosition: 'ตำแหน่งปัจจุบันของวัสดุตกค้าง',
    click: 'คลิก',
    grayStart: 'สี่เหลี่ยมสีเทาสามารถสลับจุดเริ่มต้นของสถานี',
    currentStart: 'มันเป็นจุดเริ่มต้นของสถานีปัจจุบัน',
    holeSlotSetting: 'การตั้งค่าการประมวลผลช่องเสียบแบบเส้นตรงและรู',
    knifeDiameter: 'เส้นผ่านศูนย์กลางเครื่องมือตัด',
    currentVersion: 'เวอร์ชันปัจจุบัน',
    on: 'เปิด',
    off: 'การปิด',
    firstShort: 'ปริมาตรขอบสั้นตัดครั้งแรก',
    firstLong: 'โหลดด้านข้างที่มีความยาวครั้งแรก',
    cuttingSplitLong: 'แผ่นยาวจะถูกตัดเป็นสองครั้ง - ด้านยาวเกินกว่า',
    cuttingSplitShort: 'แผ่นยาวจะถูกตัดเป็นสองครั้ง - ด้านสั้นเกินกว่า',
    plankThick: 'ความหนาของแผ่นปกติ≥',
    normalPlank: 'บอร์ดทั่วไป',
    confilictTip: 'มีความขัดแย้งในการตั้งค่า: {ConvilictTotal}',
    selectLine: 'เลือกสายการผลิต',
    selectLinePlaceholder: 'โปรดเลือกสายการผลิต',
    lineConfilictTip: 'มีความขัดแย้งระหว่างการเลือกสายการผลิตและ {line}',
    preWay: 'โซลูชัน pre-typesetting',
    paibanErrTip:
      'เมื่อมีการเปลี่ยนแปลงในการประมวลผลบอร์ดที่ยาว [ขอบยาวและสั้นเพิ่มปริมาณ] มันอาจนำไปสู่ความล้มเหลวของการจัดวาง',
    paibanTip:
      'เค้าโครงนี้มีเครื่องตัดที่กำหนดสำหรับวัสดุแผ่นขนาดใหญ่ดังนั้นจึงจำเป็นต้องพิมพ์ใหม่',
    reLayout: 'จัดขึ้นใหม่ด้วยสายการผลิตปัจจุบัน',
    fetchErr: 'ไม่ได้รับการตั้งค่าก่อนประเภท',
    resetSuccess: 'ฟื้นฟูได้สำเร็จ',
    resetConfirm: 'ยืนยันว่าจะคืนค่าเป็นเวอร์ชันประวัติศาสตร์หรือไม่?',
    prolineName: 'ชื่อสายการผลิต',
    layoutGap: 'ช่องว่างการเรียงพิมพ์',
    generalThickness: 'ความหนาของแผ่นความหนาของแผ่นรอง',
    cutGeneralPlank: 'บอร์ดการตัดทุติยภูมิ',
    cutSingleDoor: 'แผงการตัดสองประตู',
    changeProline: 'สวิตช์สายการผลิต',
    backTip: 'โปรดกลับไปที่สายการผลิตการเลือกเค้าโครงทั่วโลก',
    preLayout: 'พิมพ์ตามรูปแบบเค้าโครงก่อนประเภท',
    preWayEmpty: '(ว่าง) โครงการประเภทก่อน',
    specialConfilictTip:
      'กระบวนการกัดส่วนโค้งด้านล่างมีผลต่อผลการจัดวางและจำเป็นต้องพิมพ์ใหม่อีกครั้ง',
  },
  lunbo: {
    tips: 'เคล็ดลับการดำเนินงาน',
    introduction: 'การแนะนำการทำงาน',
    qtDownloadText:
      'การแจ้งเตือนที่อบอุ่น: หากมีไฟล์ดาวน์โหลดที่ไม่สมบูรณ์โปรดไปที่หน้าเว็บคลาวด์เลย์เอาต์เพื่อดาวน์โหลด!',
  },
}
